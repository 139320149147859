import classNames from "classnames";
import React, { InputHTMLAttributes } from "react";
import { CheckBoxProps } from "@common/types";
import { Text } from "../text";

export const variants = {
  success:
    "text-green-50 bg-green-200 dark:bg-green-300 dark:text-green-700 focus:ring-green-500",
  danger:
    "text-red-50 bg-red-200 dark:bg-red-300 focus:ring-red-500 dark:text-red-500",
  disabled:
    "text-gray-700 bg-gray-100 dark:bg-gray-800 cursor-not-allowed dark:text-gray-700",
  default:
    "text-gray-700 bg-gray-200 dark:bg-gray-600 dark:text-gray-700 focus:ring-primary-500",
};

export const CheckBox = React.forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> &
    CheckBoxProps & { inputClassName?: string }
>(function CheckBox(
  {
    label,
    className,
    inputClassName,
    hint,
    error,
    block,
    success,
    onChange,
    onInputChange,
    icon,
    iconPosition,
    ...props
  },
  ref,
) {
  const errorMessage = error
    ? typeof error === "string"
      ? error
      : typeof error === "object"
        ? error.message
        : false
    : false;

  const variant = error
    ? "danger"
    : success
      ? "success"
      : props.disabled
        ? "disabled"
        : "default";

  return (
    <div>
      <label
        className={classNames(
          "items-center",
          className,
          block ? "flex" : "inline-flex",
        )}
      >
        <input
          type="checkbox"
          className={classNames(
            "rounded border-none dark:ring-offset-gray-800",
            variants[variant],
            inputClassName,
          )}
          ref={ref}
          onChange={(e) => {
            onChange?.(e);
            onInputChange?.(e.currentTarget.checked);
          }}
          {...props}
        />
        {label && (
          <Text
            className="ms-2"
            variant={variant}
            icon={icon}
            iconPosition={iconPosition}
          >
            {label}
          </Text>
        )}
      </label>
      {hint && (
        <Text variant="muted" block className="mt-2">
          {hint}
        </Text>
      )}
      {errorMessage ? (
        <Text variant="danger" className="mt-2 text-xs">
          {errorMessage}
        </Text>
      ) : success ? (
        <Text variant="success" className="mt-2 text-xs">
          {success}
        </Text>
      ) : null}
    </div>
  );
});
