import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { EditorPollSectionProps } from "@common/types";
import { Section } from "@app/elements/container";
import { Button } from "@app/elements/form";
import { Confirm } from "@app/elements/modals";

export default function PollSection({
  poll,
  onEdit,
  onDelete,
}: EditorPollSectionProps) {
  return (
    <Section
      pure
      className="flex w-full items-center justify-center rounded-md border p-4"
    >
      <FontAwesomeIcon icon={regular("poll-people")} />
      <div className="mx-2 flex-1 truncate">
        <b>{__("Poll")}:</b> {poll.question}
      </div>
      {onEdit && onDelete && (
        <div className="ms-auto space-s-2">
          <Button
            padding="none"
            variant="light"
            className="p-2"
            onClick={onEdit}
          >
            <FontAwesomeIcon icon={regular("pen")} />
          </Button>
          <Button
            padding="none"
            variant="light"
            className="p-2"
            onClick={() => {
              Confirm({
                title: __("Are you sure to delete this?"),
                okText: __("Yes, remove it"),
                onOk: onDelete,
              });
            }}
          >
            <FontAwesomeIcon icon={regular("trash")} />
          </Button>
        </div>
      )}
    </Section>
  );
}
