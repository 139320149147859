import {
  InsightsResponse,
  ProcessData,
  backgroundColors,
} from "@common/features/Insights";
import { AccountPost } from "@common/features/Social";
import { __ } from "@common/helpers/i18n";

interface InsightsData {
  growth: { data: Highcharts.Options; summary: number };
  impressions: { data: Highcharts.Options; summary: number };
  mediatype: Highcharts.Options;
  bestHour?: [string, string];

  total: {
    page_follow_total?: number;
    growth?: number;
    average_impressions?: number;
    total_impressions?: number;
    average_post_daily?: number;
    total_posts_sent?: number;
  };

  top_post: AccountPost[];

  emptyData: boolean;
}

export function TelegramSelector<
  TMetric extends string | undefined = undefined,
>(
  _response: InsightsResponse,
  metric?: TMetric,
): TMetric extends string ? Highcharts.Options : InsightsData {
  const data: Partial<InsightsData> = {};

  if (typeof metric === "undefined") {
    data.total = {};
  }
  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }

    switch (response.metric) {
      case "page_follow_growth": {
        const growth = ProcessData.genericMetric(response.data);
        data.growth = {
          data: {
            xAxis: {
              categories: growth.label,
            },
            yAxis: {
              tickPositioner() {
                const maxDeviation = Math.ceil(
                  Math.max(Math.abs(this.max || 0), Math.abs(this.min || 0)),
                );
                const halfMaxDeviation = Math.ceil(maxDeviation / 2);

                return [
                  -maxDeviation,
                  -halfMaxDeviation,
                  0,
                  halfMaxDeviation,
                  maxDeviation,
                ];
              },
            },
            series: [
              {
                data: growth.data,
                type: "column",
                name: __("Telegram"),
                color: backgroundColors.Telegram[1],
                edgeColor: backgroundColors.Telegram[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        if (!metric) {
          data.total!.growth = ProcessData.sumSummary(response.data);
        }
        break;
      }
      case "impressions": {
        const impressions = ProcessData.genericMetric(response.data);

        data.impressions = {
          data: {
            xAxis: {
              categories: impressions.label,
            },
            series: [
              {
                type: "line",
                name: __("Telegram"),
                data: impressions.data,
                color: backgroundColors.Telegram[0],
                negativeColor: backgroundColors.Telegram[1],
                borderColor: backgroundColors.Telegram[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        if (!metric) {
          data.total!.average_impressions = ProcessData.avgSummary(
            impressions.data,
          );
          data.total!.total_impressions = ProcessData.sumSummary(
            impressions.data,
          );
        }
        break;
      }
      case "top_post": {
        data.top_post = response.data;
        break;
      }
      case "num_posts_by_type": {
        const mediatype = ProcessData.numPostsByType(response.data);
        data.mediatype = {
          xAxis: {
            categories: mediatype.label,
          },
          series: [
            {
              type: "column",
              name: __("Image"),
              data: mediatype.data.image,
              color: backgroundColors.Image[0],
              colorAxis: backgroundColors.Image[1],
              borderColor: backgroundColors.Image[1],
            },
            {
              type: "column",
              name: __("Video"),
              data: mediatype.data.video,
              color: backgroundColors.Video[0],
              colorAxis: backgroundColors.Video[1],
              borderColor: backgroundColors.Video[1],
            },
            {
              type: "column",
              name: __("Album"),
              data: mediatype.data.album,
              color: backgroundColors.Album[0],
              colorAxis: backgroundColors.Album[1],
              borderColor: backgroundColors.Album[1],
            },
            {
              type: "column",
              name: __("Story"),
              data: mediatype.data.story,
              color: backgroundColors.Story[0],
              colorAxis: backgroundColors.Story[1],
              borderColor: backgroundColors.Story[1],
            },
          ],
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
        };
        break;
      }
      case "page_follow_total":
      case "total_posts_sent":
      case "average_post_daily":
        data.total![response.metric] = response.data;
        break;
    }
  });
  data.emptyData = _response.insights.length === 0;

  return metric ? Object.values(data)[0] : (data as any);
}
