export type NotificationType = {
  id: number;
  type: "success" | "error" | "warning" | "info" | "default";
  title: string | React.ReactNode;
  message?: string | React.ReactNode;
  priority?: boolean;
  onClose?: () => void;
  timeout?: number | false;
};

class NotificationManager {
  listNotify: NotificationType[] = [];

  callback?: (notifications: NotificationType[]) => void;

  create = (
    type: NotificationType["type"],
    notification: Omit<NotificationType, "id" | "type"> | string,
  ) => {
    const notify: NotificationType =
      typeof notification === "string"
        ? {
            title: notification,
            id: new Date().getTime() + Math.random(),
            type,
          }
        : { ...notification, id: new Date().getTime() + Math.random(), type };
    const listNotify = [...this.listNotify];
    if (notify.priority) {
      listNotify.unshift(notify);
    } else {
      listNotify.push(notify);
    }
    this.listNotify = listNotify;
    this.callback?.(listNotify);
    return notify;
  };

  info = (notification: Omit<NotificationType, "id" | "type"> | string) =>
    this.create("info", notification);

  success = (notification: Omit<NotificationType, "id" | "type"> | string) =>
    this.create("success", notification);

  warning = (notification: Omit<NotificationType, "id" | "type"> | string) =>
    this.create("warning", notification);

  error = (notification: Omit<NotificationType, "id" | "type"> | string) =>
    this.create("error", notification);

  remove = (notification: NotificationType) => {
    this.listNotify = this.listNotify.filter((n) => notification.id !== n.id);
    this.callback?.(this.listNotify);
    notification.onClose?.();
  };
}

const notify = new NotificationManager();

export default notify;
