import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import React, { useCallback } from "react";
import { DndProvider } from "react-dnd-multi-backend";
import Dropzone, { FileRejection } from "react-dropzone";
import { File as FileModel } from "@common/features/File";
import notify from "@common/helpers/NotificationManager";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import {
  FilemanagerEditorSectionProps,
  FilemanagerEditorSectionRef,
} from "@common/types";
import { Section } from "@app/elements/container";
import { Text } from "@app/elements/text";

export const mimePerType: { [key in FileModel["fileType"]]: string[] } = {
  image: [".jpg", ".jpeg", ".png", "image/jpeg", "image/png"],
  video: [".mp4", ".mov", "video/mp4", "video/quicktime"],
  document: [
    ".pdf",
    ".mp3",
    ".rar",
    ".wav",
    ".zip",
    ".mkv",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".aac",
    ".m4a",
    ".wav",
    "application/pdf",
    "audio/mpeg",
    "application/vnd.rar",
    "application/x-rar-compressed",
    "application/zip",
    "application/x-zip-compressed",
    "multipart/x-zip",
    "video/x-matroska",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "audio/aac",
    "audio/mp4",
    "audio/wav",
    "audio/ogg",
  ],
};

const FilemanagerEditorSection = React.forwardRef<
  FilemanagerEditorSectionRef,
  FilemanagerEditorSectionProps
>(function FilemanagerEditorSection(
  { maxSize, allowType, onUpload, children },
  ref,
) {
  const onDrop = async (acceptedFiles: File[]) => {
    const onDrop = (acceptedFiles: File[]) => {
      // if is not album
      const checkType = {
        hasImage: acceptedFiles.some((file) =>
          mimePerType.image.includes(file.type),
        ),
        hasVideo: acceptedFiles.some((file) =>
          mimePerType.video.includes(file.type),
        ),
        hasDocument: acceptedFiles.some((file) =>
          mimePerType.document.includes(file.type),
        ),
      };

      if (acceptedFiles.length > 1) {
        if (checkType.hasDocument) {
          return false;
        }
      }

      return true;
    };

    if (!onDrop(acceptedFiles)) {
      notify.error(__("File type is not allowed"));

      return;
    }

    onUpload(
      acceptedFiles.map((file) => ({
        file,
        previewUrl: URL.createObjectURL(file),
      })),
    );
  };

  const onReject = useCallback(
    (files: FileRejection[]) => {
      if (maxSize)
        files.forEach((file) => {
          if (file.file.size > maxSize) {
            notify.error(__("Max. file size to upload"));
          } else {
            notify.error(__("File type is not allowed"));
          }
        });
    },
    [maxSize],
  );

  return (
    <div className="col-span-2">
      <DndProvider options={HTML5toTouch}>
        <Section padding="large">
          <Dropzone
            onDropAccepted={onDrop}
            onDropRejected={onReject}
            accept={
              allowType
                ? ([] as string[]).concat(
                    ...allowType.map((item) => mimePerType[item]),
                  )
                : undefined
            }
            noClick
            multiple
            ref={ref}
            maxSize={maxSize}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                tabIndex={undefined}
                role={undefined}
                className="relative space-y-4"
              >
                {isDragActive && (
                  <Text
                    variant="gray"
                    block
                    className="absolute inset-0 z-20 flex flex-col items-center justify-center space-y-4 border bg-white pt-2 dark:bg-dark-background"
                  >
                    <FontAwesomeIcon
                      icon={regular("upload")}
                      className="animate-bounce text-6xl"
                    />
                    <p>{__("Drop files here to upload")}</p>
                  </Text>
                )}
                {children}
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </Section>
      </DndProvider>
    </div>
  );
});

export default FilemanagerEditorSection;
