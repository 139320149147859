import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import { Hashtag, Location, People } from "../../Post";

export const searchKeys = createQueryKeys("search", {
  hashtag: (query?: string) => [{ query }],
  location: (accountIds: number[], query?: string) => [{ query, accountIds }],
  people: (accountIds: number[], query?: string) => [{ query, accountIds }],
});

export const useSearchHashtag = (query?: string) =>
  useQuery({
    ...searchKeys.hashtag(query),
    queryFn: async ({ signal }) => {
      const response = await api.post<Hashtag[]>(
        "search/hashtag",
        { query },
        { signal },
      );
      return response.data;
    },
    staleTime: Infinity,
    enabled: query !== undefined,
  });

export const useSearchLocation = <TData = Location[]>(
  accountIds: number[],
  query?: string,
  select?: (data: Location[]) => TData,
) =>
  useQuery({
    ...searchKeys.location(accountIds, query),
    queryFn: async ({ signal }) => {
      const response = await api.post<Location[]>(
        "search/location",
        {
          query,
          account_ids: accountIds,
        },
        { signal },
      );
      return response.data;
    },
    staleTime: Infinity,
    select,
    enabled: query !== undefined,
  });

export const useSearchPeople = <TData = People[]>(
  accountIds: number[],
  query?: string,
  select?: (data: People[]) => TData,
) =>
  useQuery({
    ...searchKeys.people(accountIds, query),
    queryFn: async ({ signal }) => {
      const response = await api.post<People[]>(
        "search/people",
        {
          query,
          account_ids: accountIds,
        },
        { signal },
      );
      return response.data;
    },
    staleTime: Infinity,
    select,
    enabled: query !== undefined,
  });
