import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import {
  findDataOnInfiniteQuery,
  getNextCursorPaginatePage,
  insertDataOnArray,
  updateDataOnArray,
  updateDataOnInfiniteQuery,
} from "@common/helpers/queries";
import { CursorPaginate } from "@common/interfaces/Api";
import {
  AutoForm,
  AutoFormResponse,
  AutoMessage,
  AutoMessageResponse,
  AutoProduct,
  AutoResponse,
  AutomationButton,
  Condition,
} from "../interfaces";

const MenuFieldExpansion = "";
const MessageFieldExpansion = "file,products(file),quick_replies,buttons";
const ProductFieldExpansion = "file,buttons";
const FormFieldExpansion = "autoFormMessages(buttons)";

export const automationKeys = createQueryKeys("automation", {
  usage: null,

  features: (accountId?: number) => [{ accountId }],

  menu: (accountId: number) => [{ accountId }],
  question: (accountId: number) => [{ accountId }],

  messages: (accountId: number) => [{ accountId }],
  message: (id?: number) => [{ id }],
  messageResponses: (id: number) => [{ id }],

  products: (accountId: number) => [{ accountId }],
  product: (id?: number) => [{ id }],
  responses: (accountId: number, filter?: AutoResponseFilter) => [
    { accountId, filter },
  ],
  response: (id?: number) => [{ id }],
  forms: (accountId: number) => [{ accountId }],
  form: (id?: number) => [{ id }],
  formResponses: (id: number) => [{ id }],
  formResponse: (id?: number) => [{ id }],
});

export const useFetchUsage = () =>
  useQuery({
    ...automationKeys.usage,
    queryFn: async () => {
      const response = await api.get<{ total: number; used: number }>(
        "automation/usage",
      );
      return response.data;
    },
  });

export const useFetchAutomationMenu = (accountId: number) =>
  useQuery({
    ...automationKeys.menu(accountId),
    queryFn: async () => {
      const response = await api.get<AutomationButton[]>(
        `automation/${accountId}/menu`,
        { params: { with: MenuFieldExpansion } },
      );
      return response.data;
    },
    enabled: accountId !== undefined,
  });

export const useUpdateAutomantionMenu = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      menu: Omit<AutomationButton, "id" | "created_at">[];
    }) =>
      api
        .post(`automation/${accountId}/menu`, data, {
          params: { with: MenuFieldExpansion },
        })
        .then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: automationKeys.menu._def,
      });
    },
  });
};

export const useFetchAutomationQuestion = (accountId: number) =>
  useQuery({
    ...automationKeys.question(accountId),
    queryFn: async () => {
      const response = await api.get<AutomationButton[]>(
        `automation/${accountId}/question`,
        { params: { with: MenuFieldExpansion } },
      );
      return response.data;
    },
    enabled: accountId !== undefined,
  });

export const useUpdateAutomantionQuestion = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      menu: Array<Omit<AutomationButton, "id" | "created_at" | "type" | "url">>;
    }) =>
      api
        .post(`automation/${accountId}/question`, data, {
          params: { with: MenuFieldExpansion },
        })
        .then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: automationKeys.question._def,
      });
    },
  });
};

export const useFetchAutoMessages = (accountId: number) =>
  useQuery({
    ...automationKeys.messages(accountId),
    queryFn: async () => {
      const response = await api.get<AutoMessage[]>(
        `automation/${accountId}/message`,
        {
          params: {
            with: MessageFieldExpansion,
          },
        },
      );
      return response.data;
    },
  });

export const useFetchAutoMessage = (
  accountId: number,
  id: number | undefined,
) =>
  useQuery({
    ...automationKeys.message(id),
    queryFn: async () => {
      const response = await api.get<AutoMessage>(
        `automation/${accountId}/message/${id}`,
        { params: { with: MessageFieldExpansion } },
      );
      return response.data;
    },
    enabled: id !== undefined,
  });

interface MessageRequest {
  title: string;
  type: string;
  text?: string | null;
  file_id?: number | null;
  product_ids?: number[] | null;
  quick_replies?: Array<{ title: string; message_ids: number[] }>;
  buttons: Array<{
    title: string;
    type: "message" | "link";
    url?: string | null;
    message_ids: number[];
  }>;
}

export const useCreateAutoMessage = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: MessageRequest) =>
      api
        .post<AutoMessage>(`automation/${accountId}/message`, data, {
          params: { with: MessageFieldExpansion },
        })
        .then((response) => response.data),
    onSuccess: (newTicket) => {
      queryClient.setQueryData<AutoMessage[]>(
        automationKeys.messages(accountId).queryKey,
        (previous) => insertDataOnArray(previous, newTicket),
      );

      queryClient.invalidateQueries({
        queryKey: automationKeys.messages(accountId).queryKey,
        refetchType: "none",
      });
    },
  });
};

export const useUpdateAutoMessage = (
  accountId: number,
  id: number | undefined,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: MessageRequest) =>
      api
        .put<AutoMessage>(`automation/${accountId}/message/${id}`, data, {
          params: { with: MessageFieldExpansion },
        })
        .then((response) => response.data),
    onSuccess: (newTicket) => {
      queryClient.setQueryData<AutoMessage[]>(
        automationKeys.messages(accountId).queryKey,
        (previous) => updateDataOnArray(previous, newTicket),
      );
      queryClient.invalidateQueries(automationKeys.message(id));
    },
  });
};

export const useCreateOrUpdateAutoMessage = (
  accountId: number,
  id: number | undefined,
) => {
  const create = useCreateAutoMessage(accountId);
  const update = useUpdateAutoMessage(accountId, id);
  return id ? update : create;
};

export const useDeleteAutoMessage = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      api.delete(`automation/${accountId}/message/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: automationKeys._def });
    },
  });
};

export const useFetchAutoProducts = (accountId: number) =>
  useQuery({
    ...automationKeys.products(accountId),
    queryFn: async () => {
      const response = await api.get<AutoProduct[]>(
        `automation/${accountId}/product`,
        { params: { with: ProductFieldExpansion } },
      );
      return response.data;
    },
  });

export const useFetchAutoProduct = (
  accountId: number,
  id: number | undefined,
) =>
  useQuery({
    ...automationKeys.product(id),
    queryFn: async () => {
      const response = await api.get<AutoProduct>(
        `automation/${accountId}/product/${id}`,
        { params: { with: ProductFieldExpansion } },
      );
      return response.data;
    },
    enabled: id !== undefined,
  });

interface CreateProductRequest {
  title: string;
  subtitle?: string | null;
  file_id?: number | null;
  url?: string | null;
  buttons: Array<{
    title: string;
    type: "message" | "link";
    url?: string | null;
    message_ids: number[];
  }>;
}

export const useCreateAutoProduct = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateProductRequest) =>
      api
        .post<AutoProduct>(`automation/${accountId}/product`, data, {
          params: { with: ProductFieldExpansion },
        })
        .then((response) => response.data),
    onSuccess: (newTicket) => {
      queryClient.setQueryData<AutoProduct[]>(
        automationKeys.products(accountId).queryKey,
        (previous) => insertDataOnArray(previous, newTicket),
      );

      queryClient.invalidateQueries({
        queryKey: automationKeys.products(accountId).queryKey,
        refetchType: "none",
      });
    },
  });
};

export const useUpdateAutoProduct = (accountId: number, id?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateProductRequest) =>
      api
        .put<AutoProduct>(`automation/${accountId}/product/${id}`, data, {
          params: { with: ProductFieldExpansion },
        })
        .then((response) => response.data),
    onSuccess: (newTicket) => {
      queryClient.setQueryData<AutoProduct[]>(
        automationKeys.products(accountId).queryKey,
        (previous) => updateDataOnArray(previous, newTicket),
      );

      queryClient.invalidateQueries({
        queryKey: automationKeys.products(accountId).queryKey,
        refetchType: "none",
      });

      queryClient.invalidateQueries(automationKeys.product(id));
    },
  });
};

export const useCreateOrUpdateAutoProduct = (
  accountId: number,
  id?: number,
) => {
  const create = useCreateAutoProduct(accountId);
  const update = useUpdateAutoProduct(accountId, id);
  return id ? update : create;
};

export const useDeleteAutoProduct = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      api.delete(`automation/${accountId}/product/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: automationKeys._def });
    },
  });
};

export type AutoResponseFilter = {
  search?: string | number;
};

export const useFetchAutoResponses = (
  accountId: number,
  filter?: AutoResponseFilter,
) => {
  return useQuery({
    ...automationKeys.responses(accountId, filter),
    queryFn: async () => {
      const response = await api.get<AutoResponse[]>(
        `automation/${accountId}/response`,
        {
          params: {
            search: filter?.search,
          },
        },
      );
      return response.data;
    },
  });
};

export const useFetchAutoResponse = (
  accountId: number,
  id: number | undefined,
) =>
  useQuery({
    ...automationKeys.response(id),
    queryFn: async () => {
      const response = await api.get<AutoResponse>(
        `automation/${accountId}/response/${id}`,
      );
      return response.data;
    },
    enabled: id !== undefined,
  });

interface CreateResponseRequest {
  direct: "1" | "0";
  comment: "1" | "0";
  condition: Condition[];
  message_ids: number[];
  react_to_direct?: "1" | "0";
  user_should_follow_business_text?: string;
  user_should_follow_business_button?: string;
  reminder_message_ids?: number[];
  reminder_after?: number;
}

export const useCreateAutoResponse = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateResponseRequest) =>
      api
        .post<AutoResponse>(`automation/${accountId}/response`, data)
        .then((response) => response.data),
    onSuccess: (newTicket) => {
      queryClient.setQueryData<AutoResponse[]>(
        automationKeys.responses(accountId).queryKey,
        (previous) => insertDataOnArray(previous, newTicket),
      );

      queryClient.invalidateQueries({
        queryKey: automationKeys.responses(accountId).queryKey,
        refetchType: "none",
      });
    },
  });
};

export const useUpdateAutoResponse = (accountId: number, id?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateResponseRequest) =>
      api
        .put<AutoResponse>(`automation/${accountId}/response/${id}`, data)
        .then((response) => response.data),
    onSuccess: (newTicket) => {
      queryClient.setQueryData<AutoResponse[]>(
        automationKeys.responses(accountId).queryKey,
        (previous) => updateDataOnArray(previous, newTicket),
      );

      queryClient.invalidateQueries({
        queryKey: automationKeys.responses(accountId).queryKey,
        refetchType: "none",
      });

      queryClient.invalidateQueries(automationKeys.response(id));
    },
  });
};

export const useCreateOrUpdateAutoResponse = (
  accountId: number,
  id?: number,
) => {
  const create = useCreateAutoResponse(accountId);
  const update = useUpdateAutoResponse(accountId, id);
  return id ? update : create;
};

export const useDeleteAutoResponse = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      api.delete(`automation/${accountId}/response/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: automationKeys._def });
    },
  });
};

export const useExportResponse = (accountId: number) =>
  useMutation({
    mutationFn: ({ id, email }: { id: number; email: string }) =>
      api.post(`automation/${accountId}/response/${id}/export`, { email }),
  });

export const useFetchAutoForms = (accountId: number) =>
  useQuery({
    ...automationKeys.forms(accountId),
    queryFn: async () => {
      const response = await api.get<AutoForm[]>(
        `automation/${accountId}/form`,
        { params: { with: FormFieldExpansion } },
      );
      return response.data;
    },
  });

export const useFetchAutoForm = (accountId: number, id: number | undefined) =>
  useQuery({
    ...automationKeys.form(id),
    queryFn: async () => {
      const response = await api.get<AutoForm>(
        `automation/${accountId}/form/${id}`,
        { params: { with: FormFieldExpansion } },
      );
      return response.data;
    },
    enabled: id !== undefined,
  });

export interface CreateFormRequest {
  title: string;
  start_command: string;
  cancel_command: string;
  cancel_message: string;
  end_message: string;
  auto_form_messages: Array<{
    text?: string | null;
    quick_replies?: string[];
  }>;
}

export const useCreateAutoForm = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateFormRequest) =>
      api
        .post<AutoForm>(`automation/${accountId}/form`, data, {
          params: { with: FormFieldExpansion },
        })
        .then((response) => response.data),
    onSuccess: (newTicket) => {
      queryClient.setQueryData<AutoForm[]>(
        automationKeys.forms(accountId).queryKey,
        (previous) => insertDataOnArray(previous, newTicket),
      );

      queryClient.invalidateQueries({
        queryKey: automationKeys.forms(accountId).queryKey,
        refetchType: "none",
      });
    },
  });
};

export const useUpdateAutoForm = (accountId: number, id?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateFormRequest) =>
      api
        .put<AutoForm>(`automation/${accountId}/form/${id}`, data, {
          params: { with: FormFieldExpansion },
        })
        .then((response) => response.data),
    onSuccess: (newTicket) => {
      queryClient.setQueryData<AutoForm[]>(
        automationKeys.forms(accountId).queryKey,
        (previous) => updateDataOnArray(previous, newTicket),
      );

      queryClient.invalidateQueries({
        queryKey: automationKeys.forms(accountId).queryKey,
        refetchType: "none",
      });

      queryClient.invalidateQueries(automationKeys.form(id));
    },
  });
};

export const useCreateOrUpdateAutoForm = (accountId: number, id?: number) => {
  const create = useCreateAutoForm(accountId);
  const update = useUpdateAutoForm(accountId, id);
  return id ? update : create;
};

export const useDeleteAutoForm = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      api.delete(`automation/${accountId}/form/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: automationKeys._def });
    },
  });
};

export const useUpdateAutoFormStatus = (accountId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ status, id }: { id: number; status: boolean }) =>
      api.put(`automation/${accountId}/form/${id}/status`, { status }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: automationKeys.form._def });
      return queryClient.invalidateQueries(automationKeys.forms(accountId));
    },
  });
};

export const useExportForm = (accountId: number) =>
  useMutation({
    mutationFn: ({ id, email }: { id: number; email: string }) =>
      api.post(`automation/${accountId}/form/${id}/export`, { email }),
  });

export type FormResponseList = CursorPaginate<AutoFormResponse>;

export const useFetchAutoFormResponses = (accountId: number, formId: number) =>
  useInfiniteQuery({
    ...automationKeys.formResponses(formId),
    queryFn: async ({ pageParam }) => {
      const response = await api.get<FormResponseList>(
        pageParam?.after || `automation/${accountId}/form/${formId}/response`,
        {
          params: {
            with: "socialUser,messages(socialUser)",
          },
        },
      );

      return response.data;
    },
    initialPageParam: undefined,
    getNextPageParam: getNextCursorPaginatePage,
  });

export const useFetchAutoFormResponse = (responseId?: number) => {
  const queryClient = useQueryClient();

  return useQuery({
    ...automationKeys.formResponse(responseId),
    queryFn: async () => {
      const response = await api.get<AutoFormResponse>(
        `automation/response/${responseId}/`,
        {
          params: {
            with: "socialUser,messages(socialUser)",
          },
        },
      );

      queryClient.setQueriesData<InfiniteData<FormResponseList>>(
        { queryKey: automationKeys.formResponses._def },
        (previous) => updateDataOnInfiniteQuery(previous, response.data),
      );

      return response.data;
    },
    initialData: () => {
      return responseId
        ? findDataOnInfiniteQuery(
            queryClient
              .getQueriesData<InfiniteData<FormResponseList>>({
                queryKey: automationKeys.formResponses._def,
                type: "active",
              })
              .map((query) => query[1]),
            responseId,
          )
        : null;
    },
    enabled: responseId !== undefined,
  });
};

export type AutoMessageResponseList = CursorPaginate<AutoMessageResponse>;

export const useFetchAutoMessageResponses = (accountId: number) =>
  useInfiniteQuery({
    ...automationKeys.messageResponses(accountId),
    queryFn: async ({ pageParam }) => {
      const response = await api.get<AutoMessageResponseList>(
        pageParam?.after || `automation/${accountId}/message-response`,
        {
          params: {
            with: "sourceMessage,responseMessage,autoMessage",
          },
        },
      );

      return response.data;
    },
    initialPageParam: undefined,
    getNextPageParam: getNextCursorPaginatePage,
  });
