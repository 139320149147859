import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { InputHTMLAttributes, useState } from "react";
import { regular } from "@common/helpers/fontawesome";
import { TextInputProps } from "@common/types";
import { TextInput } from "./TextInput";

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & TextInputProps
>(function PasswordInput({ ...props }, ref) {
  const [visible, setVisible] = useState(false);

  return (
    <TextInput
      {...props}
      ref={ref}
      type={visible ? "text" : "password"}
      iconPosition="end"
      icon={
        <div
          onClick={() => setVisible(!visible)}
          className="me-2 flex cursor-pointer items-center justify-center hover:text-gray-500"
        >
          <FontAwesomeIcon icon={regular("eye")} />
          <div
            style={{
              width: visible ? 18 : 0,
              height: 2,
              background: "#6f7285",
              transition: "width 0.3s ease 0s",
              position: "absolute",
              transform: "rotate(45deg) translateY(-50%)",
            }}
          />
        </div>
      }
    />
  );
});
