import { useEffect, useRef, useState } from "react";
import {
  FieldError,
  FieldValues,
  UseFormProps,
  useForm,
} from "react-hook-form";

type Strictify<T> = { [K in keyof T]-?: T[K] & Exclude<T[K], undefined> };

export function useFormError<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TDeps extends [any, ...any] = any,
>(
  props?: UseFormProps<TFieldValues, TContext>,
  defaultValue?: (...deps: Strictify<TDeps>) => TFieldValues,
  deps?: TDeps,
) {
  const resolve = useRef<(value: Strictify<TDeps>) => void>();
  const [promise] = useState(
    () =>
      new Promise<Strictify<TDeps>>((_resolve) => {
        resolve.current = _resolve;
      }),
  );

  useEffect(() => {
    if (deps && !deps.includes(undefined)) {
      resolve.current?.(deps as Strictify<TDeps>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || []);

  const { register, ...formData } = useForm(
    defaultValue
      ? {
          ...props,
          defaultValues: async () => {
            const deps = await promise;

            return defaultValue(...deps);
          },
        }
      : props,
  );

  const registerWithError: (
    name: Parameters<typeof register>[0],
    options?: Parameters<typeof register>[1],
  ) => ReturnType<typeof register> & {
    error?: FieldError;
  } = (name, options) => {
    return {
      ...register(name, options),
      error: getDescendantProp(formData.formState.errors, name) as FieldError,
    };
  };

  return {
    register,
    registerWithError,
    ...formData,
  };
}

function getDescendantProp(obj: any, desc: string) {
  const arr = desc.split(".");
  while (arr.length && (obj = obj[arr.shift()!]));
  return obj;
}
