import { useMutation } from "@tanstack/react-query";
import { api } from "@common/helpers/api";

export const useGenerateCaption = () =>
  useMutation({
    mutationFn: (data: {
      message: string;
      type: string;
      language: string;
      tone: string;
    }) =>
      api
        .post<{ message: string }>("generate/caption", data)
        .then((response) => response.data),
  });

export const useGenerateHashtag = () =>
  useMutation({
    mutationFn: (data: { caption: string }) =>
      api
        .post<string[]>("generate/hashtag", data)
        .then((response) => response.data),
  });

export const useGenerateComment = () =>
  useMutation({
    mutationFn: (data: { comment: string }) =>
      api
        .post<{ message: string }>("generate/comment", data)
        .then((response) => response.data),
  });

export const useGenerateDirect = () =>
  useMutation({
    mutationFn: (data: { message: string }) =>
      api
        .post<{ message: string }>("generate/direct", data)
        .then((response) => response.data),
  });
