import { useCallback } from "react";
import { PostState, usePostStore } from "../store/post";

// export const PostContext = createContext<{
//   state: PostState;
//   dispatch: React.Dispatch<PostAction>;
// }>({} as any);

export function useExtraPostState<TKey extends keyof PostState["extra"]>(
  social: TKey,
) {
  const [state, dispatch] = usePostStore((store) => store.extra[social]);
  return [
    state,
    useCallback(
      <TField extends keyof PostState["extra"][TKey]>(
        field: TField,
        value: PostState["extra"][TKey][TField],
      ) =>
        dispatch({
          type: "extraFieldChanged",
          payload: { key: social, field, value } as any,
        }),
      [social, dispatch],
    ),
  ] as const;
}
