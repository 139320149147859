import React from "react";

export const TitleContext = React.createContext<string>(null as any);

export const ChangeTitleContext = React.createContext<(title: string) => void>(
  null as any,
);

export const ThemeContext = React.createContext<"light" | "dark">(null as any);

export const ToggleThemeContext = React.createContext<() => void>(null as any);

export const BackButtonContext = React.createContext<boolean>(null as any);

export const SetBackButtonVisibilityContext = React.createContext<
  (show: boolean) => void
>(null as any);

export const ShowUpgradeModalContext = React.createContext<() => void>(
  null as any,
);
