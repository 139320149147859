import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createBrowserHistory } from "history";
import { useCallback, useMemo } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "@common/assets/fontawesome/brands.min.js";
import "@common/assets/fontawesome/regular.min.js";
import { api, setApiUrl, setAppUrl } from "@common/helpers/api";
import { setIsRTL, setLanguage } from "@common/helpers/i18n";
import { setNavigation } from "@common/helpers/navigation";
import Providers from "@common/providers";
import Error500 from "./elements/errors/Error500";
import { useLocalStorage } from "./hooks";

setApiUrl(import.meta.env.VITE_API_URL);
setAppUrl(import.meta.env.VITE_APP_URL);
setIsRTL(document.body.dir === "rtl");
setLanguage(document.documentElement.lang);

const history = createBrowserHistory();

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/api\.novinhub\.com/,
      /^https:\/\/apibeta\.novinhub\.com/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

declare const _paq: any;

history.listen(() => {
  if (typeof _paq !== "undefined") {
    _paq.push(["setCustomUrl", window.location.href]);
    _paq.push(["setDocumentTitle", document.title]);
    _paq.push(["trackPageView"]);
  }
});

window.addEventListener("appinstalled", () => {
  _paq.push(["trackEvent", "button", "appinstalled", "appinstalled"]);
});

function loadProgress(percent: number) {
  const progress = document.getElementById("load-progress");
  if (!progress) {
    return;
  }
  progress.querySelector<HTMLElement>(":scope div")!.style.width =
    percent + "%";
  if (percent === 100) {
    setTimeout(() => {
      progress.classList.add("hidden");
    }, 500);
  } else progress.classList.remove("hidden");
}

api.interceptors.request.use(
  function (config: any) {
    if (!config.meta?.hideLoading) {
      loadProgress(10);
      loadProgress(50);
    }
    return config;
  },
  function (error) {
    loadProgress(100);
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  function (response) {
    loadProgress(100);
    return response;
  },
  function (error) {
    loadProgress(100);
    return Promise.reject(error);
  },
);

export type LayoutComponentProps = {
  isPublic?: boolean;
  children: React.ReactNode;
};

// #TODO: its important to check all navigation paths, maybe we can create a const
// for each navigation path?
setNavigation({
  push: (path) => history.push("/" + path),
});

const App = ({
  routes,
  layout: Layout,
}: {
  routes: {
    [key in "publicApps" | "apps" | "errors"]?: any;
  };
  layout: React.ComponentType<LayoutComponentProps>;
}) => {
  const [theme, setTheme] = useLocalStorage<"light" | "dark">(
    "theme",
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
  );
  const [installedVersion, setInstalledVersion] = useLocalStorage(
    "installed_version",
    "0.0.0",
  );

  useMemo(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => (
        <Error500 error={error} info={{ componentStack }} />
      )}
    >
      <Providers
        theme={theme}
        toggleTheme={useCallback(() => {
          setTheme((theme) => (theme === "light" ? "dark" : "light"));
        }, [setTheme])}
        installedVersion={installedVersion}
        onInstall={setInstalledVersion}
      >
        <Router history={history}>
          <Switch>
            {routes.publicApps && (
              <Route path={Object.keys(routes.publicApps)}>
                <Layout isPublic={true}>
                  <Switch>
                    {Object.keys(routes.publicApps).map((path) => (
                      <Route
                        path={path}
                        key={path}
                        render={routes.publicApps[path]}
                      />
                    ))}
                  </Switch>
                </Layout>
              </Route>
            )}
            {routes.apps && (
              <Route path={Object.keys(routes.apps)}>
                <Layout>
                  <Switch>
                    {Object.keys(routes.apps).map((path) => (
                      <Route
                        path={path}
                        key={path}
                        render={routes.apps[path]}
                        exact={path === "/"}
                      />
                    ))}
                    <Route
                      render={({ location }) => {
                        if (location.pathname === "/") {
                          return (
                            <Redirect
                              to={{
                                pathname: "/dashboard",
                              }}
                            />
                          );
                        }
                        const Component = routes.errors[404];
                        return <Component />;
                      }}
                    />
                  </Switch>
                </Layout>
              </Route>
            )}
            <Route path="*">
              <Layout>
                <Switch>
                  <Route
                    render={() => {
                      const Component = routes.errors[500];
                      return <Component />;
                    }}
                    path="/error"
                  />
                  <Route
                    render={({ location }) => {
                      if (location.pathname === "/") {
                        return (
                          <Redirect
                            to={{
                              pathname: "/dashboard",
                            }}
                          />
                        );
                      }
                      const Component = routes.errors[404];
                      return <Component />;
                    }}
                  />
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </Providers>
    </Sentry.ErrorBoundary>
  );
};

export default App;
