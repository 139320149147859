import { useState } from "react";
import { File } from "@common/features/File";
import { useFetchActiveIntegration } from "@common/features/Integration";
import { FileBox } from "@app/features/filemanager";
import ImageEdtiorModal from "@app/pages/post/ImageEditorModal";
import PollSection from "@app/pages/post/PollSection";
import { usePostStore } from "../store/post";

export function PostPollFileSelected({
  onSave,
  onPollModal,
}: {
  onSave: (image: string, from: File) => void;
  onPollModal: () => void;
}) {
  const [{ selectedFiles, preview, poll }, dispatch] = usePostStore(
    ({ selectedFiles, preview, poll }) => ({
      poll,
      selectedFiles,
      preview,
    }),
    true,
  );

  const imageEditor = useFetchActiveIntegration("ImageEditor");

  const [ImageEditor, setImageEditor] = useState<File | undefined>();

  return (
    <>
      {poll && (
        <PollSection
          poll={poll}
          onEdit={() => onPollModal()}
          onDelete={() => dispatch({ type: "pollChanged", payload: undefined })}
        />
      )}
      {imageEditor && (
        <ImageEdtiorModal
          onClose={() => setImageEditor(undefined)}
          onSave={(image) => onSave(image, ImageEditor!)}
          image={ImageEditor}
        />
      )}
      {selectedFiles.map((file, index) => (
        <FileBox
          file={file}
          showIndex={selectedFiles.length > 1}
          index={index}
          active={preview !== undefined && file.id === preview.id}
          setActive={() => dispatch({ type: "setPreview", payload: file })}
          key={file.id}
          moveCard={(dragIndex: number, hoverIndex: number) =>
            dispatch({
              type: "moveFiles",
              payload: { dragIndex, hoverIndex },
            })
          }
          onRemove={() =>
            dispatch({
              type: "fileRemove",
              payload: file,
            })
          }
          onEdit={
            imageEditor && file.fileType === "image"
              ? () => setImageEditor(file)
              : undefined
          }
        />
      ))}
    </>
  );
}
