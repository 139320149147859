import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { useSiteNotifications } from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { Button } from "@app/elements/form";
import { Alert } from "@app/elements/text/Alert";
import { useLocalStorage } from "@app/hooks";

export default function SiteNotification() {
  const location = useLocation();
  const notifications = useSiteNotifications();

  const [closedNotifications, setClosedNotifications] = useLocalStorage<
    number[]
  >("site-notifications", []);

  return (
    <>
      {notifications.map(
        (notification) =>
          !closedNotifications.includes(notification.id) &&
          (!notification.url ||
            location.pathname.startsWith(notification.url)) && (
            <Alert
              variant="light"
              className="mb-4 rounded-md"
              key={notification.id}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: notification.content,
                }}
              />
              <div className="ms-auto flex">
                {notification.button && (
                  <Button variant="primary" href={notification.button_url!}>
                    {notification.button}
                  </Button>
                )}
                {notification.closable === 1 && (
                  <Button
                    onClick={() => {
                      setClosedNotifications([
                        ...new Set([...closedNotifications, notification.id]),
                      ]);
                    }}
                  >
                    <FontAwesomeIcon icon={regular("xmark")} />
                  </Button>
                )}
              </div>
            </Alert>
          ),
      )}
    </>
  );
}
