import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import notify, { NotificationType } from "@common/helpers/NotificationManager";
import { regular } from "@common/helpers/fontawesome";
import { Button } from "@app/elements/form";

export function NotificationContainer() {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  useEffect(() => {
    notify.callback = (notifications) => setNotifications(notifications);
    setNotifications(notify.listNotify);

    return () => (notify.callback = undefined);
  }, []);

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 bottom-12 z-50 flex items-end px-4 py-6 sm:bottom-0 sm:items-end sm:p-6"
    >
      <div className="flex w-full flex-col-reverse items-center space-y-4 space-y-reverse sm:items-end">
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
            onRequestHide={() => notify.remove(notification)}
          />
        ))}
      </div>
    </div>
  );
}

const icon = {
  success: (
    <FontAwesomeIcon
      icon={regular("circle-check")}
      className="h-6 w-6 text-green-400"
      aria-hidden="true"
    />
  ),
  error: (
    <FontAwesomeIcon
      icon={regular("circle-exclamation")}
      className="h-6 w-6 text-rose-400"
      aria-hidden="true"
    />
  ),
  info: (
    <FontAwesomeIcon
      icon={regular("circle-info")}
      className="h-6 w-6 text-sky-400"
      aria-hidden="true"
    />
  ),
  warning: (
    <FontAwesomeIcon
      icon={regular("circle-xmark")}
      className="h-6 w-6 text-amber-400"
      aria-hidden="true"
    />
  ),
  default: (
    <FontAwesomeIcon
      icon={regular("info")}
      className="h-6 w-6 text-gray-400"
      aria-hidden="true"
    />
  ),
};

function Notification({
  notification,
  onRequestHide,
}: {
  notification: NotificationType;
  onRequestHide: () => void;
}) {
  const [show, setShow] = React.useState(true);

  useEffect(() => {
    if (notification.timeout !== false) {
      const timer = setTimeout(
        () => setShow(false),
        notification.timeout || 3000,
      );
      return () => clearTimeout(timer);
    }
  }, [notification.timeout]);

  return (
    <Transition
      show={show}
      as={Fragment}
      appear
      enter="transform ease-out duration-400 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 ltr:sm:translate-x-2 rtl:sm:-translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition-all ease-in duration-500"
      leaveFrom="opacity-100 max-h-[400px]"
      leaveTo="opacity-0 max-h-0"
      afterLeave={() => onRequestHide()}
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div
            className={
              "flex " + (notification.message ? "items-start" : "items-center")
            }
          >
            <div className="flex-shrink-0 pt-2">{icon[notification.type]}</div>
            <div className="ms-3 w-0 flex-1 pt-0.5">
              <p className="font-semibold text-gray-900">
                {notification.title}
              </p>
              {notification.message && (
                <p className="mt-1 text-gray-500">{notification.message}</p>
              )}
            </div>
            <div className="ms-4 flex flex-shrink-0">
              <Button
                padding="none"
                className="px-1 py-1"
                onClick={() => {
                  setShow(false);
                }}
              >
                <span className="sr-only">Close</span>
                <FontAwesomeIcon
                  icon={regular("x")}
                  className="h-3 w-3"
                  aria-hidden="true"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
