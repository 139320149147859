import { useHistory } from "react-router-dom";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { ModalProps } from "@common/types";
import { Button } from "../form";
import { Badge } from "../text";
import { Modal } from "./Modal";

export function UpgradeModal(props: ModalProps) {
  const history = useHistory();

  return (
    <Modal {...props}>
      <div className="flex flex-col space-y-4 p-8">
        <Modal.Title>
          <div className="flex items-center text-2xl">
            <Badge
              circle
              variant="primary"
              className="h-12 w-12"
              icon={regular("bolt")}
            />
            <span className="mx-2 font-bold">
              {__("Be more professional.")}
            </span>
          </div>
        </Modal.Title>
        <Modal.Description>
          {__(
            "To access all features in %{site} you need to upgrade your plan.",
            { site: __(import.meta.env.VITE_SITE_NAME) },
          )}
        </Modal.Description>
        <Button
          variant="primary"
          onClick={() => {
            props.onClose();
            history.push("/renew");
          }}
          className="self-end"
        >
          {__("Upgrade now")}
        </Button>
      </div>
    </Modal>
  );
}
