import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { Section } from "../container";
import { Button } from "../form";

export function ExpiredOrMissingPermission() {
  return (
    <Section
      pure
      className="m-4 flex flex-1 flex-col items-center space-y-4 bg-transparent py-16"
      hasBackground={false}
    >
      <div className="mb-2 rounded-full bg-gray-200 dark:bg-gray-700">
        <FontAwesomeIcon className="p-8 text-6xl" icon={regular("bolt")} />
      </div>
      <h3 className="mb-2 text-2xl font-bold">
        {__("Need to upgrade.", {
          site: __(import.meta.env.VITE_SITE_NAME),
        })}
      </h3>
      <p className="text-sm">
        {__("To access all features in %{site} you need to upgrade your plan.")}
      </p>
      <Button to="/renew" variant="primary" padding="medium">
        {__("Upgrade")}
      </Button>
    </Section>
  );
}
