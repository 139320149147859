import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { regular } from "@common/helpers/fontawesome";
import { DropDownMenuProps } from "@common/types";
import { FadeTransition } from ".";

const positions = {
  "bottom-start": "start-0 origin-top-start",
  "bottom-end": "end-0 origin-top-end",
  "top-start": "start-0 origin-bottom-start bottom-full mb-2",
  "top-end": "end-0 origin-bottom-end bottom-full mb-2",
};

export function DropDownMenu({
  className,
  menu,
  button,
  size,
  position,
}: DropDownMenuProps) {
  return (
    <Menu as="div" className={classNames("relative inline-block", className)}>
      <Menu.Button as="div">
        {button || (
          <button className="flex items-center justify-center rounded-full p-1 hover:bg-gray-300 focus:outline-none">
            <FontAwesomeIcon
              icon={regular("ellipsis-vertical")}
              className="h-4 w-4"
            />
          </button>
        )}
      </Menu.Button>

      <FadeTransition>
        <Menu.Items
          className={classNames(
            "absolute z-30 mt-2 w-32 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700",
            size === "small"
              ? "w-32"
              : size === "medium"
                ? "w-48"
                : size === "large"
                  ? "w-64"
                  : "",
            positions[position || "bottom-start"],
          )}
        >
          <div className="py-1">
            {menu.map((menu, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <a
                    onClick={menu.onClick}
                    href={menu.url}
                    className={classNames(
                      active ? "bg-gray-100 dark:bg-gray-800" : "",
                      "flex cursor-pointer items-center px-4 py-2 text-gray-700 space-s-1 dark:text-gray-300",
                    )}
                  >
                    {menu.icon}
                    <div>{menu.text}</div>
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </FadeTransition>
    </Menu>
  );
}
