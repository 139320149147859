import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import { SocialUser } from "../interfaces";

export const socialUsersKeys = createQueryKeys("socialUsers", {
  socialUser: (id?: number) => [{ id }],
});

export const useFetchSocialUser = (socialUserId?: number, accountId?: number) =>
  useQuery({
    ...socialUsersKeys.socialUser(socialUserId),
    queryFn: async () => {
      const response = await api.get<SocialUser<true>>(
        `social-user/${socialUserId}`,
        {
          params: {
            with: "meta",
            account_id: accountId,
          },
        },
      );
      return response.data;
    },
    staleTime: Infinity,
    enabled: socialUserId !== undefined && accountId !== undefined,
  });

export const useUpdateSocialUser = (
  socialUserId: number | undefined,
  accountId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      api
        .post<SocialUser<true>>(`social-user/${socialUserId}`, {
          account_id: accountId,
          with: "meta",
        })
        .then((response) => response.data),
    onSuccess: (data) => {
      queryClient.setQueryData<SocialUser<true>>(
        socialUsersKeys.socialUser(socialUserId).queryKey,
        data,
      );
    },
  });
};

export const useUpdateSocialUserMeta = (
  socialUserId: number,
  accountId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      number?: string;
      email?: string;
      location?: string;
      company?: string;
      job?: string;
      description?: string;
    }) =>
      api
        .put<SocialUser<true>>(`social-user/${socialUserId}`, {
          ...data,
          account_id: accountId,
          with: "meta",
        })
        .then((response) => response.data),
    onSuccess: (data) => {
      queryClient.setQueryData<SocialUser<true>>(
        socialUsersKeys.socialUser(socialUserId).queryKey,
        data,
      );

      queryClient.invalidateQueries({
        queryKey: socialUsersKeys.socialUser(socialUserId).queryKey,
        refetchType: "none",
      });
    },
  });
};
