import { useEffect, useState } from "react";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { ModalProps } from "@common/types";
import { ElementsGroup } from "@app/elements/container";
import { Button, TextInput } from "@app/elements/form";
import { Modal } from "@app/elements/modals";
import { usePostStore } from "../store/post";

export function PollModal(props: ModalProps) {
  const [poll, dispatch] = usePostStore((state) => state.poll);

  const [question, setQuestion] = useState<string>(poll?.question || "");
  const [answers, setAnswers] = useState<string[]>(poll?.answers || ["", ""]);

  useEffect(() => {
    if (props.isOpen) {
      setQuestion(poll?.question || "");
      setAnswers(poll?.answers || ["", ""]);
    }
  }, [poll, props.isOpen]);

  return (
    <Modal {...props} padding="medium">
      <ElementsGroup>
        <Modal.Title>{__("Create a poll")}</Modal.Title>
        <TextInput
          placeholder={__("Your question")}
          value={question}
          onInputChange={setQuestion}
        />
        {answers.map((answer, index) => (
          <TextInput
            key={index}
            label={index === 0 ? __("Options") : undefined}
            placeholder={__("Answer")}
            value={answer}
            onInputChange={(answer) =>
              setAnswers(answers.map((a, i) => (index === i ? answer : a)))
            }
          />
        ))}
        <Button
          icon={regular("plus")}
          onClick={() => setAnswers([...answers, ""])}
          variant="link"
        >
          {__("Add option")}
        </Button>
        <ElementsGroup center>
          <Button
            onClick={() => {
              props.onClose();
              dispatch({ type: "pollChanged", payload: { question, answers } });
            }}
            variant="primary"
            center
            icon={regular("floppy-disk")}
          >
            {__("Save")}
          </Button>
        </ElementsGroup>
      </ElementsGroup>
    </Modal>
  );
}
