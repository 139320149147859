import { Location } from "history";
import { matchPath } from "react-router-dom";

export function checkIfActive(
  location: Location,
  urls: Array<string | undefined>,
  exact = false,
) {
  return urls.some(
    (url) =>
      url &&
      matchPath(location.pathname, { path: url, exact: url === "/" || exact }),
  );
}
