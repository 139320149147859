import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { useSetUserInUserAccess } from "@common/features/Team";
import { useFetchMeData } from "@common/features/User";
import notify from "@common/helpers/NotificationManager";
import { __ } from "@common/helpers/i18n";
import Error500 from "@app/elements/errors/Error500";

export function PublicRoute({ children }: { children: JSX.Element }) {
  const hasUser = useFetchMeData((data) => !!data.user).data!;
  const history = useHistory();

  const match = useRouteMatch<{
    hash?: string;
  }>("/:page(login|signup|recovery|password-reset)/:hash?");

  const setUserInUserAccess = useSetUserInUserAccess();

  const params = new URLSearchParams(history.location.search);

  const redirect = {
    pathname: params.get("redirect") || "/dashboard",
    state: { from: history.location },
  };

  if (hasUser && match?.params.hash) {
    setUserInUserAccess.mutate(match.params.hash, {
      onSuccess: () =>
        notify.success(__("You have been successfully added to teammate.")),
    });
  }

  return !hasUser ? children : <Redirect to={redirect} />;
}

export function PrivateRoute({
  children,
  verify = true,
  admin = false,
}: {
  children: JSX.Element;
  verify?: boolean;
  admin?: boolean;
}) {
  const user = useFetchMeData((data) => data.user).data!;
  const history = useHistory();

  let to: string | false = false;

  if (verify && user) {
    if (admin && user.account_type == "member") {
      const Component = Error500;
      return <Component error={new Error()} info={{}} />;
    } else if (
      import.meta.env.VITE_MOBILE_VERIFICATION == "1" &&
      "mobile_verified" in user &&
      !user.mobile_verified
    ) {
      to = user.mobile ? "/verify" : "/complete";
    } else if (
      import.meta.env.VITE_EMAIL_VERIFICATION == "1" &&
      "email_verified" in user &&
      !user.email_verified
    ) {
      to = "/verify-email";
    }
  } else if (!user) {
    to = "/login";
  }

  if (to) {
    const params = new URLSearchParams(history.location.search);
    const search =
      "redirect=" +
      encodeURIComponent(
        params.get("redirect") ||
          history.location.pathname + history.location.search,
      );
    const redirect = {
      pathname: to,
      search,
      state: { from: history.location },
    };
    return <Redirect to={redirect} />;
  }

  return children;
}
