import classNames from "classnames";
import { LabelHTMLAttributes } from "react";
import { LabelProps } from "@common/types";

const variants = {
  success: "text-green-700 dark:text-green-500",
  danger: "text-red-700 dark:text-red-500",
  default: "text-gray-900 dark:text-gray-300",
};

export function Label({
  label,
  children,
  variant,
  ...props
}: LabelProps & LabelHTMLAttributes<HTMLLabelElement>) {
  return label ? (
    <label className="block" {...props}>
      <div className={classNames("mb-2", variants[variant || "default"])}>
        {label}
      </div>
      {children}
    </label>
  ) : (
    <>{children}</>
  );
}
