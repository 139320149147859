import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import { deleteDataOnArray, insertDataOnArray } from "@common/helpers/queries";

export type TelegramButton = { id: number; text: string; link: string };

export const telegramButtonsKeys = createQueryKeys("telegramButtons");

export const useFetchTelegramButtons = () =>
  useQuery({
    queryKey: telegramButtonsKeys._def,
    queryFn: async () => {
      const response = await api.get<TelegramButton[]>("telegram-button");
      return response.data;
    },
  });

export const useDeleteTelegramButton = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => api.delete(`telegram-button/${id}`),
    onSuccess: (_data, variables) => {
      queryClient.setQueryData<TelegramButton[]>(
        telegramButtonsKeys._def,
        (previous) => deleteDataOnArray(previous, variables),
      );

      queryClient.invalidateQueries({
        queryKey: telegramButtonsKeys._def,
        refetchType: "none",
      });
    },
  });
};

export const useCreateTelegramButton = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (button: { text: string; link: string }) =>
      api.post("telegram-button", button).then((response) => response.data),
    onSuccess: (newButton) => {
      queryClient.setQueryData<TelegramButton[]>(
        telegramButtonsKeys._def,
        (previous) => insertDataOnArray(previous, newButton),
      );

      queryClient.invalidateQueries({
        queryKey: telegramButtonsKeys._def,
        refetchType: "none",
      });
    },
  });
};
