import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useGenerateHashtag } from "@common/features/Generate";
import { useFetchActiveIntegration } from "@common/features/Integration";
import { usePostStore } from "@common/features/Post";
import { useSearchHashtag } from "@common/features/Search";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { Select } from "@app/elements/form";
import { CheckPermissionButton } from "@app/elements/permission";
import HashtagSuggestion from "@app/pages/post/sections/HashtagSuggestion";

export default function HashtagSection(props: Partial<SectionToggleProps>) {
  const [{ selected, caption }, dispatch] = usePostStore(
    (state) => ({
      selected: state.hashtag,
      caption: state.captions.text.main,
    }),
    true,
  );

  const [lastHashtag, setLastHashtag] = useState<string>();

  const { data: suggestedHashtags, isLoading } = useSearchHashtag(lastHashtag);

  function addHashtag(hashtag: string) {
    const prev = selected || [];
    hashtag = hashtag.trim();

    if (!hashtag) return;

    const list = hashtag
      .split(/\s+|#/)
      .filter((item) => !!item && !prev.includes(item))
      .map((item) => item.replace("-", "_"));

    dispatch({ type: "hashtagChanged", payload: prev.concat(list) });
    setLastHashtag(hashtag);
  }

  const chatGPT = useFetchActiveIntegration("ChatGPT");
  const genrateHashtag = useGenerateHashtag();

  return (
    <SectionToggle
      {...props}
      title={__("Add hashtag")}
      hint={__(
        "Hashtag is available for: Instagram, Facebook, Twitter, YouTube, Pinterest, Tumblr, Linkedin",
      )}
      icon={<FontAwesomeIcon className="h-6 w-6" icon={regular("hashtag")} />}
      defaultOpen={selected.length !== 0}
    >
      <ElementsGroup display="block">
        <Select
          label={__(
            "Hashtags will be added in the bottom of caption in all social medias",
          )}
          placeholder={__("Enter the hashtag and press Enter")}
          onSubmit={(value) => addHashtag(value)}
          onInputChange={(search: string) => {
            if (/\s/.test(search)) {
              addHashtag(search);
              return true;
            }
          }}
          searchable
          multiple
          value={selected || []}
          onChange={(hashtag) =>
            dispatch({
              type: "hashtagChanged",
              payload: hashtag,
            })
          }
          getLabel={(item) => item}
          getKey={(item) => item}
          hideMenu
          loading={isLoading}
        />
        {chatGPT && (
          <CheckPermissionButton
            variant="gray"
            onClick={() =>
              genrateHashtag.mutate(
                { caption },
                {
                  onSuccess: (result) => {
                    dispatch({
                      type: "hashtagChanged",
                      payload: (selected || []).concat(result),
                    });
                  },
                },
              )
            }
            title={__("Generate hashtag from caption")}
            loading={genrateHashtag.isPending}
            permission="chatgpt_hashtag"
            icon={regular("wand-magic-sparkles")}
            showNoPermissionIcon
          />
        )}

        {suggestedHashtags && suggestedHashtags.length !== 0 && (
          <HashtagSuggestion
            suggested={suggestedHashtags}
            onClick={(hashtag) => addHashtag(hashtag.name)}
          />
        )}
      </ElementsGroup>
    </SectionToggle>
  );
}
