import { useState } from "react";
import { Account } from "@common/features/Account";
import {
  PeriodType,
  summaryFooter,
  useFetchMetric,
} from "@common/features/Insights";
import { useFilterMetricPermissions } from "@common/features/User";
import { ChartSection } from "@app/elements/insights";

interface Props {
  title: string;
  hint?: string;
  isEmpty?: boolean;
  emptyMessage?: string;
  loading: boolean;
  isCustom?: boolean;
  chartOption: any;
  defaultPeriod?: PeriodType;
  selector?: (data: any, metric?: string) => any;
  metric?: string;
  account?: Account | undefined;
}

export function InsightsChartSection({
  chartOption,
  loading,
  defaultPeriod,
  selector: processMetric,
  metric,
  account,
  isCustom,
  ...rest
}: Props) {
  const [selectedPeriod, setSelectedPeriod] = useState<
    PeriodType | undefined
  >();

  const filteredMetric = useFilterMetricPermissions(metric ? [metric] : []);

  const filter = {
    period: selectedPeriod,
    metric: metric ? filteredMetric : undefined,
    accountId: account?.id,
  };

  const hasCustomPeriod =
    metric !== undefined &&
    selectedPeriod !== undefined &&
    processMetric !== undefined;

  const { data: customData, isLoading } = useFetchMetric(
    filter,
    hasCustomPeriod,
    (data) => processMetric?.(data as any, metric),
  );

  const hasPermission = metric === undefined || filteredMetric.length !== 0;

  const data = hasCustomPeriod ? customData : chartOption;

  return (
    <ChartSection
      chartOption={data && "summary" in data ? data.data : data}
      loading={loading || isLoading}
      period={selectedPeriod || defaultPeriod || "week"}
      onPeriodChange={setSelectedPeriod}
      hasPermission={hasPermission}
      footer={
        data && "summary" in data
          ? summaryFooter(account?.name || "", data.summary)
          : undefined
      }
      isCustom={hasCustomPeriod ? false : isCustom}
      {...rest}
    />
  );
}
