import classNames from "classnames";

export default function Logo({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "currentcolor" }}
      className={classNames("text-primary-500 dark:text-zinc-100", className)}
      {...props}
    >
      <use xlinkHref="#logo" />
    </svg>
  );
}
