import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";
import {
  useFetchNotifications,
  useSeenNotification,
} from "@common/features/Notification";
import { regular } from "@common/helpers/fontawesome";
import { __, formatFromNow } from "@common/helpers/i18n";
import { Badge } from "@app/elements/text";

export default function Notification() {
  const { data } = useFetchNotifications();
  const seenNotification = useSeenNotification();

  const unread = data
    ? data.filter((notification) => notification.seen === 0).length
    : 0;
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              "group relative -mx-1 rounded-full p-1 ring-gray-200 hover:text-gray-500 focus:outline-none ui-focus-visible:ring-4",
            )}
            onClick={() => {
              if (!open) {
                seenNotification.mutate();
              }
            }}
          >
            <span className="sr-only">View notifications</span>
            <FontAwesomeIcon
              icon={regular("bell")}
              className="h-6 w-6 align-middle"
              aria-hidden="true"
            />
            {unread !== 0 && (
              <Badge
                variant="danger"
                padding="none"
                rounded={false}
                className="absolute bottom-0 start-0 flex h-4 w-4 rounded-full text-xs"
              >
                <div className="h-4 w-4 leading-5">{unread}</div>
              </Badge>
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute end-1/2 z-20 mt-3 w-screen max-w-md transform px-2 sm:px-0">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white px-5 py-6 dark:bg-gray-800 sm:gap-8 sm:p-8">
                  <h2 className="border-b pb-4 text-center text-gray-900 dark:text-white">
                    {__("Notifications")}
                  </h2>
                  {data &&
                    data.map((notification) => (
                      <a
                        className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50 dark:hover:bg-gray-600"
                        key={notification.id}
                        href={notification.url}
                      >
                        <div className="ml-4">
                          <p className="text-gray-900 dark:text-gray-100">
                            {notification.content}
                          </p>
                          <p className="mt-1 text-gray-500 dark:text-gray-300">
                            {formatFromNow(notification.created_at)}
                          </p>
                        </div>
                      </a>
                    ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
