import { useCallback, useEffect, useState } from "react";
import {
  Integration,
  useFetchTeamIntegrations,
} from "@common/features/Integration";

declare let gapi: any;
declare let google: any;

export function useFileStorages(callback: (url: string[]) => void) {
  const [googlePickerLoaded, setGooglePickerLoaded] = useState(false);
  const [googleGisLoaded, setGoogleGsiLoaded] = useState(false);
  const [oneDriveLoaded, setOneDriveLoaded] = useState(false);
  const [dropBoxLoaded, setDropBoxLoaded] = useState(false);

  const { data: integrations } = useFetchTeamIntegrations();

  const googledrive = integrations?.find(
    (integration): integration is Integration<"GoogleDrive"> =>
      integration.type === "GoogleDrive" && integration.active === 1,
  );
  const onedrive = integrations?.find(
    (integration): integration is Integration<"Onedrive"> =>
      integration.type === "Onedrive" && integration.active === 1,
  );
  const dropbox = integrations?.find(
    (integration): integration is Integration<"Dropbox"> =>
      integration.type === "Dropbox" && integration.active === 1,
  );

  const googleClientId = googledrive?.settings.client_id;
  const googleApiKey = googledrive?.settings.api_key;

  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];
    if (googledrive && !googlePickerLoaded) {
      const script = document.createElement("script");
      script.type = "text/javascript";

      script.onload = () => {
        gapi.load("client", () => {
          gapi.load("picker", async () => {
            await gapi.client.init({
              apiKey: googledrive.settings.api_key,
              discoveryDocs: [
                "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
              ],
            });

            setGooglePickerLoaded(true);
          });
        });
      };
      script.src = "https://apis.google.com/js/api.js";
      head.appendChild(script);
    }
  }, [googledrive, googlePickerLoaded]);

  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];

    if (googledrive && !googleGisLoaded) {
      if (!googleGisLoaded) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.onload = () => {
          setGoogleGsiLoaded(true);
        };
        script.src = "https://accounts.google.com/gsi/client";
        head.appendChild(script);
      }
    }
  }, [googledrive, googleGisLoaded]);

  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];
    if (dropbox && !dropBoxLoaded) {
      setDropBoxLoaded(true);
      const script = document.createElement("script");
      script.type = "text/javascript";
      //script.onload = () => setDropBoxLoaded(true);
      script.src = "https://www.dropbox.com/static/api/2/dropins.js";
      script.id = "dropboxjs";
      script.setAttribute("data-app-key", dropbox.settings.api_key);
      head.appendChild(script);
    }
  }, [dropbox, dropBoxLoaded]);

  useEffect(() => {
    const head = document.getElementsByTagName("head")[0];
    if (onedrive && !oneDriveLoaded) {
      setOneDriveLoaded(true);
      const script = document.createElement("script");
      script.type = "text/javascript";
      //script.onload = () => setOneDriveLoaded(true);
      script.src = "https://js.live.net/v7.2/OneDrive.js";
      head.appendChild(script);
    }
  }, [onedrive, oneDriveLoaded]);

  const dropBox = useCallback(() => {
    (window as any).Dropbox.choose({
      linkType: "direct",
      multiselect: true,
      extensions: [".jpg", ".jpeg", ".png", ".mp4"],
      success: (files: any[]) => {
        callback(files.slice(0, 10).map((file) => file.link));
      },
    });
  }, [callback]);

  const oneDrive = useCallback(() => {
    (window as any).OneDrive.open({
      clientId: onedrive?.settings.client_id,
      action: "download",
      multiSelect: true,
      openInNewWindow: true,
      advanced: {
        filter: ".jpeg,.jpg,.png,.mp4",
      },
      success: (files: any) => {
        callback(
          files.value
            .slice(0, 10)
            .map((file: any) => file["@microsoft.graph.downloadUrl"]),
        );
      },
    });
  }, [onedrive, callback]);

  const googleDrive = useCallback(() => {
    const tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: googleClientId,
      scope: "https://www.googleapis.com/auth/drive.readonly",
      callback: "", // defined later
    });
    let accessToken: any;

    const showPicker = () => {
      const view = new google.picker.View(
        google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS,
      );
      view.setMimeTypes("image/png,image/jpeg,image/jpg,video/mp4");

      new google.picker.PickerBuilder()
        .hideTitleBar()
        .addView(view)
        .setOAuthToken(accessToken)
        .setDeveloperKey(googleApiKey)
        .setCallback(async (data: any) => {
          if (
            data[google.picker.Response.ACTION] == google.picker.Action.PICKED
          ) {
            const file = data[google.picker.Response.DOCUMENTS][0];
            const id = file[google.picker.Document.ID];
            const request = (
              await gapi.client.drive.files.get({
                fileId: id,
                fields: "id,mimeType,size,fileExtension",
              })
            ).result;

            callback([
              "https://www.googleapis.com/drive/v3/files/?id=" +
                request.id +
                "&token=" +
                accessToken +
                "&ext=" +
                request.fileExtension +
                "&size=" +
                request.size,
            ]);
          }
        })
        .build()
        .setVisible(true);
    };

    tokenClient.callback = async (response: any) => {
      if (response.error !== undefined) {
        throw response;
      }
      accessToken = response.access_token;
      showPicker();
    };

    if (accessToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: "" });
    }
  }, [googleClientId, googleApiKey, callback]);

  return {
    googleDrive:
      googleGisLoaded && googlePickerLoaded ? googleDrive : undefined,
    oneDrive: oneDriveLoaded ? oneDrive : undefined,
    dropBox: dropBoxLoaded ? dropBox : undefined,
  };
}
