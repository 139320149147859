import classNames from "classnames";
import React, { HTMLAttributes, ReactNode, TableHTMLAttributes } from "react";

const TableContext = React.createContext<{
  headers: Array<React.ReactNode>;
}>({
  headers: [],
});

function TableRoot({
  headers,
  className,
  children,
  headerCellClassName = "xl:px-4 py-4",
  headerRowClassName,
  ...rest
}: {
  headers: ReactNode[];
  headerRowClassName?: string;
  headerCellClassName?: string;
} & TableHTMLAttributes<HTMLTableElement>) {
  return (
    <TableContext.Provider value={{ headers }}>
      <table className={classNames("block sm:table", className)} {...rest}>
        <thead className="hidden border-b-2 sm:table-header-group">
          {headers && (
            <tr className={headerRowClassName}>
              {headers.map((header, index) => (
                <th className={headerCellClassName} key={index}>
                  {header}
                </th>
              ))}
            </tr>
          )}
        </thead>
        {children}
      </table>
    </TableContext.Provider>
  );
}

function Row({
  children,
  className,
  ...rest
}: HTMLAttributes<HTMLTableRowElement>) {
  return (
    <tr
      className={classNames(
        "block text-gray-700 dark:text-gray-300 sm:table-row",
        className,
      )}
      {...rest}
    >
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { index } as any);
        }
        return child;
      })}
    </tr>
  );
}

function Cell({
  className = "xl:px-4 py-4",
  children,
  index,
  ...rest
}: { index?: number } & HTMLAttributes<HTMLTableCellElement>) {
  const { headers } = React.useContext(TableContext);

  return (
    <td className={classNames("flex sm:table-cell", className)} {...rest}>
      <span className="w-2/5 sm:hidden">{headers[index!]}</span>
      {children}
    </td>
  );
}

export const Table = Object.assign(TableRoot, { Row, Cell });
