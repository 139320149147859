import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { TelegramSelectProps } from "@common/types";
import { Button, Select } from "@app/elements/form";

export default function TelegramSelect({
  onDelete,
  onOpenModal,
  ...props
}: TelegramSelectProps) {
  return (
    <Select
      {...props}
      getLabel={(item, fromList) =>
        fromList ? (
          <div className="flex items-center space-s-1">
            <div>{item.text}</div>
            <button
              className="absolute end-6"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(item.id);
              }}
            >
              <FontAwesomeIcon icon={regular("trash")} />
            </button>
          </div>
        ) : (
          item.text
        )
      }
    >
      {({ options }) => (
        <>
          <li onClick={onOpenModal} onTouchStart={onOpenModal}>
            <Button className="w-full" variant="text" rounded={false}>
              {__("Add")}
            </Button>
          </li>
          {options}
        </>
      )}
    </Select>
  );
}
