import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import { Integration, IntegrationType } from "../interfaces";

export const integrationsKeys = createQueryKeys("integrations", {
  list: null,
  integration: (integrationId?: number) => [{ integrationId }],
});

export const useFetchIntegrations = <TData = Integration[]>(
  select?: (data: Integration[]) => TData,
) =>
  useQuery({
    ...integrationsKeys.list,
    queryFn: async () => {
      const response = await api.get<Integration[]>("integration");
      return response.data;
    },
    select,
  });

export const useFetchTeamIntegrations = <TData = Integration[]>(
  select?: (data: Integration[]) => TData,
) =>
  useQuery({
    ...integrationsKeys.list,
    queryFn: async () => {
      const response = await api.get<Integration[]>("integration/team");
      return response.data;
    },
    select,
  });

export const useFetchActiveIntegration = <Type extends IntegrationType>(
  type: Type,
) =>
  useFetchTeamIntegrations((integrations) =>
    integrations.find(
      (integration) => integration.type === type && integration.active === 1,
    ),
  ).data as Integration<Type> | undefined;

export const useFetchIntegration = (integrationId?: number) =>
  useQuery({
    ...integrationsKeys.integration(integrationId),
    queryFn: async () => {
      const response = await api.get<Integration>(
        `integration/${integrationId}`,
      );
      return response.data;
    },
    enabled: integrationId !== undefined,
  });

export const useCreateIntegration = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) =>
      api.post("integration", data).then((response) => response.data),
    onSuccess: (response) => {
      queryClient.setQueryData(
        integrationsKeys.integration(response.id).queryKey,
        response,
      );
      queryClient.invalidateQueries(integrationsKeys.list);
    },
  });
};

export const useUpdateIntegration = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, ...data }: any) =>
      api.put(`integration/${id}`, data).then((response) => response.data),
    onSuccess: (response) => {
      queryClient.setQueryData(
        integrationsKeys.integration(response.id).queryKey,
        response,
      );

      queryClient.invalidateQueries(integrationsKeys.list);
    },
  });
};

export const useRunIntegrationAction = (id?: number | null, action?: string) =>
  useMutation({
    mutationFn: (data: any) =>
      api
        .post(`integration/${id}/${action}`, data)
        .then((response) => response.data),
  });
