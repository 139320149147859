import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ComponentProps, useCallback, useState } from "react";
import {
  BackButtonContext,
  ChangeTitleContext,
  SetBackButtonVisibilityContext,
  ShowUpgradeModalContext,
  ThemeContext,
  TitleContext,
  ToggleThemeContext,
} from "@common/context/ThemeContext";
import { handleApiError, shouldRetryOnError } from "@common/helpers/api";
import { __ } from "@common/helpers/i18n";
import { UpgradeModal } from "@app/elements/modals";
import { NotificationContainer } from "@app/layout/NotificationContainer";
import UserDataProvider from "./UserDataProvider";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      retry: shouldRetryOnError,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      handleApiError(
        error,
        query.meta !== undefined && query.meta.hideError === true,
      );
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      handleApiError(
        error,
        mutation.meta !== undefined && mutation.meta.hideError === true,
      );
    },
  }),
});

export default function Providers({
  children,
  theme,
  toggleTheme,
  installedVersion,
  onInstall,
}: {
  children: React.ReactNode;
  theme: "light" | "dark";
  toggleTheme: () => void;
} & Omit<ComponentProps<typeof UserDataProvider>, "children">) {
  const [title, setTitle] = useState("");
  const [showBackButton, setShowBackButton] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const changeTitle = useCallback((title: string) => {
    setTitle(title);
    if (typeof document !== "undefined") {
      (document as any).title =
        title + " - " + __(import.meta.env.VITE_SITE_NAME);
    }
  }, []);

  const upgradeModal = useCallback(() => setIsUpgradeModalOpen(true), []);

  return (
    <QueryClientProvider client={queryClient}>
      <UserDataProvider
        installedVersion={installedVersion}
        onInstall={onInstall}
      >
        <TitleContext.Provider value={title}>
          <ChangeTitleContext.Provider value={changeTitle}>
            <ThemeContext.Provider value={theme}>
              <ToggleThemeContext.Provider value={toggleTheme}>
                <BackButtonContext.Provider value={showBackButton}>
                  <SetBackButtonVisibilityContext.Provider
                    value={setShowBackButton}
                  >
                    <ShowUpgradeModalContext.Provider value={upgradeModal}>
                      <UpgradeModal
                        isOpen={isUpgradeModalOpen}
                        onClose={() => setIsUpgradeModalOpen(false)}
                        showCloseIcon
                      />
                      {children}
                      <NotificationContainer />
                    </ShowUpgradeModalContext.Provider>
                  </SetBackButtonVisibilityContext.Provider>
                </BackButtonContext.Provider>
              </ToggleThemeContext.Provider>
            </ThemeContext.Provider>
          </ChangeTitleContext.Provider>
        </TitleContext.Provider>
      </UserDataProvider>
    </QueryClientProvider>
  );
}
