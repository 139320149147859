import { Transition, TransitionRootProps } from "@headlessui/react";
import { ElementType, Fragment } from "react";

export function FadeTransition<T extends ElementType>(
  props: TransitionRootProps<T>,
) {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      {...(props as any)}
    />
  );
}
