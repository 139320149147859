import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { AlertProps } from "@common/types/elements/text/Alert";

const variants = {
  success:
    "text-green-800 border-green-300 bg-green-50 dark:bg-gray-700 dark:text-green-400 dark:border-green-800",
  danger:
    "text-red-800 border-red-300 bg-red-50 dark:bg-gray-700 dark:text-red-400 dark:border-red-800",
  warning:
    "text-orange-700 border-orange-300 bg-orange-50 dark:bg-gray-700 dark:text-orange-400 dark:border-orange-400",
  info: "text-blue-800 border-blue-300 bg-blue-50 dark:bg-gray-700 dark:text-blue-400 dark:border-blue-800",
  light:
    "text-gray-800 shadow bg-white dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600",
  gray: "text-gray-800 border-gray-300 bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600",
  "outline-success":
    "text-green-800 border border-green-300 bg-green-50 dark:bg-gray-700 dark:text-green-400 dark:border-transparent",
  "outline-danger":
    "text-red-800 border border-red-300 bg-red-50 dark:bg-gray-700 dark:text-red-400 dark:border-transparent",
  "outline-warning":
    "text-yellow-800 border border-yellow-300 dark:bg-gray-700 dark:text-yellow-300 dark:border-transparent",
  "outline-info":
    "text-blue-800 border border-blue-300 bg-blue-50 dark:bg-gray-700 dark:text-blue-400 dark:border-transparent",
  "outline-light":
    "text-gray-800 border border-gray-300 bg-gray-50 dark:bg-gray-700 dark:text-gray-300 dark:border-transparent",
};

export function Alert({
  variant,
  icon,
  children,
  className,
  padding = "medium",
  ...rest
}: AlertProps &
  (
    | React.HTMLAttributes<HTMLDivElement>
    | React.HTMLAttributes<HTMLParagraphElement>
  )) {
  return (
    <div
      className={classNames(
        padding === "large"
          ? "p-5"
          : padding === "medium"
            ? "p-4"
            : padding === "small" && "p-1",
        "flex text-sm",
        className,
        variants[variant || "default"],
      )}
      {...rest}
    >
      {icon &&
        (Array.isArray(icon) ? (
          <FontAwesomeIcon
            icon={icon}
            className="me-3 inline h-5 w-5 flex-shrink-0"
          />
        ) : (
          icon
        ))}
      {children}
    </div>
  );
}
