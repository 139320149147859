import { Switch } from "@headlessui/react";
import moment from "moment-jalaali";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  useFetchTeamAccounts,
  useUpdateAutomationStatus,
} from "@common/features/Account";
import { useFetchUsage } from "@common/features/Automation";
import {
  DataResponse,
  useFeatureSupports,
  useMasterUser,
} from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { __, formatDateTime } from "@common/helpers/i18n";
import SubMenuItems, {
  SubMenuItemsProps,
} from "@app/elements/container/SubMenuItems";
import { Button, Select } from "@app/elements/form";
import { Loading } from "@app/elements/loading";
import { Modal } from "@app/elements/modals";
import { SocialIcon, SocialUserAvatar } from "@app/elements/social";
import { Text } from "@app/elements/text";
import { useLocalStorage } from "@app/hooks";

const featureOfMenu: { [key: string]: keyof DataResponse["featureSupports"] } =
  {
    "/menu": "automation_menu",
    "/question": "automation_menu",
    "/product": "automation_button",
  };

moment.relativeTimeRounding(Math.floor);
moment.relativeTimeThreshold("d", 30);
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("h", 24);

moment.updateLocale(document.documentElement.lang.split("-")[0], {
  relativeTime: {
    past: "%s",
    future: "%s",
  },
});
export default function AutomationSidebar(props: SubMenuItemsProps) {
  const masterUser = useMasterUser();
  const history = useHistory();

  const [selectedAccountStored, setSelectedAccountStored] = useLocalStorage<
    number | null
  >("automation-account", null);

  const match = useRouteMatch<{ accountId?: string }>();
  const accountId = match.params.accountId
    ? parseInt(match.params.accountId, 10)
    : undefined;

  const { data: usage } = useFetchUsage();
  const updateAutomationStatus = useUpdateAutomationStatus();

  const featureSupports = useFeatureSupports();

  const { data: accounts, isLoading: isAccountsLoading } = useFetchTeamAccounts(
    (accounts) =>
      accounts.filter(
        (item) =>
          item.login_required === 0 &&
          featureSupports.automation.includes(item.type) &&
          item.can_send_direct,
      ),
  );

  useEffect(() => {
    if (!accountId && accounts && accounts.length > 0) {
      history.replace(
        `/automation/${selectedAccountStored && accounts.some((item) => item.id === selectedAccountStored) ? selectedAccountStored : accounts[0].id}/response`,
      );
    }
  }, [accountId, accounts, history, selectedAccountStored]);

  useEffect(() => {
    if (accounts && !accounts.some((item) => item.id === accountId)) {
      return;
    }
    if (accountId) setSelectedAccountStored(accountId);
  }, [accountId, setSelectedAccountStored, history, accounts]);

  const selectedAccount = accounts?.find((item) => item.id === accountId);
  const [showTutorialModal, setShowTutorialModal] = useState(false);

  const daysLeftToExpire = masterUser.expire_date
    ? moment(formatDateTime(masterUser.expire_date, "Y-MM-DD HH:mm:ss")).diff(
        moment(),
        "days",
      )
    : -1;

  const isExpired = masterUser.expire_date
    ? moment(
        formatDateTime(masterUser.expire_date, "Y-MM-DD HH:mm:ss"),
      ).isBefore()
    : true;

  const startOfNextMonth = useMemo(() => {
    return masterUser.expire_date
      ? moment(
          formatDateTime(masterUser.expire_date, "Y-MM-DD HH:mm:ss"),
        ).subtract(Math.floor(daysLeftToExpire / 30) * 30, "days")
      : moment();
  }, [daysLeftToExpire, masterUser.expire_date]);

  const items = props.items.filter(
    (item) =>
      !(item.url in featureOfMenu) ||
      !selectedAccount ||
      featureSupports[featureOfMenu[item.url]].includes(selectedAccount.type),
  );

  return (
    <>
      <Modal
        size="large"
        padding="medium"
        panelClassName="flex flex-col"
        showCloseIcon
        isOpen={showTutorialModal}
        onClose={() => setShowTutorialModal(false)}
      >
        {showTutorialModal && (
          <>
            <Modal.Title>{__("Automation tutorial video")}</Modal.Title>
            <video
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(
                  navigator.userAgent,
                )
                  ? import.meta.env.VITE_TUTORIAL_VIDEOS_AUTOMATION_MOBILE
                  : import.meta.env.VITE_TUTORIAL_VIDEOS_AUTOMATION_WEB
              }
              controls
              autoPlay
              className="mt-4 h-full w-full"
            />
          </>
        )}
      </Modal>
      <Select
        getLabel={(item) => (
          <div className="flex items-center space-s-2">
            <SocialUserAvatar
              className="mx-auto h-12 w-12 flex-shrink-0 rounded-full"
              user={item.socialUser}
              tooltipPosition="none"
              account={item}
              icon={
                <SocialIcon
                  className="h-8 w-8 rounded-full"
                  social={item.type}
                />
              }
            />
            <div>
              {item.type == "Telegram"
                ? item.meta?.business_username
                : item.name}
            </div>
          </div>
        )}
        getKey={(item) => item.id + ""}
        className="h-14 mt-4 mb-2"
        options={accounts}
        value={selectedAccount}
        onChange={(item) => history.push(`/automation/${item.id}/response`)}
        loading={isAccountsLoading}
        placeholder={__("Choose account")}
      >
        {({ options }) =>
          options.length === 0 ? (
            <Button
              to={"/accounts/new"}
              variant="outline-light"
              className="w-full border-none"
              icon={regular("plug")}
            >
              <span className="text-sm font-medium">{__("Add account")}</span>
            </Button>
          ) : (
            options
          )
        }
      </Select>
      <div className="pb-4 mb-2 border-b text-gray-600 flex items-center px-3 py-2 font-semibold">
        <span className="truncate text-gray-900 dark:text-gray-300">
          {__("Status")}
        </span>
        <Switch
          checked={selectedAccount?.automation_status}
          onChange={(status) => {
            selectedAccount &&
              updateAutomationStatus.mutate({
                accountId: selectedAccount.id,
                status,
              });
          }}
          className={`${
            selectedAccount?.automation_status
              ? "bg-primary-500"
              : "bg-gray-200 dark:bg-gray-400"
          } relative inline-flex h-4 w-8 items-center rounded-full transition-colors ms-auto`}
        >
          <Loading enabled={updateAutomationStatus.isPending} size="small" />
          <span
            className={`${
              selectedAccount?.automation_status
                ? "ltr:translate-x-4 rtl:-translate-x-4"
                : "ltr:translate-x-1 rtl:-translate-x-1"
            } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
      <SubMenuItems {...props} items={items} />

      <div className="mt-auto">
        {usage && usage.total !== -1 && usage.used >= usage.total && (
          <Button
            variant="link"
            to="/renew"
            padding="small"
            icon={regular("chevron-left")}
            iconPosition="end"
          >
            {__("Upgrade")}
          </Button>
        )}
        <Text variant="muted">
          <Button
            icon={regular("circle-play")}
            className="text p-2.5 mt-2 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-zinc-100 w-full mb-3"
            onClick={() => setShowTutorialModal(true)}
          >
            {__("Automation tutorial video")}
          </Button>
        </Text>
        {usage && masterUser.package_id != -1 && (
          <div className="mt-auto">
            <div className="flex justify-between text-xs items-center">
              <Text
                variant={
                  usage.used >= usage.total && usage.total != -1
                    ? "danger"
                    : "gray"
                }
              >
                {__("Used credit")}
              </Text>
              <div className="flex bg-gray-200 dark:bg-gray-700 rounded-sm px-1 py-0.5">
                <Text variant="gray">
                  {usage.total === -1 ? "∞" : usage.total}
                </Text>
                /{usage.used}
              </div>
            </div>
            {usage.total != -1 && (
              <div className="bg-gray-200 dark:bg-gray-700 rounded-md mt-2">
                <div
                  className="h-1 bg-gray-900 dark:bg-gray-200 rounded-md"
                  style={{
                    width: (usage.used / usage.total) * 100 + "%",
                  }}
                />
              </div>
            )}
            <div className="flex flex-col mt-3">
              {daysLeftToExpire > -1 &&
                masterUser.expire_date &&
                !isExpired &&
                !(daysLeftToExpire > 29 && usage.total == -1) && (
                  <Text variant="muted">
                    {__(
                      daysLeftToExpire > 29
                        ? "%{duration} left for recharge"
                        : "%{duration} left to end of subscription",
                      {
                        duration: (daysLeftToExpire > 29 && usage.total != -1
                          ? startOfNextMonth
                          : moment(
                              formatDateTime(
                                masterUser.expire_date,
                                "Y-MM-DD HH:mm:ss",
                              ),
                            )
                        ).toNow(),
                      },
                    )}
                  </Text>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
