import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { regular } from "@common/helpers/fontawesome";
import { FileBoxProps } from "@common/types";
import { FileIcon } from "./FileManager";

interface DragItem {
  index: number;
  file: FileBoxProps["file"];
}

export function FileBox({
  active,
  file,
  index,
  onRemove,
  setActive,
  onEdit,
  showIndex,
  moveCard,
}: FileBoxProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "file",

    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "file",
    item: () => {
      return { index, file } as DragItem;
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      className={classNames(
        "group relative w-20 rounded-md ring-1 ring-gray-200 dark:ring-gray-700",
        active && "ring-offset-1",
      )}
      ref={ref}
      style={{ opacity }}
      onClick={setActive}
    >
      <div className="relative">
        <div
          className="aspect-h-1 aspect-w-1 bg-gray-200 bg-contain"
          style={{
            backgroundImage: `url("${file.thumbnailUrl}")`,
          }}
        >
          {file.fileType === "document" && (
            <div className="flex items-center justify-center">
              <FontAwesomeIcon icon={FileIcon[file.ext]} className="h-8 w-8" />
            </div>
          )}
        </div>
        <FontAwesomeIcon
          icon={regular("circle-xmark")}
          className="absolute -start-2 -top-2 z-10 hidden h-6 w-6 cursor-pointer rounded-full bg-gray-800/80 text-white group-hover:block"
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
        />
        {onEdit && (
          <FontAwesomeIcon
            icon={regular("pen")}
            className="absolute bottom-1 start-px z-10 cursor-pointer rounded-md bg-gray-800/80 p-2 text-white"
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          />
        )}
      </div>
      <div className="absolute inset-0 hidden cursor-pointer bg-gray-300/20 group-hover:block" />
      {showIndex && <div className="text-center">{index + 1}</div>}
    </div>
  );
}
