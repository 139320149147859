import {
  Alignment,
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from "@floating-ui/react";
import React, { useRef, useState } from "react";
import { isRTL } from "@common/helpers/i18n";

type Side = "top" | "side-start" | "bottom" | "side-end";
type AlignedPlacement = `${Side}-${Alignment}`;
type Placement = Side | AlignedPlacement;

interface TooltipProps {
  placement?: Placement;
  tooltip: React.ReactNode;
  children: React.ReactElement;
}

export function Tooltip({
  children,
  placement = "top",
  tooltip,
}: TooltipProps) {
  const arrowRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: placement
      .replace("side-start", isRTL ? "left" : "right")
      .replace("side-end", isRTL ? "right" : "left") as any,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5,
      }),
      shift({ padding: 20 }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const hover = useHover(context, {
    move: false,
    enabled: !!tooltip,
  });

  const focus = useFocus(context, { enabled: !!tooltip });
  const role = useRole(context, { role: "tooltip", enabled: !!tooltip });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    role,
  ]);

  const { isMounted, styles } = useTransitionStyles(context, { duration: 100 });

  return (
    <>
      {React.cloneElement(
        children,
        getReferenceProps({
          ...children.props,
          ref: refs.setReference,
        }),
      )}
      {isMounted && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              ...styles,
              maxWidth: "calc(100vw - 40px)",
            }}
            {...getFloatingProps()}
            className="z-40 min-w-[150px] whitespace-pre-wrap rounded-md bg-gray-500 px-4 py-2 text-center text-white dark:text-zinc-100"
          >
            {tooltip}
            <FloatingArrow
              ref={arrowRef}
              context={context}
              className="fill-gray-500"
            />
          </div>
        </FloatingPortal>
      )}
    </>
  );
}
