import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { isBefore } from "date-fns";
import { ImgHTMLAttributes, useState } from "react";
import { SocialUser, useUpdateSocialUser } from "@common/features/Social";
import { regular } from "@common/helpers/fontawesome";
import { socialAssetLink } from "@common/helpers/functions";
import { SocialUserAvatarProps } from "@common/types";
import { MediaWithFallback, Tooltip } from "../helpers";
import { SocialIcon } from "./SocialIcon";

export function SocialUserAvatar({
  user,
  account,
  tooltipPosition,
  icon,
  ...rest
}: SocialUserAvatarProps & ImgHTMLAttributes<HTMLImageElement>) {
  const [socialUser, setSocialUser] = useState<SocialUser<boolean>>();

  const updateSocialUser = useUpdateSocialUser(user?.id, account.id);

  return (
    <div className="relative flex items-center">
      <MediaWithFallback
        {...rest}
        src={socialAssetLink(
          socialUser?.image || user?.image || "",
          account.type,
        )}
        alt={user?.username}
        altImage={
          icon ? (
            icon
          ) : (
            <div
              className={classNames(
                "flex items-center justify-center rounded-full bg-gray-400/80 dark:bg-gray-500/80",
                rest.className,
              )}
            >
              <FontAwesomeIcon icon={regular("user")} className="text-white" />
            </div>
          )
        }
        onError={() => {
          if (
            socialUser === undefined &&
            user &&
            // updated social user if last update more than 7 day
            isBefore(
              new Date(user.updated_at),
              new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            ) &&
            (user?.social === "InstagramOfficial" ||
              user?.social === "Instagram")
          ) {
            updateSocialUser.mutate(undefined, {
              onSuccess: (socialUser) => {
                setSocialUser(socialUser);
              },
              onError: () => {
                setSocialUser(user);
              },
            });
          }
        }}
      />
      {tooltipPosition !== "none" && (
        <Tooltip
          tooltip={account.name}
          placement={tooltipPosition === "start" ? "side-start" : "side-end"}
        >
          <span className="absolute -bottom-1 z-10 flex rounded-full border bg-gray-100 p-0.5 ltr:-right-1 rtl:-left-1 dark:bg-zinc-800">
            <SocialIcon social={account.type} />
          </span>
        </Tooltip>
      )}
    </div>
  );
}
