import { useState } from "react";
import {
  useFetchActionCategories,
  useFetchActionPlaylists, // useFetchActionPlaylists,
} from "@common/features/Account";
import {
  YoutubePrivacyList,
  useExtraPostState,
  usePostStore,
} from "@common/features/Post";
import { regular } from "@common/helpers/fontawesome";
import { parseIntUndefined } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { CheckBox, Select, TextInput } from "@app/elements/form";
import { SocialIcon } from "@app/elements/social";
import { Text } from "@app/elements/text";
import { DropBox } from "@app/features/filemanager";

// import {Text} from '@app/elements/text';

export default function YouTubeSection(props: Partial<SectionToggleProps>) {
  const [data, dispatch] = useExtraPostState("YouTube");
  const [accountId] = usePostStore(
    (state) =>
      state.selectedAccounts.find((item) => item.type === "YouTube")!.id,
  );
  const [coverToggle, setCoverToggle] = useState(false);

  const { data: categories, isLoading: isCategoryLoading } =
    useFetchActionCategories(accountId);
  const { data: playlists, isLoading: isPlayListLoading } =
    useFetchActionPlaylists(accountId);

  return (
    <SectionToggle
      {...props}
      title={__("YouTube settings")}
      defaultOpen
      icon={<SocialIcon social="YouTube" />}
    >
      <ElementsGroup>
        <TextInput
          placeholder={__("Title")}
          onInputChange={(title) => dispatch("title", title)}
          value={data.title || ""}
          icon={regular("tag")}
          name="youtube_title"
        />
        <Select
          name="youtube_category"
          label={__("Select category for YouTube")}
          placeholder={__("Select category for YouTube")}
          options={Object.keys(categories || []).map((value) => ({
            value,
          }))}
          value={data.category ? { value: data.category } : undefined}
          getLabel={(item) => categories?.[item.value]}
          onChange={(item: any) => dispatch("category", item.value)}
          loading={isCategoryLoading}
        />
        <Select
          name="youtube_privacy"
          label={__("YouTube video privacy")}
          placeholder={__("YouTube video privacy")}
          options={(
            Object.keys(YoutubePrivacyList) as Array<
              keyof typeof YoutubePrivacyList
            >
          ).map((value) => ({
            value,
          }))}
          value={data.privacy ? { value: data.privacy } : undefined}
          getLabel={(item) => YoutubePrivacyList[item.value]}
          onChange={(item) => dispatch("privacy", item!.value)}
        />
        <Select
          name="youtube_playlist"
          label={__("YouTube video playlist")}
          placeholder={__("YouTube video playlist")}
          options={playlists || []}
          value={data.playlist || []}
          getLabel={(item) => item.title}
          getKey={(item) => item.id || item.title}
          onChange={(item) => dispatch("playlist", item)}
          loading={isPlayListLoading}
          onSubmit={(playlist) =>
            dispatch("playlist", [
              ...(data.playlist || []),
              { title: playlist },
            ])
          }
          searchable
          multiple
        >
          {({ options }) =>
            options.length === 0 ? (
              <Text padding="medium" block>
                {__("To add new playlist press Enter")}
              </Text>
            ) : (
              options
            )
          }
        </Select>
        <CheckBox
          id="video_cover"
          checked={coverToggle}
          onInputChange={() => setCoverToggle(!coverToggle)}
          label={
            <>
              <Text block>{__("Cover image")}</Text>
            </>
          }
        />
        {coverToggle && (
          <DropBox
            selectedFileId={parseIntUndefined(data.video_cover)}
            onChange={(fileId) => dispatch("video_cover", fileId + "")}
            allowType={["image"]}
            sizeCaption={{ maxSize: 2 }}
          />
        )}
      </ElementsGroup>
    </SectionToggle>
  );
}
