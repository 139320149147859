import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useFetchFile } from "@common/features/File";
import {
  People,
  checkTypeExistArray,
  useExtraPostState,
  usePostStore,
} from "@common/features/Post";
import { useSearchPeople } from "@common/features/Search";
import { useFeatureSupports } from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { parseIntUndefined, socialAssetLink } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { Section } from "@app/elements/container";
import { TextInput } from "@app/elements/form";
import { Modal } from "@app/elements/modals";
import { Text } from "@app/elements/text";
import PollSection from "./PollSection";

const tagPosition: { x: number; y: number } = { x: 0, y: 0 };

export default function MediaPreview() {
  const featureSupports = useFeatureSupports();

  const [
    {
      preview,
      selectedAccounts,
      thumbnailsSecond,
      selectedTag,
      caption,
      hashtag,
      postType,
      poll,
    },
    dispatch,
  ] = usePostStore(
    (state) => ({
      preview: state.preview,
      selectedAccounts: state.selectedAccounts,
      thumbnailsSecond: state.preview
        ? state.thumbnailsSecond[state.preview.id]
        : undefined,
      selectedTag: state.preview
        ? state.mediaTags[state.preview.id]
        : undefined,
      caption: state.captions.html.main,
      hashtag:
        state.postType !== "story" &&
        checkTypeExistArray(state.selectedAccounts, featureSupports.hashtag)
          ? state.hashtag
          : undefined,
      postType: state.postType,
      poll: state.poll,
    }),
    true,
  );

  const [paddingTop, setPaddingTop] = useState(0);
  const [tagModal, setTagModal] = useState(false);
  const [searchText, setSearchText] = useState<string>();

  const [data] = useExtraPostState("InstagramOfficial");

  const { data: cover } = useFetchFile(parseIntUndefined(data.video_cover));

  const videoRef = useRef<HTMLVideoElement>(null);

  const { data: searchPeoples, isPending: isSearching } = useSearchPeople(
    (selectedAccounts || []).map((item) => item.id),
    selectedAccounts !== undefined ? searchText : undefined,
  );

  useEffect(() => {
    if (preview) {
      if (preview.fileType === "image") {
        const preloaderImg = document.createElement("img");
        preloaderImg.src = preview.url;

        preloaderImg.addEventListener("load", () => {
          const w = preloaderImg.width;
          const h = preloaderImg.height;
          let paddingTop: number;
          if (h > w) {
            paddingTop = (h / w > 1.25 ? 1.25 : h / w) * 100;
          } else {
            paddingTop = (w / h > 1.91 ? 100 / 191 : h / w) * 100;
          }

          setPaddingTop(paddingTop);
        });
      } else setPaddingTop(0);

      if (preview.fileType === "video" && videoRef.current) {
        videoRef.current.currentTime = thumbnailsSecond || 0;
      }
    }
  }, [preview, thumbnailsSecond]);

  function openTagModal(e: React.MouseEvent<HTMLDivElement>) {
    const rect = e.currentTarget.getBoundingClientRect();
    if (!rect.height || !rect.width) return;
    tagPosition.x = (e.pageX - (window.pageXOffset + rect.left)) / rect.width;
    tagPosition.y = (e.pageY - (window.pageYOffset + rect.top)) / rect.height;

    setTagModal(true);
  }

  function addTag(people: People) {
    if (!preview) return;
    const tag = (selectedTag || []).concat({
      id: people.pk,
      text: people.username,
      locationX: tagPosition.x,
      locationY: tagPosition.y,
    });
    dispatch({ type: "mediaTagsChanged", payload: tag });
    setTagModal(false);
  }

  function removeTag(index: number) {
    const tag = selectedTag!;
    tag.splice(index, 1);
    dispatch({ type: "mediaTagsChanged", payload: tag });
  }

  const canAddTag =
    postType !== "story" &&
    selectedAccounts &&
    selectedAccounts.some((item) =>
      featureSupports.photo_tag.includes(item.type),
    );
  return (
    <Section
      className="relative hidden sm:block"
      pure
      title={postType !== "story" ? __("Preview") : undefined}
    >
      {preview ? (
        <div className="relative">
          <Modal isOpen={tagModal} onClose={() => setTagModal(false)}>
            <div className="p-1">
              <TextInput
                loading={isSearching}
                icon={regular("magnifying-glass")}
                onKeyDown={(e) => {
                  e.keyCode === 13 && setSearchText(e.currentTarget.value);
                }}
                onChange={(e) => {
                  e.preventDefault();
                  setSearchText(undefined);
                }}
                name="tag_search"
              />
              {searchPeoples && searchPeoples.length !== 0 ? (
                <ul className="divide-y">
                  {searchPeoples.map((item) => (
                    <li
                      className="flex cursor-pointer items-center p-2 space-s-1"
                      key={item.pk}
                      onClick={() => addTag(item)}
                    >
                      <img
                        src={socialAssetLink(
                          item.profile_pic_url,
                          "InstagramOfficial",
                        )}
                        className="h-8 w-8 rounded-full bg-gray-200 dark:bg-gray-600"
                        alt=""
                      />
                      <div className="content">{item.username}</div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div
                  className="field-caption p-2"
                  style={{
                    margin: "0.5em",
                    border: "1px solid #ff4242",
                    width: "auto",
                  }}
                >
                  {__(
                    "You can only tag public profiles & write the username in full.",
                  )}
                </div>
              )}
            </div>
          </Modal>
          {preview.fileType === "image" ? (
            <>
              <div
                className={
                  postType === "story"
                    ? "aspect-h-16 aspect-w-9 absolute left-0 top-0 h-full w-full bg-black bg-contain bg-center bg-no-repeat"
                    : "bg-gray-100 bg-cover bg-center dark:bg-gray-600"
                }
                style={{
                  paddingTop:
                    postType !== "story" ? `${paddingTop}%` : undefined,
                  backgroundImage: `url(${preview.url})`,
                }}
              />
              {canAddTag && (
                <div onClick={openTagModal} className="absolute inset-0 z-10">
                  {selectedTag &&
                    selectedTag.map((item, index) => (
                      <div
                        onClick={(e) => {
                          removeTag(index);
                          e.stopPropagation();
                        }}
                        className="group absolute h-4 w-4 cursor-pointer rounded-full bg-rose-600"
                        style={{
                          left: `calc(${item.locationX * 100}% - 7.5px)`,
                          top: `calc(${item.locationY * 100}% - 7.5px)`,
                        }}
                        key={item.id}
                      >
                        <div className="absolute left-1/2 top-6 hidden -translate-x-1/2 cursor-pointer select-none bg-rose-500 px-4 py-2 text-center text-white group-hover:block">
                          {item.text}
                        </div>
                      </div>
                    ))}
                  <div className="absolute bottom-2 start-2 z-20 flex items-center justify-center rounded-md bg-slate-800/60 p-2 text-white space-s-1">
                    <FontAwesomeIcon icon={regular("circle-user")} />
                    <div>{__("For tag people click on photo")}</div>
                  </div>
                </div>
              )}
            </>
          ) : preview.fileType === "video" ? (
            <>
              {cover && (
                <div
                  className="absolute inset-0 z-10 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${cover.url})`,
                  }}
                />
              )}
              <video
                className="w-full"
                src={preview.url}
                playsInline
                muted
                loop
                ref={videoRef}
                disablePictureInPicture
              />
              {canAddTag && (
                <div className="absolute bottom-0 end-0 flex">
                  <FontAwesomeIcon
                    icon={regular("caret-right")}
                    className="cursor-pointer bg-black px-2 py-1 text-white"
                    onClick={() => {
                      if (
                        !thumbnailsSecond ||
                        thumbnailsSecond + 1 < videoRef.current!.duration
                      )
                        dispatch({
                          type: "thumbnailsSecondChanged",
                          payload: (thumbnailsSecond || 0) + 1,
                        });
                    }}
                  />
                  <FontAwesomeIcon
                    icon={regular("caret-left")}
                    className="cursor-pointer bg-black px-2 py-1 text-white"
                    onClick={() => {
                      if (thumbnailsSecond && thumbnailsSecond >= 1)
                        dispatch({
                          type: "thumbnailsSecondChanged",
                          payload: (thumbnailsSecond || 0) - 1,
                        });
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="flex bg-white pt-4">
              <div className="mx-2 flex h-16 w-16 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-600">
                <FontAwesomeIcon icon={regular("file")} className="h-8 w-8" />
              </div>
              <div className="flex flex-col justify-center overflow-hidden">
                <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {preview.title}
                </div>
                <Text variant="muted">
                  {__("File size") + ": " + preview.filesize}
                </Text>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={
            postType === "story"
              ? "aspect-h-16 aspect-w-9 bg-white dark:bg-gray-700"
              : "aspect-h-1 aspect-w-1 bg-gray-100 dark:bg-gray-700"
          }
        >
          {postType === "story" && (
            <Text className="flex items-center justify-center" variant="muted">
              {__("Preview")}
            </Text>
          )}
        </div>
      )}
      {postType !== "story" && (
        <div className="min-h-[190px] space-y-2 p-4">
          {caption === "" ? (
            <div>
              <span className="mb-2.5 block h-2.5 bg-gray-200 dark:bg-gray-400" />
              <span className="mb-2.5 block h-2.5 w-3/5 bg-gray-200 dark:bg-gray-400" />
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: caption,
              }}
            />
          )}

          <div>
            {hashtag !== undefined &&
              hashtag.length !== 0 &&
              "#" + hashtag.join(" #")}
          </div>

          {poll && <PollSection poll={poll} />}
        </div>
      )}
    </Section>
  );
}
