import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@common/helpers/fontawesome";
import { ucfirst } from "@common/helpers/functions";
import { __, formatFromNow } from "@common/helpers/i18n";
import { FileManagerBoxProps } from "@common/types";
import { DropDownMenu } from "../../elements/helpers/DropDownMenu";
import { FileIcon } from "./FileManager";

export const FileManagerBox = ({
  file,
  onSelect,
  active,
  onRemove,
  onDelete,
  displayType,
  onStar,
}: FileManagerBoxProps) => {
  return displayType === "grid" ? (
    <div className="group rounded-md border p-2 shadow-sm">
      <div className="relative">
        <div
          className="aspect-h-1 aspect-w-1 bg-gray-200 bg-cover bg-center dark:bg-gray-600"
          style={{
            backgroundImage: `url("${file.thumbnailUrl}")`,
          }}
        />
        {file.fileType === "video" && (
          <div className="absolute inset-0 flex items-center justify-center">
            <FontAwesomeIcon
              icon={regular("play")}
              className="h-12 w-12 text-primary-500"
            />
          </div>
        )}
        {file.fileType === "document" && (
          <div className="absolute inset-0 flex items-center justify-center">
            <FontAwesomeIcon icon={FileIcon[file.ext]} className="h-12 w-12" />
          </div>
        )}
        <span className="absolute start-2 top-2 hidden rounded-md bg-primary-500 px-2 py-1 text-xs text-white group-hover:block">
          {file.ext}
        </span>
        <div
          className={
            "absolute inset-0 z-10 flex cursor-pointer items-center justify-center bg-gray-300/30 transition group-hover:opacity-100 " +
            (active ? "opacity-100" : "opacity-0")
          }
          onClick={active ? onRemove : onSelect}
        >
          <FontAwesomeIcon
            icon={regular("circle-check")}
            className={
              "h-12 w-12 rounded-full " +
              (active ? "bg-white text-primary-500 opacity-100" : "opacity-30")
            }
          />
        </div>
        {file.star == 1 && (
          <FontAwesomeIcon
            icon={regular("star")}
            className="absolute bottom-2 start-2 h-4 w-4 text-amber-400"
          />
        )}
        <div className="absolute end-2 top-2">
          <DropDownMenu
            className="z-10"
            position="bottom-end"
            menu={[
              {
                text: __("View"),
                icon: <FontAwesomeIcon icon={regular("eye")} />,
                onClick: () => {
                  window.open(file.url, "_blank")?.focus();
                },
              },
              {
                text: file.star == 1 ? __("Un-star") : __("Star"),
                icon: <FontAwesomeIcon icon={regular("star")} />,
                onClick: onStar,
              },
              {
                text: __("Delete"),
                icon: <FontAwesomeIcon icon={regular("trash")} />,
                onClick: onDelete,
              },
            ]}
          />
        </div>
      </div>

      <div className="overflow-hidden overflow-ellipsis pt-2 dark:text-zinc-100">
        {file.title}
      </div>
    </div>
  ) : (
    <tr
      className="cursor-pointer border-t-2"
      key={file.id}
      onClick={active ? onRemove : onSelect}
    >
      <td className="px-6 py-4">
        <FontAwesomeIcon
          icon={regular("circle-check")}
          className={active ? "text-primary-500 " : "text-gray-200"}
        />
      </td>
      <td className="px-6 py-4">
        <div
          style={{
            width: 40,
            height: 40,
            backgroundImage: `url("${file.thumbnailUrl}")`,
            backgroundSize: "contain",
          }}
        />
      </td>
      <td className="px-6 py-4">{file.title}</td>
      <td className="px-6 py-4">{formatFromNow(file.created_at)}</td>
      <td className="px-6 py-4">{file.filesize}</td>
      <td className="px-6 py-4">{__(ucfirst(file.fileType))}</td>
    </tr>
  );
};
