import { Suspense, lazy } from "react";
import { Loading } from "../loading";
import type { EmojiPickerProps as EmojiPickerPropsRoot } from "./emojipicker/EmojiPicker";

const LazyEmojiPicker = lazy(() => import("./emojipicker/EmojiPicker"));

export type EmojiPickerProps = EmojiPickerPropsRoot;

export function EmojiPicker(props: EmojiPickerProps) {
  return (
    <Suspense fallback={<Loading />}>
      <LazyEmojiPicker {...props} />
    </Suspense>
  );
}
