import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SetBackButtonVisibilityContext } from "@common/context/ThemeContext";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";

const tabs = [
  { route: "/posts", icon: regular("file-lines"), label: __("Posts") },
  { route: "/inbox", icon: regular("envelope"), label: __("Messages") },
  { route: "/post", icon: regular("circle-plus"), label: "" },
  { route: "/comment", icon: regular("comments"), label: __("Comments") },
  {
    route: "/automation-accounts",
    icon: regular("message-bot"),
    label: __("Automation"),
  },
];

export default function Footer() {
  const setBackButtonVisibility = useContext(SetBackButtonVisibilityContext);

  const location = useLocation();
  const history = useHistory();

  const isActive = (menu: (typeof tabs)[0]) =>
    location.pathname.replace(/\/$/g, "") === menu.route;

  function redirectTo(location: string) {
    setBackButtonVisibility(false);
    history.push(location);
  }

  function renderItem(item: (typeof tabs)[0]) {
    return item.label === "" ? (
      <div
        className={classNames(
          "flex flex-1 cursor-pointer flex-col items-center justify-center",
          isActive(item) ? "text-primary-500" : "text-black",
        )}
        key={item.route}
        onClick={() => redirectTo(item.route)}
      >
        <FontAwesomeIcon
          icon={item.icon}
          className="h-10 w-10 rounded-full bg-primary-500 text-zinc-100 dark:bg-primary-600"
        />
      </div>
    ) : (
      <button
        onClick={() => redirectTo(item.route)}
        className={classNames(
          "flex flex-1 cursor-pointer flex-col items-center justify-center",
          isActive(item) ? "text-primary-500" : "",
        )}
        key={item.route}
      >
        <FontAwesomeIcon icon={item.icon} className="h-5 w-5" />
        <span className="text-xs">{__(item.label as any)}</span>
      </button>
    );
  }

  return (
    <nav
      className="inset-x-0 bottom-0 z-20 flex bg-white shadow dark:bg-dark-background md:hidden"
      style={{
        height: "calc(46px + env(safe-area-inset-bottom))",
        paddingBottom: "calc(env(safe-area-inset-bottom) / 2)",
      }}
    >
      {tabs.map((item) => renderItem(item))}
    </nav>
  );
}
