import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { PermissionProps } from "@common/types";
import { Button } from "../form";

export const Permission = ({
  enable,
  ...other
}: PermissionProps & React.ButtonHTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...other}>
      {enable && (
        <div className="absolute inset-0 z-10 flex h-full w-full flex-col items-center justify-center rounded-md bg-white/70 p-4 dark:bg-gray-700/70">
          <FontAwesomeIcon
            icon={regular("bolt")}
            className="mb-4 h-20 w-20 text-gray-400"
          />
          <span className="mb-2 text-2xl font-bold">
            {__("You need to upgrade your account")}
          </span>
          <span>
            {__(
              "To access all features in %{site} you need to upgrade your plan.",
              { site: __(import.meta.env.VITE_SITE_NAME) },
            )}
          </span>
          <span className="mt-4">
            <Button
              to="/renew"
              title={__("Upgrade")}
              className="button button-successful linked"
              variant="primary"
            >
              {__("Upgrade")}
            </Button>
          </span>
        </div>
      )}
    </div>
  );
};
