import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useRef } from "react";
import { createRoot } from "react-dom/client";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { ConfirmProps } from "@common/types";
import { Button } from "../form";
import { Modal } from "./Modal";

const typeBackground = {
  danger: "bg-red-100",
  warning: "bg-orange-100",
  info: "bg-blue-100",
  success: "bg-green-100",
};

const typeIcon = {
  danger: (
    <FontAwesomeIcon
      icon={regular("triangle-exclamation")}
      className="h-6 w-6 text-red-600"
      aria-hidden="true"
    />
  ),
  warning: (
    <FontAwesomeIcon
      icon={regular("triangle-exclamation")}
      className="h-6 w-6 text-red-600"
      aria-hidden="true"
    />
  ),
  info: (
    <FontAwesomeIcon
      icon={regular("triangle-exclamation")}
      className="h-6 w-6 text-red-600"
      aria-hidden="true"
    />
  ),
  success: (
    <FontAwesomeIcon
      icon={regular("triangle-exclamation")}
      className="h-6 w-6 text-red-600"
      aria-hidden="true"
    />
  ),
};

function ConfirmModal({
  title,
  caption,
  okText,
  cancelText,
  type = "danger",
  icon,
  onCancel,
  onOk,
  onClose,
}: {
  onClose: () => void;
} & ConfirmProps) {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal onClose={onClose} isOpen initialFocus={cancelButtonRef}>
      <div className="bg-white px-4 pb-4 pt-5 dark:bg-dark-background sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div
            className={classNames(
              "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
              typeBackground[type!],
            )}
          >
            {icon || typeIcon[type!]}
          </div>
          <div className="mt-3 text-center sm:ms-4 sm:mt-0 sm:text-start">
            {title && (
              <Modal.Title
                as="h3"
                className="text-base font-semibold leading-6 text-gray-900 dark:text-zinc-100"
              >
                {title}
              </Modal.Title>
            )}
            <div className="mt-2">
              <p>{caption}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-gray-100 px-4 py-3 space-s-4 dark:bg-gray-800 sm:flex-row-reverse sm:justify-normal sm:px-6 sm:space-s-reverse">
        <Button
          variant={type}
          onClick={() => {
            onOk();
            onClose();
          }}
        >
          {okText ? okText : __("Ok")}
        </Button>
        <Button
          variant="outline-light"
          onClick={() => {
            onCancel?.();
            onClose();
          }}
          ref={cancelButtonRef}
        >
          {cancelText ? cancelText : __("Cancel")}
        </Button>
      </div>
    </Modal>
  );
}

export function Confirm(props: ConfirmProps) {
  let divTarget = document.getElementById("confirm-modal");
  if (!divTarget) {
    divTarget = document.createElement("div");
    divTarget.id = "confirm-modal";
    document.body.appendChild(divTarget);
  }

  const root = createRoot(divTarget);

  function removeElementReconfirm() {
    const target = document.getElementById("confirm-modal");
    if (target) {
      root.unmount();
      target.parentNode!.removeChild(target);
    }
  }
  divTarget.focus();

  setTimeout(() => {
    root.render(
      <ConfirmModal
        {...props}
        onClose={() => {
          removeElementReconfirm();
        }}
      />,
    );
  }, 50);
}
