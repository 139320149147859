import { Account } from "@common/features/Account";
import {
  GroupInsightsResponse,
  MetricData,
  MetricName,
  backgroundColors,
} from "@common/features/Insights";
import { __, formatDate, isRTL } from "@common/helpers/i18n";

interface InsightsData {
  growth: Highcharts.Options;
  impressions: Highcharts.Options;
  mediatype: Highcharts.Options;
  posts_sent: Highcharts.Options;

  total: {
    impressions?: number;
    growth?: number;
    total_follower?: number;
    sent?: number;
  };
}

export function GroupSelector<TMetric extends string | undefined = undefined>(
  _response: GroupInsightsResponse,
  accounts: { [key: number]: Account },
  metric?: TMetric,
): TMetric extends string ? Highcharts.Options : InsightsData {
  const data: Partial<InsightsData> = {};

  if (typeof metric === "undefined")
    data.total = {
      impressions: 0,
      growth: 0,
      sent: 0,
      total_follower: 0,
    };

  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }
    switch (response.metric) {
      case "num_posts_by_type": {
        const postPerType: {
          [key in "text" | "album" | "image" | "video"]: {
            [date: string]: number;
          };
        } = {
          text: {},
          album: {},
          image: {},
          video: {},
        };
        const dateLabels = Object.keys(Object.values(response.data)[0]).map(
          (date) =>
            formatDate(
              date,
              formatDate(date, isRTL ? "jYYYY/jMM/jDD" : "YYYY/MM/DD"),
            ),
        );
        Object.keys(response.data).forEach((accountId) => {
          const datavalue = response.data[accountId as any];
          Object.keys(datavalue).forEach((date) => {
            const value = datavalue[date];
            if (typeof postPerType.album[date] === "undefined") {
              postPerType.text[date] = 0;
              postPerType.album[date] = 0;
              postPerType.image[date] = 0;
              postPerType.video[date] = 0;
            }

            try {
              const parseData = JSON.parse(value);
              (
                Object.keys(parseData) as Array<
                  "text" | "image" | "album" | "video" | "link" | "document"
                >
              ).forEach((key) => {
                const _value = parseData[key];
                if (key === "link" || key === "document") {
                  key = "text";
                }
                postPerType[key][date] += parseInt(_value);
              });
            } catch (e) {
              // ignore
            }
          });
        });

        data.mediatype = {
          xAxis: {
            categories: dateLabels,
          },
          series: [
            {
              type: "column",
              name: __("Text"),
              data: Object.values(postPerType.text),
              color: backgroundColors.Text[0],
              colorAxis: backgroundColors.Text[1],
              borderColor: backgroundColors.Text[1],
            },
            {
              type: "column",
              name: __("Image"),
              data: Object.values(postPerType.image),
              color: backgroundColors.Image[0],
              colorAxis: backgroundColors.Image[1],
              borderColor: backgroundColors.Image[1],
            },
            {
              type: "column",
              name: __("Video"),
              data: Object.values(postPerType.video),
              color: backgroundColors.Video[0],
              colorAxis: backgroundColors.Video[1],
              borderColor: backgroundColors.Video[1],
            },
            {
              type: "column",
              name: __("Album"),
              data: Object.values(postPerType.album),
              color: backgroundColors.Album[0],
              colorAxis: backgroundColors.Album[1],
              borderColor: backgroundColors.Album[1],
            },
          ],
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
        };
        break;
      }
      case "page_follow_growth": {
        const growth: MetricData<{ [date: string]: number }> = {};
        Object.values(accounts).forEach((account) => {
          growth[account.type] = {};
        });

        const dateLabels = Object.keys(Object.values(response.data)[0]).map(
          (date) =>
            formatDate(
              date,
              formatDate(date, isRTL ? "jYYYY/jMM/jDD" : "YYYY/MM/DD"),
            ),
        );
        (Object.keys(response.data) as unknown as number[]).forEach(
          (accountId) => {
            const datavalue = response.data[accountId];
            const account = accounts[accountId];
            Object.keys(datavalue).forEach((date) => {
              const value = datavalue[date];
              if (typeof growth[account.type]![date] === "undefined") {
                growth[account.type]![date] = 0;
              }
              growth[account.type]![date] += value;
            });
          },
        );

        const Growth: {
          [key: string]: Highcharts.SeriesColumnOptions;
        } = {};

        (Object.keys(growth) as Array<keyof typeof growth>).forEach((type) => {
          const name = (MetricName.AccountType as any)[type];

          Growth[type] = {
            type: "column",
            name,
            data: Object.keys(growth[type]!).map((key) => {
              return growth[type]![key];
            }),
            color: (backgroundColors as any)[type][1],
            edgeColor: (backgroundColors as any)[type][1],
            // borderColor: backgroundColors[type][1],
          };
        });

        data.growth = {
          xAxis: {
            categories: dateLabels,
          },
          series: Object.values(Growth),
        };

        if (!metric) {
          Object.keys(response.data).forEach((accountId) => {
            const datavalue = response.data[accountId as any];
            Object.keys(datavalue).forEach((date) => {
              const value = datavalue[date];
              data.total!.growth! += value;
            });
          });
        }

        break;
      }
      case "posts_sent": {
        const sent: { [date: string]: number } = {};
        const dateLabels = Object.keys(Object.values(response.data)[0]).map(
          (date) =>
            formatDate(
              date,
              formatDate(date, isRTL ? "jYYYY/jMM/jDD" : "YYYY/MM/DD"),
            ),
        );
        Object.keys(response.data).forEach((accountId) => {
          const datavalue = response.data[accountId as any];
          Object.keys(datavalue).forEach((date) => {
            const value = datavalue[date];
            if (typeof sent[date] === "undefined") {
              sent[date] = 0;
            }
            sent[date] += value;
          });
        });
        const Message: {
          [key: string]: Highcharts.SeriesLineOptions;
        } = {
          /*'received': {
              label: 'Received',
              data: Object.keys(received).map(function (key) {
                return {x: new Date(key), y: received[key]};
              }),
              backgroundColor: backgroundColors['Received'][0],
              hoverBackgroundColor: backgroundColors['Received'][1],
              borderColor: backgroundColors['Received'][1],
              fill: false,
            },*/
          sent: {
            type: "line",
            name: __("Sent"),
            data: Object.values(sent),
            color: backgroundColors.Sent[0],
            colorAxis: backgroundColors.Sent[1],
            borderColor: backgroundColors.Sent[1],
          },
        };

        data.posts_sent = {
          xAxis: {
            categories: dateLabels,
          },
          series: Object.values(Message),
        };

        if (!metric) {
          Object.keys(response.data).forEach((accountId) => {
            const datavalue = response.data[accountId as any];
            Object.keys(datavalue).forEach((date) => {
              const value = datavalue[date];
              data.total!.sent! += value;
            });
          });
        }
        break;
      }
      case "impressions": {
        const impressions: MetricData<{ [date: string]: number }> = {};

        Object.values(accounts).forEach((account) => {
          impressions[account.type] = {};
        });
        const dateLabels = Object.keys(Object.values(response.data)[0]).map(
          (date) => formatDate(date, isRTL ? "jYYYY/jMM/jDD" : "YYYY/MM/DD"),
        );
        (Object.keys(response.data) as unknown as number[]).forEach(
          (accountId) => {
            const datavalue = response.data[accountId as any];
            const account = accounts[accountId];
            Object.keys(datavalue).forEach((date) => {
              const value = datavalue[date];
              if (typeof impressions[account.type]![date] === "undefined") {
                impressions[account.type]![date] = 0;
              }
              impressions[account.type]![date] += value;
            });
          },
        );

        const Impressions: {
          [key: string]: Highcharts.SeriesLineOptions;
        } = {};

        (Object.keys(impressions) as Array<keyof typeof impressions>).forEach(
          (type) => {
            const name = (MetricName.AccountType as any)[type];

            if (type !== "Twitter") {
              Impressions[type] = {
                type: "line",
                name,
                data: Object.keys(impressions[type]!).map((key) => {
                  return impressions[type]![key];
                }),
                color: (backgroundColors as any)[type][0],
                negativeColor: (backgroundColors as any)[type][1],
                borderColor: (backgroundColors as any)[type][1],
              };
            }
          },
        );

        data.impressions = {
          xAxis: {
            categories: dateLabels,
          },
          series: Object.values(Impressions),
        };
        if (!metric) {
          Object.keys(response.data).forEach((accountId) => {
            const datavalue = response.data[accountId as any];
            Object.keys(datavalue).forEach((date) => {
              const value = datavalue[date];
              data.total!.impressions! += value;
            });
          });
        }
        break;
      }
      case "page_follow_total": {
        if (!metric) {
          Object.keys(response.data).forEach((accountId) => {
            const datavalue = response.data[accountId as any];
            data.total!.total_follower! += datavalue;
          });
        }
      }
    }
  });

  return metric ? Object.values(data)[0] : (data as any);
}
