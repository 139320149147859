import { Suspense, lazy } from "react";
import { File } from "@common/features/File";
import { Loading } from "@app/elements/loading";
import { Modal } from "@app/elements/modals";

const ImageEditor = lazy(() => import("./ImageEditor"));

export default function ImageEdtiorModal({
  image,
  onClose,
  onSave,
}: {
  image?: File;
  onClose: () => void;
  onSave: (image: string) => void;
}) {
  return (
    <Modal
      size="full"
      panelClassName="h-full"
      onClose={onClose}
      isOpen={image !== undefined}
      dir="ltr"
    >
      <Suspense fallback={<Loading />}>
        <ImageEditor image={image} onSave={onSave} onClose={onClose} />
      </Suspense>
    </Modal>
  );
}
