import { useExtraPostState } from "@common/features/Post";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { SectionToggle } from "@app/elements/container";
import { TextInput } from "@app/elements/form";
import { SocialIcon } from "@app/elements/social";

export default function LinkedinSection(props: Partial<SectionToggleProps>) {
  const [data, dispatch] = useExtraPostState("Linkedin");
  return (
    <SectionToggle
      {...props}
      title={__("Linkedin settings")}
      defaultOpen
      icon={<SocialIcon social="Linkedin" />}
    >
      <TextInput
        placeholder={__("Title")}
        onInputChange={(title) => dispatch("linkedin_title", title)}
        value={data.linkedin_title || ""}
        icon={regular("tag")}
        name="linkedin_title"
      />
    </SectionToggle>
  );
}
