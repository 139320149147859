import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ComponentType, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { Team } from "@common/features/Team";
import {
  DataResponse,
  useFetchMeData,
  useIsTeammate,
} from "@common/features/User";
import Logo from "@app/assets/Logo";
import { SubMenuItemsProps } from "@app/elements/container/SubMenuItems";
import { Tooltip } from "@app/elements/helpers";
import { Badge } from "@app/elements/text";
import { checkIfActive } from "@app/helpers/menu";

export type MenuProps = {
  generateMenu: (data: DataResponse) => MenuItemsType[];
};

export type ChildMenu = {
  url: string;
  label: string;
  icon: [IconPrefix, IconName];
  teamPermission?: keyof Team["permissions"] | false;
  external?: boolean;
  unread?: () => ReactNode;
  exact?: boolean;
};

export type MenuItemsType = {
  url?: string;
  icon?: [IconPrefix, IconName];
  childs?: ChildMenu[];
  teamPermission?: keyof Team["permissions"] | false;
  label?: string;
  unread?: () => ReactNode | boolean;
  showSelectOnSmall?: boolean;
  Component?: ComponentType<SubMenuItemsProps>;
};

export default function Menu({ generateMenu }: MenuProps) {
  const location = useLocation();
  const { data } = useFetchMeData();
  const isTeamamte = useIsTeammate();
  const menu = generateMenu ? generateMenu(data!) : [];

  const active = menu.find((item) =>
    checkIfActive(location, [
      item.url,
      ...(item.childs || []).map((i) => i.url),
    ]),
  );

  return (
    <nav
      aria-label="Sidebar"
      className="hidden overflow-y-auto bg-gray-100 shadow dark:border-e dark:bg-dark-background md:block md:flex-shrink-0"
    >
      <div className="inset-y-0 left-0 hidden md:block md:flex-shrink-0">
        <a
          className="flex h-16 w-16 items-center justify-center md:w-20"
          href="/"
        >
          <Logo className="h-8 w-8" />
        </a>
      </div>
      <div className="relative flex w-20 flex-col space-y-3 p-3">
        {menu.map((item, index) => {
          const url = !item.url ? item.childs?.[0].url : item.url;
          if (
            isTeamamte &&
            item.teamPermission !== undefined &&
            (item.teamPermission === false ||
              !data!.masterUser!.permissions?.[item.teamPermission])
          ) {
            return null;
          }
          const unread = item.unread?.();
          return (
            <Tooltip tooltip={item.label} placement="side-end" key={index}>
              <Link
                to={url || ""}
                className={classNames(
                  item == active
                    ? "bg-primary-500 text-white dark:bg-primary-500/90"
                    : "text-gray-700 hover:bg-gray-200 dark:text-zinc-100 dark:hover:bg-gray-800",
                  "relative inline-flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg",
                )}
              >
                <span className="sr-only">{item.label}</span>
                <FontAwesomeIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  icon={item.icon!}
                />
                {unread &&
                  (unread === true ? (
                    <Badge
                      variant="danger"
                      padding="none"
                      rounded={false}
                      className="absolute bottom-4 end-4 flex h-2 w-2 rounded-full text-xs"
                    />
                  ) : (
                    <Badge
                      variant="danger"
                      padding="none"
                      rounded={false}
                      style={{ fontSize: 10 }}
                      className="absolute bottom-2 end-2 flex h-4 w-4 items-center justify-center rounded-full text-xs"
                    >
                      {unread}
                    </Badge>
                  ))}
              </Link>
            </Tooltip>
          );
        })}
      </div>
    </nav>
  );
}
