import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, HTMLAttributes } from "react";
import { regular } from "@common/helpers/fontawesome";
import { ModalProps } from "@common/types";
import { Button } from "../form";

type DialogType = typeof Dialog;

const sizes = {
  small: "w-full mx-4 sm:mx-0 sm:max-w-lg",
  medium: "w-full mx-4 sm:mx-0 sm:max-w-3xl",
  large: "w-full mx-4 sm:mx-0 sm:max-w-6xl",
  full: "w-full mx-4 sm:mx-8",
};

const paddings = {
  small: "p-2",
  medium: "p-4",
  large: "p-8",
  none: "",
};

function ModalRoot({
  children,
  isOpen,
  onClose,
  showCloseIcon,
  size,
  panelClassName,
  showOverflow,
  padding,
  ...rest
}: ModalProps & Parameters<DialogType>[0] & HTMLAttributes<HTMLDivElement>) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={onClose} {...rest}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-50 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div tabIndex={1} />
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={classNames(
                "relative max-h-[90%] transform rounded-lg bg-white shadow-xl transition-all dark:bg-dark-background",
                sizes[size || "small"],
                panelClassName,
                padding && paddings[padding],
                showOverflow !== true && "overflow-hidden overflow-y-auto",
              )}
            >
              {showCloseIcon && (
                <Button
                  padding="none"
                  variant="text"
                  className="absolute end-4 top-4 z-40 px-1 py-1"
                  onClick={onClose}
                >
                  <FontAwesomeIcon icon={regular("x")} className="h-3 w-3" />
                </Button>
              )}
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function Title({ className, ...props }: Parameters<DialogType["Title"]>[0]) {
  return (
    <Dialog.Title
      className={classNames("text-base font-bold", className)}
      {...props}
    />
  );
}

function Description(props: Parameters<DialogType["Description"]>[0]) {
  return <Dialog.Description {...props} />;
}

export const Modal = Object.assign(ModalRoot, { Title, Description });
