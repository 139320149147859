import Highcharts from "highcharts";
import {
  InsightsResponse,
  MetricName,
  ProcessData,
  backgroundColors,
} from "@common/features/Insights";
import { AccountPost } from "@common/features/Social";
import { __ } from "@common/helpers/i18n";
import worldGeo from "../world.geo.json";

interface InsightsData {
  growth: { data: Highcharts.Options; summary: number };
  impressions: Highcharts.Options;
  engagement: { data: Highcharts.Options; summary: number };
  reach: { data: Highcharts.Options; summary: number };
  top_hashtag: Highcharts.Options;
  mediatype: Highcharts.Options;

  online_follower: Highcharts.Options;
  online_follower_hour: Highcharts.Options;

  bestHour?: [string, string];

  total: {
    page_follow_total?: number;
    growth?: number;
    profile_views?: number;
    average_engagement?: number;
    impressions?: number;
    total_posts_sent?: number;

    //second row
    total_link_clicks?: number;
    phone_click?: number;
    average_like?: number;
    average_comment?: number;
    average_reach?: number;
  };

  top_post: AccountPost[];

  emptyData: boolean;
}

interface InsightsLifetimeData {
  gender: Highcharts.Options;
  country: Highcharts.Options;
}

export function FacebookSelector<
  TMetric extends string | undefined = undefined,
>(
  _response: InsightsResponse<{
    metric: "impressions_by_type";
    data: {
      [key in
        | "impressions_organic"
        | "impressions_paid"
        | "impressions_viral"]: {
        metric: key;
        data: { [date: string]: number | null };
        prev: { [date: string]: number | null };
      };
    };
  }>,
  metric?: TMetric,
): TMetric extends string ? Highcharts.Options : InsightsData {
  const data: Partial<InsightsData> = {};

  if (typeof metric === "undefined") {
    data.total = {};
  }
  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }

    switch (response.metric) {
      case "page_follow_growth": {
        const growth = ProcessData.genericMetric(response.data);

        data.growth = {
          data: {
            xAxis: {
              categories: growth.label,
            },
            series: [
              {
                data: growth.data,
                type: "column",
                name: __("Facebook"),
                color: backgroundColors.Facebook[1],
                edgeColor: backgroundColors.Facebook[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        if (!metric) {
          data.total!.growth = ProcessData.sumSummary(response.data);
        }
        break;
      }
      case "impressions_by_type": {
        const impressions = {
          data: {
            paid: Object.values(response.data.impressions_paid.data).map(
              (item) => item || 0,
            ),
            organic: Object.values(response.data.impressions_organic.data).map(
              (item) => item || 0,
            ),
            viral: Object.values(response.data.impressions_viral.data).map(
              (item) => item || 0,
            ),
          },
          label: ProcessData.dateLabels(response.data.impressions_paid),
        };

        data.impressions = {
          xAxis: {
            categories: impressions.label,
          },
          series: [
            {
              type: "line",
              name: __("Organic"),
              data: impressions.data.organic,
              color: backgroundColors.Facebook[0],
              negativeColor: backgroundColors.Facebook[1],
              borderColor: backgroundColors.Facebook[1],
            },
            {
              type: "line",
              name: __("Paid"),
              data: impressions.data.paid,
              color: backgroundColors.Facebook[0],
              negativeColor: backgroundColors.Facebook[1],
              borderColor: backgroundColors.Facebook[1],
            },
            {
              type: "line",
              name: __("Viral"),
              data: impressions.data.viral,
              color: backgroundColors.Facebook[0],
              negativeColor: backgroundColors.Facebook[1],
              borderColor: backgroundColors.Facebook[1],
            },
          ],
        };

        if (!metric) {
          data.total!.impressions = ProcessData.sumSummary(
            impressions.data.organic
              .concat(impressions.data.paid)
              .concat(impressions.data.viral),
          );
        }
        break;
      }
      case "engagement": {
        const engagement = ProcessData.genericMetric(response.data);

        data.engagement = {
          data: {
            xAxis: {
              categories: engagement.label,
            },
            series: [
              {
                type: "line",
                name: __("Group Engagement"),
                data: engagement.data,
                negativeColor: backgroundColors.Facebook[0],
                color: backgroundColors.Facebook[1],
                borderColor: backgroundColors.Facebook[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        if (!metric) {
          data.total!.average_engagement = ProcessData.avgSummary(
            response.data,
          );
        }
        break;
      }
      case "reach": {
        const reach = ProcessData.genericMetric(response.data);

        data.reach = {
          data: {
            xAxis: {
              categories: reach.label,
            },
            series: [
              {
                type: "line",
                name: __("Reach"),
                data: reach.data,
                negativeColor: backgroundColors.Facebook[0],
                color: backgroundColors.Facebook[1],
                borderColor: backgroundColors.Facebook[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        if (!metric) {
          data.total!.average_reach = ProcessData.avgSummary(response.data);
        }
        break;
      }
      case "online_followers": {
        const online_follower_hour = ProcessData.onlineFollowersHours(
          response.data,
        );
        const online_follower = ProcessData.onlineFollowers(response.data);

        data.online_follower = {
          xAxis: {
            categories: online_follower.label,
          },
          series: [
            {
              type: "line",
              name: __("Online followers by day"),
              data: online_follower.data,
              color: backgroundColors.Facebook[1],
              negativeColor: backgroundColors.Facebook[1],
              // borderColor: backgroundColors[type][1],
            },
          ],
        };

        data.online_follower_hour = {
          xAxis: {
            categories: online_follower_hour.label,
          },
          series: [
            {
              type: "column",
              name: __("Online followers by hours"),
              data: online_follower_hour.data,
              negativeColor: backgroundColors.Facebook[1],
              color: backgroundColors.Facebook[1],
              // borderColor: backgroundColors[type][1],
            },
          ],
        };

        if (!metric) {
          data.bestHour = online_follower_hour.bestHour;
        }
        break;
      }
      case "top_post": {
        data.top_post = response.data;
        break;
      }
      case "num_posts_by_type": {
        const mediatype = ProcessData.numPostsByType(response.data);

        data.mediatype = {
          xAxis: {
            categories: mediatype.label,
          },
          series: [
            {
              type: "column",
              name: __("Image"),
              data: mediatype.data.image,
              color: backgroundColors.Image[0],
              colorAxis: backgroundColors.Image[1],
              borderColor: backgroundColors.Image[1],
            },
            {
              type: "column",
              name: __("Video"),
              data: mediatype.data.video,
              color: backgroundColors.Video[0],
              colorAxis: backgroundColors.Video[1],
              borderColor: backgroundColors.Video[1],
            },
            {
              type: "column",
              name: __("Album"),
              data: mediatype.data.album,
              color: backgroundColors.Album[0],
              colorAxis: backgroundColors.Album[1],
              borderColor: backgroundColors.Album[1],
            },
            {
              type: "column",
              name: __("Story"),
              data: mediatype.data.story,
              color: backgroundColors.Story[0],
              colorAxis: backgroundColors.Story[1],
              borderColor: backgroundColors.Story[1],
            },
          ],
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
        };

        break;
      }
      case "top_hashtag": {
        data.top_hashtag =
          Object.keys(response.data).length !== 0
            ? {
                series: [
                  {
                    type: "wordcloud",
                    data: ProcessData.hashtag(response.data),
                    name: __("Hashtag"),
                  },
                ],
              }
            : undefined;
        break;
      }
      case "total_posts_sent":
      case "page_follow_total":
      case "average_like":
      case "average_comment":
      case "total_link_clicks":
      case "phone_click":
      case "profile_views": {
        data.total![response.metric] = response.data;
        break;
      }
    }
  });
  data.emptyData = _response.insights.length === 0;
  if (metric === "online_follower_hour")
    return data.online_follower_hour as any;

  return metric ? Object.values(data)[0] : (data as any);
}

export function FacebookSelectorLifetime(_response: InsightsResponse) {
  const data: Partial<InsightsLifetimeData> = {};

  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }

    switch (response.metric) {
      case "gender": {
        const gender = ProcessData.gender(response.data);
        data.gender = {
          xAxis: {
            categories: [MetricName.Gender.male, MetricName.Gender.female],
          },
          legend: { enabled: true },
          series: [
            {
              type: "pie",
              name: __("Gender"),
              data: [
                { name: __("Male"), y: gender.male },
                { name: __("Female"), y: gender.female },
              ],
              // config: {
              //   colors: [
              //     processColor(backgroundColors.Male),
              //     processColor(backgroundColors.Female),
              //   ],
              //   valueTextSize: 20,
              //   valueTextColor: processColor('green'),
              //   sliceSpace: 5,
              //   selectionShift: 13,
              //   // xValuePosition: "OUTSIDE_SLICE",
              //   // yValuePosition: "OUTSIDE_SLICE",
              //   valueLineColor: processColor('green'),
              //   valueLinePart1Length: 0.5,
              // },
            },
          ],
        };
        break;
      }
      case "country": {
        data.country = {
          series: [
            {
              type: "map",
              data: ProcessData.country(response.data),
              mapData: worldGeo,
              joinBy: ["hc-key", "key"] as any,
              name: __("Count"),
              states: {
                hover: {
                  color: Highcharts.getOptions().colors![2],
                },
              },
            },
          ],
          legend: { enabled: true },
          colorAxis: {
            type: "linear",
            min: 0,
            stops: [
              [0, "#EFEFFF"],
              [
                0.3,
                new Highcharts.Color(Highcharts.getOptions().colors![0])
                  .brighten(-0.2)
                  .get(),
              ],
              [
                0.6,
                new Highcharts.Color(Highcharts.getOptions().colors![0])
                  .brighten(-0.4)
                  .get(),
              ],
              [
                1,
                new Highcharts.Color(Highcharts.getOptions().colors![0])
                  .brighten(-0.5)
                  .get(),
              ],
            ] as any,
          },
        };
        break;
      }
    }
  });

  return data;
}
