import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useRef } from "react";
import Dropzone, { DropzoneRef, FileRejection } from "react-dropzone";
import {
  useFetchFile,
  useFetchFileInfo,
  useUploadFile,
} from "@common/features/File";
import notify from "@common/helpers/NotificationManager";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { DropboxProps } from "@common/types/elements/filemanager/DropBox";
import { Button } from "@app/elements/form";
import { Loading } from "@app/elements/loading";
import { Text } from "@app/elements/text";
import { mimePerType } from "@app/pages/post/FilemanagerEditorSection";
import { FileIcon } from "./FileManager";

export function DropBox({
  onChange,
  selectedFileId,
  allowType,
  sizeCaption,
}: DropboxProps) {
  const dropzoneRef = useRef<DropzoneRef>(null);

  const { previews, upload } = useUploadFile();
  const { data: info } = useFetchFileInfo();

  const { data: file } = useFetchFile(selectedFileId);

  const onDrop = async (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      upload({ file, previewUrl: URL.createObjectURL(file) }, (resp) => {
        onChange(resp.id);
      });
    });
  };

  const onReject = useCallback(
    (files: FileRejection[]) => {
      files.forEach((file) => {
        if (file.file.size > info!.max_file_size) {
          notify.error(__("Max. file size to upload"));
        } else {
          notify.error(__("File type is not allowed"));
        }
      });
    },
    [info],
  );

  return (
    <Dropzone
      onDropAccepted={onDrop}
      onDropRejected={onReject}
      accept={
        allowType
          ? ([] as string[]).concat(
              ...allowType.map((item) => mimePerType[item]),
            )
          : undefined
      }
      noClick
      ref={dropzoneRef}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()} tabIndex={undefined} role={undefined}>
          {isDragActive && (
            <Text
              variant="gray"
              block
              className="absolute inset-0 z-20 flex flex-col items-center justify-center space-y-4 border bg-white pt-2 dark:bg-dark-background"
            >
              <FontAwesomeIcon
                icon={regular("upload")}
                className="animate-bounce text-6xl"
              />
              <p>{__("Drop files here to upload")}</p>
            </Text>
          )}
          <input {...getInputProps()} />
          {previews.length === 0 && file ? (
            <div className="group relative mb-4 w-20 overflow-hidden rounded-md ring-1 ring-gray-200 ring-offset-2 ">
              <FontAwesomeIcon
                icon={regular("circle-xmark")}
                className="absolute start-2 top-2 z-10 hidden text-white group-hover:block"
                onClick={(e) => {
                  e.stopPropagation();
                  onChange();
                }}
              />
              <div className="relative">
                <div
                  className="aspect-h-1 aspect-w-1 bg-gray-200 bg-contain"
                  style={{
                    backgroundImage: `url("${file.thumbnailUrl}")`,
                  }}
                >
                  {file.fileType === "document" && (
                    <div className="flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={FileIcon[file.ext]}
                        className="h-8 w-8"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            previews.map((item) => (
              <div
                className="group relative mb-4 w-20 overflow-hidden rounded-md ring-1 ring-primary-500 ring-offset-2 "
                key={item.previewUrl}
              >
                <div className="relative">
                  <div
                    className="aspect-h-1 aspect-w-1 bg-gray-200 bg-cover bg-center dark:bg-gray-600"
                    style={{
                      backgroundImage: `url("${item.previewUrl}")`,
                    }}
                  />
                  <Loading size={48} />
                  <div className="absolute inset-0 flex items-center justify-center dark:text-black">
                    <div>{item.percent}%</div>
                  </div>
                  <div
                    className="absolute inset-0 z-10 hidden items-center justify-center bg-gray-300/30 group-hover:flex"
                    onClick={() => item.abort()}
                  >
                    <FontAwesomeIcon
                      icon={regular("xmark")}
                      className={"h-8 w-8 cursor-pointer rounded-full"}
                    />
                  </div>
                </div>
              </div>
            ))
          )}
          <Button
            variant="light"
            onClick={() => dropzoneRef.current?.open()}
            type="button"
          >
            {__("Upload")}
          </Button>
          {sizeCaption && (
            <Text variant="muted" block className="mt-2">
              <span className="name">{__("Max. file size to upload")}: </span>
              <span className="value ofm-infobox-max-upload-size">
                {sizeCaption.customText ?? sizeCaption.maxSize + __("MG")}
              </span>
            </Text>
          )}
        </div>
      )}
    </Dropzone>
  );
}
