import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useExtraPostState, usePostStore } from "@common/features/Post";
import { useSearchPeople } from "@common/features/Search";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { useEvent } from "@common/hooks";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { Select } from "@app/elements/form";

export default function CollabSection(props: Partial<SectionToggleProps>) {
  const [data, dispatch] = useExtraPostState("InstagramOfficial");

  const [{ selectedAccounts, postType }] = usePostStore(
    ({ selectedAccounts, postType }) => ({
      selectedAccounts,
      postType,
    }),
    true,
  );

  const [tagSearch, setTagSearch] = useState<string>();
  const [collaboratorsSearch, setCollaboratorsSearch] = useState<string>();

  const { data: searchPeoples, isLoading: isSearching } = useSearchPeople(
    (selectedAccounts || []).map((item) => item.id),
    selectedAccounts !== undefined
      ? tagSearch || collaboratorsSearch
      : undefined,
  );

  const setTag = useEvent(() => {
    if (searchPeoples) {
      if (tagSearch) {
        setTagSearch(undefined);
        if (searchPeoples.length > 0)
          dispatch("reels_tags", [
            ...(data.reels_tags || []),
            { username: searchPeoples[0].username },
          ]);
      } else {
        setCollaboratorsSearch(undefined);
        if (searchPeoples.length > 0)
          dispatch("instagram_collaborators", [
            ...(data.instagram_collaborators || []),
            searchPeoples[0].username,
          ]);
      }
    }
  });

  useEffect(() => {
    setTag();
  }, [searchPeoples, setTag]);

  return (
    <SectionToggle
      {...props}
      title={__("Collaborator post")}
      hint={__(
        "By adding another person's username, the post will be published jointly after the approval of the other party.",
      )}
      icon={<FontAwesomeIcon icon={regular("user-group")} />}
      defaultOpen={data.instagram_collaborators !== undefined}
    >
      <ElementsGroup>
        <Select
          disabled={postType === "story"}
          label={__("Username")}
          placeholder={__("Enter the username and press Enter")}
          onSubmit={(search) => setCollaboratorsSearch(search)}
          searchable
          multiple
          value={data.instagram_collaborators || []}
          onChange={(instagram_collaborators) =>
            dispatch("instagram_collaborators", instagram_collaborators)
          }
          getLabel={(item) => item}
          getKey={(item) => item}
          hideMenu
          loading={isSearching}
        />
      </ElementsGroup>
    </SectionToggle>
  );
}
