import { useState } from "react";
import {
  useCreateTelegramButton,
  useDeleteTelegramButton,
  useExtraPostState,
  useFetchTelegramButtons,
} from "@common/features/Post";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { Button, CheckBox, TextInput } from "@app/elements/form";
import { Tooltip } from "@app/elements/helpers";
import { Modal } from "@app/elements/modals";
import { SocialIcon } from "@app/elements/social";
import { Text } from "@app/elements/text";
import TelegramSelect from "@app/pages/post/sections/TelegramSelect";

export default function TelegramSection(props: Partial<SectionToggleProps>) {
  const [data, dispatch] = useExtraPostState("Telegram");

  const [modal, setModal] = useState(false);
  const [text, setText] = useState("");
  const [link, setLink] = useState("");

  const { data: buttons, isLoading } = useFetchTelegramButtons();

  const deleteButton = useDeleteTelegramButton();
  const createButton = useCreateTelegramButton();

  return (
    <SectionToggle
      {...props}
      title={__("Telegram settings")}
      icon={<SocialIcon social="Telegram" />}
      defaultOpen={
        (data.buttons?.length || 0) > 0 ||
        data.has_signature !== undefined ||
        data.pin_message !== undefined
      }
    >
      <Modal isOpen={modal} onClose={() => setModal(false)} padding="medium">
        <ElementsGroup>
          <Modal.Title>{__("Add telegram button")}</Modal.Title>
          <TextInput
            placeholder={__("Title")}
            icon={regular("note-sticky")}
            name="telegram_title"
            value={text}
            onInputChange={(title) => setText(title)}
          />
          <TextInput
            placeholder={__("Link")}
            icon={regular("link")}
            value={link}
            onInputChange={(link) => setLink(addScheme(link))}
            name="telegram_link"
          />
          <Button
            variant="primary"
            onClick={() => {
              dispatch("buttons", [
                ...(data.buttons || []),
                { id: 0, text, link },
              ]);
              setText("");
              setLink("");
              setModal(false);

              createButton.mutate({ text, link });
            }}
            icon={regular("save")}
            center
          >
            {__("Add")}
          </Button>
        </ElementsGroup>
      </Modal>
      <ElementsGroup>
        <CheckBox
          checked={data.pin_message === "1"}
          onInputChange={(checked) =>
            dispatch("pin_message", checked ? "1" : "0")
          }
          label={
            <Tooltip
              tooltip={__(
                "The post is displayed as a pin at the top of the channel",
              )}
            >
              <Text icon={regular("circle-question")} iconPosition="end" block>
                {__("Pin the post on the channel")}
              </Text>
            </Tooltip>
          }
        />
        <CheckBox
          checked={data.has_signature === "1"}
          onInputChange={(checked) =>
            dispatch("has_signature", checked ? "1" : "0")
          }
          label={
            <Tooltip
              tooltip={__(
                "Channel's username will be added to the end of the caption in the posts",
              )}
            >
              <Text icon={regular("circle-question")} iconPosition="end" block>
                {__("Add the channel's username to the end of the caption")}
              </Text>
            </Tooltip>
          }
        />
        {props.showButton && (
          <TelegramSelect
            placeholder={__("Add telegram button")}
            loading={isLoading}
            value={data.buttons || []}
            multiple
            searchable
            searchFilter={(query, option) => {
              return option.text.toLowerCase().includes(query.toLowerCase());
            }}
            onDelete={(id) => deleteButton.mutate(id)}
            getKey={(item: any) => item.id || item.link + item.text}
            options={buttons}
            onChange={(buttons) => dispatch("buttons", buttons)}
            menuPlacement="top"
            onOpenModal={() => setModal(true)}
          />
        )}
      </ElementsGroup>
    </SectionToggle>
  );
}

function addScheme(url: string) {
  if (validURL(url) && !/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  return url;
}

function validURL(str: string) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return pattern.test(str);
}
