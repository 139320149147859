import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Team } from "@common/features/Team";
import { api } from "@common/helpers/api";
import { convertKeyValueToArray } from "@common/helpers/functions";
import { SuccessResponse } from "@common/interfaces/Api";
import {
  DataResponse,
  Language,
  Permissions,
  User,
  Webservice,
} from "../interfaces";

export const meKeys = createQueryKeys("me", {
  data: null,
  activities: null,
  languages: null,
  timezones: null,
  user: null,
  webservice: null,
});

export const useFetchMeData = <TData = DataResponse>(
  select?: (data: DataResponse) => TData,
  enabled?: boolean,
) =>
  useQuery({
    ...meKeys.data,
    queryFn: async () => {
      const response = await api.get<DataResponse>("me/data");
      return response.data;
    },
    select,
    staleTime: 5 * 60000,
    enabled,
    refetchOnWindowFocus: "always",
    throwOnError: true,
  });

export const useFetchActivities = () =>
  useQuery({
    ...meKeys.activities,
    queryFn: async () => {
      const response = await api.get<{ [key: string]: string }>("me/activity");
      return response.data;
    },
    select: (data) => convertKeyValueToArray(data),
  });

export const useFetchLanguages = () =>
  useQuery({
    ...meKeys.languages,
    queryFn: async () => {
      const response = await api.get<Language[]>("me/language");
      return response.data;
    },
  });

export const useFetchTimezones = () =>
  useQuery({
    ...meKeys.timezones,
    queryFn: async () => {
      const response = await api.get<{ [key: string]: string }>("me/timezone");
      return response.data;
    },
    select: (data) => convertKeyValueToArray(data),
  });

export const useFetchUser = () =>
  useQuery({
    ...meKeys.user,
    queryFn: async () => {
      const response = await api.get<User>("me/user");
      return response.data;
    },
  });

export const useFetchWebserivce = () =>
  useQuery({
    ...meKeys.webservice,
    queryFn: async () => {
      const response = await api.get<Webservice>("me/webservice");
      return response.data;
    },
  });

export const useMasterUser = () =>
  useFetchMeData((data) => data.masterUser).data!;

export const useSiteNotifications = () =>
  useFetchMeData((data) => data.notifications).data!;

export const useUser = () => useFetchMeData((data) => data.user).data!;

export const usePermissions = () =>
  useFetchMeData((data) => data.permissions).data!;

export const useHasPermissions = (permission: keyof Permissions) => {
  const { data } = useFetchMeData((data) => data.permissions[permission]);
  return data!;
};

export const useMasterPermissions = () =>
  useFetchMeData((data) => data.masterPermissions).data!;

export const useHasMasterPermissions = (permission: keyof Permissions) => {
  const { data } = useFetchMeData((data) => data.masterPermissions[permission]);
  return data!;
};

export const useIsTeammate = () => {
  const { data } = useFetchMeData((data) => data.is_teammate);
  return data!;
};

export const useHasTeammatePermissions = (
  permission: keyof Team["permissions"],
) => {
  const { data } = useFetchMeData(
    (data) => !data.is_teammate || data.masterUser!.permissions[permission],
  );
  return data!;
};

export const useFeatureSupports = () =>
  useFetchMeData((data) => data.featureSupports).data!;

export const useSocialTypes = () =>
  useFetchMeData((data) => data.socialTypes).data!;

export const useFilterMetricPermissions = (metric: string[]): string[] => {
  const mapMetrics: { [key: string]: string } = {
    online_follower_hour: "online_followers",
    mention: "engagement",
  };

  const mapPermissions: { [key: string]: string } = {
    impressions: "insights_impressions",
    engagement: "insights_engagement",
    page_follow_growth: "insights_audience_growth",
    impressions_by_hour: "insights_impressions_by_hour",
    top_hashtag: "insights_top_hashtags",
    impressions_by_hashtag: "insights_impressions_by_hashtags",
    num_posts_by_type: "insights_media_type",
    posts_sent: "insights_posts_publish",
    total_posts_sent: "insights_posts_publish",
    top_post: "insights_top_posts",
    gender: "insights_gender",
    country: "insights_country",
    page_follow_total: "insights_summary",
    online_followers: "insights_online_followers",
    online_follower_hour: "insights_online_followers",
    profile_views: "insights_summary",
    reach: "insights_reach",
    share_total: "insights_engagement",
    job: "num_posts_by_type",
    country_by_type: "insights_country",
  };

  const { data } = useFetchMeData((data) =>
    metric
      .map((item) => (item in mapMetrics ? mapMetrics[item] : item))
      .filter((item) =>
        typeof mapPermissions[item] === "undefined"
          ? true
          : (data.masterPermissions as any)[mapPermissions[item]],
      ),
  );
  return data!;
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      type: string;

      name?: string;
      mobile?: string;
      national_code?: string;

      company?: string;
      national_id?: number;
      tax_number?: number;
      registration_number?: number;

      timezones: string;
      language?: string;

      address?: string;
      postal_code?: number;
    }) => api.post<SuccessResponse>("me/profile", data),
    onSuccess: () => {
      queryClient.invalidateQueries(meKeys.user);
    },
  });
};

export const useUpdatePassword = () => {
  return useMutation({
    mutationFn: (data: { password: string; new_password: string }) =>
      api.post<SuccessResponse>("me/password", data),
  });
};

export const useCreateWebservice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      api.post<Webservice>("me/webservice").then((response) => response.data),
    onSuccess: (webservice) => {
      queryClient.setQueryData<Webservice>(
        meKeys.webservice.queryKey,
        webservice,
      );

      queryClient.invalidateQueries({
        queryKey: meKeys.webservice.queryKey,
        refetchType: "none",
      });
    },
  });
};

export const useUpdateNotificationSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (notifications: User["notifications"]) =>
      api.post<SuccessResponse>("me/notification", { notifications }),
    onSuccess: () => {
      queryClient.invalidateQueries(meKeys.user);
    },
  });
};
