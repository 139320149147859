import Highcharts from "highcharts";
import {
  InsightsResponse,
  ProcessData,
  backgroundColors,
} from "@common/features/Insights";
import { AccountPost } from "@common/features/Social";
import { roundNumber } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import worldGeo from "../world.geo.json";

interface InsightsData {
  growth: { data: Highcharts.Options; summary: number };
  impressions: { data: Highcharts.Options; summary: number };
  engagement: { data: Highcharts.Options; summary: number };
  mediatype: Highcharts.Options;

  bestHour?: [string, string];

  total: {
    page_follow_total?: number;
    growth?: number;
    average_engagement?: number;
    link_clicks?: number;
    profile_views?: number;
    total_posts_sent?: number;
  };

  top_post: AccountPost[];

  emptyData: boolean;
}

interface InsightsLifetimeData {
  country: Highcharts.Options;

  job: Highcharts.Options;
}

export function LinkedinSelector<
  TMetric extends string | undefined = undefined,
>(
  _response: InsightsResponse<
    | {
        metric: "follow_by_type";
        data: {
          [key in "follow_growth_organic" | "follow_growth_paid"]: {
            metric: key;
            data: { [date: string]: number | null };
            prev: { [date: string]: number | null };
          };
        };
      }
    | {
        metric: "engagement_by_type";
        data: {
          [key in
            | "like_count"
            | "comments_received"
            | "link_clicks"
            | "share_count"]: {
            metric: key;
            data: { [date: string]: number | null };
            prev: { [date: string]: number | null };
          };
        };
      }
  >,
  metric?: TMetric,
): TMetric extends string ? Highcharts.Options : InsightsData {
  const data: Partial<InsightsData> = {};

  if (typeof metric === "undefined") {
    data.total = {};
  }
  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }

    switch (response.metric) {
      case "follow_by_type": {
        const sum =
          ProcessData.sumSummary(response.data.follow_growth_organic.prev) +
          ProcessData.sumSummary(response.data.follow_growth_paid.prev);

        data.growth = {
          data: {
            xAxis: {
              categories: ProcessData.dateLabels(
                response.data.follow_growth_organic.data,
              ),
            },
            series: [
              {
                data: Object.values(
                  response.data.follow_growth_organic.data,
                ).map((item) => item || 0),
                type: "column",
                name: __("Organic"),
                color: backgroundColors.Linkedin[0],
                edgeColor: backgroundColors.Linkedin[0],
              },
              {
                data: Object.values(response.data.follow_growth_paid.data).map(
                  (item) => item || 0,
                ),
                type: "column",
                name: __("Paid"),
                color: backgroundColors.Linkedin[1],
                edgeColor: backgroundColors.Linkedin[1],
              },
            ],
          },
          summary:
            sum === 0
              ? 100
              : roundNumber(
                  ((ProcessData.sumSummary(
                    response.data.follow_growth_organic.data,
                  ) +
                    ProcessData.sumSummary(
                      response.data.follow_growth_paid.data,
                    ) -
                    sum) /
                    sum) *
                    100,
                ),
        };

        if (!metric) {
          data.total!.growth =
            ProcessData.sumSummary(response.data.follow_growth_organic.data) +
            ProcessData.sumSummary(response.data.follow_growth_paid.data);
        }
        break;
      }
      case "impressions": {
        const impressions = ProcessData.genericMetric(response.data);
        data.impressions = {
          data: {
            xAxis: {
              categories: impressions.label,
            },
            series: [
              {
                type: "line",
                name: __("Linkedin"),
                data: impressions.data,
                color: backgroundColors.Linkedin[0],
                negativeColor: backgroundColors.Linkedin[1],
                borderColor: backgroundColors.Linkedin[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };
        break;
      }
      case "engagement_by_type": {
        const engagement = {
          data: {
            like: Object.values(response.data.like_count.data).map(
              (item) => item || 0,
            ),
            share: Object.values(response.data.share_count.data).map(
              (item) => item || 0,
            ),
            link: Object.values(response.data.link_clicks.data).map(
              (item) => item || 0,
            ),
            comment: Object.values(response.data.comments_received.data).map(
              (item) => item || 0,
            ),
          },
          label: ProcessData.dateLabels(response.data.like_count),
        };

        const sum =
          ProcessData.sumSummary(response.data.like_count.prev) +
          ProcessData.sumSummary(response.data.share_count.prev) +
          ProcessData.sumSummary(response.data.link_clicks.prev) +
          ProcessData.sumSummary(response.data.comments_received.prev);

        data.engagement = {
          data: {
            xAxis: {
              categories: engagement.label,
            },
            series: [
              {
                type: "line",
                name: __("Like"),
                data: engagement.data.like,
                negativeColor: backgroundColors.Linkedin[0],
                color: backgroundColors.Linkedin[1],
                borderColor: backgroundColors.Linkedin[1],
              },
              {
                type: "line",
                name: __("Comment"),
                data: engagement.data.comment,
                negativeColor: backgroundColors.Linkedin[0],
                color: backgroundColors.Linkedin[1],
                borderColor: backgroundColors.Linkedin[1],
              },
              {
                type: "line",
                name: __("Share"),
                data: engagement.data.share,
                negativeColor: backgroundColors.Linkedin[0],
                color: backgroundColors.Linkedin[1],
                borderColor: backgroundColors.Linkedin[1],
              },
              {
                type: "line",
                name: __("Link"),
                data: engagement.data.link,
                negativeColor: backgroundColors.Linkedin[0],
                color: backgroundColors.Linkedin[1],
                borderColor: backgroundColors.Linkedin[1],
              },
            ],
          },
          summary:
            sum === 0
              ? 100
              : roundNumber(
                  ((ProcessData.sumSummary(response.data.like_count.data) +
                    ProcessData.sumSummary(response.data.share_count.data) +
                    ProcessData.sumSummary(response.data.link_clicks.data) +
                    ProcessData.sumSummary(
                      response.data.comments_received.data,
                    ) -
                    sum) /
                    sum) *
                    100,
                ),
        };

        if (!metric) {
          data.total!.link_clicks = ProcessData.sumSummary(
            engagement.data.link,
          );
          data.total!.average_engagement = ProcessData.avgSummary(
            engagement.data.like
              .concat(engagement.data.share)
              .concat(engagement.data.link)
              .concat(engagement.data.comment),
          );
        }
        break;
      }
      case "top_post": {
        data.top_post = response.data;
        break;
      }
      case "num_posts_by_type": {
        const mediatype = ProcessData.numPostsByType(response.data);
        data.mediatype = {
          xAxis: {
            categories: mediatype.label,
          },
          series: [
            {
              type: "column",
              name: __("Image"),
              data: mediatype.data.image,
              color: backgroundColors.Image[0],
              colorAxis: backgroundColors.Image[1],
              borderColor: backgroundColors.Image[1],
            },
            {
              type: "column",
              name: __("Video"),
              data: mediatype.data.video,
              color: backgroundColors.Video[0],
              colorAxis: backgroundColors.Video[1],
              borderColor: backgroundColors.Video[1],
            },
            {
              type: "column",
              name: __("Album"),
              data: mediatype.data.album,
              color: backgroundColors.Album[0],
              colorAxis: backgroundColors.Album[1],
              borderColor: backgroundColors.Album[1],
            },
            {
              type: "column",
              name: __("Story"),
              data: mediatype.data.story,
              color: backgroundColors.Story[0],
              colorAxis: backgroundColors.Story[1],
              borderColor: backgroundColors.Story[1],
            },
          ],
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
        };
        break;
      }
      case "page_follow_total":
      case "total_posts_sent":
      case "profile_views": {
        data.total![response.metric] = response.data;
        break;
      }
    }
  });

  data.emptyData = _response.insights.length === 0;

  return metric ? Object.values(data)[0] : (data as any);
}

export function LinkedinSelectorLifetime(
  _response: InsightsResponse<
    | {
        metric: "job";
        data: {
          [key in "function" | "function_view"]: {
            metric: key;
            data: { [title: string]: number | null };
            prev: { [title: string]: number | null };
          };
        };
      }
    | {
        metric: "country_by_type";
        data: {
          [key in "country" | "country_view"]: {
            metric: key;
            data: { [countryCode: string]: number | null };
            prev: { [countryCode: string]: number | null };
          };
        };
      }
  >,
) {
  const data: Partial<InsightsLifetimeData> = {};

  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }

    switch (response.metric) {
      case "job": {
        data.job = {
          xAxis: {
            categories: [
              ...new Set([
                ...Object.keys(response.data.function.data),
                ...Object.keys(response.data.function_view.data),
              ]),
            ],
          },
          series: [
            {
              type: "column",
              name: __("Follower"),
              data: Object.values(response.data.function.data).map(
                (item) => item || 0,
              ),
              color: backgroundColors.Image[0],
              colorAxis: backgroundColors.Image[1],
              borderColor: backgroundColors.Image[1],
            },
            {
              type: "column",
              name: __("View"),
              data: Object.values(response.data.function_view.data).map(
                (item) => item || 0,
              ),
              color: backgroundColors.Video[0],
              colorAxis: backgroundColors.Video[1],
              borderColor: backgroundColors.Video[1],
            },
          ],
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
        };
        break;
      }
      case "country_by_type": {
        const country = [
          ...new Set([
            ...Object.keys(response.data.country.data),
            ...Object.keys(response.data.country_view.data),
          ]),
        ].map((key) => [
          key.toLowerCase(),
          (response.data.country.data[key] || 0) +
            (response.data.country_view.data[key] || 0),
          response.data.country.data[key] || 0,
          response.data.country_view.data[key] || 0,
        ]);
        data.country = {
          series: [
            {
              type: "map",
              data: country as any,
              name: __("Follower"),
              keys: ["key", "value", "follower", "view"],
              joinBy: ["hc-key", "key"] as any,
              mapData: worldGeo,
              states: {
                hover: {
                  color: Highcharts.getOptions().colors![2],
                },
              },
            },
          ],
          legend: { enabled: true },
          colorAxis: {
            type: "linear",
            min: 0,
            stops: [
              [0, "#EFEFFF"],
              [
                0.3,
                new Highcharts.Color(Highcharts.getOptions().colors![0])
                  .brighten(-0.2)
                  .get(),
              ],
              [
                0.6,
                new Highcharts.Color(Highcharts.getOptions().colors![0])
                  .brighten(-0.4)
                  .get(),
              ],
              [
                1,
                new Highcharts.Color(Highcharts.getOptions().colors![0])
                  .brighten(-0.5)
                  .get(),
              ],
            ] as any,
          },
          tooltip: {
            pointFormat:
              "<span>Total: <b>{point.value}</b><br>Follower: <b>{point.follower}</b><br>View: <b>{point.view}</b></span>",
          },
        };
        break;
      }
    }
  });

  return data;
}
