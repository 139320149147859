import classNames from "classnames";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useFetchMeData } from "@common/features/User";
import SubMenuItems from "@app/elements/container/SubMenuItems";
import { checkIfActive } from "@app/helpers/menu";
import { MenuProps } from "./Menu";

export default function SubMenu({ generateMenu }: MenuProps) {
  const location = useLocation();
  const { url: baseUrl } = useRouteMatch();

  const { data } = useFetchMeData();

  const menu = generateMenu ? generateMenu(data!) : [];

  const active = menu.find((item) =>
    checkIfActive(location, [
      item.url,
      ...(item.childs || []).map((i) => (item.url ? baseUrl + i.url : i.url)),
    ]),
  );

  if (!active?.childs) {
    return null;
  }

  const Component = active.Component || SubMenuItems;

  return (
    <aside className={classNames("hidden lg:block lg:flex-shrink-0")}>
      <div
        className={classNames(
          "relative z-10 flex h-full w-64 flex-col overflow-y-auto border-e bg-white p-3 dark:bg-dark-background",
        )}
      >
        <nav aria-label="Sidebar" className="flex flex-1 flex-col">
          <Component
            items={active.childs}
            baseUrl={active.url ? baseUrl : ""}
            showSelectOnSmall={active.showSelectOnSmall}
          />
        </nav>
      </div>
    </aside>
  );
}
