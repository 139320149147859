import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { DataResponse, meKeys } from "@common/features/User";
import { api } from "@common/helpers/api";
import { SuccessResponse } from "@common/interfaces/Api";
import { useLocalStorage } from "@app/hooks";

declare const _paq: any;

export const useLogin = () =>
  useUserStateMutation(
    (data: {
      email: string;
      password: string;
      "g-recaptcha-response"?: string;
    }) =>
      api
        .post<SuccessResponse>("user/login", data)
        .then((response) => response.data),
    {
      loggedIn: true,
    },
  );

export const useLogout = () =>
  useUserStateMutation(
    () =>
      api
        .post<SuccessResponse>("user/logout")
        .then((response) => response.data),
    {
      loggedOut: true,
    },
  );

export const useSignUp = () =>
  useUserStateMutation(
    (data: {
      name: string;
      email: string;
      password: string;
      "g-recaptcha-response"?: string;
      affiliate?: string;
      implicit_name?: string | null;
      implicit_email?: string | null;
      implicit_mobile?: string | null;
      client_id?: string | null;
    }) =>
      api
        .post<SuccessResponse>("user/register", data)
        .then((response) => response.data),
    {
      signedup: true,
    },
  );

export const useGoogleLogin = () =>
  useUserStateMutation(
    (data: { idToken: string; affiliate?: string }) =>
      api
        .post<SuccessResponse & { new_user: boolean }>("user/google", data)
        .then((response) => response.data),
    {
      isGoogle: true,
    },
  );

export const useFacebookLogin = () =>
  useUserStateMutation(
    (data: { token: string; affiliate?: string }) =>
      api
        .post<SuccessResponse & { new_user: boolean }>("user/facebook", data)
        .then((response) => response.data),
    {
      isFacebook: true,
    },
  );

export const useCompleteProfile = () =>
  useUserStateMutation(
    (data: { mobile: string }) =>
      api
        .post<SuccessResponse>("user/complete", data)
        .then((response) => response.data),
    {
      completed: true,
    },
  );

export const useSendVerifyMobile = () =>
  useMutation({
    mutationFn: () =>
      api
        .post<SuccessResponse>("user/send/sms")
        .then((response) => response.data),
  });

export const useVerifyMobile = () =>
  useUserStateMutation(
    (data: { code: string; "g-recaptcha-response"?: string }) =>
      api
        .post<SuccessResponse>("user/verify/mobile", data)
        .then((response) => response.data),
    {
      mobileVerified: true,
    },
  );

export const useSendVerifyEmail = () =>
  useMutation({
    mutationFn: () =>
      api
        .post<SuccessResponse>("user/send/email")
        .then((response) => response.data),
  });

export const useVerifyEmail = () =>
  useUserStateMutation((data: { hash: string | null }) =>
    api
      .post<SuccessResponse>("user/verify/email", data)
      .then((response) => response.data),
  );

export const useSendRecoverPassword = () =>
  useMutation({
    mutationFn: (data: {
      "g-recaptcha-response"?: string;
      emailOrMobile: string;
    }) =>
      api
        .post<SuccessResponse>("user/recovery", data)
        .then((response) => response.data),
  });

export const useVerifyRecoveryCode = () =>
  useMutation({
    mutationFn: (data: {
      emailOrMobile: string;
      code: string;
      "g-recaptcha-response"?: string;
    }) =>
      api
        .post<SuccessResponse>("user/recovery/verify", data)
        .then((response) => response.data),
  });

export const useResetPassword = () =>
  useMutation({
    mutationFn: (data: {
      emailOrMobile: string;
      code: string;
      password: string;
    }) =>
      api
        .post<SuccessResponse>("user/recovery/reset", data)
        .then((response) => response.data),
  });

function useUserStateMutation<
  TData extends SuccessResponse & { new_user?: boolean },
  TVariables = void,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  state?: {
    loggedOut?: boolean;
    loggedIn?: boolean;
    signedup?: boolean;
    completed?: boolean;
    mobileVerified?: boolean;
    isGoogle?: boolean;
    isFacebook?: boolean;
  },
) {
  const queryClient = useQueryClient();
  const history = useHistory();

  const [, setInstalledVersion] = useLocalStorage("installed_version", "");

  return useMutation({
    mutationFn,
    onSuccess: async (response) => {
      if (response.success !== true) return;

      await queryClient.refetchQueries(
        { queryKey: meKeys.data.queryKey },
        { throwOnError: true },
      );

      const data = queryClient.getQueryData<DataResponse>(meKeys.data.queryKey);

      if (!data) return;

      if (!state) return;

      // this.setState({ data: {} as any });
      const params = new URLSearchParams(history.location.search);
      const redirect = params.get("redirect") || "";

      if (state.isGoogle) {
        state.signedup = response.new_user;
        state.loggedIn = !response.new_user;
      }

      if (state.isFacebook) {
        state.signedup = response.new_user;
        state.loggedIn = !response.new_user;
      }

      if (typeof _paq !== "undefined" && !state.loggedOut) {
        _paq.push(["setUserId", data.user.email]);

        if (state.isGoogle) {
          _paq.push(["trackEvent", "button", "logingoogle", "logingoogle"]);
        }

        if (state.isFacebook) {
          _paq.push(["trackEvent", "button", "loginfacebook", "loginfacebook"]);
        }
      }

      if (state.loggedIn) {
        if (
          typeof _paq !== "undefined" &&
          !state.isFacebook &&
          !state.isGoogle
        ) {
          _paq.push(["trackEvent", "button", "loginbutton", "loginbutton"]);
        }
      }

      if (state.mobileVerified) {
        if (typeof _paq !== "undefined") {
          _paq.push([
            "trackEvent",
            "button",
            "completeprofile",
            "completeprofile",
          ]);
        }
        setInstalledVersion(data.webapp.version);

        history.replace({
          pathname: redirect || "/dashboard",
          search: "firstlogin=1",
        });
      }

      if (state.signedup) {
        if (
          typeof _paq !== "undefined" &&
          !state.isFacebook &&
          !state.isGoogle
        ) {
          _paq.push(["trackEvent", "button", "signupbutton", "signupbutton"]);
        }

        setInstalledVersion(data.webapp.version);
      }

      if (state.completed) {
        if (typeof _paq !== "undefined") {
          _paq.push([
            "trackEvent",
            "button",
            "completeprofile",
            "completeprofile",
          ]);
        }

        history.replace({
          pathname: "/verify",
          search: "redirect=" + encodeURIComponent(redirect),
        });
      }
    },
  });
}
