import { ExpiredOrMissingPermission } from "@app/elements/errors";

export function withExpiredOrPermission<P>(
  WrappedComponent: React.ComponentType<P>,
  usePermission: () => boolean,
): React.ComponentType<P> {
  return function Expire(props: any) {
    return !usePermission() ? (
      <ExpiredOrMissingPermission />
    ) : (
      <WrappedComponent {...props} />
    );
  };
}
