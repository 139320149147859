import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import Panel from "react-datepicker2/src/components/TimePicker/Panel";
import { isRTL } from "@common/helpers/i18n";
import { TextInputProps, TimePickerProps } from "@common/types";
import "./datepicker.css";
import { FadeTransition } from "../helpers";
import { TextInput } from "./TextInput";

const disabledMinutes = () => {
  return [...Array(60)].map((_v, i) => i).filter((v) => v % 5 !== 0);
};

export function TimePicker({
  value,
  onChange,
  className,
  ...props
}: TimePickerProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> &
  TextInputProps) {
  return (
    <Popover as="div" className={"relative inline-block"}>
      <Popover.Button as="div">
        {({ open }) => (
          <TextInput
            readOnly
            value={value?.format("hh:mm A")}
            {...props}
            className={classNames(
              className,
              open &&
                "z-10 !border-primary-500 !ring-1 !ring-primary-500 focus:z-10",
            )}
          />
        )}
      </Popover.Button>
      <FadeTransition>
        <Popover.Panel className="absolute start-0 z-30 mt-2 rounded-md">
          <Panel
            value={value}
            onChange={onChange}
            isGregorian={!isRTL}
            clearText="clear"
            prefixCls="rc-time-picker-panel"
            onClear={() => {}}
            showHour={true}
            onEsc={() => {}}
            showSecond={false}
            showAMPM={true}
            allowEmpty
            format={"hh:mm A"}
            disabledHours={() => {}}
            disabledMinutes={disabledMinutes}
            disabledSeconds={() => {}}
            hideDisabledOptions={true}
          />
        </Popover.Panel>
      </FadeTransition>
    </Popover>
  );
}
