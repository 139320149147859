import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";
import {
  Post,
  useAcceptPostGroup,
  useCancelPostGroup,
  useDeletePostGroup,
  useRetryPostGroup,
  useUpdatePostTags,
} from "@common/features/Post";
import { useFetchPostTags } from "@common/features/Post/queries/postGroupTags";
import {
  useFeatureSupports,
  useHasTeammatePermissions,
  useIsTeammate,
} from "@common/features/User";
import notify from "@common/helpers/NotificationManager";
import { regular } from "@common/helpers/fontawesome";
import { stripTags, ucfirst } from "@common/helpers/functions";
import { __, formatDateTime } from "@common/helpers/i18n";
import { PostBoxProps } from "@common/types";
import { Box } from "@app/elements/container";
import { Button } from "@app/elements/form";
import { Tooltip } from "@app/elements/helpers";
import { Loading } from "@app/elements/loading";
import { Confirm } from "@app/elements/modals";
import { SocialIcon } from "@app/elements/social";
import { Text } from "@app/elements/text";
import { FileIcon } from "@app/features/filemanager";
import PostErrorModal from "./PostErrorModal";

export function PostBox({ postGroup }: PostBoxProps) {
  const cancelPost = useCancelPostGroup();
  const deletePostGroup = useDeletePostGroup();
  const acceptPostGroup = useAcceptPostGroup();
  const retryPostGroup = useRetryPostGroup();

  const isTeammate = useIsTeammate();
  const featureSupports = useFeatureSupports();
  const canDelete = useHasTeammatePermissions("post_delete");
  const canRetry = useHasTeammatePermissions("post_retry");

  const { data: postTags, isLoading: isPostTagsLoading } = useFetchPostTags();
  const updatePostTags = useUpdatePostTags();

  if (postGroup.post.length === 0) return null;
  const firstPost = postGroup.post[0];

  const counts: { [key in Post["status"]]?: number } = {};

  for (let i = 0; i < postGroup.post.length; i++) {
    const key = postGroup.post[i].status;
    counts[key] = counts[key] ? counts[key]! + 1 : 1;
  }
  const status_text = (Object.keys(counts) as Array<Post["status"]>).sort(
    function (a, b) {
      return counts[b]! - counts[a]!;
    },
  )[0];

  const videoCover = postGroup.post.some((post) => post.cover === null)
    ? null
    : firstPost.cover;

  function deletePost(id: number) {
    Confirm({
      title: __("Are you sure to delete this post?"),
      okText: __("Yes, remove it"),
      onOk: () => deletePostGroup.mutate(id),
    });
  }

  function cancel(id: number) {
    Confirm({
      title: __("Are you sure to cancel this post?"),
      okText: __("Yes, cancel it"),
      onOk: () => cancelPost.mutate(id),
    });
  }

  function accept(id: number) {
    Confirm({
      title: __("Are you sure to accept this post?"),
      okText: __("Yes, accept it"),
      onOk: () => acceptPostGroup.mutate(id),
    });
  }

  function retry(id: number) {
    Confirm({
      title: __("Are you sure to retry to send this post?"),
      okText: __("Yes, resend it"),
      onOk: () => retryPostGroup.mutate(id),
    });
  }
  return (
    <Box.Item key={postGroup.id} className="divide-y">
      <div className="group relative block w-full bg-gray-100 dark:bg-gray-700">
        <div className="aspect-h-9 aspect-w-16">
          {firstPost.file[0] && firstPost.file[0].thumbnailUrl ? (
            <img
              src={
                videoCover
                  ? videoCover.thumbnailUrl
                  : firstPost.file[0].thumbnailUrl
              }
              alt=""
              className="pointer-events-none w-full object-cover group-hover:opacity-75"
            />
          ) : (
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={
                  firstPost.file[0]
                    ? FileIcon[firstPost.file[0].ext]
                    : regular("text")
                }
                className="h-10 w-10"
              />
            </div>
          )}
        </div>
        <div className="pointer-events-none absolute bottom-0 end-0 flex items-center bg-gray-800/70 p-1 text-xs font-semibold text-zinc-100">
          <FontAwesomeIcon
            icon={post_type_icons[postGroup.type]}
            className="me-1"
          />
          <div>{" " + __(ucfirst(postGroup.type))}</div>
        </div>
        <Listbox
          value={(postTags || []).filter((tag) =>
            postGroup.tags.map((tag) => tag.id).includes(tag.id),
          )}
          onChange={(value) => {
            updatePostTags.mutate(
              {
                id: postGroup.id,
                tag: value?.map((item) => item.id),
              },
              { onSuccess: () => notify.success(__("Changes saved!")) },
            );
          }}
          multiple
        >
          {isPostTagsLoading && <Loading />}
          <Listbox.Button
            as="div"
            className="absolute bottom-1 start-1 flex space-s-1"
          >
            {postGroup.tags.length > 0 ? (
              <>
                <Button
                  variant="light"
                  padding="none"
                  className="px-1 py-0.5 text-xs"
                  icon={regular("tags")}
                >
                  <div className="max-w-[8rem] truncate">
                    {postGroup.tags[0].name}
                  </div>
                </Button>
                <Button
                  variant="light"
                  padding="none"
                  center
                  className="w-5 px-1 text-xs"
                >
                  {postGroup.tags.length > 1 ? "..." : "+"}
                </Button>
              </>
            ) : (
              <Button
                variant="light"
                padding="none"
                className="px-1 py-0.5 text-xs"
                icon={regular("plus")}
              >
                {__("Add label")}
              </Button>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 max-h-56 w-56 overflow-auto rounded-md bg-gray-50 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800">
              {(postTags || []).map((item) => (
                <Listbox.Option
                  key={item.id}
                  value={item}
                  className={({ active, selected }) =>
                    classNames(
                      selected
                        ? "bg-primary-500 text-white dark:bg-primary-600"
                        : active
                          ? "bg-gray-200 text-gray-700 dark:bg-gray-600 dark:text-white"
                          : "text-gray-900 dark:text-white",
                      "relative cursor-pointer select-none px-3 py-2",
                    )
                  }
                >
                  <div className="flex items-center space-s-1">
                    <span
                      style={{
                        background: "#" + item.color,
                      }}
                      className="h-4 w-4 rounded-full text-center"
                    />
                    <div>{item.name}</div>
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </Listbox>
      </div>
      <div className="w-full p-2">
        <p className="my-3 line-clamp-2 block h-[21px]">
          {stripTags(postGroup.caption)}
        </p>
        <Text variant="gray" block className="font-light">
          {postGroup.from && (
            <div>{__("Sent from") + ": " + postGroup.from}</div>
          )}
          <div className="text-xs">
            {__("Schedule date") +
              ": " +
              formatDateTime(postGroup.schedule_date)}
          </div>
          <div className="text-xs">
            {__("Created date") + ": " + formatDateTime(postGroup.created_at)}
          </div>
        </Text>
        <dl className="mt-1 flex flex-grow flex-col text-center">
          <dt className="sr-only">Status</dt>
          <dd className="my-3">
            <span
              className={classNames(
                "inline-flex items-center rounded-full border px-2 py-1 text-xs space-s-1",
                status_text === "failed" && "text-red-500",
                status_text === "published" && "text-green-600",
              )}
            >
              {Object.keys(counts).length < 2 && (
                <FontAwesomeIcon
                  icon={regular(
                    status_text === "published"
                      ? "check"
                      : status_text === "scheduled" ||
                          status_text === "publishing"
                        ? "clock"
                        : status_text === "process"
                          ? "gears"
                          : status_text === "draft"
                            ? "square-pen"
                            : status_text === "recurring"
                              ? "repeat"
                              : "xmark",
                  )}
                />
              )}
              <span>
                {Object.keys(counts).length > 1
                  ? (Object.keys(counts) as Array<Post["status"]>).map(
                      (status, index) => (
                        <span
                          key={index}
                          className={classNames(
                            status === "failed" && "text-red-500",
                            status === "published" && "text-green-600",
                          )}
                        >
                          <FontAwesomeIcon
                            icon={regular(
                              status === "published"
                                ? "check"
                                : status === "scheduled" ||
                                    status === "publishing"
                                  ? "clock"
                                  : status === "process"
                                    ? "gears"
                                    : status === "draft"
                                      ? "square-pen"
                                      : status === "recurring"
                                        ? "repeat"
                                        : "xmark",
                            )}
                          />
                          {counts[status] + " " + __(status)}
                        </span>
                      ),
                    )
                  : __(
                      postGroup.is_scheduled === 1 ||
                        status_text !== "scheduled"
                        ? status_text
                        : "in publishing queue",
                    )}
              </span>
            </span>
          </dd>
          {postGroup.post.some((post) => post.status === "failed") && (
            <PostErrorModal postGroup={postGroup} />
          )}
          <dt className="sr-only">Account</dt>
          <dd className="my-3 space-s-1">
            {postGroup.post.map((post) => (
              <Tooltip
                key={post.id}
                tooltip={
                  post.account.name +
                  (post.status === "failed" && post.log ? ": " + post.log : "")
                }
              >
                <a
                  key={post.id}
                  href={post.accountPost?.permalink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    variant={
                      post.status === "failed"
                        ? "danger"
                        : post.status === "published"
                          ? "success"
                          : "default"
                    }
                  >
                    <SocialIcon
                      className={
                        post.status == "failed"
                          ? "!text-red-500"
                          : "!text-gray-900 dark:!text-white"
                      }
                      social={post.account.type}
                    />
                  </Text>
                </a>
              </Tooltip>
            ))}
          </dd>
        </dl>
      </div>
      <Box.FooterButton>
        {postGroup.post.filter((post) => post.status === "pending").length !==
          0 && !isTeammate ? (
          <>
            <Box.FooterButtonItem
              icon={regular("check")}
              title={__("Accept")}
              onClick={() => {
                accept(postGroup.id);
              }}
            />
            <Box.FooterButtonItem
              icon={regular("pen")}
              title={__("Edit")}
              to={`/post/${postGroup.id}`}
            />
            <Box.FooterButtonItem
              icon={regular("xmark")}
              title={__("Cancel")}
              onClick={() => {
                cancel(postGroup.id);
              }}
            />
          </>
        ) : (
          <>
            {postGroup.post.some(
              (post) =>
                post.status === "scheduled" ||
                post.status === "pending" ||
                post.status === "draft" ||
                post.status === "recurring" ||
                post.status === "failed",
            ) && (
              <Box.FooterButtonItem
                icon={regular("pen")}
                title={__("Edit")}
                to={`/${postGroup.type === "story" ? "story" : "post"}/${
                  postGroup.id
                }`}
              />
            )}
            {!postGroup.post.some((post) => post.status === "draft") &&
            postGroup.post.some((post) =>
              featureSupports.post_insights.includes(post.account.type),
            ) &&
            postGroup.post.some((post) => post.status === "published") ? (
              <Box.FooterButtonItem
                icon={regular("chart-simple")}
                title={__("Insights")}
                to={`insights/post/${postGroup.id}`}
              />
            ) : undefined}
            {!postGroup.post.some((post) => post.status === "draft") &&
              (postGroup.post.filter((post) => post.status === "failed")
                .length !== 0
                ? canRetry && (
                    <Box.FooterButtonItem
                      icon={regular("arrow-rotate-right")}
                      title={__("Try again")}
                      onClick={(e) => {
                        e.preventDefault();
                        retry(postGroup.id);
                      }}
                    />
                  )
                : undefined)}
            <Box.FooterButtonItem
              icon={regular("repeat")}
              title={__("Resend it")}
              to={`/${postGroup.type === "story" ? "story" : "post"}?from=${
                postGroup.id
              }`}
            />
            {postGroup.post.some(
              (post) =>
                post.status === "scheduled" ||
                post.status === "pending" ||
                post.status === "recurring",
            ) && (
              <Box.FooterButtonItem
                icon={regular("hand")}
                title={__("Cancel")}
                onClick={(e) => {
                  e.preventDefault();
                  cancel(postGroup.id);
                }}
              />
            )}
            {postGroup.post.filter((post) =>
              [
                "scheduled",
                "publishing",
                "process",
                "processing",
                "pending",
              ].includes(post.status),
            ).length === 0 &&
              canDelete && (
                <Box.FooterButtonItem
                  icon={regular("trash")}
                  title={__("Delete post from %{site}", {
                    site: __(import.meta.env.VITE_SITE_NAME),
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    deletePost(postGroup.id);
                  }}
                />
              )}
          </>
        )}
      </Box.FooterButton>
    </Box.Item>
  );
}

export const post_type_icons = {
  story: regular("circle-plus"),
  reels: regular("clapperboard-play"),
  image: regular("image"),
  video: regular("video"),
  album: regular("photo-film"),
  document: regular("file"),
  text: regular("text"),
  link: regular("link"),
  poll: regular("poll-people"),
};
