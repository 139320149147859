import { InfiniteData } from "@tanstack/react-query";
import { CursorPaginate, Paginate } from "../interfaces/Api";

export function insertDataOnArray<T extends { id: number }>(
  previous: T[] | undefined,
  newData: T,
) {
  return previous ? [...previous, newData] : undefined;
}

export function updateDataOnArray<T extends { id: number }>(
  previous: T[] | undefined,
  newData: T,
) {
  return previous?.map((item) => (item.id !== newData.id ? item : newData));
}

export function deleteDataOnArray<T extends { id: number }>(
  previous: T[] | undefined,
  deleteId: number,
) {
  return previous?.filter((item) => item.id !== deleteId);
}

export function insertDataOnInfiniteQuery<
  T extends { id: number },
  U extends InfiniteData<Paginate<T> | CursorPaginate<T>>,
>(previous: U | undefined, newData: T, direction: "start" | "end" = "end"): U {
  return {
    pages: previous?.pages.map((page, i) =>
      (direction === "start" && i === 0) ||
      (direction === "end" && i === previous.pages.length - 1)
        ? {
            data:
              direction === "start"
                ? [newData, ...page.data]
                : [...page.data, newData],
            paging: page.paging,
          }
        : page,
    ) ?? [{ data: [newData], paging: {} }],
    pageParams: previous?.pageParams ?? [],
  } as U;
}

export function updateDataOnInfiniteQuery<
  T extends { id: number },
  U extends InfiniteData<Paginate<T> | CursorPaginate<T>>,
>(previous: U | undefined, newData: T): U {
  return {
    pages:
      previous?.pages.map((page) => ({
        data: page.data.map((val) => (val.id !== newData.id ? val : newData)),
        paging: page.paging,
      })) ?? [],
    pageParams: previous?.pageParams ?? [],
  } as U;
}

export function findDataOnInfiniteQuery<
  T extends { id: number },
  U extends InfiniteData<Paginate<T> | CursorPaginate<T>>,
>(
  previous: (U | undefined)[],
  idToFind: number,
):
  | (U extends InfiniteData<Paginate<infer T> | CursorPaginate<infer T>>
      ? T
      : unknown)
  | undefined {
  return previous?.map(
    (previous) =>
      previous?.pages.map((page) =>
        page.data.find((val) => val.id === idToFind),
      )?.[0],
  )?.[0] as any;
}

export function deleteDataOnInfiniteQuery<
  T extends { id: number },
  U extends InfiniteData<Paginate<T> | CursorPaginate<T>>,
>(previous: U | undefined, deleteId: number): U {
  return {
    pages:
      previous?.pages.map((page) => ({
        data: page.data.filter((val) => val.id !== deleteId),
        paging: page.paging,
      })) ?? [],
    pageParams: previous?.pageParams ?? [],
  } as U;
}

export function getNextPaginatePage(data: Paginate<any> | undefined) {
  return data &&
    ((typeof data.paging.total_page === "undefined" &&
      data.data.length >= data.paging.limit) ||
      (typeof data.paging.total_page !== "undefined" &&
        data.paging.total_page !== 0 &&
        data.paging.total_page > (data.paging.page || 0)))
    ? (data.paging.page || 1) + 1
    : undefined;
}

export function getNextCursorPaginatePage(data?: CursorPaginate<any>) {
  return data && data.paging.after ? { after: data.paging.after } : undefined;
}

export function getPreviousCursorPaginatePage(data?: CursorPaginate<any>) {
  return data && data.paging.before
    ? { before: data.paging.before }
    : undefined;
}
