import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, {
  TextareaHTMLAttributes,
  useEffect,
  useImperativeHandle,
} from "react";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { TextAreaProps } from "@common/types";
import { Text } from "../text";
import { EmojiPicker, EmojiPickerProps } from "./EmojiPicker";
import { Input, getInputClassName } from "./Input";

export const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  TextareaHTMLAttributes<HTMLTextAreaElement> & TextAreaProps
>(function TextArea(
  {
    label,
    error,
    hint,
    loading,
    className,
    icon,
    iconPosition,
    emojiPicker,
    charLeft,
    autoHeight,
    emojiIcon,
    onChange,
    onInputChange,
    resizeable,
    onAutoHeight,
    success,
    autoWidth,
    ...props
  },
  ref,
) {
  const [emojiPickerVisible, setEmojiPickerVisible] = React.useState(false);
  const innerRef = React.useRef<HTMLTextAreaElement>(null);

  useImperativeHandle(ref, () => innerRef.current!);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        innerRef.current &&
        !innerRef.current.contains(event.target as Node)
      ) {
        setEmojiPickerVisible(false);
      }
    }

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (!innerRef.current || !autoHeight) return;
    innerRef.current.style.height = "0px";
    const scrollHeight = innerRef.current.scrollHeight + 2;
    innerRef.current.style.height = scrollHeight + "px";
    onAutoHeight?.(scrollHeight);
  }, [props.value, autoHeight, onAutoHeight]);

  const onEmojiSelect: EmojiPickerProps["onEmojiInsert"] = (emoji) => {
    setEmojiPickerVisible(false);
    if (!innerRef.current) {
      return;
    }
    const el = innerRef.current;
    const cursorPosition = el.selectionStart;
    const textBeforeCursorPosition = el.value.substring(0, cursorPosition);
    const textAfterCursorPosition = el.value.substring(
      cursorPosition,
      el.value.length,
    );
    onInputChange?.(
      textBeforeCursorPosition + emoji.native + textAfterCursorPosition,
    );
    //this.props.onEmojiSelect?.(emoji);
  };

  return (
    <Input
      label={label}
      error={error}
      hint={hint}
      loading={loading}
      iconPosition={iconPosition}
      success={success}
    >
      <div className="w-full">
        <div className="relative">
          <textarea
            className={classNames(
              !autoWidth && "block w-full",
              getInputClassName(
                error
                  ? "danger"
                  : success
                    ? "success"
                    : props.disabled
                      ? "disabled"
                      : "default",
              ),
              className,
              !resizeable ? "resize-none" : "",
              autoHeight && "max-h-48",
              "input-padding",
            )}
            ref={innerRef}
            onChange={(e) => {
              onChange?.(e);
              onInputChange?.(e.target.value);
            }}
            {...props}
          />
          {emojiPicker && (
            <EmojiPicker
              isOpen={emojiPickerVisible}
              onEmojiInsert={onEmojiSelect}
              renderTarget={(ref: any) => (
                <div
                  ref={ref}
                  className="absolute end-4 top-5 hidden -translate-y-1/2 cursor-pointer items-center md:flex"
                  onClick={(e) => {
                    setEmojiPickerVisible(!emojiPickerVisible);
                    e.stopPropagation();
                  }}
                >
                  {emojiIcon || (
                    <FontAwesomeIcon
                      icon={regular("face-smile")}
                      className="h-4 w-4"
                    />
                  )}
                </div>
              )}
            />
          )}
          {iconPosition !== "none" ? (
            <div
              className={
                "flex " +
                (icon &&
                  (iconPosition === "end"
                    ? "absolute end-0"
                    : "absolute start-0"))
              }
            >
              {Array.isArray(icon) ? (
                <FontAwesomeIcon icon={icon} className="px-4" />
              ) : (
                icon
              )}
            </div>
          ) : Array.isArray(icon) ? (
            <FontAwesomeIcon icon={icon} className="px-4" />
          ) : (
            icon
          )}
        </div>
        {typeof charLeft !== "undefined" && (
          <Text variant="muted" className="mt-1.5" block>
            {__("Character left")}: {charLeft}
          </Text>
        )}
      </div>
    </Input>
  );
});
