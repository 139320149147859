import I18n from "i18n-js";
import { useEffect, useMemo, useState } from "react";
import { useFetchMeData } from "@common/features/User";
import { versionCompare } from "@common/helpers/functions";
import { __, language, setLanguage, setTimeZone } from "@common/helpers/i18n";
import { NewFeatureModal } from "@app/elements/modals";

declare const _paq: any;

export default function UserDataProvider({
  installedVersion,
  onInstall,
  children,
}: {
  installedVersion?: string;
  onInstall?: (version: string) => void;
  children: JSX.Element;
}) {
  const [_language, _setLanguage] = useState(language);
  const [loadedLanguage, setLoadedLanguage] = useState<string>();

  const { data } = useFetchMeData();

  const isLoaded = data !== undefined;

  useEffect(() => {
    if (data) {
      if (data.user && typeof _paq !== "undefined") {
        _paq.push(["setUserId", data.user.email]);
      }

      if (data.masterUser) {
        setTimeZone(data.masterUser.timezone);
      }

      if (typeof document !== "undefined") {
        (document as any).body.dir = data.is_rtl ? "rtl" : "ltr";
        (document as any).documentElement.lang = data.language;
      }

      if (data.language !== language) {
        setLanguage(data.language);
        _setLanguage(data.language);
        // this.fetchTranslation();
      }
    }
  }, [data]);

  useEffect(() => {
    if (loadedLanguage !== _language && _language) {
      import(`@app/assets/locale/${_language}.json`).then((module) => {
        I18n.translations = {
          [language]: {
            ...I18n.translations[language],
            ...module.default,
            ...{
              [import.meta.env.VITE_SITE_NAME]: import.meta.env.VITE_SITE_TITLE,
            },
          },
        };
        I18n.locale = language;
        __.cache.clear?.();
        setLoadedLanguage(_language);
      });
    }
  }, [_language, loadedLanguage]);

  useEffect(() => {
    if (loadedLanguage && isLoaded && typeof document !== "undefined") {
      (document as any).getElementsByClassName("splash")[0]?.remove();
    }
  }, [isLoaded, loadedLanguage]);

  const isNewVersion = useMemo(
    () =>
      (installedVersion &&
        data?.user &&
        data.webapp?.version &&
        versionCompare(installedVersion, data.webapp.version) < 0) ||
      false,
    [data, installedVersion],
  );

  if (!isLoaded || !loadedLanguage) {
    return null;
  }

  return (
    <>
      {data && (
        <NewFeatureModal
          isOpen={isNewVersion}
          onClose={() => onInstall?.(data.webapp?.version)}
          title={data.webapp?.title}
          subtitle={data.webapp?.subtitle}
          description={data.webapp?.description}
        />
      )}
      {children}
    </>
  );
}
