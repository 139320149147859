import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { Moment } from "moment-jalaali";
import { useSocialTypes } from "@common/features/User";
import notify from "@common/helpers/NotificationManager";
import ValidationError from "@common/helpers/ValidationError";
import { api } from "@common/helpers/api";
import { __, convertDateToUTC, formatDateTime } from "@common/helpers/i18n";
import { navigation } from "@common/helpers/navigation";
import {
  deleteDataOnInfiniteQuery,
  getNextPaginatePage,
  insertDataOnInfiniteQuery,
  updateDataOnInfiniteQuery,
} from "@common/helpers/queries";
import { DefaultSelectValue, Paginate } from "@common/interfaces/Api";
import { calendarKeys } from "../../Calendar";
import { SocialNetwrokType } from "../../Social";
import {
  PostCount,
  PostGroupWithDetailFile,
  PostGroupWithFileAccountAccountPost,
  SendPostBody,
} from "../interfaces";
import { PostState } from "../store/post";

const PostGroupListFieldExpansion = "post(file,account,accountPost)";

export type PostFilter = {
  selectedTag?: number[];
  status?: DefaultSelectValue;
  order?: DefaultSelectValue;
  accounts?: number[];
  publishFrom?: Moment;
  publishTo?: Moment;
};

export const postGroupsKeys = createQueryKeys("postGroups", {
  list: (filter: PostFilter & { isDraft?: boolean }) => [{ filter }],
  postGroup: (postGroupId: number) => [{ postGroupId }],
  postGroupDetail: (postGroupId?: number) => [{ postGroupId }],
  count: null,
});

export type PostGroupList = Paginate<PostGroupWithFileAccountAccountPost>;

export const useFetchPostGroups = (filter: PostFilter) =>
  useInfiniteQuery({
    ...postGroupsKeys.list(filter),
    queryFn: async ({ pageParam }) => {
      const response = await api.get<PostGroupList>("post", {
        params: {
          status: filter.status?.value,
          order: filter.order?.value,
          account_ids: filter.accounts,
          publish_from: filter.publishFrom?.format("YYYY/MM/DD"),
          publish_to: filter.publishTo?.format("YYYY/MM/DD"),
          tag: filter.selectedTag,
          with: PostGroupListFieldExpansion,
          page: pageParam,
        },
      });
      return response.data;
    },
    initialPageParam: undefined,
    getNextPageParam: getNextPaginatePage,
  });

export const useFetchPostGroup = (postGroupId: number) =>
  useQuery({
    ...postGroupsKeys.postGroup(postGroupId),
    queryFn: async () => {
      const response = await api.get<PostGroupWithFileAccountAccountPost>(
        "post/group/" + postGroupId,
        {
          params: {
            with: PostGroupListFieldExpansion,
          },
        },
      );
      return response.data;
    },
  });

export const useFetchPostGroupDetail = (postGroupId?: number) =>
  useQuery({
    ...postGroupsKeys.postGroupDetail(postGroupId),
    queryFn: async () => {
      const response = await api.get<PostGroupWithDetailFile>(
        "post/group/" + postGroupId,
        {
          params: {
            with: "post(file,detail)",
          },
        },
      );
      return response.data;
    },
    enabled: postGroupId !== undefined,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useFetchDraft = (filter: PostFilter) =>
  useInfiniteQuery({
    ...postGroupsKeys.list({ ...filter, isDraft: true }),
    queryFn: async ({ pageParam }) => {
      const response = await api.get<PostGroupList>("post/draft", {
        params: {
          account_ids: filter.accounts,
          with: PostGroupListFieldExpansion,
          page: pageParam,
        },
      });
      return response.data;
    },
    initialPageParam: undefined,
    getNextPageParam: getNextPaginatePage,
  });

export const useDeletePostGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      api.delete(`post/${id}`, { data: { with: PostGroupListFieldExpansion } }),
    onSuccess: (_data, variables) =>
      optimisticDeletePostGroups(queryClient, variables),
  });
};

export const useRetryPostGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      api
        .put(`post/${id}/retry`, { with: PostGroupListFieldExpansion })
        .then((response) => response.data),
    onSuccess: (response) => optimisticUpdatePostGroups(queryClient, response),
  });
};

export const useCancelPostGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      api
        .put(`post/${id}/cancel`, { with: PostGroupListFieldExpansion })
        .then((response) => response.data),
    onSuccess: (response) => optimisticUpdatePostGroups(queryClient, response),
  });
};

export const useAcceptPostGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      api
        .put(`post/${id}/accept`, { with: PostGroupListFieldExpansion })
        .then((response) => response.data),
    onSuccess: (response) => optimisticUpdatePostGroups(queryClient, response),
  });
};

export const useSavePost = (
  state: PostState,
  postGroup?: PostGroupWithDetailFile,
) => {
  const queryClient = useQueryClient();
  const socialTypes = useSocialTypes();

  return useMutation({
    mutationFn: async () => {
      const type =
        state.postType === "story"
          ? "story"
          : state.selectedFiles.length == 0
            ? state.poll
              ? "poll"
              : "text"
            : state.selectedFiles.length == 1
              ? state.selectedFiles[0].fileType
              : "album";

      if (type === "album" && state.selectedFiles.length < 2) {
        throw new ValidationError(
          __("Please select at least 2 media album post."),
        );
      } else if (type === "story" && state.selectedFiles.length < 1) {
        throw new ValidationError(
          __("Please select one media for story post."),
        );
      } else if (state.selectedAccounts.length < 1) {
        throw new ValidationError(__("Please select at least one account."));
      }

      const selectedAccounts =
        state.postType === "post"
          ? state.selectedAccounts
          : state.selectedAccounts.filter((account) =>
              socialTypes.story.includes(account.type),
            );

      const { main, ...customCaptions } = state.captions.html;
      const data: SendPostBody = {
        is_draft: state.saveType === "draft" ? 1 : 0,
        account_ids: selectedAccounts.map((item) => item.id),
        caption: main,
        custom_captions: customCaptions,
        is_scheduled: state.saveType === "schedule" ? 1 : 0,
        type,
        media_ids:
          state.selectedFiles.length > 0
            ? state.selectedFiles.map((file) => file.id)
            : [],
        photo_tags: {},
        thumbnail_second: {},
        recurring: state.recurring,
      };
      Object.entries(state.mediaTags).forEach(([key, value]) => {
        if (data.media_ids.includes(+key)) {
          data.photo_tags[key] = [];

          for (const item of value) {
            data.photo_tags[key].push([
              item.locationX,
              item.locationY,
              item.text,
              item.id,
            ]);
          }
        }
      });

      data.thumbnail_second = state.thumbnailsSecond;

      if (state.scheduleDate) {
        data.schedule_date =
          convertDateToUTC(state.scheduleDate)
            .set({ second: 0, millisecond: 0 })
            .toDate()
            .getTime() / 1000;
      }

      const checkTypeExist = (typeSearch: SocialNetwrokType) =>
        selectedAccounts.some((account) => account.type === typeSearch);

      if (checkTypeExist("Telegram")) {
        data.telegram_has_signature = state.extra.Telegram.has_signature;
        data.telegram_pin_message = state.extra.Telegram.pin_message;

        if (state.extra.Telegram.buttons)
          data.telegram_buttons = state.extra.Telegram.buttons.map(
            (item) => item.text + ":" + item.link,
          );
      }

      if (checkTypeExist("Gap")) {
        data.gap_has_signature = state.extra.Gap.has_signature;
      }

      if (checkTypeExist("Bale")) {
        data.bale_has_signature = state.extra.Bale.has_signature;
      }

      if (checkTypeExist("Eitaa")) {
        data.eitaa_has_signature = state.extra.Eitaa.has_signature;
        data.eitaa_pin_message = state.extra.Eitaa.pin_message;
      }

      if (checkTypeExist("YouTube")) {
        data.youtube_category = state.extra.YouTube.category + "";
        data.youtube_title = state.extra.YouTube.title;
        data.youtube_privacy = state.extra.YouTube.privacy;
        data.youtube_playlist = state.extra.YouTube.playlist;
        data.youtube_video_cover = state.extra.YouTube.video_cover;
      }

      if (checkTypeExist("Aparat")) {
        data.aparat_category = state.extra.Aparat.category + "";
        data.aparat_title = state.extra.Aparat.title;
        data.aparat_playlist = state.extra.Aparat.playlist;
        data.aparat_video_cover = state.extra.Aparat.video_cover;
      }

      if (checkTypeExist("Linkedin")) {
        data.linkedin_title = state.extra.Linkedin.linkedin_title;
      }

      if (state.location) {
        data.location = JSON.stringify(state.location.value);
      }

      if (state.hashtag) {
        data.hashtag = state.hashtag;
      }

      data.reels_sharetofeed = state.extra.InstagramOfficial.reels_sharetofeed;
      data.reels_audioname = state.extra.InstagramOfficial.reels_audioname;
      data.reels_tags = state.extra.InstagramOfficial.reels_tags;
      data.instagram_collaborators =
        state.extra.InstagramOfficial.instagram_collaborators;

      if (state.extra.InstagramOfficial.first_comment) {
        data.first_comment = state.extra.InstagramOfficial.first_comment;
      }

      data.video_cover = state.extra.InstagramOfficial.video_cover;

      if (state.autoDeleteHour && state.autoDeleteAccounts.length !== 0) {
        data.auto_delete = {
          hour: state.autoDeleteHour,
          account_ids: state.autoDeleteAccounts,
        };
      }

      if (checkTypeExist("Pinterest")) {
        data.pinterest_url = state.extra.Pinterest.pinterest_url;
        data.pinterest_title = state.extra.Pinterest.pinterest_title;
      }

      if (checkTypeExist("TikTok")) {
        data.tiktok_disable_comment = state.extra.TikTok.disable_comment;
        data.tiktok_disable_duet = state.extra.TikTok.disable_duet;
        data.tiktok_disable_stitch = state.extra.TikTok.disable_stitch;
        data.tiktok_privacy_level = state.extra.TikTok.privacy_level;
      }

      if (checkTypeExist("Twitter")) {
        data.tweets = state.extra.Twitter.tweets;
      }

      data.poll = state.poll;

      return (
        await api.post<PostGroupWithFileAccountAccountPost>(
          "post/" +
            (postGroup
              ? postGroup.post.some((post) => post.status === "published")
                ? postGroup.id + "/edit"
                : postGroup.id
              : ""),
          { ...data, with: PostGroupListFieldExpansion },
        )
      ).data;
    },
    onSuccess: (response) => {
      optimisticInsertPostGroups(queryClient, response);

      if (state.saveType === "draft") {
        notify.success(__("Draft saved"));
        navigation.push("draft");
        return;
      }
      if (postGroup) {
        notify.success(__("Post updated"));
      } else if (state.saveType === "schedule") {
        notify.success(
          __("Post has been scheduled to %{date}", {
            date: formatDateTime(response.schedule_date),
          }),
        );
      } else {
        notify.success(__("Post published"));
      }
      navigation.push("posts");
    },
  });
};

function optimisticInsertPostGroups(
  queryClient: QueryClient,
  postGroup: PostGroupWithFileAccountAccountPost,
) {
  queryClient.setQueriesData(postGroupsKeys.postGroup(postGroup.id), postGroup);

  queryClient.setQueriesData<InfiniteData<PostGroupList>>(
    { queryKey: postGroupsKeys.list._def },
    (previous) => insertDataOnInfiniteQuery(previous, postGroup),
  );

  queryClient.invalidateQueries({
    queryKey: postGroupsKeys.list._def,
    refetchType: "none",
  });

  queryClient.invalidateQueries(postGroupsKeys.postGroupDetail(postGroup.id));

  queryClient.invalidateQueries({ queryKey: calendarKeys._def });
}

function optimisticUpdatePostGroups(
  queryClient: QueryClient,
  postGroup: PostGroupWithFileAccountAccountPost,
) {
  queryClient.setQueriesData(postGroupsKeys.postGroup(postGroup.id), postGroup);

  queryClient.setQueriesData<InfiniteData<PostGroupList>>(
    { queryKey: postGroupsKeys.list._def },
    (previous) => updateDataOnInfiniteQuery(previous, postGroup),
  );

  queryClient.invalidateQueries({
    queryKey: postGroupsKeys.list._def,
    refetchType: "none",
  });

  queryClient.invalidateQueries(postGroupsKeys.postGroupDetail(postGroup.id));

  queryClient.invalidateQueries({ queryKey: calendarKeys._def });
}

function optimisticDeletePostGroups(
  queryClient: QueryClient,
  postGroupId: number,
) {
  queryClient.invalidateQueries(postGroupsKeys.postGroup(postGroupId));
  queryClient.invalidateQueries(postGroupsKeys.postGroupDetail(postGroupId));

  queryClient.setQueriesData<InfiniteData<PostGroupList>>(
    { queryKey: postGroupsKeys.list._def },
    (previous) => deleteDataOnInfiniteQuery(previous, postGroupId),
  );

  queryClient.invalidateQueries({
    queryKey: postGroupsKeys.list._def,
    refetchType: "none",
  });

  queryClient.invalidateQueries({ queryKey: calendarKeys._def });
}

export const useUpdatePostTags = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, tag }: { id: number; tag?: number[] }) =>
      api
        .post(`post/${id}/tag`, {
          tag,
          with: PostGroupListFieldExpansion,
        })
        .then((response) => response.data),
    onSuccess: (postGroup) => {
      queryClient.setQueriesData<InfiniteData<PostGroupList>>(
        { queryKey: postGroupsKeys.list._def },
        (previous) => updateDataOnInfiniteQuery(previous, postGroup),
      );
      queryClient.invalidateQueries(postGroupsKeys.postGroup(postGroup.id));
    },
  });
};

export const useFetchPostCount = (enabled = true) =>
  useQuery({
    ...postGroupsKeys.count,
    queryFn: async () => {
      const response = await api.get<PostCount>("post/count");
      return response.data;
    },
    enabled,
  });
