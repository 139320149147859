import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TikTokPrivacyList, useExtraPostState } from "@common/features/Post";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { CheckBox, Select } from "@app/elements/form";
import { Tooltip } from "@app/elements/helpers";
import { SocialIcon } from "@app/elements/social";
import { Text } from "@app/elements/text";

export default function TikTokSection(props: Partial<SectionToggleProps>) {
  const [data, dispatch] = useExtraPostState("TikTok");
  return (
    <SectionToggle
      {...props}
      title={__("TikTok settings")}
      defaultOpen
      icon={<SocialIcon social="TikTok" />}
    >
      <ElementsGroup>
        <Select
          label={
            <Text
              iconPosition="end"
              block
              className="flex items-center space-s-2"
            >
              <div>{__("Privacy level")}</div>
              <Tooltip tooltip={__("Who can view the video")}>
                <FontAwesomeIcon
                  icon={regular("circle-question")}
                  className="rtl:-scale-x-100"
                />
              </Tooltip>
            </Text>
          }
          placeholder={__("Privacy level")}
          value={data.privacy_level ? { value: data.privacy_level } : undefined}
          options={(
            Object.keys(TikTokPrivacyList) as Array<
              keyof typeof TikTokPrivacyList
            >
          ).map((value) => ({
            value,
          }))}
          getLabel={(item) => __(TikTokPrivacyList[item.value])}
          onChange={(item) => dispatch("privacy_level", item.value)}
        />
        <CheckBox
          checked={data.disable_comment === "1"}
          onInputChange={(checked) =>
            dispatch("disable_comment", checked ? "1" : "0")
          }
          label={__("Disable comment")}
        />
        <CheckBox
          checked={data.disable_duet === "1"}
          onInputChange={(checked) =>
            dispatch("disable_duet", checked ? "1" : "0")
          }
          label={__("Disable Duet")}
        />
        <CheckBox
          checked={data.disable_stitch === "1"}
          onInputChange={(checked) =>
            dispatch("disable_stitch", checked ? "1" : "0")
          }
          label={__("Disable Stitch")}
        />
      </ElementsGroup>
    </SectionToggle>
  );
}
