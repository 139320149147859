import classNames from "classnames";

export function LoadProgress({ hasFooter }: { hasFooter: boolean }) {
  return (
    <div
      id="load-progress"
      className={classNames(
        "fixed left-0 right-0 z-20 hidden h-0.5 bg-transparent sm:bottom-auto sm:top-0",
        hasFooter
          ? "bottom-[calc(45px+env(safe-area-inset-bottom))]"
          : "bottom-[env(safe-area-inset-bottom)]",
      )}
    >
      <div className="float-left h-full bg-primary-500 transition-all duration-500 ease-in-out dark:bg-gray-400" />
    </div>
  );
}
