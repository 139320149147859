import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { PostGroupWithFileAccountAccountPost } from "@common/features/Post";
import { regular } from "@common/helpers/fontawesome";
import { ucfirst } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { ElementsGroup } from "@app/elements/container";
import { Button } from "@app/elements/form";
import { Modal } from "@app/elements/modals";
import { Text } from "@app/elements/text";

export default function PostErrorModal({
  postGroup,
}: {
  postGroup: PostGroupWithFileAccountAccountPost;
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        onClose={() => setShowModal(false)}
        isOpen={showModal}
        showCloseIcon
        padding="medium"
      >
        <ElementsGroup display="block">
          <Modal.Title>{__("Error detail")}</Modal.Title>
          {postGroup.post
            .filter((post) => post.status === "failed")
            .map((item) => (
              <Text
                block
                key={item.id}
                className="relative flex w-full cursor-pointer items-center px-1 py-5"
              >
                <h3 className="me-2 border-e pe-2">
                  {__(ucfirst(item.account.type))}
                </h3>
                <p>{item.log}</p>
              </Text>
            ))}
        </ElementsGroup>
      </Modal>
      <dt className="sr-only">Error</dt>
      <dd className="my-3">
        <Button
          className="inline-flex items-center rounded-full border px-2 py-1 text-xs space-s-1"
          onClick={() => setShowModal(true)}
        >
          <FontAwesomeIcon icon={regular("circle-info")} />
          <span className="">{__("Error detail")}</span>
        </Button>
      </dd>
    </>
  );
}
