import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@common/helpers/fontawesome";
import { PreviewBoxProps } from "@common/types";
import { Loading } from "@app/elements/loading";

export const PreviewBox = <T,>({ preview }: PreviewBoxProps<T>) => {
  return (
    <div className="group relative w-20 overflow-hidden rounded-md ring-1 ring-primary-500 ring-offset-2 ">
      <div className="relative">
        <div
          className="aspect-h-1 aspect-w-1 bg-gray-200 bg-cover bg-center dark:bg-gray-600"
          style={{
            backgroundImage: `url("${preview.previewUrl}")`,
          }}
        />
        <Loading size={48} />
        <div className="absolute inset-0 flex items-center justify-center dark:text-black">
          <div>{preview.percent}%</div>
        </div>
        <div
          className="absolute inset-0 z-10 hidden items-center justify-center bg-gray-300/30 group-hover:flex"
          onClick={() => preview.abort()}
        >
          <FontAwesomeIcon
            icon={regular("xmark")}
            className={"h-8 w-8 cursor-pointer rounded-full"}
          />
        </div>
      </div>
    </div>
  );
};
