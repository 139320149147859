import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { TextProps } from "@common/types";

const variants = {
  default: "text-black dark:text-white",
  gray: "text-gray-500 dark:text-gray-400",
  muted: "text-xs text-gray-500 dark:text-gray-300",
  disabled: "text-gray-500 dark:text-gray-300",
  primary: "text-primary-500",
  success: "text-green-600 dark:text-green-600",
  danger: "text-red-500 dark:text-red-400",
  warning: "text-yellow-400 dark:text-yellow-300",
  info: "text-sky-700 dark:border-sky-500",
  light: "text-gray-900 dark:text-gray-200",
  dark: "text-black dark:text-gray-200",
};

export const Text = React.forwardRef(function Text(
  {
    variant,
    block,
    icon,
    children,
    className,
    iconPosition = "start",
    padding,
    ...rest
  }: TextProps &
    (
      | React.HTMLAttributes<HTMLDivElement>
      | React.HTMLAttributes<HTMLParagraphElement>
    ),
  ref,
) {
  const Component: any = block ? "div" : "span";
  return (
    <Component
      className={classNames(
        className,
        variants[variant || "default"],
        padding === "large"
          ? "px-3 py-3"
          : padding === "medium"
            ? "px-3 py-2"
            : padding === "small" && "px-3 py-1.5",
      )}
      ref={ref}
      {...rest}
    >
      {icon ? (
        <div className="flex items-center space-s-1">
          {iconPosition === "start" &&
            (Array.isArray(icon) ? <FontAwesomeIcon icon={icon} /> : icon)}
          <div>{children}</div>
          {iconPosition === "end" &&
            (Array.isArray(icon) ? <FontAwesomeIcon icon={icon} /> : icon)}
        </div>
      ) : (
        children
      )}
    </Component>
  );
});
