import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  ThemeContext,
  TitleContext,
  ToggleThemeContext,
} from "@common/context/ThemeContext";
import { regular } from "@common/helpers/fontawesome";
import HeaderLiveModeToggle from "./HeaderLiveModeToggle";
import HeaderUser from "./HeaderUser";
import Notification from "./Notification";

export default function Header({ children }: { children?: React.ReactNode }) {
  const theme = useContext(ThemeContext);
  const title = useContext(TitleContext);
  const toggleTheme = useContext(ToggleThemeContext);

  // const duration = masterUser.expire_date
  //   ? moment.duration(
  //       toMoment(masterUser.expire_date, 'YYYY/MM/DD HH:mm').diff(moment()),
  //     )
  //   : null;

  return (
    <>
      {/* {(duration === null || duration.asDays() <= 5) && (
        <div className="h-16 flex bg-primary-500 items-center px-4">
          {duration === null || duration.asDays() < 0 ? (
            <>
              <FontAwesomeIcon
                icon={regular('rocket')}
                className="h-8 w-8 text-secondary pe-4"
              />
              <div className="text-white">
                <div className="font-bold">
                  {__('Become a pro member of %{site}')}
                </div>
                <div className="text-xs">
                  {__(
                    'To access all %{site} features subscribe to one of %{site} packages',
                  )}
                </div>
              </div>
              <Button
                variant="light"
                className="ms-auto"
                to="/renew"
                size="small">
                {__('Upgrade')}
              </Button>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={regular('bell-on')}
                className="h-8 w-8 text-secondary pe-4"
              />
              <div className="text-white">
                <div className="font-bold">
                  {__('Only %{duration} left from your %{package} package', {
                    package: masterUser.packageTitle,
                    duration:
                      duration.asDays() > 1
                        ? duration.humanize({h: 24})
                        : duration.humanize({d: moment().daysInMonth()}),
                  })}
                </div>
                <div className="text-xs">
                  {_('For accessing  please renew your subscription')}
                </div>
              </div>
              <Button
                variant="light"
                className="ms-auto"
                to="/renew"
                size="small">
                {__('Renew')}
              </Button>
            </>
          )}
        </div>
      )} */}
      <header className="hidden flex-shrink-0 items-center border-b bg-white dark:bg-dark-background md:flex">
        <div className="flex h-16 text-gray-700 clear-start dark:text-zinc-100 md:min-w-0 md:flex-1 md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <div className="relative max-w-2xl">
              <h1 className="ps-12 text-base font-bold">{title}</h1>
            </div>
          </div>
          <div className="ms-10 flex flex-shrink-0 items-center pe-4 space-s-10">
            {import.meta.env.MODE === "beta" && <HeaderLiveModeToggle />}
            {children}
            <div className="flex items-center space-s-8">
              <Notification />
              <FontAwesomeIcon
                icon={
                  theme !== "light" ? regular("sun-bright") : regular("moon")
                }
                className="h-6 w-6 cursor-pointer align-middle"
                onClick={toggleTheme}
              />
              <HeaderUser />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
