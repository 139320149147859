import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import {
  deleteDataOnArray,
  insertDataOnArray,
  updateDataOnArray,
} from "@common/helpers/queries";
import { AccountGroup } from "../interfaces";

export const accountGroupsKeys = createQueryKeys("accountGroups");

export const useFetchAccountGroups = () =>
  useQuery({
    queryKey: accountGroupsKeys._def,
    queryFn: async () => {
      const response = await api.get<AccountGroup[]>("account-group");
      return response.data;
    },
  });

export const useDeleteAccountGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => api.delete(`account-group/${id}`),
    onSuccess: (_data, variables) => {
      queryClient.setQueryData<AccountGroup[]>(
        accountGroupsKeys._def,
        (previous) => deleteDataOnArray(previous, variables),
      );

      queryClient.invalidateQueries({
        queryKey: accountGroupsKeys._def,
        refetchType: "none",
      });
    },
  });
};

export const useCreateAccountGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (accountGroup: {
      name: string;
      account_ids: number[] | undefined;
    }) =>
      api.post("account-group", accountGroup).then((response) => response.data),
    onSuccess: (response) => {
      queryClient.setQueryData<AccountGroup[]>(
        accountGroupsKeys._def,
        (previous) => insertDataOnArray(previous, response),
      );

      queryClient.invalidateQueries({
        queryKey: accountGroupsKeys._def,
        refetchType: "none",
      });
    },
  });
};

export const useUpdateAccountGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      ...accountGroup
    }: {
      id: number;
      name: string;
      account_ids: number[] | undefined;
    }) =>
      api
        .put("account-group/" + id, accountGroup)
        .then((response) => response.data),
    onSuccess: (response) => {
      queryClient.setQueryData<AccountGroup[]>(
        accountGroupsKeys._def,
        (previous) => updateDataOnArray(previous, response),
      );

      queryClient.invalidateQueries({
        queryKey: accountGroupsKeys._def,
        refetchType: "none",
      });
    },
  });
};
