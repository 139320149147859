import classNames from "classnames";
import type { DatePickerProps as DatePickerPropsRoot } from "react-datepicker2";
import DatePicker2 from "react-datepicker2";
import { isRTL } from "@common/helpers/i18n";
import { DatePickerProps } from "@common/types";
import "./datepicker.css";
import { getInputClassName } from "./Input";

export function DatePicker({
  placeholder,
  className,
  autoWidth,
  ...props
}: DatePickerProps & DatePickerPropsRoot) {
  return (
    <DatePicker2
      className={classNames(
        !autoWidth && "block w-full",
        className,
        getInputClassName("default"),
      )}
      isGregorian={!isRTL}
      timePicker={false}
      {...props}
      inputReadOnly={true}
      ref={(datepicker: any) =>
        datepicker &&
        placeholder &&
        (datepicker.input.placeholder = placeholder)
      }
    />
  );
}
