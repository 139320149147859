import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone, { DropzoneRef, FileRejection } from "react-dropzone";
import InfiniteScroll from "react-infinite-scroller";
import {
  FileFilter,
  useClearFiles,
  useDeleteFile,
  useFetchFileInfo,
  useFetchFiles,
  useStarFile,
  useUploadFile,
} from "@common/features/File";
import { usePostStore } from "@common/features/Post";
import notify from "@common/helpers/NotificationManager";
import { brands, regular } from "@common/helpers/fontawesome";
import { __, isRTL } from "@common/helpers/i18n";
import { useDebounce, useFilterReducer } from "@common/hooks";
import { FileManagerProps } from "@common/types";
import { Section } from "@app/elements/container";
import { Button, TextInput } from "../../elements/form";
import { Loading } from "../../elements/loading";
import { Confirm, Modal } from "../../elements/modals";
import { Text } from "../../elements/text";
import { FileManagerBox } from "./FileManagerBox";
import { useFileStorages } from "./hooks";

const accept = [
  ".jpg",
  ".jpeg",
  ".png",
  ".mp4",
  ".mov",
  ".pdf",
  ".mp3",
  ".rar",
  ".zip",
  ".mkv",
  ".xlsx",
  ".xsl",
  ".doc",
  ".docx",
];

export function FileManager({ isOpen, onClose, mediaType }: FileManagerProps) {
  const [selectedFiles, dispatch] = usePostStore(
    (state) => state.selectedFiles,
  );

  const [filter, setFilter] = useFilterReducer<FileFilter>(
    {
      type: mediaType || [],
    },
    false,
  );

  useEffect(() => {
    setFilter({
      type: mediaType || [],
    });
  }, [setFilter, mediaType]);

  const [infoMode, setInfoMode] = useState(false);
  const [urlToggle, setUrlToggle] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);
  const [url, setUrl] = useState("");
  const [displayType, setDisplayType] = useState<"grid" | "list">("grid");

  const dropzoneRef = useRef<DropzoneRef>(null);

  const { data, fetchNextPage, hasNextPage } = useFetchFiles(
    useDebounce(filter),
  );

  const { data: info } = useFetchFileInfo();

  const starFile = useStarFile();
  const deleteFile = useDeleteFile();
  const clearFiles = useClearFiles();

  const { previews, upload } = useUploadFile();

  const { googleDrive, dropBox, oneDrive } = useFileStorages(
    useCallback(
      (urls) => urls.forEach((url) => upload({ url, previewUrl: url })),
      [upload],
    ),
  );

  function uploadFromUrl() {
    if (url) {
      upload({ url, previewUrl: url });
    }
    setUrlToggle(false);
    setUrl("");
  }

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        upload({ file, previewUrl: URL.createObjectURL(file) });
      });
    },
    [upload],
  );

  const onReject = useCallback(
    (files: FileRejection[]) => {
      files.forEach((file) => {
        if (file.file.size > info!.max_file_size) {
          notify.error(__("Max. file size to upload"));
        } else {
          notify.error(__("File type is not allowed"));
        }
      });
    },
    [info],
  );

  function renderFiles() {
    const selectedIds = selectedFiles.map((file) => file.id);
    return (
      <>
        {previews
          .map((item) =>
            displayType === "grid" ? (
              <div
                className="group rounded-md border p-2 shadow-sm"
                key={item.previewUrl}
              >
                <div className="relative">
                  <div
                    className="aspect-h-1 aspect-w-1 bg-gray-200 bg-cover bg-center"
                    style={{
                      backgroundImage: `url("${item.url}")`,
                    }}
                  />
                  <Loading size={48} />
                  <div className="absolute inset-0 flex items-center justify-center dark:text-black">
                    <div>{item.percent}%</div>
                  </div>
                  <div
                    className={
                      "absolute inset-0 z-10 hidden items-center justify-center bg-gray-300/30 group-hover:flex"
                    }
                    onClick={() => item.abort()}
                  >
                    <FontAwesomeIcon
                      icon={regular("xmark")}
                      className={
                        "h-8 w-8 cursor-pointer rounded-full text-gray-800"
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              <tr className="border-t-2" key={item.previewUrl}>
                <td className="px-6 py-4">
                  <FontAwesomeIcon
                    icon={regular("xmark")}
                    className="cursor-pointer text-gray-200"
                    onClick={() => item.abort()}
                  />
                </td>
                <td className="px-6 py-4">
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      backgroundImage: `url("${item.url}")`,
                      backgroundSize: "contain",
                    }}
                  />
                </td>
                <td className="px-6 py-4">{item.percent}%</td>
                <td className="px-6 py-4" />
                <td className="px-6 py-4" />
                <td className="px-6 py-4" />
              </tr>
            ),
          )
          .reverse()}
        {data &&
          data.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group.data.map((file) => (
                <FileManagerBox
                  displayType={displayType}
                  active={selectedIds.includes(file.id)}
                  file={file}
                  onSelect={() => dispatch({ type: "fileAdd", payload: file })}
                  onRemove={() =>
                    dispatch({ type: "fileRemove", payload: file })
                  }
                  onDelete={() =>
                    Confirm({
                      title: __("Are you sure to delete this?"),
                      okText: __("Yes, remove it"),
                      onOk: () => deleteFile.mutate(file.id),
                    })
                  }
                  onStar={() => starFile.mutate(file.id)}
                  key={file.id}
                />
              ))}
            </React.Fragment>
          ))}
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        return document.getElementById("confirm-modal") === null
          ? onClose()
          : null;
      }}
      showCloseIcon
      size="large"
      panelClassName="h-full"
    >
      <Dropzone
        onDropAccepted={onDrop}
        onDropRejected={onReject}
        accept={accept}
        noClick
        multiple
        ref={dropzoneRef}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            className="flex h-full cursor-default flex-col divide-y overflow-hidden"
            {...getRootProps()}
          >
            <header className="z-30 bg-white px-8 py-4 dark:bg-dark-background">
              <Loading enabled={deleteFile.isPending} />
              <input {...getInputProps()} />
              <div className="flex space-s-2">
                <Button
                  variant="primary"
                  title={__("Your PC")}
                  onClick={dropzoneRef.current?.open}
                  icon={regular("upload")}
                  padding="small"
                >
                  {__("Upload new file")}
                </Button>

                <Button
                  variant="outline-light"
                  className="px-1.5 py-1.5"
                  title={__("URL")}
                  onClick={() => setUrlToggle((urlToggle) => !urlToggle)}
                  padding="none"
                >
                  <FontAwesomeIcon icon={regular("link")} className="h-5 w-5" />
                </Button>

                {dropBox && (
                  <Button
                    variant="outline-light"
                    className="px-1.5 py-1.5"
                    title={__("Dropbox")}
                    onClick={dropBox}
                    padding="none"
                  >
                    <FontAwesomeIcon
                      icon={brands("dropbox")}
                      className="h-5 w-5"
                    />
                  </Button>
                )}

                {oneDrive && (
                  <Button
                    variant="outline-light"
                    className="px-1.5 py-1.5"
                    title={__("OneDrive")}
                    onClick={oneDrive}
                    padding="none"
                  >
                    <FontAwesomeIcon
                      icon={regular("cloud")}
                      className="h-5 w-5"
                    />
                    <i className="fas fa-cloud icon" />
                  </Button>
                )}

                {googleDrive && (
                  <Button
                    variant="outline-light"
                    className="px-1.5 py-1.5"
                    title={__("Google Drive")}
                    onClick={googleDrive}
                    padding="none"
                  >
                    <FontAwesomeIcon
                      icon={brands("google-drive")}
                      className="h-5 w-5"
                    />
                  </Button>
                )}
              </div>
            </header>
            <div className="relative flex flex-col p-4 sm:flex-row">
              <div
                className={
                  "absolute left-0 right-0 z-20 space-y-4 border-b bg-white p-4 transition-transform dark:bg-dark-background " +
                  (urlToggle ? "-translate-y-4" : "-translate-y-48")
                }
              >
                <TextInput
                  placeholder={__("Paste your link here...")}
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Enter") return;
                    uploadFromUrl();
                  }}
                  icon={regular("link")}
                  iconPosition="end"
                />
                <Button variant="primary" onClick={uploadFromUrl}>
                  {__("Upload")}
                </Button>
              </div>
              <div className="flex space-s-4">
                <TextInput
                  placeholder={__("Search in files")}
                  name="search"
                  className="w-full sm:w-auto"
                  value={filter.search}
                  onInputChange={(value) => setFilter({ search: value })}
                />
                <Button
                  variant="outline-light"
                  className="inline-flex px-1.5 py-1.5 sm:hidden"
                  onClick={() => setFilterToggle((filter) => !filter)}
                  padding="none"
                >
                  <FontAwesomeIcon
                    icon={regular("sliders")}
                    className={
                      "h-5 w-5" +
                      (filterToggle
                        ? " text-primary-500 dark:text-zinc-100"
                        : "")
                    }
                  />
                </Button>
              </div>
              <div
                className={
                  "z-4 my-4 grid grid-cols-2 gap-4 overflow-hidden transition-all sm:my-0 sm:ms-auto sm:max-h-full sm:grid-flow-col sm:grid-cols-none " +
                  (filterToggle ? "max-h-28" : "max-h-0")
                }
              >
                <Button
                  variant="outline-light"
                  className={
                    "hidden sm:inline-flex" +
                    (displayType === "grid"
                      ? " bg-gray-200 dark:bg-gray-700"
                      : "")
                  }
                  padding="small"
                  onClick={() => setDisplayType("grid")}
                >
                  <FontAwesomeIcon
                    icon={regular("table-cells-large")}
                    className="h-5 w-5"
                  />
                </Button>
                <Button
                  variant="outline-light"
                  className={
                    "hidden sm:inline-flex" +
                    (displayType === "list"
                      ? " bg-gray-200 dark:bg-gray-700"
                      : "")
                  }
                  padding="small"
                  onClick={() => setDisplayType("list")}
                >
                  <FontAwesomeIcon icon={regular("list")} className="h-5 w-5" />
                </Button>
                <Button
                  variant="outline-light"
                  padding="small"
                  onClick={() => dispatch({ type: "fileClear" })}
                >
                  {__("Cancel selected")}
                </Button>
                <Button
                  variant="outline-light"
                  padding="small"
                  title={__("Delete Mode")}
                  onClick={() =>
                    Confirm({
                      title: __("Are you sure to delete this?"),
                      okText: __("Yes, remove it"),
                      onOk: () =>
                        Promise.all(
                          selectedFiles.map((file) =>
                            deleteFile.mutateAsync(file.id),
                          ),
                        ).then(() => {
                          dispatch({ type: "fileClear" });
                        }),
                    })
                  }
                  icon={regular("trash")}
                >
                  {__("Delete")}
                </Button>

                <Button
                  variant="outline-light"
                  padding="small"
                  title={__("Stars")}
                  className={
                    filter.star === "1" ? "bg-gray-200 dark:bg-gray-700" : ""
                  }
                  onClick={() =>
                    setFilter({ star: filter.star === "1" ? "0" : "1" })
                  }
                  icon={regular("star")}
                >
                  {__("Stars")}
                </Button>

                <Button
                  variant="outline-light"
                  padding="small"
                  title={__("Info")}
                  onClick={() => setInfoMode((info) => !info)}
                  icon={regular("circle-info")}
                  className={infoMode ? "bg-gray-200 dark:bg-gray-700" : ""}
                >
                  {__("Files info")}
                </Button>
              </div>
            </div>
            <div className="relative h-full overflow-hidden">
              <div className="h-full">
                <div
                  className="h-full overflow-y-auto overflow-x-hidden"
                  style={{
                    height:
                      selectedFiles.length !== 0 ? "calc(100% - 78px)" : "100%",
                  }}
                >
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => fetchNextPage()}
                    hasMore={hasNextPage}
                    loader={<Loading type="block" key={0} />}
                    useWindow={false}
                    threshold={400}
                  >
                    <div className="p-4">
                      {displayType === "grid" ? (
                        <div className="grid grid-cols-2 gap-4 sm:grid-cols-5">
                          {renderFiles()}
                        </div>
                      ) : (
                        <table className="table w-full divide-y text-start">
                          <tr>
                            <th className="px-6 py-4">
                              <FontAwesomeIcon
                                icon={regular("circle-check")}
                                className="text-gray-200"
                              />
                            </th>
                            <th className="px-6 py-4" />
                            <th className="px-6 py-4">{__("Filename")}</th>
                            <th className="px-6 py-4">{__("Upload date")}</th>
                            <th className="px-6 py-4">{__("File size")}</th>
                            <th className="px-6 py-4">{__("File type")}</th>
                          </tr>
                          {renderFiles()}
                        </table>
                      )}
                    </div>
                  </InfiniteScroll>
                </div>
                {selectedFiles.length !== 0 && (
                  <div className="absolute inset-x-0 bottom-0 z-10 flex items-center border-t bg-white px-8 py-4 shadow-md shadow-black dark:bg-dark-background">
                    <div className="flex w-full min-w-0 flex-1 flex-wrap gap-4">
                      {selectedFiles.map((file) => (
                        <div
                          key={file.id}
                          className="h-10 w-10 bg-contain"
                          style={{
                            backgroundImage: `url("${file.thumbnailUrl}")`,
                          }}
                        />
                      ))}
                    </div>
                    <div>
                      <Button
                        variant="primary"
                        onClick={onClose}
                        icon={
                          isRTL ? regular("angle-left") : regular("angle-right")
                        }
                        padding="small"
                        iconPosition="end"
                      >
                        {__("Select medias")}
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              {data && data.pages[0].data.length === 0 && (
                <div className="absolute inset-0 flex flex-col items-center justify-center space-y-2">
                  <FontAwesomeIcon
                    icon={regular("image")}
                    className="h-12 w-12"
                  />
                  <Text variant="muted">
                    {__("Your media volume is empty.")}
                  </Text>
                  <Text variant="muted">{__("Drag some files here.")}</Text>
                  <Button
                    variant="primary"
                    onClick={() => dropzoneRef.current?.open()}
                  >
                    {__("Select files")}
                  </Button>
                </div>
              )}

              {infoMode && (
                <Section
                  pure
                  className="absolute inset-0 z-40 flex flex-col p-4"
                >
                  <h2 className="mt-4 text-base leading-3 text-primary-500 dark:text-white">
                    {info?.remaining_storage_readable}
                  </h2>
                  <Text variant="muted">{__("Remaining storage")}</Text>
                  <div>
                    <div className="relative mt-8 h-6 w-full overflow-hidden rounded-md bg-gray-200 dark:bg-gray-700">
                      <div
                        className="absolute start-0 top-0 h-full bg-primary-500 dark:bg-primary-600"
                        style={{
                          width:
                            (info
                              ? (+info.used_storage / +info.total_storage) * 100
                              : 0) + "%",
                        }}
                      />
                    </div>
                    <div className="flex justify-between text-gray-500">
                      <span
                        className="mt-2"
                        dangerouslySetInnerHTML={{
                          __html: __("Used %{used} of %{total}", {
                            used: `<b>${info?.used_storage_readable}</b>`,
                            total: `<b>${info?.total_storage_readable}</b>`,
                          }),
                        }}
                      />
                      <button
                        onClick={() =>
                          Confirm({
                            title: __("Are you sure to delete this?"),
                            okText: __("Yes, remove it"),
                            onOk: () => clearFiles.mutate(),
                          })
                        }
                      >
                        {__("Clear storage")}
                      </button>
                    </div>
                  </div>
                  <div className="mt-8 text-gray-500">
                    <div>
                      <span className="name">{__("Total")}:</span>
                      <span className="value">
                        <span className="ofm-infobox-total-files">
                          {info?.total_files}
                        </span>
                        {" " + __("Files")}
                      </span>
                    </div>
                    <div>
                      <span className="name">
                        {__("Max. file size to upload")}:
                      </span>
                      <span className="value ofm-infobox-max-upload-size">
                        {info?.max_file_size_readable}
                      </span>
                    </div>
                  </div>
                </Section>
              )}

              {isDragActive && (
                <div className="absolute inset-0 z-40 flex flex-col items-center justify-center bg-white pt-2 text-gray-400">
                  <FontAwesomeIcon
                    icon={regular("upload")}
                    className="animate-bounce text-6xl"
                  />
                  <p>{__("Drop files here to upload")}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </Modal>
  );
}

export const FileIcon: { [key: string]: ReturnType<typeof regular> } = {
  pdf: regular("file-pdf"),
  mp3: regular("file-audio"),
  rar: regular("file-archive"),
  zip: regular("file-zipper"),
  xlsx: regular("file-excel"),
  xls: regular("file-excel"),
  docx: regular("file-word"),
  doc: regular("file-word"),
};
