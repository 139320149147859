import Highcharts from "highcharts";
import {
  InsightsResponse,
  MetricName,
  ProcessData,
  backgroundColors,
} from "@common/features/Insights";
import { AccountPost } from "@common/features/Social";
import { __ } from "@common/helpers/i18n";
import worldGeo from "../world.geo.json";

interface InsightsData {
  growth: { data: Highcharts.Options; summary: number };
  impressions: { data: Highcharts.Options; summary: number };
  engagement: { data: Highcharts.Options; summary: number };
  reach: { data: Highcharts.Options; summary: number };
  top_hashtag: Highcharts.Options;
  mediatype: Highcharts.Options;

  online_follower: Highcharts.Options;
  online_follower_hour: Highcharts.Options;

  bestHour?: [string, string];

  total: {
    page_follow_total?: number;
    growth?: number;
    average_engagement?: number;
    total_link_clicks?: number;
    profile_views?: number;
    total_posts_sent?: number;

    //second row
    email_click?: number;
    phone_click?: number;
    sms_click?: number;
    average_like?: number;
    average_comment?: number;
    average_impressions?: number;
  };

  top_post: AccountPost[];
  top_story: AccountPost[];

  emptyData: boolean;
}

interface InsightsLifetimeData {
  gender: Highcharts.Options;
  country: Highcharts.Options;
}

export function InstagramOfficialSelector<
  TMetric extends string | undefined = undefined,
>(
  _response: InsightsResponse,
  metric?: TMetric,
): TMetric extends string ? Highcharts.Options : InsightsData {
  const data: Partial<InsightsData> = {};

  if (typeof metric === "undefined") {
    data.total = {};
  }
  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }

    switch (response.metric) {
      case "page_follow_growth": {
        const growth = ProcessData.genericMetric(response.data);

        data.growth = {
          data: {
            xAxis: {
              categories: growth.label,
            },
            series: [
              {
                data: growth.data,
                type: "column",
                name: __("InstagramOfficial"),
                color: backgroundColors.InstagramOfficial[1],
                edgeColor: backgroundColors.InstagramOfficial[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        if (!metric) {
          data.total!.growth = ProcessData.sumSummary(response.data);
        }
        break;
      }
      case "impressions": {
        const impressions = ProcessData.genericMetric(response.data);

        data.impressions = {
          data: {
            xAxis: {
              categories: impressions.label,
            },
            series: [
              {
                type: "line",
                name: __("InstagramOfficial"),
                data: impressions.data,
                color: backgroundColors.InstagramOfficial[0],
                negativeColor: backgroundColors.InstagramOfficial[1],
                borderColor: backgroundColors.InstagramOfficial[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        if (!metric) {
          data.total!.average_impressions = ProcessData.avgSummary(
            response.data,
          );
        }
        break;
      }
      case "engagement": {
        const engagement = ProcessData.genericMetric(response.data);

        data.engagement = {
          data: {
            xAxis: {
              categories: engagement.label,
            },
            series: [
              {
                type: "line",
                name: __("Group Engagement"),
                data: engagement.data,
                negativeColor: backgroundColors.InstagramOfficial[0],
                color: backgroundColors.InstagramOfficial[1],
                borderColor: backgroundColors.InstagramOfficial[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        if (!metric) {
          data.total!.average_engagement = ProcessData.avgSummary(
            response.data,
          );
        }
        break;
      }
      case "reach": {
        const reach = ProcessData.genericMetric(response.data);
        data.reach = {
          data: {
            xAxis: {
              categories: reach.label,
            },
            series: [
              {
                type: "line",
                name: __("Reach"),
                data: reach.data,
                negativeColor: backgroundColors.InstagramOfficial[0],
                color: backgroundColors.InstagramOfficial[1],
                borderColor: backgroundColors.InstagramOfficial[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };
        break;
      }
      case "online_followers": {
        const online_follower_hour = ProcessData.onlineFollowersHours(
          response.data,
        );
        const online_follower = ProcessData.onlineFollowers(response.data);

        data.online_follower = {
          xAxis: {
            categories: online_follower.label,
          },
          series: [
            {
              type: "line",
              name: __("Online followers by day"),
              data: online_follower.data,
              color: backgroundColors.InstagramOfficial[1],
              negativeColor: backgroundColors.InstagramOfficial[1],
              // borderColor: backgroundColors[type][1],
            },
          ],
        };

        data.online_follower_hour = {
          xAxis: {
            categories: online_follower_hour.label,
          },
          series: [
            {
              type: "column",
              name: __("Online followers by hours"),
              data: online_follower_hour.data,
              negativeColor: backgroundColors.InstagramOfficial[1],
              color: backgroundColors.InstagramOfficial[1],
              // borderColor: backgroundColors[type][1],
            },
          ],
        };

        if (!metric) {
          data.bestHour = online_follower_hour.bestHour;
        }
        break;
      }
      case "top_post": {
        data.top_post = response.data;
        break;
      }
      case "top_story": {
        data.top_story = response.data;
        break;
      }
      case "num_posts_by_type": {
        const mediatype = ProcessData.numPostsByType(response.data);

        data.mediatype = {
          xAxis: {
            categories: mediatype.label,
          },
          series: [
            {
              type: "column",
              name: __("Image"),
              data: mediatype.data.image,
              color: backgroundColors.Image[0],
              colorAxis: backgroundColors.Image[1],
              borderColor: backgroundColors.Image[1],
            },
            {
              type: "column",
              name: __("Video"),
              data: mediatype.data.video,
              color: backgroundColors.Video[0],
              colorAxis: backgroundColors.Video[1],
              borderColor: backgroundColors.Video[1],
            },
            {
              type: "column",
              name: __("Album"),
              data: mediatype.data.album,
              color: backgroundColors.Album[0],
              colorAxis: backgroundColors.Album[1],
              borderColor: backgroundColors.Album[1],
            },
            {
              type: "column",
              name: __("Story"),
              data: mediatype.data.story,
              color: backgroundColors.Story[0],
              colorAxis: backgroundColors.Story[1],
              borderColor: backgroundColors.Story[1],
            },
          ],
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
        };
        break;
      }
      case "top_hashtag": {
        data.top_hashtag =
          Object.keys(response.data).length !== 0
            ? {
                xAxis: {
                  crosshair: false,
                },
                series: [
                  {
                    type: "wordcloud",
                    data: ProcessData.hashtag(response.data),
                    name: __("Count"),
                  },
                ],
                plotOptions: {
                  wordcloud: {
                    rotation: {
                      from: 0,
                      to: 0,
                    },
                  },
                },
              }
            : undefined;
        break;
      }
      case "page_follow_total": {
        data.total!.page_follow_total = response.data;
        break;
      }
      /* falls through */
      case "sms_click":
      case "email_click":
      case "phone_click":
      case "total_posts_sent":
      case "average_like":
      case "average_comment":
      case "total_link_clicks":
      case "profile_views": {
        data.total![response.metric] = response.data;
        break;
      }
    }
  });

  data.emptyData = _response.insights.length === 0;

  if (metric === "online_follower_hour")
    return data.online_follower_hour as any;

  return metric ? Object.values(data)[0] : (data as any);
}

export function InstagramOfficialSelectorLifetime(_response: InsightsResponse) {
  const data: Partial<InsightsLifetimeData> = {};

  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }

    switch (response.metric) {
      case "gender": {
        const gender = ProcessData.gender(response.data);
        data.gender = {
          xAxis: {
            categories: [MetricName.Gender.male, MetricName.Gender.female],
          },
          legend: { enabled: true },
          series: [
            {
              type: "pie",
              name: __("Gender"),
              data: [
                { name: __("Male"), y: gender.male },
                { name: __("Female"), y: gender.female },
              ],
              // config: {
              //   colors: [
              //     processColor(backgroundColors.Male),
              //     processColor(backgroundColors.Female),
              //   ],
              //   valueTextSize: 20,
              //   valueTextColor: processColor('green'),
              //   sliceSpace: 5,
              //   selectionShift: 13,
              //   // xValuePosition: "OUTSIDE_SLICE",
              //   // yValuePosition: "OUTSIDE_SLICE",
              //   valueLineColor: processColor('green'),
              //   valueLinePart1Length: 0.5,
              // },
            },
          ],
        };
        break;
      }
      case "country": {
        data.country = {
          series: [
            {
              type: "map",
              data: ProcessData.country(response.data),
              mapData: worldGeo,
              joinBy: ["hc-key", "key"] as any,
              name: __("Count"),
              states: {
                hover: {
                  color: Highcharts.getOptions().colors![2],
                },
              },
            },
          ],
          legend: { enabled: true },
          colorAxis: {
            type: "linear",
            min: 0,
            stops: [
              [0, "#EFEFFF"],
              [
                0.3,
                new Highcharts.Color(Highcharts.getOptions().colors![0])
                  .brighten(-0.2)
                  .get(),
              ],
              [
                0.6,
                new Highcharts.Color(Highcharts.getOptions().colors![0])
                  .brighten(-0.4)
                  .get(),
              ],
              [
                1,
                new Highcharts.Color(Highcharts.getOptions().colors![0])
                  .brighten(-0.5)
                  .get(),
              ],
            ] as any,
          },
        };
        break;
      }
    }
  });

  return data;
}
