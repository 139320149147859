import React, { useEffect } from "react";
import { Account } from "@common/features/Account";
import { useFetchTeamAccounts } from "@common/features/Account";
import {
  useFeatureSupports,
  useFilterMetricPermissions,
  useMasterPermissions,
} from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { isBrowser } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { ElementsGroup } from "@app/elements/container";
import ChartGroup from "@app/pages/insights/ChartGroup";
import { GroupSelector } from "@app/pages/insights/selectors";
import { BoxNumbers } from "../components/BoxNumbers";
import { InsightsChartSection } from "../components/InsightsChartSection";
import { InsightsState } from "../providers/InsightsProvider";
import { useFetchMetric } from "../queries/insights";

interface Props {
  filter: InsightsState;
}

function GroupInsights({
  filter: { customFrom, customTo, printPeriod },
}: Props) {
  const featureSupports = useFeatureSupports();
  const masterPermissions = useMasterPermissions();

  const { data: accounts } = useFetchTeamAccounts((accounts) =>
    accounts.reduce<{ [key: number]: Account }>((prev, item) => {
      if (
        featureSupports.insights.includes(item.type) &&
        item.login_required === 0
      ) {
        prev[item.id] = item;
      }
      return prev;
    }, {}),
  );

  const { data, isLoading } = useFetchMetric(
    {
      period: printPeriod
        ? printPeriod
        : customFrom && customTo
          ? "custom"
          : "month",
      metric: useFilterMetricPermissions([
        "impressions",
        "num_posts_by_type",
        "page_follow_growth",
        "page_follow_total",
        "posts_sent",
      ]),
      customFrom:
        !printPeriod && customFrom && customTo ? customFrom : undefined,
      customTo: !printPeriod && customFrom && customTo ? customTo : undefined,
    },
    accounts !== undefined,
    (data) => GroupSelector(data, accounts!),
  );

  useEffect(() => {
    if (isBrowser) {
      if (printPeriod && !isLoading) {
        (window as any).print();
      }
    }
  }, [printPeriod, isLoading]);

  const rowOne = {
    total_follower: {
      icon: regular("users"),
      title: __("Total audience"),
      hide: !masterPermissions.insights_summary,
    },
    growth: {
      icon: regular("user-plus"),
      title: __("New followers"),
      hide: !masterPermissions.insights_audience_growth,
    },
    impressions: {
      icon: regular("eye"),
      title: __("Total impression"),
      hide: !masterPermissions.insights_impressions,
    },
    sent: {
      icon: regular("file-circle-check"),
      title: __("Total published posts"),
      hide: !masterPermissions.insights_posts_publish,
    },
  };

  const isCustom = customFrom !== undefined && customTo !== undefined;

  return (
    <ElementsGroup>
      <BoxNumbers
        loading={data === undefined}
        items={data?.total || {}}
        options={rowOne}
      />
      <InsightsChartSection
        title={__("Audience Growth")}
        loading={isLoading}
        chartOption={data?.growth}
        selector={(data, metric) => GroupSelector(data, accounts!, metric)}
        metric="page_follow_growth"
        isCustom={isCustom}
        defaultPeriod="month"
      />
      <InsightsChartSection
        title={__("Group Impressions")}
        loading={isLoading}
        chartOption={data?.impressions}
        selector={(data, metric) => GroupSelector(data, accounts!, metric)}
        metric="impressions"
        isCustom={isCustom}
        defaultPeriod="month"
      />
      <ChartGroup>
        <InsightsChartSection
          title={__("Media Type")}
          loading={isLoading}
          chartOption={data?.mediatype}
          selector={(data, metric) => GroupSelector(data, accounts!, metric)}
          metric="num_posts_by_type"
          isCustom={isCustom}
          defaultPeriod="month"
        />
        <InsightsChartSection
          title={__("Post Publish")}
          loading={isLoading}
          chartOption={data?.posts_sent}
          selector={(data, metric) => GroupSelector(data, accounts!, metric)}
          metric="posts_sent"
          isCustom={isCustom}
          defaultPeriod="month"
        />
      </ChartGroup>
    </ElementsGroup>
  );
}

export default React.memo(GroupInsights);
