import { useExtraPostState } from "@common/features/Post";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { CheckBox } from "@app/elements/form";
import { Tooltip } from "@app/elements/helpers";
import { SocialIcon } from "@app/elements/social";
import { Text } from "@app/elements/text";

export default function EitaaSection(props: Partial<SectionToggleProps>) {
  const [data, dispatch] = useExtraPostState("Eitaa");

  return (
    <SectionToggle
      {...props}
      title={__("Eitaa settings")}
      icon={<SocialIcon social="Eitaa" />}
      defaultOpen={
        data.has_signature !== undefined || data.pin_message !== undefined
      }
    >
      <ElementsGroup>
        <CheckBox
          checked={data.pin_message === "1"}
          onInputChange={(checked) =>
            dispatch("pin_message", checked ? "1" : "0")
          }
          label={
            <Tooltip
              tooltip={__(
                "The post is displayed as a pin at the top of the channel",
              )}
            >
              <Text icon={regular("circle-question")} iconPosition="end" block>
                {__("Pin the post on the channel")}
              </Text>
            </Tooltip>
          }
        />
        <CheckBox
          checked={data.has_signature === "1"}
          onInputChange={(checked) =>
            dispatch("has_signature", checked ? "1" : "0")
          }
          label={
            <Tooltip
              tooltip={__(
                "Channel's username will be added to the end of the caption in the posts",
              )}
            >
              <Text icon={regular("circle-question")} iconPosition="end" block>
                {__("Add the channel's username to the end of the caption")}
              </Text>
            </Tooltip>
          }
        />
      </ElementsGroup>
    </SectionToggle>
  );
}
