import moment, { Moment } from "moment-jalaali";
import { Account, AccountGroup } from "@common/features/Account";
import { File } from "@common/features/File";
import { SocialNetwrokType } from "@common/features/Social";
import createStore from "@common/helpers/createStore";
import { timezone, toMoment } from "@common/helpers/i18n";
import {
  AparatPost,
  BalePost,
  EitaaPost,
  GapPost,
  InstagramOfficialPost,
  LinkedinPost,
  Location,
  PinterestPost,
  PostGroupWithDetailFile,
  SearchItem,
  TelegramPost,
  TikTokPost,
  TwitterPost,
  YouTubePost,
} from "../interfaces";
import { checkTypeExist } from "../providers/PostProvider";

export interface TagItem {
  locationX: number;
  locationY: number;
  text: string;
  id: string;
}

export interface PostState {
  postType: "post" | "story";
  saveType: "draft" | "schedule" | "instant";
  hasCustomCaption: boolean;
  captions: {
    html: MultiCaptions;
    text: MultiCaptions;
  };
  selectedFiles: File[];
  scheduleDate: Moment;
  recurring?: {
    type: "daily" | "weekly" | "monthly";
    max: number;
  };
  selectedAccounts: Account[];
  selectedAccountsAndGroups: Array<Account | AccountGroup>;
  mediaTags: { [fileId: number]: TagItem[] };
  thumbnailsSecond: {
    [fileId: number]: number;
  };
  preview?: File;
  coverPreview?: File;
  extra: {
    Telegram: TelegramPost["data"];
    InstagramOfficial: InstagramOfficialPost["data"];
    YouTube: YouTubePost["data"];
    Aparat: AparatPost["data"];
    Pinterest: PinterestPost["data"];
    Twitter: TwitterPost["data"];
    Linkedin: LinkedinPost["data"];
    Bale: BalePost["data"];
    Gap: GapPost["data"];
    Eitaa: EitaaPost["data"];
    TikTok: TikTokPost["data"];
  };
  location?: SearchItem<Location>;
  hashtag: string[];
  autoDeleteAccounts: number[];
  autoDeleteHour?: number;
  poll?: {
    question: string;
    answers: string[];
  };
}

export const recurringTypes = ["daily", "weekly", "monthly"] as Array<
  Exclude<PostState["recurring"], undefined>["type"]
>;
export const recurringCounts = [2, 3, 4, 5, 6, 7];

export type PostAction =
  | { type: "reset"; payload?: never }
  | {
      type: "postTypeChanged";
      payload: { type: PostState["postType"]; storyTypes: SocialNetwrokType[] };
    }
  | {
      type: "initialFromPost";
      payload: { postGroup: PostGroupWithDetailFile; accounts?: Account[] };
    }
  | { type: "schedule"; payload: Moment }
  | { type: "saveType"; payload: PostState["saveType"] }
  | { type: "toggleRecurring"; payload?: never }
  | { type: "setRecurring"; payload?: PostState["recurring"] }
  | { type: "fileAdd"; payload: File }
  | { type: "fileRemove"; payload: File }
  | { type: "fileClear"; payload?: never }
  | { type: "initialCaption"; payload: string }
  | { type: "customCaptionChanged"; payload: boolean }
  | {
      type: "captionChanged";
      payload: { html: MultiCaptions; text?: MultiCaptions };
    }
  | { type: "hashtagChanged"; payload: string[] }
  | { type: "locationChanged"; payload: PostState["location"] }
  | { type: "setPreview"; payload: File }
  | { type: "mediaTagsChanged"; payload: TagItem[] }
  | { type: "pollChanged"; payload: PostState["poll"] }
  | { type: "thumbnailsSecondChanged"; payload: number }
  | {
      type: "extraFieldChanged";
      payload: {
        [ExtraKey in keyof PostState["extra"]]: {
          [FieldKey in keyof PostState["extra"][ExtraKey]]-?: {
            key: ExtraKey;
            field: FieldKey;
            value: PostState["extra"][ExtraKey][FieldKey];
          };
        }[keyof PostState["extra"][ExtraKey]];
      }[keyof PostState["extra"]];
    }
  | {
      type: "selectedAccountsChanged";
      payload: {
        accounts?: Array<Account>;
        selected: Array<Account | AccountGroup>;
      };
    }
  | { type: "moveFiles"; payload: { dragIndex: number; hoverIndex: number } }
  | { type: "setAutoDeleteHour"; payload?: number }
  | { type: "setAutoDeleteAccounts"; payload: number[] };

export type MultiCaptions = { main: string } & {
  [key in SocialNetwrokType]?: string;
};

const initialState: Omit<PostState, "scheduleDate"> = {
  saveType: "instant",
  postType: "post",
  hasCustomCaption: false,
  captions: {
    html: { main: "" },
    text: { main: "" },
  },
  selectedFiles: [],
  selectedAccounts: [],
  selectedAccountsAndGroups: [],
  mediaTags: {},
  thumbnailsSecond: {},
  extra: {
    Telegram: {},
    Aparat: {},
    YouTube: {},
    Pinterest: {},
    Twitter: {},
    InstagramOfficial: {},
    Linkedin: {},
    Bale: {},
    Eitaa: {},
    Gap: {},
    TikTok: {},
  },
  hashtag: [],
  autoDeleteAccounts: [],
};

function setExtraField(
  state: PostState,
  payload: Extract<PostAction, { type: "extraFieldChanged" }>["payload"],
) {
  return {
    ...state,
    extra: {
      ...state.extra,
      [payload.key]: {
        ...state.extra[payload.key],
        [payload.field]: payload.value,
      },
    },
  };
}

const reducer = (
  state: PostState,
  { type, payload }: PostAction,
): PostState => {
  let newState = { ...state };

  switch (type) {
    case "reset":
      newState = {
        ...initialState,
        scheduleDate: moment().utc().add(timezone, "minutes"),
      };
      break;
    case "postTypeChanged": {
      newState.postType = payload.type;

      if (payload.type === "story") {
        newState.selectedAccountsAndGroups =
          newState.selectedAccountsAndGroups.filter(
            (account) =>
              !("type" in account) || payload.storyTypes.includes(account.type),
          );
        newState.selectedAccounts = newState.selectedAccounts.filter(
          (account) => payload.storyTypes.includes(account.type),
        );
      }

      break;
    }
    case "initialFromPost": {
      if (payload.postGroup.post.length === 0) {
        break;
      }

      payload.postGroup.post.forEach((post) => {
        const { photo_tags, post_hashtag, ...data } = post.data;

        newState.extra = {
          ...newState.extra,
          [post.social]: {
            ...(data as any),
          },
        };

        if (post.location) {
          const location = JSON.parse(post.location);
          newState.location = {
            name: location.name,
            value: location,
            description: location.description,
          };
        }

        if (post_hashtag && post_hashtag.length !== 0) {
          newState.hashtag = post_hashtag;
        }
        if (photo_tags) {
          const newMediaTag: PostState["mediaTags"] = {};
          Object.keys(photo_tags).forEach((item) => {
            newMediaTag[+item] = photo_tags[+item].map((item) => {
              return {
                locationX: item[0],
                locationY: item[1],
                text: item[2],
                id: item[3],
              };
            });
          });
          newState.mediaTags = newMediaTag;
        }
      });

      const captions = makeCaption(payload.postGroup);

      newState.saveType =
        payload.postGroup.is_scheduled == 1
          ? "schedule"
          : payload.postGroup.post[0].status === "draft"
            ? "draft"
            : "instant";
      newState.scheduleDate = toMoment(
        payload.postGroup.schedule_date,
        "YYYY/MM/DD HH:mm",
      );

      // TODO: insteaf of storing file model we should store only file id and let react query fetch files
      newState.selectedFiles = payload.postGroup.post[0].file;
      newState.preview = payload.postGroup.post[0].file[0];
      newState.postType = payload.postGroup.type === "story" ? "story" : "post";

      let thumbnailsSecond: PostState["thumbnailsSecond"] | number = {};

      payload.postGroup.post.forEach((post) => {
        if ("thumbnail_second" in post.data && post.data.thumbnail_second) {
          thumbnailsSecond = post.data.thumbnail_second;
        }
      });

      if (typeof thumbnailsSecond !== "object") {
        thumbnailsSecond =
          payload.postGroup.post[0].file.length !== 0
            ? { [newState.preview.id]: thumbnailsSecond }
            : {};
      }

      newState.thumbnailsSecond = thumbnailsSecond;

      if (payload.accounts) {
        newState.captions = {
          html: { ...captions },
          text: { ...captions },
        };
        newState.hasCustomCaption = Object.keys(captions).length > 1;

        const newSelectedAccounts: PostState["selectedAccounts"] =
          payload.postGroup.post
            .map((post) =>
              payload.accounts!.find(
                (account) => account.id == post.account_id,
              ),
            )
            .filter((item): item is Account => item !== undefined);

        newState.selectedAccounts = newSelectedAccounts;
        newState.selectedAccountsAndGroups = [...newSelectedAccounts];
      } else {
        newState.captions = {
          html: { main: captions.main },
          text: { main: captions.main },
        };
      }

      newState.autoDeleteAccounts = payload.postGroup.post
        .filter((post) => post.data.auto_delete === 1)
        .map((post) => post.account_id);
      newState.autoDeleteHour = payload.postGroup.post[0].data.auto_delete;

      if (payload.postGroup.recurring)
        newState.recurring = payload.postGroup.recurring;

      if (payload.postGroup.post[0].data.poll)
        newState.poll = payload.postGroup.post[0].data.poll;

      break;
    }
    case "customCaptionChanged": {
      newState.hasCustomCaption = payload;
      newState.captions = {
        html: { main: state.captions.html.main },
        text: { main: state.captions.text.main },
      };
      break;
    }
    case "initialCaption": {
      newState.captions = {
        html: { main: decodeCaption(payload) },
        text: { main: decodeCaption(payload) },
      };
      break;
    }
    case "captionChanged": {
      newState.captions = {
        html: payload.html,
        text: payload.text || payload.html,
      };
      break;
    }
    case "fileAdd": {
      newState.selectedFiles = state.selectedFiles.concat([payload]);
      newState.preview = payload;

      if (
        payload.fileType === "video" &&
        typeof state.thumbnailsSecond[payload.id] === "undefined"
      ) {
        newState.thumbnailsSecond = {
          ...state.thumbnailsSecond,
          [payload.id]: 1,
        };
      }
      break;
    }
    case "fileRemove": {
      newState.selectedFiles = state.selectedFiles.filter(
        (item) => item.id !== payload.id,
      );
      newState.preview =
        newState.selectedFiles.length > 0
          ? newState.selectedFiles[newState.selectedAccounts.length - 1]
          : undefined;
      break;
    }
    case "fileClear": {
      newState.selectedFiles = [];
      break;
    }
    case "hashtagChanged": {
      newState.hashtag = payload;
      break;
    }
    case "locationChanged": {
      newState.location = payload;
      break;
    }
    case "mediaTagsChanged": {
      newState.mediaTags = { ...state.mediaTags, [state.preview!.id]: payload };
      break;
    }
    case "thumbnailsSecondChanged": {
      newState.thumbnailsSecond = {
        ...state.thumbnailsSecond,
        [state.preview!.id]: payload,
      };
      break;
    }
    case "saveType": {
      newState.saveType = payload;
      break;
    }
    case "schedule": {
      newState.saveType = "schedule";
      newState.scheduleDate = payload;
      break;
    }
    case "toggleRecurring": {
      newState.recurring =
        newState.recurring === undefined
          ? { max: 2, type: "daily" }
          : undefined;
      break;
    }
    case "setRecurring": {
      newState.recurring = payload;
      break;
    }
    case "setPreview": {
      newState.preview = payload;
      break;
    }
    case "selectedAccountsChanged": {
      if (!payload.accounts) {
        break;
      }
      const selectedAccounts: PostState["selectedAccounts"] = [];

      (payload.selected || []).forEach((item) => {
        if ("type" in item) {
          selectedAccounts.push(item);
        } else {
          item.account_ids.forEach((id) => {
            const account = payload.accounts!.find(
              (account) => account.id === id,
            );
            if (account) {
              selectedAccounts.push(account);
            }
          });
        }
      });

      newState.selectedAccountsAndGroups = payload.selected || [];
      newState.selectedAccounts = selectedAccounts;
      newState.postType =
        selectedAccounts.length === 0 ? "post" : state.postType;
      break;
    }
    case "moveFiles": {
      const selectedFiles = Array.from(state.selectedFiles);
      const dragCard = selectedFiles[payload.dragIndex];
      selectedFiles.splice(payload.dragIndex, 1);
      selectedFiles.splice(payload.hoverIndex, 0, dragCard);

      newState.selectedFiles = selectedFiles;
      break;
    }
    case "extraFieldChanged": {
      newState = setExtraField(state, payload);
      break;
    }
    case "pollChanged": {
      newState.poll = payload;
      break;
    }
    case "setAutoDeleteHour": {
      newState.autoDeleteHour = payload;
      break;
    }
    case "setAutoDeleteAccounts": {
      newState.autoDeleteAccounts = payload;
      break;
    }
    default:
      throw new Error("Invalid action");
  }

  if (["selectedAccountsChanged", "fileAdd"].includes(type)) {
    if (
      newState.selectedFiles.length === 1 &&
      newState.selectedFiles[0].fileType === "video" &&
      checkTypeExist(newState.selectedAccounts, "InstagramOfficial")
    ) {
      newState = setExtraField(newState, {
        key: "InstagramOfficial",
        field: "reels_sharetofeed",
        value: 1,
      });
    }
  }

  return newState;
};

// export const usePostReducer = () =>
//   useReducer(reducer, {
//     ...initialState,
//     scheduleDate: moment().utc().add(timezone, "minutes"),
//   });

export const { Provider: PostStoreProvider, useStore: usePostStore } =
  createStore(reducer, {
    ...initialState,
    scheduleDate: moment().utc().add(timezone, "minutes"),
  });

function makeCaption(postGroup: PostGroupWithDetailFile): MultiCaptions {
  const captions: MultiCaptions = {
    main: decodeCaption(postGroup.caption),
  };
  postGroup.post.forEach((post) => {
    if (postGroup.caption !== post.caption) {
      captions[post.social] = decodeCaption(post.caption);
    }
  });
  return captions;
}

function decodeCaption(caption: string) {
  let result = "";

  caption.split(/\r\n|\r|\n/).forEach((line) => {
    if (line.trim()) {
      result += `<p>${line}</p>`;
    } else if (line.trim() == "") {
      result += "<p> </p>";
    }
  });

  return result;
}
