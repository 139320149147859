import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { isBefore } from "date-fns";
import { api } from "@common/helpers/api";
import { AccountPost } from "../interfaces";

export const accountPostsKeys = createQueryKeys("accountPosts", {
  accountPost: (id?: number) => [{ id }],
});

export const useFetchAccountPost = (accountPost: AccountPost | undefined) =>
  useQuery({
    ...accountPostsKeys.accountPost(accountPost?.id),
    queryFn: async () => {
      if (
        !accountPost ||
        (accountPost.social !== "InstagramOfficial" &&
          accountPost.social !== "Instagram") ||
        accountPost.type === "story"
      ) {
        return accountPost;
      }

      if (
        isBefore(
          new Date(accountPost.updated_at),
          new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
        )
      ) {
        return accountPost;
      }

      return (
        await api.post<AccountPost>(`account-post/${accountPost.id}`, {
          meta: {
            hideLoading: true,
          },
        } as any)
      ).data;
    },
    enabled: accountPost !== undefined,
    staleTime: 300 * 1000,
  });
