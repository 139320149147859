import { Hashtag } from "@common/features/Post";
import { __ } from "@common/helpers/i18n";
import { Text } from "@app/elements/text";

export default function HashtagSuggestion({
  suggested,
  onClick,
}: {
  suggested: Hashtag[];
  onClick: (hashtag: Hashtag) => void;
}) {
  return (
    <ul className="mt-2 flex flex-wrap gap-1.5">
      <li>
        <Text variant="muted">{__("Suggested hashtag:") + " "}</Text>
      </li>
      {suggested.map((item) => (
        <li key={item.id} onClick={() => onClick(item)}>
          <a>
            #<span>{item.name}</span>
          </a>{" "}
        </li>
      ))}
    </ul>
  );
}
