import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Moment } from "moment-jalaali";
import { PeriodType, ProcessData } from "@common/features/Insights";
import { api } from "@common/helpers/api";
import { ucfirst } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { insertDataOnArray, updateDataOnArray } from "@common/helpers/queries";
import { Team, TeamStatisticsResponse } from "../interfaces";

export const teamsKeys = createQueryKeys("teams", {
  list: null,
  team: (id?: number) => [{ id }],
  statistics: (id: number, filter: any) => [{ id, filter }],
});

export const useFetchTeams = () =>
  useQuery({
    ...teamsKeys.list,
    queryFn: async () => {
      const response = await api.get<Team[]>("team");
      return response.data;
    },
  });

export const useFetchTeam = (id: number | undefined) =>
  useQuery({
    ...teamsKeys.team(id),
    queryFn: async () => {
      const response = await api.get<Team>(`team/${id}`);
      return response.data;
    },
    enabled: id !== undefined,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => api.delete(`team/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: teamsKeys._def });
    },
  });
};

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (team: {
      accounts: number[];
      permissions: {
        [key in keyof Team["permissions"]]?: boolean;
      };
      email: string;
    }) => api.post<Team>("team", team).then((response) => response.data),
    onSuccess: (newTeam) => {
      queryClient.setQueryData<Team[]>(teamsKeys.list.queryKey, (previous) =>
        insertDataOnArray(previous, newTeam),
      );

      queryClient.invalidateQueries({
        queryKey: teamsKeys.list.queryKey,
        refetchType: "none",
      });
    },
  });
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      ...team
    }: {
      id: number;
      accounts: number[];
      permissions: {
        [key in keyof Team["permissions"]]?: boolean;
      };
    }) => api.put<Team>(`team/${id}`, team).then((response) => response.data),
    onSuccess: (newTeam) => {
      queryClient.setQueryData<Team[]>(teamsKeys.list.queryKey, (previous) =>
        updateDataOnArray(previous, newTeam),
      );

      queryClient.invalidateQueries({
        queryKey: teamsKeys._def,
        refetchType: "none",
      });
    },
  });
};

export const useFetchTeamStatistics = <TData = TeamStatisticsResponse>(
  id: number,
  filter: {
    period?: PeriodType;
    metric?: string[];
    customFrom?: Moment;
    customTo?: Moment;
  },
  enabled: boolean,
  select: (data: TeamStatisticsResponse) => TData,
) =>
  useQuery({
    ...teamsKeys.statistics(id, filter),
    queryFn: async () => {
      const response = await api.get<TeamStatisticsResponse>(
        "team/" + id + "/statistics",
        {
          params: {
            period: filter.period,
            metric: filter.metric,
            custom_from: filter.customFrom?.format("YYYY/MM/DD"),
            custom_to: filter.customTo?.format("YYYY/MM/DD"),
          },
        },
      );
      return response.data;
    },
    select,
    enabled,
  });

interface InsightsData {
  post: Highcharts.Options;
  direct: Highcharts.Options;
  comment: Highcharts.Options;

  total: {
    average_post?: number;
    average_comment?: number;
    average_direct?: number;

    total_response_post?: number;
    total_response_comment?: number;
    total_response_direct?: number;

    published_post?: number;
    scheduled_post?: number;
    pending_post?: number;

    total_comment?: number;
    total_direct?: number;
    total_conversation?: number;
  };

  emptyData: boolean;
}

export function TeamStatisticsSelector<
  TMetric extends string | undefined = undefined,
>(
  _response: TeamStatisticsResponse,
  metric?: TMetric,
): TMetric extends string ? Highcharts.Options : InsightsData {
  const data: Partial<InsightsData> = {};

  if (typeof metric === "undefined") {
    data.total = {};
  }
  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }

    switch (response.metric) {
      case "post":
      case "comment":
      case "direct": {
        const post = ProcessData.genericMetric(response.data);

        data[response.metric] = {
          xAxis: {
            categories: post.label,
          },
          series: [
            {
              data: post.data,
              type: "column",
              name: __(ucfirst(response.metric) as any),
            },
          ],
        };

        if (typeof metric === "undefined") {
          data.total![`average_${response.metric}`] = ProcessData.avgSummary(
            post.data,
          );
        }
        if (typeof metric === "undefined") {
          data.total![`total_response_${response.metric}`] =
            ProcessData.sumSummary(post.data);
        }
        break;
      }
      case "published_post":
      case "scheduled_post":
      case "pending_post":
      case "total_comment":
      case "total_direct":
      case "total_conversation": {
        data.total![response.metric] = response.data;
        break;
      }
    }
  });

  data.emptyData = _response.insights.length === 0;

  return metric ? Object.values(data)[0] : (data as any);
}
