import { useMemo } from "react";
import { __ } from "@common/helpers/i18n";
import Logo from "@app/assets/Logo";
import add from "@app/assets/share-ios/add.png";
import homescreen from "@app/assets/share-ios/homescreen.png";
import share from "@app/assets/share-ios/share.png";
import { Button } from "@app/elements/form";
import { useLocalStorage } from "@app/hooks";

export default function PwaPopUp() {
  const [skip, setSkip] = useLocalStorage("ios-skip", 0);

  const show = useMemo(() => {
    const date = new Date();
    if (skip > date.getTime()) {
      return false;
    }

    if (!navigator || !(navigator as any).standalone) {
      const ua = window.navigator.userAgent;
      const iOS = !!/iPad/i.exec(ua) || !!/iPhone/i.exec(ua);
      const webkit = !!/WebKit/i.exec(ua);
      const iOSSafari = iOS && webkit && !/CriOS/i.exec(ua);

      return iOSSafari;
    }

    return false;
  }, [skip]);

  if (!show) return null;

  return (
    <div className="absolute inset-0 z-50 flex flex-col justify-center bg-white p-6 dark:bg-dark-background">
      <Logo className="mx-auto my-8 h-12 w-12 rounded-md bg-primary-500 p-2 text-white" />
      <h1 className="text-center text-2xl font-bold">
        {__("Add the %{site} web application to your mobile home screen.", {
          site: __(import.meta.env.VITE_SITE_NAME),
        })}
      </h1>
      <div className="mb-4 mt-auto space-y-4">
        <div className="flex space-s-2">
          <img src={share} className="h-6 w-6" />
          <span>{__("1. In the bottom bar, click the Share button.")}</span>
        </div>
        <div className="flex space-s-2">
          <img src={homescreen} className="h-6 w-6" />
          <span>
            {__(
              "2. In the menu that opens at the bottom, select the Add to home screen option.",
            )}
          </span>
        </div>
        <div className="flex space-s-2">
          <img src={add} className="h-6 w-6" />
          <span>{__("3. Next, tap the Add option at the top.")}</span>
        </div>
      </div>
      <Button
        className="w-full"
        center
        variant="primary"
        onClick={() => {
          setSkip(new Date().getTime() + 7 * 3600 * 24 * 1000);
        }}
      >
        {__("Skip")}
      </Button>
    </div>
  );
}
