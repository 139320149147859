import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { Moment } from "moment-jalaali";
import { api } from "@common/helpers/api";
import { IntlDateFormat, formatDate, isRTL } from "@common/helpers/i18n";
import { CalendarResponse, MonthConfig, Occasion } from "../interfaces";

const PostGroupListFieldExpansion = "post(file,account,accountPost)";

type CalendarFilter = {
  year?: number;
  month?: number;
};

export const calendarKeys = createQueryKeys("postGroups", {
  monthConfig: (filter?: CalendarFilter) => [{ filter }],
});

export const useFetchCalendar = (filter?: CalendarFilter) => {
  const queryClient = useQueryClient();

  return useQuery({
    ...calendarKeys.monthConfig(filter),
    queryFn: async () => {
      const response = await api.get<CalendarResponse>("calendar", {
        params: {
          ...filter,
          with: PostGroupListFieldExpansion,
        },
      });
      if (filter === undefined) {
        queryClient.setQueryData(
          calendarKeys.monthConfig({
            year: response.data.year,
            month: response.data.month,
          }).queryKey,
          response.data,
        );
      }
      return response.data;
    },
    placeholderData: keepPreviousData,
    select: selectMonthConfig,
  });
};

export const useDeleteOccasion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => api.delete(`occasion/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: calendarKeys.monthConfig._def,
      });
    },
  });
};

export const useCreateOccasion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ text, date }: { text: string; date: Moment }) =>
      api
        .post("occasion", { text, date: date?.format("YYYY/MM/DD") })
        .then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: calendarKeys.monthConfig._def,
      });
    },
  });
};

const selectMonthConfig = ({
  occasions,
  from: fromString,
  to: toString,
  posts: apiPosts,
  ...rest
}: CalendarResponse): MonthConfig => {
  const days: MonthConfig["days"] = [];

  const from = parse(fromString, "yyyy-MM-dd", new Date());
  const to = parse(toString, "yyyy-MM-dd", new Date());
  from.setHours(0);
  from.setMinutes(0);
  to.setHours(0);
  to.setMinutes(0);
  const occasionsByDay = occasions.reduce<{
    [day: string]: Occasion[];
  }>((acc, curr) => {
    acc[curr.date] = [...(acc[curr.date] || []), curr];
    return acc;
  }, {});

  const numberOfDaysBeforeFirstDay = (from.getDay() + (isRTL ? 1 : -1)) % 7;

  const numberOfDaysAfterLastDay = 6 - ((to.getDay() + (isRTL ? 1 : -1)) % 7);

  const d = new Date(from.getTime());
  d.setDate(d.getDate() - numberOfDaysBeforeFirstDay);

  const lastCalendarDate = new Date(to.getTime());
  lastCalendarDate.setDate(
    lastCalendarDate.getDate() + numberOfDaysAfterLastDay,
  );

  const today = new Date();

  const posts: MonthConfig["posts"] = [
    ...[...Array(rest.number_of_days).keys()].map(() => ({})),
  ];

  for (
    ;
    d <= lastCalendarDate;
    d.setDate(d.getDate() + 1), d.setHours(0), d.setMinutes(0)
  ) {
    const date = format(d, "yyyy-MM-dd");
    const day = IntlDateFormat(d, { day: "numeric" });
    days.push({
      date,
      day: +day,
      occasions: occasionsByDay[date] || [],
      isCurrentMonth: d >= from && d <= to,
      isToday: d.toDateString() === today.toDateString(),
    });
  }

  apiPosts.forEach((post) => {
    const [day, hour] = formatDate(
      post.schedule_date,
      isRTL ? "jD HH" : "D HH",
    ).split(" ");
    if (posts[+day - 1][+hour] === undefined) {
      posts[+day - 1][+hour] = [];
    }
    posts[+day - 1][+hour].push(post);
  });

  return {
    days,
    from,
    to,
    posts,
    ...rest,
  };
};
