import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Chart } from "highcharts/highmaps";
import wordcloud from "highcharts/modules/wordcloud";
import { useEffect, useRef } from "react";
import { regular } from "@common/helpers/fontawesome";
import { __, isRTL } from "@common/helpers/i18n";
import { ChartSectionProps } from "@common/types";
import { Section } from "../container";
import { Button } from "../form";
import { DropDownMenu, Tooltip } from "../helpers";
import { Loading } from "../loading";
import { Permission } from "../permission";

wordcloud(Highcharts);

Highcharts.setOptions({
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
  symbols: ["circle"],
} as any);

const defaultChartOption: Highcharts.Options = {
  title: {
    text: "",
  },
  chart: {
    backgroundColor: undefined,
    type: "spline",
    style: {
      fontFamily: isRTL
        ? "IRANSans, Open Sans, sans-serif"
        : "Roboto, sans-serif",
    },
    styledMode: true,
  },
  legend: { enabled: false },
  xAxis: { crosshair: true },
  yAxis: {
    title: {
      text: "",
    },
  },
  tooltip: {
    xDateFormat: "%Y/%m/%d",
  },
  plotOptions: {
    spline: {
      marker: {
        radius: 4,
        lineColor: "#666666",
        lineWidth: 1,
      },
    },
  },
};

Highcharts.addEvent(Highcharts.Axis, "afterInit", function () {
  const logarithmic = (this as any).logarithmic;

  if (logarithmic && (this.options as any).custom?.allowNegativeLog) {
    // Avoid errors on negative numbers on a log axis
    (this as any).positiveValuesOnly = false;

    // Override the converter functions
    logarithmic.log2lin = (num: number) => {
      const isNegative = num < 0;

      let adjustedNum = Math.abs(num);

      if (adjustedNum < 10) {
        adjustedNum += (10 - adjustedNum) / 10;
      }

      const result = Math.log(adjustedNum) / Math.LN10;
      return isNegative ? -result : result;
    };

    logarithmic.lin2log = (num: number) => {
      const isNegative = num < 0;

      let result = Math.pow(10, Math.abs(num));
      if (result < 10) {
        result = (10 * (result - 1)) / (10 - 1);
      }
      return isNegative ? -result : result;
    };
  }
});

export function ChartSection({
  chartOption,
  loading,
  title,
  period,
  emptyMessage,
  footer,
  hint,
  isCustom,
  isEmpty,
  onPeriodChange,
  hasPermission,
}: ChartSectionProps<Highcharts.Options>) {
  const chart = useRef<Chart | null>(null);

  useEffect(() => {
    let resetParams: any = {};

    function beforePrint() {
      resetParams = [
        chart.current!.chartWidth,
        chart.current!.chartHeight,
        false,
      ];
      chart.current!.setSize(900, undefined, false);
      chart.current!.reflow();
    }

    function afterPrint() {
      chart.current!.setSize(...resetParams);
    }

    window.addEventListener("beforeprint", beforePrint);
    window.addEventListener("afterprint", afterPrint);

    return () => {
      window.removeEventListener("beforeprint", beforePrint);
      window.removeEventListener("afterprint", afterPrint);
    };
  }, [chart]);

  const options = {
    ...defaultChartOption,
    ...chartOption,
  };
  return (
    <Section
      title={
        <>
          <span>{title}</span>
          {hint && (
            <Tooltip tooltip={hint}>
              <FontAwesomeIcon
                icon={regular("circle-question")}
                style={{
                  transform: isRTL ? "scaleX(-1)" : undefined,
                }}
              />
            </Tooltip>
          )}
        </>
      }
      action={
        onPeriodChange && (
          <DropDownMenu
            position="bottom-end"
            button={
              period &&
              period !== "lifetime" && (
                <Button variant="outline-light">
                  {period === "custom" || isCustom
                    ? __("Custom")
                    : __(`period.${period}`)}
                  <div className="ms-3 flex items-center">
                    <FontAwesomeIcon icon={regular("chevron-down")} />
                  </div>
                </Button>
              )
            }
            menu={(["week", "month", "three_months"] as const).map((menu) => ({
              text: __(`period.${menu}`),
              onClick: () => {
                onPeriodChange(menu);
              },
            }))}
            size="large"
          />
        )
      }
    >
      {hasPermission !== undefined && <Permission enable={!hasPermission} />}
      {(hasPermission === undefined || hasPermission) && (
        <Loading enabled={loading} />
      )}
      {hasPermission && !loading && isEmpty ? (
        <div className="absolute left-0 right-0 top-1/2 flex -translate-y-1/2 flex-col items-center">
          {typeof emptyMessage !== "string" ? (
            emptyMessage
          ) : (
            <>
              <FontAwesomeIcon
                icon={regular("chart-pie")}
                className="h-20 w-20 text-gray-400"
              />
              <span className="text">
                {emptyMessage || __("No data to display")}
              </span>
            </>
          )}
        </div>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          callback={(ref: any) => (chart.current = ref)}
        />
      )}

      {footer && (
        <div
          className="chart-footer"
          style={{
            borderTop: "1px solid #e6e7ef",
            marginTop: "1em",
            paddingTop: "1em",
            textAlign: "center",
            fontSize: "1em",
          }}
        >
          {typeof footer === "string" ? (
            <div dangerouslySetInnerHTML={{ __html: footer }} />
          ) : (
            footer
          )}
        </div>
      )}
    </Section>
  );
}
