import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { InputProps } from "@common/types";
import { Text } from "../text";
import { Label } from "./Label";

const variants = {
  success:
    "bg-green-50 border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-green-500",
  danger:
    "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500",
  disabled:
    "bg-gray-100 border-gray-300 text-gray-900 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400",
  default:
    "bg-gray-50 border-gray-300 text-gray-900 focus:ring-primary-500 focus:border-primary-500 dark:bg-dark-background dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-100 dark:focus:ring-primary-500 dark:focus:border-primary-500",
};

export function getInputClassName(variant: keyof typeof variants = "default") {
  return "border rounded-md transition " + variants[variant];
}

export function Input({
  label,
  error,
  children,
  hint,
  icon,
  iconPosition = "start",
  success,
  className,
}: InputProps & { className?: string }) {
  const errorMessage = error
    ? typeof error === "string"
      ? error
      : typeof error === "object"
        ? error.message
        : false
    : false;

  return (
    <Label
      label={label}
      variant={error ? "danger" : success ? "success" : "default"}
    >
      <div
        className={classNames(icon && "relative flex items-center", className)}
      >
        {children}
        {icon &&
          icon !== true &&
          (iconPosition !== "none" ? (
            <div
              className={
                "flex " +
                (icon &&
                  (iconPosition === "end"
                    ? "absolute end-0"
                    : "absolute start-0"))
              }
            >
              {Array.isArray(icon) ? (
                <FontAwesomeIcon icon={icon} className="px-4" />
              ) : (
                icon
              )}
            </div>
          ) : Array.isArray(icon) ? (
            <FontAwesomeIcon icon={icon} className="px-4" />
          ) : (
            icon
          ))}
      </div>
      {hint && (
        <Text variant="muted" block className="mt-1">
          {hint}
        </Text>
      )}
      {errorMessage ? (
        <Text variant="danger" block className="mt-1 text-xs">
          {errorMessage}
        </Text>
      ) : success ? (
        <Text variant="success" block className="mt-1 text-xs">
          {success}
        </Text>
      ) : null}
    </Label>
  );
}
