import axios from "axios";
import notify from "./NotificationManager";
import { isBrowser } from "./functions";

declare const localStorage: any;

export let apiUrl = "";
export let appUrl = "";

export function setApiUrl(url: string) {
  apiUrl = url;
  api.defaults.baseURL = url;
}

export function setAppUrl(url: string) {
  appUrl = url;
}

export const api = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
});

api.defaults.withCredentials = true;

if (import.meta.env.DEV && typeof localStorage !== "undefined") {
  api.defaults.headers["X-Live-Mode"] =
    localStorage.getItem("live-mode") === "true" ? "1" : false;
}

export function shouldRetryOnError(failureCount: number, error: any) {
  if (failureCount >= 2) {
    return false;
  }

  if (axios.isAxiosError(error)) {
    const status = error.response?.status || 0;
    return status < 400 || status >= 500;
  }

  return true;
}

export function getApiError(payload: any) {
  return axios.isAxiosError(payload) &&
    !!payload.response?.data &&
    "error" in payload.response.data &&
    "message" in payload.response.data.error
    ? payload.response.data.error.message
    : typeof payload === "object" && payload.name === "ValidationError"
      ? payload.message
      : undefined;
}

export function handleApiError(error: any, hideError: boolean) {
  const message = getApiError(error);
  if (message) {
    if (!hideError) notify.error(message);
    if (isBrowser)
      console.error(
        "Error message: " +
          message +
          (error.response?.data.error.trace_id
            ? " \ntrace id:" + error.response?.data.error.trace_id
            : ""),
      );
  }
}
