import classNames from "classnames";
import { ElementsGroupProps } from "@common/types";

export function ElementsGroup({
  children,
  display = "flex-col",
  center,
  space = 4,
  gridCol,
  padding,
}: ElementsGroupProps) {
  return (
    <div
      className={classNames(
        space && space === "between" ? "justify-between" : `space-y-${space}`,
        space && display === "grid" && "gap-x-4",
        display === "flex-row" && "flex",
        display === "flex-col" && "flex flex-col",
        center && "items-center",
        display === "grid" && "grid",
        gridCol && `grid-cols-${gridCol}`,
        padding === "large"
          ? "p-3"
          : padding === "medium"
            ? "px-3 py-2"
            : padding === "small" && "px-3 py-1.5",
      )}
    >
      {children}
    </div>
  );
}
