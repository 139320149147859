import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { PostDatePickerProps } from "@common/types";
import { DatePicker, Input, TimePicker } from "@app/elements/form";

export default function PostDatePicker({
  date,
  onChange,
}: PostDatePickerProps) {
  return (
    <div className="mx-auto flex">
      <div className="-me-px">
        <TimePicker
          value={date}
          onChange={onChange}
          placeholder={__("Schedule date")}
          className="w-full rounded-e-none pe-10 text-center"
          icon={
            <FontAwesomeIcon
              icon={regular("clock")}
              className="absolute end-0 z-10 px-4"
            />
          }
          iconPosition="none"
        />
      </div>
      <Input icon={regular("calendar")} iconPosition="end">
        <DatePicker
          timePicker={false}
          value={date}
          onChange={onChange}
          inputFormat={"YYYY/MM/DD"}
          inputJalaaliFormat={"jYYYY/jMM/jDD"}
          placeholder={__("Schedule date")}
          tetherProps={{
            attachment: "top center",
            constraints: [
              {
                to: "window",
                attachment: "together",
                pin: true,
              },
            ],
            offset: "-10px -10px",
          }}
          className="w-full rounded-s-none pe-10 text-center"
        />
      </Input>
    </div>
  );
}
