import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import { Notification } from "../interfaces";

export const notificationsKeys = createQueryKeys("notifications");

export const useFetchNotifications = () =>
  useQuery({
    queryKey: notificationsKeys._def,
    queryFn: async () => {
      const response = await api.get<Notification[]>("me/notification", {
        meta: {
          hideLoading: true,
        },
      } as any);
      return response.data;
    },
    refetchInterval: 30000,
    meta: {
      hideError: true,
    },
  });

export const useSeenNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () =>
      api.put("me/notification", undefined, {
        meta: {
          hideLoading: true,
        },
      } as any),
    meta: {
      hideError: true,
    },
    onSuccess: () => {
      queryClient.setQueriesData<Notification[]>(
        { queryKey: notificationsKeys._def },
        (previous) =>
          previous?.map((notification) => ({ ...notification, seen: 1 })),
      );

      queryClient.invalidateQueries({
        queryKey: notificationsKeys._def,
        refetchType: "none",
      });
    },
  });
};
