export function LoadingDots() {
  return (
    <div className="ms-2 flex self-center text-center align-middle leading-none transition-colors duration-150 space-s-1">
      <span
        className="inline-block h-1 w-1 rounded-full bg-primary-500 align-top"
        style={{ animation: "dot-animation 1s ease-in-out 0ms infinite" }}
      />
      <span
        className="inline-block h-1 w-1 rounded-full bg-primary-500 align-top"
        style={{ animation: "dot-animation 1s ease-in-out 160ms infinite" }}
      />
      <span
        className="inline-block h-1 w-1 rounded-full bg-primary-500 align-top"
        style={{ animation: "dot-animation 1s ease-in-out 320ms infinite" }}
      />
    </div>
  );
}
