import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import {
  GroupInsightsResponse,
  InsightsFilter,
  InsightsResponse,
} from "../interfaces";

export const insightsKeys = createQueryKeys("insights", {
  metric: (filter: InsightsFilter<any>) => [
    { filter, accountId: filter.accountId || "group" },
  ],
});

export const useFetchMetric = <
  TExtra = never,
  TAccount extends number | undefined = undefined,
  TData = TAccount extends undefined
    ? GroupInsightsResponse
    : InsightsResponse<TExtra>,
>(
  filter: InsightsFilter<TAccount>,
  enabled?: boolean,
  select?: (
    data: TAccount extends undefined
      ? GroupInsightsResponse
      : InsightsResponse<TExtra>,
  ) => TData,
) =>
  useQuery({
    ...insightsKeys.metric(filter),
    queryFn: async () => {
      const response = await api.get<
        TAccount extends undefined
          ? GroupInsightsResponse
          : InsightsResponse<TExtra>
      >("insights/metric" + (filter.accountId ? `/${filter.accountId}` : ""), {
        params: {
          period: filter.period,
          metric: filter.metric,
          custom_from: filter.customFrom?.format("YYYY/MM/DD"),
          custom_to: filter.customTo?.format("YYYY/MM/DD"),
        },
      });
      return response.data;
    },
    select,
    enabled,
  });

export const useExportInsights = () =>
  useMutation({
    mutationFn: (filter: InsightsFilter & { email: string }) =>
      api.post("insights/export", {
        period: filter.period,
        metric: filter.metric,
        custom_from: filter.customFrom?.format("YYYY/MM/DD"),
        custom_to: filter.customTo?.format("YYYY/MM/DD"),
        email: filter.email,
      }),
  });
