import noDocument from "@common/assets/no-file-square.png";
import noImage from "@common/assets/no-image-square.jpg";
import { __ } from "@common/helpers/i18n";

export const buttonTypeList = [
  {
    value: "message",
    label: __("Message"),
  },
  { value: "link", label: __("Link") },
];

export const sampleData = {
  products: [
    {
      id: 1,
      title: __("Product title"),
      subtitle: __("Product subtitle"),
      file: {
        thumbnailUrl: noImage,
      },
      url: import.meta.env.VITE_APP_URL,
      created_at: new Date().toISOString(),
      buttons: [
        {
          title: __("Button %{number}", { number: 1 }),
          type: "link",
          url: import.meta.env.VITE_APP_URL,
        },
        {
          title: __("Button %{number}", { number: 2 }),
          type: "link",
          url: import.meta.env.VITE_APP_URL,
        },
        {
          title: __("Button %{number}", { number: 3 }),
          type: "link",
          url: import.meta.env.VITE_APP_URL,
        },
      ],
    },
    {
      id: 2,
      title: __("Product title"),
      subtitle: __("Product subtitle"),
      file: {
        thumbnailUrl: noImage,
      },
      url: import.meta.env.VITE_APP_URL,
      created_at: new Date().toISOString(),
      buttons: [
        {
          title: __("Button %{number}", { number: 1 }),
          type: "link",
          url: import.meta.env.VITE_APP_URL,
        },
        {
          title: __("Button %{number}", { number: 2 }),
          type: "link",
          url: import.meta.env.VITE_APP_URL,
        },
        {
          title: __("Button %{number}", { number: 3 }),
          type: "link",
          url: import.meta.env.VITE_APP_URL,
        },
      ],
    },
  ],
  menu: [
    {
      title: __("Button"),
      type: buttonTypeList[0],
      message_ids: [],
      url: import.meta.env.VITE_APP_URL,
    },
    {
      title: __("Website Url"),
      type: buttonTypeList[1],
      message_ids: [],
      url: import.meta.env.VITE_APP_URL,
    },
  ],
  faq: [
    {
      title: __("FAQ %{number}", { number: 1 }),
      message_ids: [],
    },
    {
      title: __("FAQ %{number}", { number: 2 }),
      message_ids: [],
    },
  ],
  text: "Text",
  quick_replies: [
    { title: __("Reply %{number}", { number: 1 }), message_id: 1 },
    { title: __("Reply %{number}", { number: 2 }), message_id: 1 },
    { title: __("Reply %{number}", { number: 3 }), message_id: 1 },
  ],
  image: noImage,
  document: noDocument,
  buttons: [
    {
      title: __("Button %{number}", { number: 1 }),
      type: "link",
      url: import.meta.env.VITE_APP_URL,
    },
    {
      title: __("Button %{number}", { number: 2 }),
      type: "link",
      url: import.meta.env.VITE_APP_URL,
    },
    {
      title: __("Button %{number}", { number: 3 }),
      type: "link",
      url: import.meta.env.VITE_APP_URL,
    },
  ],
  form: [
    { text: __("Name"), quick_replies: [] },
    { text: __("Mobile"), quick_replies: [] },
  ],
} as const;
