import classNames from "classnames";
import React, { InputHTMLAttributes } from "react";
import { RadioProps } from "@common/types";
import { Text } from "../text";
import { variants } from "./CheckBox";

export const Radio = React.forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> &
    RadioProps & {
      inputClassName?: string;
    }
>(function Radio(
  { label, className, block, hint, error, inputClassName, success, ...props },
  ref,
) {
  const errorMessage = error
    ? typeof error === "string"
      ? error
      : typeof error === "object"
        ? error.message
        : false
    : false;

  const variant = error
    ? "danger"
    : success
      ? "success"
      : props.disabled
        ? "disabled"
        : "default";

  return (
    <div className={block ? "flex" : "inline-flex"}>
      <label
        className={classNames(
          block ? "flex" : "inline-flex",
          "items-center",
          className,
        )}
      >
        <input
          type="radio"
          className={classNames(
            "rounded border-none dark:ring-offset-gray-800",
            variants[variant],
            inputClassName,
          )}
          ref={ref}
          {...props}
        />
        {label && (
          <Text variant={variant} className="me-3 ms-2">
            {label}
          </Text>
        )}
      </label>
      {hint && <Text variant="muted">{hint}</Text>}
      {errorMessage ? (
        <Text variant="danger" className="mt-2 text-xs">
          {errorMessage}
        </Text>
      ) : success ? (
        <Text variant="success" className="mt-2 text-xs">
          {success}
        </Text>
      ) : null}
    </div>
  );
});
