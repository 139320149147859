import { useExtraPostState } from "@common/features/Post";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { TextInput } from "@app/elements/form";
import { SocialIcon } from "@app/elements/social";

export default function FirstCommentSection(
  props: Partial<SectionToggleProps>,
) {
  const [data, dispatch] = useExtraPostState("InstagramOfficial");

  return (
    <SectionToggle
      {...props}
      title={__("First comment")}
      hint={__("First comment is available for: Instagram")}
      icon={<SocialIcon social="InstagramOfficial" />}
      defaultOpen={!!data.first_comment}
    >
      <ElementsGroup>
        <TextInput
          label={__("First comment")}
          placeholder={__("First comment")}
          value={data.first_comment || ""}
          onInputChange={(first_comment) =>
            dispatch("first_comment", first_comment)
          }
          name="first_comment"
        />
      </ElementsGroup>
    </SectionToggle>
  );
}
