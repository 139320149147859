import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, {
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
} from "react";
import { regular } from "@common/helpers/fontawesome";
import { TextInputProps } from "@common/types";
import { EmojiPicker, EmojiPickerProps } from "@app/elements/form/EmojiPicker";
import { Input, getInputClassName } from "./Input";

export const TextInput = React.forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & TextInputProps
>(function TextInput(
  {
    label,
    error,
    hint,
    loading,
    className,
    icon,
    iconPosition,
    emojiPicker,
    emojiIcon,
    success,
    onChange,
    onInputChange,
    onClear,
    autoWidth,
    maxLength,
    ...props
  },
  ref,
) {
  const [length, setLength] = React.useState(0);

  const [emojiPickerVisible, setEmojiPickerVisible] = React.useState(false);
  const innerRef = React.useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => innerRef.current!);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        innerRef.current &&
        !innerRef.current.contains(event.target as Node)
      ) {
        setEmojiPickerVisible(false);
      }
    }

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const onEmojiSelect: EmojiPickerProps["onEmojiInsert"] = (emoji) => {
    setEmojiPickerVisible(false);
    if (!innerRef.current) {
      return;
    }

    const el = innerRef.current;
    const cursorPosition = el.selectionStart;
    if (cursorPosition || cursorPosition === 0) {
      const textBeforeCursorPosition = el.value.substring(0, cursorPosition);
      const textAfterCursorPosition = el.value.substring(
        cursorPosition,
        el.value.length,
      );
      onInputChange?.(
        textBeforeCursorPosition + emoji.native + textAfterCursorPosition,
      );
    }
  };

  return (
    <Input
      label={label}
      error={error}
      hint={hint}
      loading={loading}
      icon={icon || !!maxLength}
      iconPosition={iconPosition}
      success={success}
      className={className}
    >
      <input
        className={classNames(
          !autoWidth && "block w-full",
          getInputClassName(
            error
              ? "danger"
              : success
                ? "success"
                : props.disabled
                  ? "disabled"
                  : "default",
          ),
          className,
          maxLength && "pe-8",
          "input-padding",
          icon && icon !== true && (iconPosition === "end" ? "pe-10" : "ps-10"),
        )}
        ref={innerRef}
        type="text"
        onChange={(e) => {
          onChange?.(e);
          onInputChange?.(e.target.value);
          if (maxLength) setLength(e.target.value.length);
        }}
        {...props}
      />
      {onClear && (
        <FontAwesomeIcon
          onClick={() => onClear()}
          icon={regular("circle-x")}
          className="absolute end-4 cursor-pointer"
        />
      )}

      {emojiPicker && (
        <EmojiPicker
          isOpen={emojiPickerVisible}
          onEmojiInsert={onEmojiSelect}
          renderTarget={(ref: any) => (
            <div
              ref={ref}
              className="absolute end-2 top-5 hidden -translate-y-1/2 cursor-pointer items-center md:flex me-1"
              onClick={(e) => {
                setEmojiPickerVisible(!emojiPickerVisible);
                e.stopPropagation();
              }}
            >
              {emojiIcon || (
                <FontAwesomeIcon
                  icon={regular("face-smile")}
                  className="h-4 w-4"
                />
              )}
            </div>
          )}
        />
      )}

      {maxLength && length !== 0 && (
        <div
          dir="ltr"
          className={classNames(
            "absolute bottom-2 left-6 block max-w-lg cursor-text select-none rounded-lg pl-2 text-left transition",
            length > maxLength ? "text-red-600" : "",
          )}
        >
          {length}
        </div>
      )}
    </Input>
  );
});
