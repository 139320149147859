import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import { PeriodType, PostInsightsResponse } from "../interfaces";

export interface PostInsightsFilter {
  postGroupId: number;
  accountId?: number;
  period?: PeriodType;
  metric?: string[];
}

export const postInsightsKey = createQueryKeys("insights", {
  post: (filter: PostInsightsFilter) => [{ filter }],
});

export const useFetchPostInsightsMetric = <TData = PostInsightsResponse>(
  filter: PostInsightsFilter,
  enabled?: boolean,
  select?: (data: PostInsightsResponse) => TData,
) =>
  useQuery({
    ...postInsightsKey.post(filter),
    queryFn: async () => {
      const response = await api.get<PostInsightsResponse>(
        `insights/post/${filter.postGroupId}`,
        {
          params: {
            account_id: filter.accountId,
            metric: filter.metric,
            period: filter.period,
          },
        },
      );
      return response.data;
    },
    select,
    enabled,
  });
