import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { SVGAttributes } from "react";
import { brands } from "@common/helpers/fontawesome";
import { useThemeMode } from "@common/hooks";
import { SocialIconProps } from "@common/types";

export const Icons = {
  Aparat: {
    light: "text-[#ed145b]",
    dark: "",
  },
  Bale: {
    light: "text-[#0cb689]",
    dark: "",
  },
  Gap: {
    light: "text-[#9867eb]",
    dark: "",
  },
  Soroush: {
    light: "text-[#17509f]",
    dark: "",
  },
  Eitaa: {
    light: "text-[#e97800]",
    dark: "",
  },
  Facebook: {
    icon: brands("facebook"),
    light: "text-[#1877f2]",
    dark: "",
  },
  Instagram: {
    icon: brands("instagram"),
    light: "text-[#c32aa3]",
    dark: "",
  },
  InstagramOfficial: {
    icon: brands("instagram"),
    light: "text-[#c32aa3]",
    dark: "",
  },
  Linkedin: {
    icon: brands("linkedin"),
    light: "text-[#0a66c2]",
    dark: "",
  },
  Pinterest: {
    icon: brands("pinterest"),
    light: "text-[#df0022]",
    dark: "",
  },
  TikTok: {
    icon: brands("tiktok"),
    light: "text-[#010101]",
    dark: "",
  },
  Telegram: {
    icon: brands("telegram"),
    light: "text-[#0088cc]",
    dark: "",
  },
  Tumblr: {
    icon: brands("tumblr"),
    light: "text-[#1da1f2]",
    dark: "",
  },
  Twitter: {
    icon: brands("twitter"),
    light: "text-[#1da1f2]",
    dark: "",
  },
  Whatsapp: {
    icon: brands("whatsapp"),
    light: "text-[#00c209]",
    dark: "",
  },
  YouTube: {
    icon: brands("youtube"),
    light: "text-[#ff0000]",
    dark: "",
  },
} as const;

export const SocialIcon = ({
  social,
  className,
  ...rest
}: SocialIconProps &
  (SVGAttributes<HTMLOrSVGElement> | FontAwesomeIconProps)) => {
  const theme = useThemeMode();

  const icon = Icons[social];

  if (!("icon" in icon)) {
    return (
      <svg
        className={classNames(
          "svg-inline--fa min-h-[1.9rem] min-w-[1.9rem] fill-current",
          className,
          theme === "light" ? icon.light : icon.dark,
        )}
        {...(rest as SVGAttributes<HTMLOrSVGElement>)}
      >
        <use xlinkHref={"#" + social.toLowerCase()} />
      </svg>
    );
  }

  return (
    <FontAwesomeIcon
      {...(rest as FontAwesomeIconProps)}
      icon={icon.icon}
      className={classNames(
        "svg-inline--fa min-h-[1.9rem] min-w-[1.9rem] fill-current",
        className,
        theme === "light" ? icon.light : icon.dark,
      )}
    />
  );
};
