import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { ModalProps } from "@common/types";
import { Text } from "../text";
import { Modal } from "./Modal";

export function NewFeatureModal({
  title,
  subtitle,
  description,
  ...props
}: ModalProps & { title: string; subtitle: string; description: string }) {
  return (
    <Modal {...props}>
      <div tabIndex={1} className="focus:outline-none" />
      <Modal.Title className="mb-2 border-b border-b-primary-500 p-4">
        {title}
        <Text variant="muted" className="text-xs">
          {subtitle}
        </Text>
      </Modal.Title>

      <div
        className="p-4"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <div
        className="mt-2 flex items-center justify-between border-t p-4 text-primary-500"
        style={{
          color: "#00b4eb",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {__("Follow %{site} in social medias:", {
            site: __(import.meta.env.VITE_SITE_NAME),
          })}
        </div>
        <div className="flex space-s-2">
          <a
            href={import.meta.env.VITE_SOCIAL_URL_TELEGRAM}
            className="h-6 w-6 bg-primary-50 p-1"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={brands("telegram")} />
          </a>
          <a
            href={import.meta.env.VITE_SOCIAL_URL_INSTAGRAM}
            className="h-6 w-6 bg-primary-50 p-1"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={brands("instagram")} />
          </a>
          <a
            href={import.meta.env.VITE_SOCIAL_URL_FACEBOOK}
            className="h-6 w-6 bg-primary-50 p-1"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={brands("facebook")} />
          </a>
          <a
            href={import.meta.env.VITE_SOCIAL_URL_TWITTER}
            className="h-6 w-6 bg-primary-50 p-1"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={brands("twitter")} />
          </a>
        </div>
      </div>
    </Modal>
  );
}
