import { WarningsProps } from "@common/types";
import { SocialIcon } from "@app/elements/social";
import { Alert } from "@app/elements/text/Alert";

export default function Warnings({ warnings }: WarningsProps) {
  return warnings.length > 0 ? (
    <div className="my-4 space-y-2">
      {warnings.map(([account, warning], index) => (
        <Alert
          variant="danger"
          className="border-s-4"
          key={index}
          icon={
            <SocialIcon
              social={account.type}
              className="me-3 inline flex-shrink-0"
            />
          }
        >
          <span className="font-medium">[{account.name}]: </span>
          {warning}
        </Alert>
      ))}
    </div>
  ) : null;
}
