import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import {
  deleteDataOnInfiniteQuery,
  getNextPaginatePage,
  updateDataOnInfiniteQuery,
} from "@common/helpers/queries";
import { Paginate } from "@common/interfaces/Api";
import { FileInfo } from "../../Post";
import { File as FileModel } from "../interfaces";

export interface FileFilter {
  type: Array<FileModel["fileType"]>;
  star?: "1" | "0";
  search?: string;
}

export const filesKeys = createQueryKeys("files", {
  list: (filter: FileFilter) => [{ filter }],
  file: (id?: number | null) => [{ id }],
  info: null,
});

export type FileList = Paginate<FileModel>;

export const useFetchFiles = (filter: FileFilter) =>
  useInfiniteQuery({
    ...filesKeys.list(filter),
    queryFn: async ({ pageParam, signal }) => {
      const response = await api.get<FileList>("file", {
        params: {
          ...filter,
          page: pageParam,
        },
        signal,
      });
      return response.data;
    },
    initialPageParam: undefined,
    getNextPageParam: getNextPaginatePage,
  });

export const useFetchFile = (id?: number | null) =>
  useQuery({
    ...filesKeys.file(id),
    queryFn: async () => {
      const response = await api.get<FileModel>(`file/${id}`);
      return response.data;
    },
    enabled: !!id,
  });

export const useFetchFileInfo = () =>
  useQuery({
    ...filesKeys.info,
    queryFn: async () => {
      const response = await api.get<FileInfo>("me/files");
      return response.data;
    },
  });

export const useStarFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      api
        .put<FileModel>("file/" + id + "/star")
        .then((response) => response.data),
    onSuccess: (newFile) => {
      queryClient.setQueriesData<InfiniteData<FileList>>(
        { queryKey: filesKeys.list._def },
        (previous) => updateDataOnInfiniteQuery(previous, newFile),
      );

      queryClient.invalidateQueries({
        queryKey: filesKeys.list._def,
        refetchType: "none",
      });
    },
  });
};

export const useDeleteFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => api.delete(`file/${id}`),
    onSuccess: (_data, variables) => {
      queryClient.setQueriesData<InfiniteData<FileList>>(
        { queryKey: filesKeys.list._def },
        (previous) => deleteDataOnInfiniteQuery(previous, variables),
      );

      queryClient.invalidateQueries({
        queryKey: filesKeys.list._def,
        refetchType: "none",
      });

      queryClient.invalidateQueries(filesKeys.info);
    },
  });
};

export const useClearFiles = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => api.delete("file"),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: filesKeys._def });
    },
  });
};
