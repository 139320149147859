import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import { deleteDataOnArray, insertDataOnArray } from "@common/helpers/queries";
import { Caption } from "../interfaces";

export const captionsKeys = createQueryKeys("captions", {
  list: (type: Caption["type"]) => [{ type }],
});

export const useFetchCaptions = (type: Caption["type"]) =>
  useQuery({
    ...captionsKeys.list(type),
    queryFn: async () => {
      const response = await api.get<Caption[]>("caption", {
        params: { type },
      });
      return response.data;
    },
  });

export const useDeleteCaption = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => api.delete(`caption/${id}`),
    onSuccess: (_data, variables) => {
      queryClient.setQueriesData<Caption[]>(
        { queryKey: captionsKeys.list._def },
        (previous) => deleteDataOnArray(previous, variables),
      );

      queryClient.invalidateQueries({
        queryKey: captionsKeys.list._def,
        refetchType: "none",
      });
    },
  });
};

export const useCreateCaption = (type: Caption["type"]) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (caption: { title: string; caption: string }) =>
      api
        .post("caption", { type, ...caption })
        .then((response) => response.data),
    onSuccess: (newCaption) => {
      queryClient.setQueryData<Caption[]>(
        captionsKeys.list(type).queryKey,
        (previous) => insertDataOnArray(previous, newCaption),
      );

      queryClient.invalidateQueries({
        queryKey: captionsKeys.list(type).queryKey,
        refetchType: "none",
      });
    },
  });
};
