import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

export function brands(iconName: IconName): [IconPrefix, IconName] {
  return ["fab", iconName];
}
export function duotone(iconName: IconName): [IconPrefix, IconName] {
  return ["fad", iconName];
}
export function light(iconName: IconName): [IconPrefix, IconName] {
  return ["fal", iconName];
}
export function regular(iconName: IconName): [IconPrefix, IconName] {
  return ["far", iconName];
}
export function solid(iconName: IconName): [IconPrefix, IconName] {
  return ["fas", iconName];
}
export function thin(iconName: IconName): [IconPrefix, IconName] {
  return ["fat", iconName];
}
