import moment from "moment-jalaali";
import { Account } from "@common/features/Account";
import { roundNumber } from "@common/helpers/functions";
import { __, formatDate, isRTL, timezone } from "@common/helpers/i18n";
import { InsightsResponseData } from "../interfaces";
import { InsightsState } from "../providers/InsightsProvider";

/**
 * If you are here because you think there is bug here,
 * please don't try to be smart, this code is working and go search for bug somewhere else
 * but if you want to change something be my guest. :)
 *
 * This file took 1 week from me to write and test.
 */

export type InsightsTypes = keyof typeof import("../sections");

export type ProcessDataEvent =
  | "fetchAll"
  | "updateMetric"
  | "print"
  | "lifetime";

export interface SummaryDiff {
  sent: number;
  received: number;
  engagement: number;
  impressions: number;
  growth: number;
  mediatype: { text: number; album: number; image: number; video: number };
}

export interface SummaryData {
  sent: number;
  received: number;
  engagement: number;
  impressions: number;
  growth: number;
}

export interface CommonSocialProps {
  account: Account;

  filter: InsightsState;
}

export const backgroundColors = {
  Facebook: ["#3b5999", "#3b5999"],
  InstagramOfficial: ["#e4405f", "#e4405f"],
  Telegram: ["#1E96C8", "#1E96C8"],
  Twitter: ["#1E96C8", "#1E96C8"],
  Linkedin: ["#0077B5", "#0077B5"],
  Sent: ["#08C4B2", "#08C4B2"],
  Received: ["#067C7C", "#067C7C"],
  Album: ["#E4405F", "#E4405F"],
  Video: ["#08C4B2", "#08C4B2"],
  Text: ["#3B5998", "#3B5998"],
  Image: ["#12b200", "#12b200"],
  Story: ["#3B5998", "#3B5998"],
  Male: ["#3B5998", "#3B5998"],
  Female: ["#2daae1", "#2daae1"],
  Country: ["#2daae1", "#2daae1"],
};

export class ProcessData {
  static genericMetric(data: { [date: string]: number | null }) {
    return {
      data: Object.values(data).map((item) => item || 0),
      label: ProcessData.dateLabels(data),
    };
  }

  static dateLabels(data: { [date: string]: any }) {
    return Object.keys(data).map((date) =>
      formatDate(
        date,
        formatDate(date, isRTL ? "jYYYY/jMM/jDD" : "YYYY/MM/DD"),
      ),
    );
  }

  static numPostsByType(
    data: Extract<
      InsightsResponseData,
      { metric: "num_posts_by_type" }
    >["data"],
  ) {
    const postPerType: {
      [key in "text" | "album" | "image" | "video" | "story"]: {
        [date: string]: number;
      };
    } = {
      text: {},
      album: {},
      image: {},
      video: {},
      story: {},
    };

    Object.keys(data).forEach((date) => {
      const value = data[date];
      if (typeof postPerType.album[date] === "undefined") {
        postPerType.text[date] = 0;
        postPerType.album[date] = 0;
        postPerType.image[date] = 0;
        postPerType.video[date] = 0;
        postPerType.story[date] = 0;
      }

      try {
        const parseData = JSON.parse(value || "");
        (
          Object.keys(parseData) as Array<
            "text" | "image" | "album" | "video" | "link" | "document"
          >
        ).forEach((key) => {
          const _value = parseData[key];
          if (key === "link" || key === "document") {
            key = "text";
          }
          postPerType[key][date] += parseInt(_value);
        });
      } catch (e) {
        // ignore
      }
    });
    return {
      data: {
        text: Object.values(postPerType.text),
        image: Object.values(postPerType.image),
        album: Object.values(postPerType.album),
        video: Object.values(postPerType.video),
        story: Object.values(postPerType.story),
      },
      label: ProcessData.dateLabels(data),
    };
  }

  static onlineFollowers(
    data: Extract<InsightsResponseData, { metric: "online_followers" }>["data"],
  ) {
    const onlineFollowers: { [date: string]: number } = {};

    Object.keys(data).forEach((date) => {
      const value = data[date];
      if (typeof onlineFollowers[date] === "undefined") {
        onlineFollowers[date] = 0;
      }
      if (!value) {
        return;
      }
      Object.values(value).forEach((_value) => {
        onlineFollowers[date] += parseInt(_value);
      });
    });

    return {
      data: Object.keys(onlineFollowers).map((key) => onlineFollowers[key]),
      label: this.dateLabels(data),
    };
  }

  static onlineFollowersHours(
    data: Extract<InsightsResponseData, { metric: "online_followers" }>["data"],
  ) {
    const hourLabelOnline: string[] = [];
    // its just a label so only check having 30 in minute or not
    const minutePrefix = +timezone % 60 === 0 ? ":00" : ":30";

    for (let i = 0; i < 24; i++) {
      hourLabelOnline.push(i + minutePrefix);
    }

    const sum: { [key: string]: number } = {};

    Object.keys(data).forEach((date) => {
      const value = data[date];
      if (!value) {
        return;
      }
      Object.keys(value).forEach((key) => {
        const _value = value[key];
        if (typeof sum[key] === "undefined") {
          sum[key] = 0;
        }
        sum[key] = (sum[key] + parseInt(_value)) / 2;
      });
    });

    const onlinePerHour: { [key: string]: number } = {};
    for (let i = 0; i < 24; i++) {
      const temp =
        sum[
          moment(i + minutePrefix, "H:mm") // convert to timezone time
            .utcOffset(timezone) // set current timetzone
            .utc() // convert to utc to get index of api array
            .format("H") + ":00" // just get H (its not for showing) and add minute to label
        ];
      onlinePerHour[i + minutePrefix] = Math.round(temp || 0);
    }

    const sumPerHour = Object.keys(onlinePerHour).sort(
      (a, b) => onlinePerHour[b] - onlinePerHour[a],
    );

    return {
      bestHour: sumPerHour.length
        ? ([sumPerHour[1], sumPerHour[0]] as [string, string])
        : undefined,
      data: Object.values(onlinePerHour),
      label: hourLabelOnline,
    };
  }

  static gender(
    data: Extract<InsightsResponseData, { metric: "gender" }>["data"],
  ) {
    return {
      male: data.male || 0,
      female: data.female || 0,
    };
  }

  static country(
    data: Extract<InsightsResponseData, { metric: "country" }>["data"],
  ) {
    return Object.keys(data).map((key) => ({
      key: key.toLowerCase(),
      value: +data[key],
    }));
  }

  static hashtag(
    data: Extract<
      InsightsResponseData,
      { metric: "impressions_by_hashtag" | "top_hashtag" }
    >["data"],
  ) {
    return Object.keys(data).map((key) => [key, data[key]] as const);
  }

  static impressionsByHour(
    data: Extract<
      InsightsResponseData,
      { metric: "impressions_by_hour" }
    >["data"],
  ) {
    const impressionByHour: { [key: string]: number } = {};
    const label: string[] = [];

    for (let i = 0; i < 24; i++) {
      label.push(i + "");
      impressionByHour[i] = typeof data[i] === "undefined" ? 0 : data[i];
    }

    return {
      data: Object.values(impressionByHour),
      label,
    };
  }

  static sumSummary(
    data: { [key: string]: string | number | null } | number[],
  ): number {
    return Object.values(data).reduce<number>((a, b) => +a + +(b || 0), 0);
  }

  static summaryDiff<
    T extends {
      data: { [key: string]: string | number | null };
      prev: { [key: string]: string | number | null };
    },
  >({ data, prev }: T): number {
    const sum = this.sumSummary(prev);
    return sum === 0
      ? 100
      : roundNumber(((ProcessData.sumSummary(data) - sum) / sum) * 100);
  }

  static avgSummary(
    data: { [key: string]: string | number | null } | number[],
  ): number {
    return Object.values(data).length > 0
      ? ProcessData.sumSummary(data) / Object.values(data).length
      : 0;
  }
}

export const MetricName = {
  Growth: {
    Facebook: __("New Facebook Fans"),
    Twitter: __("New Twitter Followers"),
    InstagramOfficial: __("New Instagram Followers"),
    Telegram: __("New telegram members"),
    Linkedin: __("New Linkedin Followers"),
  },
  Sent: {
    Facebook: __("Facebook Posts Sent"),
    Twitter: __("Twitter Tweets and DMs Sent"),
    InstagramOfficial: __("Instagram Media Sent"),
    Telegram: __("Telegram Posts Sent"),
    Linkedin: __("Linkedin Posts Sent"),
  },
  Received: {
    Facebook: __("Facebook Messages Received"),
    Twitter: __("Twitter Messages Received"),
    InstagramOfficial: __("Instagram Comments Received"),
    Linkedin: __("Linkedin Comments Received"),
  },
  Engagement: {
    Facebook: __("Facebook Engagements"),
    Twitter: __("Twitter Engagements"),
    InstagramOfficial: __("Instagram Engagements"),
    Linkedin: __("Linkedin Engagements"),
  },
  Impressions: {
    Facebook: __("Facebook Impressions"),
    InstagramOfficial: __("Instagram Impressions"),
    Telegram: __("Telegram views"),
    Linkedin: __("Linkedin Impressions"),
  },
  MediaType: {
    text: __("Text Message"),
    album: __("Album Message"),
    image: __("Image Message"),
    video: __("Video Message"),
  },
  Gender: {
    female: __("Female"),
    male: __("Male"),
  },
  AccountType: {
    InstagramOfficial: __("Instagram"),
    Telegram: __("Telegram"),
    Facebook: __("Facebook"),
    Linkedin: __("Linkedin"),
    Twitter: __("Twitter"),
  },
};

export function summaryFooter(name: string, number?: number) {
  number = number || 0;
  return __("insights.summary", {
    account: name,
    percent: number,
    class: number > 0 ? "increased" : number < 0 ? "decreased" : "",
  });
}
