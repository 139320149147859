import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { usePostStore } from "@common/features/Post";
import { useFeatureSupports } from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { CheckBox, Label, Select } from "@app/elements/form";
import { Tooltip } from "@app/elements/helpers";
import { SocialIcon } from "@app/elements/social";
import { Text } from "@app/elements/text";

const hours = [...Array(48).keys()].slice(1);

export default function AutoDeleteSection(props: Partial<SectionToggleProps>) {
  const featureSupports = useFeatureSupports();
  const [{ autoDeleteHour, autoDeleteAccounts, selectedAccounts }, dispatch] =
    usePostStore(
      ({ autoDeleteHour, autoDeleteAccounts, selectedAccounts }) => ({
        autoDeleteHour,
        autoDeleteAccounts,
        selectedAccounts,
      }),
      true,
    );

  return (
    <SectionToggle
      {...props}
      title={__("Auto delete post")}
      hint={__(
        "Content will be automatically deleted from your account at the specified time.",
      )}
      icon={
        <FontAwesomeIcon
          className="h-6 w-6"
          icon={regular("trash-can-clock")}
        />
      }
      defaultOpen={autoDeleteAccounts.length !== 0}
    >
      <ElementsGroup>
        <Label
          label={
            __(
              "Select the accounts in which the post will be automatically deleted",
            ) + ":"
          }
        />
        <ElementsGroup display="grid" gridCol={3} space={2}>
          {selectedAccounts.map((account) => (
            <React.Fragment key={account.id}>
              {
                <CheckBox
                  id={"accounts" + account.id}
                  onInputChange={(checked) =>
                    dispatch({
                      type: "setAutoDeleteAccounts",
                      payload: checked
                        ? [...autoDeleteAccounts, account.id]
                        : autoDeleteAccounts.filter((id) => id !== account.id),
                    })
                  }
                  checked={autoDeleteAccounts.includes(account.id)}
                  icon={<SocialIcon social={account.type} />}
                  disabled={!featureSupports.auto_delete.includes(account.type)}
                  label={
                    featureSupports.auto_delete.includes(account.type) ? (
                      account.name
                    ) : (
                      <Tooltip
                        tooltip={__(
                          "Auto delete is not enabled for this social media.",
                        )}
                      >
                        <Text
                          variant="disabled"
                          icon={regular("circle-question")}
                          iconPosition="end"
                          block
                        >
                          {account.name}
                        </Text>
                      </Tooltip>
                    )
                  }
                />
              }
            </React.Fragment>
          ))}
        </ElementsGroup>
        <Select
          label={__(
            "After how many hours the post will be deleted automatically?",
          )}
          placeholder={__("Select a hour")}
          value={autoDeleteHour}
          options={hours}
          getLabel={(item) => item}
          getKey={(item) => item}
          menuPlacement="top"
          onChange={(item) =>
            dispatch({ type: "setAutoDeleteHour", payload: item })
          }
        />
      </ElementsGroup>
    </SectionToggle>
  );
}
