import React, { useEffect } from "react";
import { useFetchMetric } from "@common/features/Insights";
import {
  useFilterMetricPermissions,
  useMasterPermissions,
} from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { isBrowser } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { ElementsGroup, EmptyPage } from "@app/elements/container";
import BestHour from "@app/pages/insights/BestHour";
import ChartGroup from "@app/pages/insights/ChartGroup";
import InsightsPosts from "@app/pages/insights/InsightsPosts";
import { TelegramSelector } from "@app/pages/insights/selectors";
import { BoxNumbers } from "../components/BoxNumbers";
import { InsightsChartSection } from "../components/InsightsChartSection";
import { CommonSocialProps } from "../helpers/common";

function Telegram({
  account,
  filter: { customFrom, customTo, printPeriod },
}: CommonSocialProps) {
  const masterPermissions = useMasterPermissions();

  const { data, isLoading } = useFetchMetric(
    {
      period: printPeriod
        ? printPeriod
        : customFrom && customTo
          ? "custom"
          : "week",
      metric: useFilterMetricPermissions([
        "impressions",
        "num_posts_by_type",
        "page_follow_growth",
        "page_follow_total",
        "total_posts_sent",
        "top_post",
        "average_post_daily",
      ]),
      customFrom:
        !printPeriod && customFrom && customTo ? customFrom : undefined,
      customTo: !printPeriod && customFrom && customTo ? customTo : undefined,
      accountId: account.id,
    },
    true,
    TelegramSelector,
  );

  useEffect(() => {
    if (isBrowser) {
      if (printPeriod && !isLoading) {
        (window as any).print();
      }
    }
  }, [printPeriod, isLoading]);

  const rowOne = {
    page_follow_total: {
      title: __("Total audience"),
      hide: !masterPermissions.insights_audience_growth,
    },
    growth: {
      title: __("New followers"),
      hide: !masterPermissions.insights_audience_growth,
    },
    average_impressions: {
      title: __("Average impressions"),
      hide: !masterPermissions.insights_impressions,
    },
    total_impressions: {
      title: __("Total impressions"),
      hide: !masterPermissions.insights_impressions,
    },
    average_post_daily: {
      title: __("Avg post daily"),
      hide: !masterPermissions.insights_summary,
    },
    total_posts_sent: {
      title: __("Total published posts"),
      hide: !masterPermissions.insights_posts_publish,
    },
  };

  if (data?.emptyData) {
    return (
      <EmptyPage
        text={__("Data is being called, please wait a few minutes!")}
        icon={regular("hourglass-half")}
      />
    );
  }

  const isCustom = customFrom !== undefined && customTo !== undefined;

  return (
    <ElementsGroup>
      <BoxNumbers
        loading={isLoading}
        items={data?.total || {}}
        options={rowOne}
      />
      <InsightsChartSection
        title={__("Audience Growth")}
        loading={isLoading}
        chartOption={data?.growth}
        selector={TelegramSelector}
        metric="page_follow_growth"
        account={account}
        isCustom={isCustom}
      />
      <ChartGroup>
        <InsightsChartSection
          title={__("Group Impressions")}
          hint={__(
            "Overall number of impression of published posts in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.impressions}
          selector={TelegramSelector}
          metric="impressions"
          account={account}
          isCustom={isCustom}
        />
        <InsightsChartSection
          title={__("Media Type")}
          loading={isLoading}
          chartOption={data?.mediatype}
          selector={TelegramSelector}
          metric="num_posts_by_type"
          account={account}
          isCustom={isCustom}
        />
      </ChartGroup>

      {data?.bestHour && (
        <BestHour from={data.bestHour[0]} to={data.bestHour[1]} />
      )}

      <InsightsPosts
        account={account}
        posts={data?.top_post}
        likeText={__("View")}
        commentText={false}
      />
    </ElementsGroup>
  );
}

export default React.memo(Telegram);
