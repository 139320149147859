import classNames from "classnames";
import { LoadingProps } from "@common/types";

const sizes = {
  small: 16,
  medium: 24,
  large: 28,
};

export function Loading({
  enabled,
  type = "cover",
  size = "medium",
  rounded = true,
}: LoadingProps) {
  const actualSize = (sizes as any)[size] || size;
  return (
    <div
      className={classNames(
        "z-10 flex justify-center overflow-hidden transition-all duration-150 ease-linear",
        type === "cover" &&
          "absolute inset-0 h-full w-full bg-white/70 dark:bg-gray-700/70",
        rounded && "rounded-md",
        type === "slide" ? enabled && "mb-4" : "items-center",
        type !== "slide" && enabled === false && "hidden",
        type === "block" && "my-4",
      )}
      style={{
        maxHeight: type === "slide" ? (enabled ? actualSize : 0) : undefined,
      }}
    >
      <svg
        className={classNames(
          "animate-spin text-primary-500 dark:text-primary-400",
        )}
        style={{
          height: actualSize,
          width: actualSize,
        }}
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  );
}
