import { createRoot } from "react-dom/client";
import "@app/style/main.css";
import App from "./App";
import Main from "./layout/Main";
import * as routes from "./routes";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(<App routes={routes} layout={Main} />);
