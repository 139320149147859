import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorInfo } from "react";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import useReportError from "@common/hooks/useReportError";
import { Section } from "../container";
import { Button } from "../form";
import { Text } from "../text";

const getUrl = () => window.location.toString();

export default function Error500({
  error,
  info,
}: {
  error: Error;
  info: ErrorInfo;
}) {
  const traceId = useReportError(error, info, getUrl);

  return (
    <Section className="flex min-h-full flex-col pb-12 pt-16" pure>
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <div className="mb-2 inline-block rounded-full bg-gray-200 dark:bg-gray-700">
              <FontAwesomeIcon
                className="p-8 text-6xl"
                icon={regular("triangle-exclamation")}
              />
            </div>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">
              {__("Something went wrong.")}
            </h1>
            <Text variant="gray" className="mt-6 text-base" block>
              {__("Oops! An error occured. Please try again later!")}
            </Text>
          </div>
        </div>
      </main>
      <Text className="mb-2 text-center" variant="muted">
        {__("To resolve this issue, send this id to support team.")}
      </Text>
      {traceId && (
        <Text className="mb-8 text-center" variant="muted">
          {traceId}
        </Text>
      )}
      <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-s-4">
          <div className="flex flex-1 justify-end">
            <Button
              href="/ticket"
              variant="link"
              className="text-sm font-medium"
            >
              {__("Contact Support")}
            </Button>
          </div>
          <span className="inline-block border-l" aria-hidden="true" />
          <div className="flex-1">
            <Button
              href={import.meta.env.VITE_SOCIAL_URL_TWITTER}
              target="_blank"
              variant="link"
              className="text-sm font-medium"
              rel="noreferrer"
            >
              {__("Twitter")}
            </Button>
          </div>
        </nav>
      </footer>
    </Section>
  );
}
