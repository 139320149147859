import {
  FloatingPortal,
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { RangeStatic } from "quill";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import twitter from "twitter-text";
import * as yup from "yup";
import { CaptionModal } from "@common/features/Caption";
import {
  useFetchActiveIntegration,
  useRunIntegrationAction,
} from "@common/features/Integration";
import { MultiCaptions, usePostStore } from "@common/features/Post";
import CaptionGenerateModal from "@common/features/Post/components/CaptionGenerateModal";
import { SocialNetwrokType } from "@common/features/Social";
import { useSocialTypes } from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { __, isRTL } from "@common/helpers/i18n";
import { useFormError } from "@common/hooks";
import { useEvent } from "@common/hooks";
import { EditorWrapperProps } from "@common/types";
import { Section } from "@app/elements/container";
import {
  Button,
  EmojiPicker,
  EmojiPickerProps,
  TextInput,
} from "@app/elements/form";
import { Tooltip } from "@app/elements/helpers";
import { CheckPermissionButton } from "@app/elements/permission";
import { SocialIcon } from "@app/elements/social";
import QuillMagicUrl from "@app/helpers/QuillMagicUrl";
import ReactQuill, { Quill } from "@app/helpers/ReactQuill";
import Editor from "./Editor";

// const icons = Quill.import('ui/icons');
// icons.bold = null;
// icons.italic = null;
// icons.link = null;

const formats = ["bold", "italic", "link"];

const Link = Quill.import("formats/link");
const Delta: typeof import("quill").Delta = Quill.import("delta");

const socialCharLimits: { [key in SocialNetwrokType]: number } = {
  Instagram: 2200,
  InstagramOfficial: 2200,
  Twitter: 280,
  Facebook: 63206,
  Telegram: 4096,
  Linkedin: 3000,
  Aparat: 2000,
  YouTube: 5000,
  Pinterest: 500,
  Tumblr: 50000,
  Soroush: 4096,
  Bale: 4096,
  Gap: 4096,
  Eitaa: 4096,
  Whatsapp: 5000,
  TikTok: 2200,
};

class MyLink extends Link {
  static create(value: any) {
    const node = super.create(value);
    node.removeAttribute("target");
    node.removeAttribute("rel");
    return node;
  }

  format(name: string, value: string) {
    super.format(name, value);
    this.domNode.removeAttribute("target");
    this.domNode.removeAttribute("rel");
  }
}

Quill.register(MyLink, true);
Quill.register("modules/magicUrl", QuillMagicUrl, true);

const schema = yup.object({
  url: yup.string().required().label("Url"),
});

type FormType = yup.InferType<typeof schema>;

export default function EditorWrapper({
  onFileManager,
  onPollModal,
  onUpload,
  readOnly,
  children,
}: EditorWrapperProps) {
  const [{ captions, hasCustomCaption, selectedAccounts, hasPoll }, dispatch] =
    usePostStore(
      ({ captions, hasCustomCaption, selectedAccounts, poll }) => ({
        captions,
        hasCustomCaption,
        selectedAccounts,
        hasPoll: poll !== undefined,
      }),
      true,
    );

  const selectedTypes = selectedAccounts
    .map((account) => account.type)
    .filter((v, i, a) => a.indexOf(v) === i);

  const [activeTab, setActiveTab] = useState<keyof MultiCaptions>("main");
  const [captionModal, setCaptionModal] = useState(false);
  const [captionGenerate, setCaptionGenerate] = useState(false);

  const grammarly = useFetchActiveIntegration("Grammarly");
  const chatGPT = useFetchActiveIntegration("ChatGPT");
  const bitly = useFetchActiveIntegration("Bitly");

  const socialTypes = useSocialTypes();

  useEffect(() => {
    if (activeTab !== "main" && !selectedTypes.includes(activeTab)) {
      setActiveTab("main");
    }
  }, [activeTab, selectedTypes]);

  const editorsRef = useRef<{
    [key in keyof MultiCaptions]?: ReactQuill | null;
  }>({});

  const onEmojiInsert: EmojiPickerProps["onEmojiInsert"] = (emoji) => {
    if (editorsRef.current[activeTab]) {
      const editor = editorsRef.current[activeTab]?.getEditor();
      if (editor) {
        editor.focus();
        editor.insertText(editor.getSelection()!.index, emoji.native);
      }
    }
    setEmojiPicker(false);
  };

  const [emojiPicker, setEmojiPicker] = useState(false);

  useEffect(() => {
    function closeEmoji() {
      setEmojiPicker(false);
    }
    document.addEventListener("click", closeEmoji);
    return () => document.removeEventListener("click", closeEmoji);
  }, []);

  const activeCaption =
    typeof captions.text[activeTab] === "undefined"
      ? captions.text.main
      : captions.text[activeTab]!;

  const charCount =
    (activeCaption.length
      ? activeTab === "Twitter"
        ? twitter.getTweetLength(activeCaption) - 1
        : activeCaption.length - 1
      : 0) || 0;

  const totalChar =
    activeTab === "main"
      ? selectedTypes.length
        ? Math.min(...selectedTypes.map((type) => socialCharLimits[type]))
        : 0
      : socialCharLimits[activeTab];

  const canFormat = selectedTypes.some((type) => type === "Telegram");

  const [isOpen, setIsOpen] = useState(false);
  const [linkRange, setLinkRange] = useState<[number, number] | false>(false);

  const {
    registerWithError: register,
    handleSubmit,
    reset,
    setValue,
  } = useFormError({
    resolver: yupResolver(schema),
    defaultValues: {
      url: "",
    },
  });

  const createOrder = useRunIntegrationAction(bitly?.id, "shorten");

  const onBitLy: SubmitHandler<FormType> = (data) => {
    if (!linkRange) {
      return;
    }
    createOrder.mutate(data, {
      onSuccess: (response) => {
        const editor = editorsRef.current[activeTab]!.getEditor();
        editorsRef.current[activeTab]?.focus();
        setValue("url", response.result);
        editor.updateContents(
          new Delta()
            .retain(linkRange[0])
            .delete(linkRange[1])
            .insert(data.url, { link: data.url }),
        );
        reset();
        setIsOpen(false);
      },
    });
  };

  const onUrlAdd: SubmitHandler<FormType> = (data) => {
    editorsRef.current[activeTab]?.focus();
    if (linkRange) {
      const editor = editorsRef.current[activeTab]!.getEditor();
      editor.formatText(linkRange[0], linkRange[1], "link", data.url, "user");
    } else {
      makeFormatHandler(editorsRef.current[activeTab], "link", data.url);
    }
    reset();
    setIsOpen(false);
  };

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([dismiss]);

  const onChangeSelection = useEvent((range: RangeStatic, source: string) => {
    if (range == null) return;

    if (range.length === 0 && source === "user") {
      const editor = editorsRef.current[activeTab]?.getEditor();

      const [link, offset] = (editor!.scroll as any).descendant(
        MyLink,
        range.index,
      );

      if (link != null) {
        setLinkRange([range.index - offset, link.length()]);
        setValue("url", MyLink.formats(link.domNode));
        refs.setReference({
          getBoundingClientRect: () =>
            (editor as any).selection.getBounds(range.index, range.length),
          // getClientRects: () => range.getClientRects(),
        });
        setIsOpen(true);
        return;
      }
    }
    reset();
    setIsOpen(false);
    setLinkRange(false);
  });

  return (
    <div className="relative">
      <CaptionModal
        isOpen={captionModal}
        onClose={() => setCaptionModal(false)}
        onSelect={(item) => {
          const editor = editorsRef.current.main?.getEditor();
          if (editor) editor.setText(item.caption);
        }}
        type="post"
      />
      {chatGPT && (
        <CaptionGenerateModal
          isOpen={captionGenerate}
          onClose={(caption) => {
            setCaptionGenerate(false);
            if (caption) {
              const editor = editorsRef.current.main?.getEditor();
              if (editor) editor.setContents(editor.clipboard.convert(caption));
            }
          }}
        />
      )}
      <div>
        <ul className="flex">
          <li
            className={
              "flex cursor-pointer items-center justify-center rounded-t-md bg-gray-200 px-2 py-1 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-500 " +
              (activeTab === "main"
                ? "border-b-2 border-gray-700 dark:border-gray-300"
                : "border-b-2 border-b-transparent")
            }
            onClick={() => setActiveTab("main")}
          >
            {__("Main")}
          </li>
          {hasCustomCaption &&
            selectedTypes.map((type) => (
              <li
                className={
                  "ms-2 flex cursor-pointer items-center justify-center rounded-t-md bg-gray-200 px-2 py-1 hover:bg-gray-100 hover:text-white dark:bg-gray-700 dark:hover:bg-gray-500 " +
                  (typeof captions.html[type] !== "undefined"
                    ? "border-b-2 border-gray-700 "
                    : "") +
                  (activeTab === type
                    ? "border-b-2 border-gray-700 dark:border-gray-300"
                    : "border-b-2 border-b-transparent")
                }
                onClick={() => setActiveTab(type)}
                key={type}
              >
                <SocialIcon social={type} />
              </li>
            ))}
          <li className="ms-auto">
            <Switch.Group>
              <div className="flex items-center">
                <Switch.Label className="me-2 flex items-center space-s-1">
                  <div>{__("Customize caption")}</div>
                </Switch.Label>
                <Tooltip
                  tooltip={__(
                    "Use this option to customize the caption text for each social media.",
                  )}
                >
                  <FontAwesomeIcon
                    icon={regular("circle-question")}
                    style={{
                      transform: isRTL ? "scaleX(-1)" : undefined,
                      marginLeft: "3px",
                    }}
                  />
                </Tooltip>
                <Switch
                  checked={hasCustomCaption}
                  onChange={() => {
                    dispatch({
                      type: "customCaptionChanged",
                      payload: !hasCustomCaption,
                    });
                    setActiveTab("main");
                  }}
                  className={`${
                    hasCustomCaption
                      ? "bg-primary-500"
                      : "bg-gray-200 dark:bg-gray-400"
                  } relative inline-flex h-4 w-8 items-center rounded-full transition-colors`}
                >
                  <span
                    className={`${
                      hasCustomCaption
                        ? "ltr:translate-x-4 rtl:-translate-x-4"
                        : "ltr:translate-x-1 rtl:-translate-x-1"
                    } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
              </div>
            </Switch.Group>
          </li>
        </ul>
      </div>
      <div className="relative rounded-md border-2 rounded-ts-none">
        <div className="flex flex-wrap gap-4 border-b-2 px-2 py-2">
          <div className="flex text-sm space-s-2">
            <CheckPermissionButton
              variant="gray"
              padding="small"
              icon={regular("folder")}
              onClick={onFileManager}
              permission="file_storage"
              showNoPermissionIcon
              disabled={hasPoll}
            >
              {__("Files")}
            </CheckPermissionButton>
            <Button
              variant="gray"
              padding="small"
              icon={regular("upload")}
              onClick={onUpload}
              disabled={hasPoll}
            >
              {__("Upload")}
            </Button>
            {chatGPT && (
              <CheckPermissionButton
                permission="chatgpt_caption"
                variant="gray"
                padding="small"
                onClick={() => setCaptionGenerate(true)}
                title={__("AI generator")}
                icon={regular("wand-magic-sparkles")}
                showNoPermissionIcon
              />
            )}
          </div>
          <div className="ms-auto flex items-center space-s-2">
            {selectedTypes.some((type) => socialTypes.poll.includes(type)) && (
              <Button
                variant="gray"
                padding="none"
                center
                className="h-8 w-8"
                icon={regular("poll-people")}
                onClick={() => onPollModal()}
              />
            )}
            <Button
              variant="gray"
              padding="none"
              center
              className="h-8 w-8"
              icon={regular("floppy-disk")}
              onClick={() => setCaptionModal(true)}
            />
            {canFormat && (
              <>
                <Button
                  variant="gray"
                  padding="none"
                  center
                  className="h-8 w-8"
                  icon={regular("bold")}
                  onClick={() =>
                    makeFormatHandler(editorsRef.current[activeTab], "bold")
                  }
                />
                <Button
                  variant="gray"
                  padding="none"
                  center
                  className="h-8 w-8"
                  icon={regular("italic")}
                  onClick={() =>
                    makeFormatHandler(editorsRef.current[activeTab], "italic")
                  }
                />
                <Button
                  variant="gray"
                  padding="none"
                  center
                  className="h-8 w-8"
                  icon={regular("link")}
                  onClick={() => {
                    const quillRef = editorsRef.current[activeTab];
                    if (!quillRef) return;
                    const editor = quillRef.getEditor();

                    const range = editor.getSelection();
                    if (!range) return;
                    const selectionFormat = editor.getFormat(range);
                    editor.getBounds(range.index, range.length);

                    formats.forEach((f) =>
                      f !== "link" ? editor.format(f, false) : "",
                    );
                    if (selectionFormat.link) {
                      editor.format("link", false, "user");
                    } else {
                      refs.setReference({
                        getBoundingClientRect: () =>
                          (editor as any).selection.getBounds(
                            range.index,
                            range.length,
                          ),
                        // getClientRects: () => range.getClientRects(),
                      });
                      setIsOpen(true);
                    }
                  }}
                />
              </>
            )}
            <div className="relative flex">
              <Button
                variant="gray"
                padding="none"
                center
                className="hidden h-8 w-8 md:block"
                icon={regular("face-smile")}
                onClick={(e) => {
                  e.nativeEvent.stopImmediatePropagation();
                  setEmojiPicker(!emojiPicker);
                }}
              />
              <EmojiPicker isOpen={emojiPicker} onEmojiInsert={onEmojiInsert} />
            </div>
            <Tooltip
              placement="bottom"
              tooltip={
                totalChar
                  ? __("Remaining character: %{count}", {
                      count: totalChar - charCount,
                    })
                  : undefined
              }
            >
              <div className="relative flex h-8 w-8 items-center justify-center self-center rounded-full bg-gray-200 dark:bg-gray-800">
                <div
                  className="absolute h-full w-full rounded-full bg-[conic-gradient(var(--gradient-start),rgba(0,0,0,0)0%)] dark:bg-[conic-gradient(var(--dark-gradient-start),rgba(0,0,0,0)0%)]"
                  style={
                    {
                      "--gradient-start": `rgb(${
                        totalChar - charCount > 0 ? "0,27,16" : "220,38,38"
                      }) ${(charCount / totalChar) * 100}%`,
                      "--dark-gradient-start": `rgb(${
                        totalChar - charCount > 0
                          ? "148, 163, 184"
                          : "185,28,28"
                      }) ${(charCount / totalChar) * 100}%`,
                    } as any
                  }
                />
                <div className="z-10 h-3/5 w-3/5 rounded-full bg-white dark:bg-dark-background"></div>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="relative">
          <div className={activeTab === "main" ? "" : "hidden"}>
            <Editor
              html={captions.html.main}
              onChange={(caption, text) =>
                dispatch({
                  type: "captionChanged",
                  payload: {
                    html: { ...captions.html, main: caption },
                    text: {
                      ...captions.text,
                      main: text,
                    },
                  },
                })
              }
              onChangeSelection={onChangeSelection}
              canFormat={canFormat}
              ref={(el) => (editorsRef.current.main = el)}
              grammarly={grammarly?.settings.client_id}
              readOnly={readOnly}
            />
          </div>
          {hasCustomCaption &&
            selectedTypes.map((type) => (
              <div key={type} className={activeTab === type ? "" : "hidden"}>
                <Editor
                  ref={(el) => (editorsRef.current[type] = el)}
                  html={
                    typeof captions.html[type] === "undefined"
                      ? captions.html.main
                      : captions.html[type]!
                  }
                  onChange={(caption, text) =>
                    activeTab === type &&
                    dispatch({
                      type: "captionChanged",
                      payload: {
                        html: { ...captions.html, [type]: caption },
                        text: {
                          ...captions.text,
                          [type]: text,
                        },
                      },
                    })
                  }
                  onChangeSelection={onChangeSelection}
                  canFormat={type === "Telegram"}
                  grammarly={grammarly?.settings.client_id}
                  readOnly={readOnly}
                />
              </div>
            ))}
          {isOpen && (
            <FloatingPortal>
              <Section
                pure
                ref={refs.setFloating}
                className="z-10 rounded-md border p-2 shadow"
                {...getFloatingProps()}
                style={floatingStyles}
              >
                <div className="flex space-s-2">
                  <TextInput
                    className="!w-40 text-xs"
                    placeholder={__("Enter link")}
                    {...register("url")}
                  />
                  {canFormat && (
                    <>
                      <Button
                        variant="light"
                        padding="small"
                        onClick={handleSubmit(onUrlAdd)}
                      >
                        {linkRange ? __("Edit") : __("Add")}
                      </Button>
                      {linkRange && (
                        <Button
                          variant="light"
                          padding="small"
                          onClick={() => {
                            editorsRef.current[activeTab]?.focus();
                            editorsRef.current[
                              activeTab
                            ]!.getEditor().formatText(
                              linkRange[0],
                              linkRange[1],
                              "link",
                              false,
                              "user",
                            );
                            reset();
                            setIsOpen(false);
                          }}
                        >
                          {__("Delete")}
                        </Button>
                      )}
                    </>
                  )}
                  {bitly !== undefined && (
                    <Button
                      variant="light"
                      padding="small"
                      onClick={handleSubmit(onBitLy)}
                    >
                      <svg className="svg-inline--fa fill-current">
                        <use xlinkHref="#bitly" />
                      </svg>
                      {!canFormat && (
                        <div className="ms-2">{__("Shorten URL")}</div>
                      )}
                    </Button>
                  )}
                </div>
              </Section>
            </FloatingPortal>
          )}
        </div>
        <div className="m-4 flex flex-shrink-0 flex-wrap gap-4 empty:hidden">
          {children}
        </div>
      </div>
    </div>
  );
}

function makeFormatHandler(
  quillRef: ReactQuill | undefined | null,
  format: string,
  value?: any,
) {
  if (!quillRef) return;
  const editor = quillRef.getEditor();

  const range = editor.getSelection();
  if (!range) return;
  const selectionFormat = editor.getFormat(range);

  formats.forEach((f) => (f !== format ? editor.format(f, false) : ""));
  editor.format(format, value || !selectionFormat[format], "user");
}
