import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { AnchorHTMLAttributes, HTMLAttributes, LiHTMLAttributes } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Tooltip } from "../helpers";
import { Text } from "../text";

// import Insights from './pages/Insights';

function BoxRoot({ className, ...rest }: HTMLAttributes<HTMLUListElement>) {
  return (
    <ul
      className={classNames(
        "grid",
        className ||
          "grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
      )}
      {...rest}
    />
  );
}

function Item({ className, ...props }: LiHTMLAttributes<HTMLLIElement>) {
  return (
    <li
      className={classNames(
        "relative col-span-1 flex flex-col items-center rounded-lg border bg-white shadow dark:bg-dark-background",
        className,
      )}
      {...props}
    />
  );
}

function Avatar({
  image,
  icon,
}: {
  image?: string;
  icon?: React.ReactNode | [IconPrefix, IconName];
}) {
  return (
    <div>
      {image ? (
        <img
          className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
          src={image}
          alt=""
        />
      ) : icon ? (
        Array.isArray(icon) ? (
          <FontAwesomeIcon icon={icon} className="h-16 w-16" />
        ) : (
          icon
        )
      ) : undefined}
    </div>
  );
}

function Title(props: HTMLAttributes<HTMLHeadElement>) {
  return <h3 className="mt-6 text-base font-semibold" {...props} />;
}

function Subtitle(props: HTMLAttributes<HTMLDivElement>) {
  return <Text variant="gray" className="mt-1 text-xs" {...props} />;
}

function FooterButton(props: HTMLAttributes<HTMLDivElement>) {
  return <div className="mt-auto flex w-full divide-s" {...props} />;
}

function FooterButtonItem({
  icon,
  title,
  ...props
}: { icon: [IconPrefix, IconName]; title: string } & (
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | LinkProps
)) {
  const Component: any = (props as any).to
    ? Link
    : (props as any).href
      ? "a"
      : "button";

  return (
    <div className="flex w-0 flex-1">
      <Tooltip tooltip={title}>
        <Component
          className="relative inline-flex w-0 flex-1 items-center justify-center py-4 font-semibold hover:opacity-80"
          {...props}
        >
          <Text variant="gray">
            <FontAwesomeIcon
              icon={icon}
              className="h-5 w-5"
              aria-hidden="true"
            />
          </Text>
        </Component>
      </Tooltip>
    </div>
  );
}

export const Box = Object.assign(BoxRoot, {
  Item,
  FooterButton,
  FooterButtonItem,
  Avatar,
  Title,
  Subtitle,
});
