import { SocialNetwrokType } from "@common/features/Social";
import { apiUrl } from "./api";

declare const document: any;
declare const navigator: any;

export const isBrowser = typeof document !== "undefined";
export const isNative =
  !isBrowser &&
  typeof navigator !== "undefined" &&
  navigator.product === "ReactNative";

export function ucfirst<T extends string>(string: T) {
  return (string.charAt(0).toUpperCase() + string.slice(1)) as Capitalize<T>;
}

export function stripTags(data: string) {
  const regex = /(<([^>]+)>)/gi;
  return data.replace(regex, "");
}

export const roundNumber = (number?: number | string, float = 0) =>
  number
    ? float === 0
      ? Math.round(+number)
      : Math.round(+number * 10 * float) / (10 * float)
    : 0;

export function convertKeyValueToArray<T>(items?: { [key: string]: T } | T[]) {
  return items
    ? Object.keys(items).map((value: string | number) => ({
        value,
        label: (items as any)[value] as T,
      }))
    : [];
}

export function socialAssetLink(asset: string, type: SocialNetwrokType) {
  return asset &&
    import.meta.env.VITE_USE_IMAGE_PROXY &&
    ["InstagramOfficial", "Facebook", "Twitter"].includes(type)
    ? apiUrl + "/social-asset/social?i=" + encodeURIComponent(asset)
    : asset;
}

/**
 * Compares two software version numbers (e.g. "1.7.1" or "1.2b").
 *
 * This function was born in http://stackoverflow.com/a/6832721.
 *
 * @param {string} v1 The first version to be compared.
 * @param {string} v2 The second version to be compared.
 * @param {object} [options] Optional flags that affect comparison behavior:
 * <ul>
 *     <li>
 *         <tt>lexicographical: true</tt> compares each part of the version strings lexicographically instead of
 *         naturally; this allows suffixes such as "b" or "dev" but will cause "1.10" to be considered smaller than
 *         "1.2".
 *     </li>
 *     <li>
 *         <tt>zeroExtend: true</tt> changes the result if one version string has less parts than the other. In
 *         this case the shorter string will be padded with "zero" parts instead of being considered smaller.
 *     </li>
 * </ul>
 * @returns {number|NaN}
 * <ul>
 *    <li>0 if the versions are equal</li>
 *    <li>a negative integer iff v1 < v2</li>
 *    <li>a positive integer iff v1 > v2</li>
 *    <li>NaN if either version string is in the wrong format</li>
 * </ul>
 *
 */
export function versionCompare(
  v1: string,
  v2: string,
  options?: { zeroExtend?: boolean; lexicographical?: boolean },
) {
  const lexicographical = options && options.lexicographical;
  const zeroExtend = options && options.zeroExtend;
  let v1parts: string[] | number[] = v1.split(".");
  let v2parts: string[] | number[] = v2.split(".");

  function isValidPart(x: string) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) {
      v1parts.push("0");
    }
    while (v2parts.length < v1parts.length) {
      v2parts.push("0");
    }
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

export function isLightColor(color: string) {
  const rgb = hexToRgb(color);
  // https://github.com/bgrins/TinyColor/blob/13851a7f4950040d9ad8557c3a92d9f4d8d02843/tinycolor.js#L66

  return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 128;
}

function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (_m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
}

export function parseIntUndefined(x: any) {
  return x === undefined ? undefined : parseInt(x, 10);
}
