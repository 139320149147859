import React, { InputHTMLAttributes } from "react";
import { ShowUpgradeModalContext } from "@common/context/ThemeContext";
import { useHasMasterPermissions } from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { CheckPermissionRadioProps } from "@common/types";
import { Radio } from "../form";
import { Badge } from "../text";

export function CheckPermissionRadio({
  showLockIcon,
  permission,
  onChange,
  ...props
}: CheckPermissionRadioProps & InputHTMLAttributes<HTMLInputElement>) {
  const showUpgradeModal = React.useContext(ShowUpgradeModalContext);
  const hasPermission = useHasMasterPermissions(permission);
  return (
    <div className="flex items-center">
      {showLockIcon && !hasPermission && (
        <Badge
          circle
          variant="primary"
          padding="none"
          className="me-2 p-1"
          icon={regular("bolt")}
        />
      )}
      <Radio
        onChange={(e) => {
          if (hasPermission) onChange?.(e);
          else showUpgradeModal();
        }}
        {...props}
      />
    </div>
  );
}
