import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import AnimateHeight, { Height } from "react-animate-height";
import { regular } from "@common/helpers/fontawesome";
import { isRTL } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { Tooltip } from "../helpers";

export function SectionToggle({
  icon,
  hint,
  title,
  className,
  children,
  padding = "medium",
  defaultOpen,
  disabled,
  ...rest
}: SectionToggleProps & Omit<React.HTMLAttributes<HTMLDivElement>, "title">) {
  const [height, setHeight] = useState<Height>(defaultOpen ? "auto" : 0);

  useEffect(() => {
    setHeight(!disabled && defaultOpen ? "auto" : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <div
      className={classNames(
        "relative border-2",
        height !== "auto" && "border-transparent",
        className,
      )}
      {...rest}
    >
      <Switch.Group as="div">
        <Switch.Label
          as="div"
          className={classNames(
            "cursor-pointer select-none py-5",
            height === "auto" ? "px-6" : "",
          )}
        >
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <div className="flex items-center">
                <div className="me-2 flex items-center space-s-2">
                  {icon && (
                    <div className="flex h-8 w-8 items-center justify-center rounded-md bg-gray-100 p-2 dark:bg-gray-700">
                      {icon}
                    </div>
                  )}
                  <h3 className="font-semibold">{title}</h3>
                  {hint && (
                    <Tooltip tooltip={hint}>
                      <FontAwesomeIcon
                        icon={regular("circle-question")}
                        style={{
                          transform: isRTL ? "scaleX(-1)" : undefined,
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
                <Switch
                  disabled={disabled}
                  checked={height === "auto"}
                  onChange={() => setHeight(height === "auto" ? 0 : "auto")}
                  className={`${
                    height === "auto"
                      ? "bg-primary-500"
                      : "bg-gray-200 dark:bg-gray-400"
                  } relative inline-flex h-4 w-8 items-center rounded-full transition-colors`}
                >
                  <span
                    className={`${
                      height === "auto"
                        ? "ltr:translate-x-4 rtl:-translate-x-4"
                        : "ltr:translate-x-1 rtl:-translate-x-1"
                    } inline-block h-3 w-3 transform rounded-full bg-white transition-transform dark:bg-gray-200`}
                  />
                </Switch>
              </div>
            </div>
            <div
              className={
                "flex-shrink-0 transition" +
                (height === "auto"
                  ? " ltr:rotate-90 rtl:-rotate-90"
                  : " px-6 opacity-0")
              }
            >
              <FontAwesomeIcon
                icon={isRTL ? regular("angle-left") : regular("angle-right")}
              />
            </div>
          </div>
        </Switch.Label>
      </Switch.Group>
      <AnimateHeight
        duration={300}
        height={height} // see props documentation below
        contentClassName={
          padding === "large"
            ? "px-8 py-8"
            : padding === "medium"
              ? "py-4 px-4"
              : padding === "small"
                ? "px-2 py-2"
                : ""
        }
      >
        {children}
      </AnimateHeight>
    </div>
  );
}
