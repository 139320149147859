import twitter from "twitter-text";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { TwitterTimelineProps } from "@common/types";
import { Button, TextArea } from "@app/elements/form";

export default function TwitterTimeline({
  onChange,
  tweets,
}: TwitterTimelineProps) {
  return (
    <div className="relative space-y-4 ps-12 after:absolute after:inset-y-0 after:start-5 after:w-1 after:rounded-md after:bg-gray-400">
      {tweets.map((tweet, index) => (
        <div className="relative" key={index}>
          <TextArea
            value={tweet}
            autoHeight
            emojiPicker
            onInputChange={(content) => {
              const newTweets = [...tweets];
              newTweets[index] = content;
              onChange(newTweets);
            }}
            charLeft={280 - twitter.getTweetLength(tweet)}
          />
          <div className="absolute top-3 z-10 h-3 w-3 rounded-full border-2 border-white bg-gray-600 ltr:-left-8 rtl:-right-8" />
        </div>
      ))}
      <div className="relative">
        <Button
          variant="outline-light"
          className="w-full"
          onClick={() => onChange(tweets.concat([""]))}
          icon={regular("circle-plus")}
          padding="small"
        >
          {__("Add new tweet")}
        </Button>
        <div className="absolute top-3 z-10 h-3 w-3 rounded-full border-2 border-white bg-gray-600 ltr:-left-8 rtl:-right-8" />
      </div>
    </div>
  );
}
