import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { Controller, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import {
  useCreatePostTag,
  useDeletePostTags,
  useFetchPostTags,
  useUpdatePostTag,
} from "@common/features/Post";
import notify from "@common/helpers/NotificationManager";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { useFormError } from "@common/hooks";
import { PostTagModalProps } from "@common/types";
import { Button, Input, TextInput } from "@app/elements/form";
import { Confirm, Modal } from "@app/elements/modals";

const schema = yup.object({
  name: yup.string().required().label("Name"),
  color: yup.string().required().label("Background color"),
});

type FormType = yup.InferType<typeof schema>;

export default function TagModal({ onClose }: PostTagModalProps) {
  const [toggle, setToggle] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>();

  const { data: postTags } = useFetchPostTags();

  const {
    registerWithError: register,
    handleSubmit,
    reset,
    control,
  } = useFormError({
    resolver: yupResolver(schema),
    defaultValues: { name: "", color: "#000000" },
  });

  const createPostTag = useCreatePostTag();
  const updatePostTag = useUpdatePostTag();
  const deletePostTag = useDeletePostTags();

  useEffect(() => {
    if (activeIndex !== undefined) {
      if (postTags?.[activeIndex])
        reset({
          name: postTags[activeIndex].name,
          color: postTags[activeIndex].color,
        });
      else setActiveIndex(undefined);
    }
  }, [postTags, activeIndex, reset]);

  const onSubmit: SubmitHandler<FormType> = (data) => {
    const onSuccess = () => {
      notify.success(__("Changes saved!"));
      onClose();
    };

    activeIndex !== undefined && postTags?.[activeIndex]
      ? updatePostTag.mutate(
          { ...data, id: postTags?.[activeIndex].id },
          { onSuccess },
        )
      : createPostTag.mutate(data, { onSuccess });
  };

  return (
    <div className="space-y-4">
      <Modal.Title>
        {__(
          "Create labels for posts to make it easier to sort and filter posts",
        )}
      </Modal.Title>
      <div className="divide-y">
        {postTags &&
          postTags.map((tag, i) => (
            <div
              key={tag.id}
              className="flex min-w-0 flex-1 items-center px-2 py-1"
            >
              <Button
                variant="light"
                onClick={() => {
                  setActiveIndex(i);
                  setToggle(true);
                }}
                padding="none"
                className="me-2 p-1"
                icon={regular("pen")}
              />
              <i
                className="me-2 h-4 w-4"
                style={{
                  background: "#" + tag.color,
                }}
              />
              <div>{tag.name}</div>
              <Button
                variant="light"
                className="ms-auto"
                onClick={() =>
                  Confirm({
                    title: __("Are you sure to delete this?"),
                    okText: __("Yes, remove it"),
                    onOk: () => deletePostTag.mutate(tag.id),
                  })
                }
                icon={regular("trash")}
              />
            </div>
          ))}
      </div>
      {toggle ? (
        <form
          className="mt-2 space-y-4 border-t-2 pt-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            label={__("Name")}
            {...register("name")}
            className="max-w-lg"
          />

          <Controller
            name="color"
            control={control}
            render={({
              field: { value, onChange, ...field },
              formState: { errors },
            }) => (
              <Input label={__("Background color")} error={errors.color}>
                <ChromePicker
                  disableAlpha
                  color={value}
                  onChange={(color) => onChange(color.hex)}
                  {...field}
                />
              </Input>
            )}
          />

          <div className="flex space-s-2">
            <Button variant="primary" icon={regular("floppy-disk")}>
              {__("Save")}
            </Button>
            <Button variant="outline-light" onClick={() => setToggle(false)}>
              {__("Cancel")}
            </Button>
          </div>
        </form>
      ) : (
        <Button
          variant="primary"
          className="mx-auto"
          block
          onClick={() => {
            setToggle(true);
            reset();
          }}
          icon={regular("plus")}
        >
          {__("New")}
        </Button>
      )}
    </div>
  );
}
