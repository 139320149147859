import React, { useEffect } from "react";
import { useFetchMetric } from "@common/features/Insights";
import {
  useFilterMetricPermissions,
  useMasterPermissions,
} from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { isBrowser } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { ElementsGroup, EmptyPage } from "@app/elements/container";
import BestHour from "@app/pages/insights/BestHour";
import ChartGroup from "@app/pages/insights/ChartGroup";
import InsightsPosts from "@app/pages/insights/InsightsPosts";
import {
  InstagramOfficialSelector,
  InstagramOfficialSelectorLifetime,
} from "@app/pages/insights/selectors";
import { BoxNumbers } from "../components/BoxNumbers";
import { InsightsChartSection } from "../components/InsightsChartSection";
import { CommonSocialProps } from "../helpers/common";

function InstagramOfficial({
  account,
  filter: { customFrom, customTo, printPeriod },
}: CommonSocialProps) {
  const masterPermissions = useMasterPermissions();

  const { data, isLoading } = useFetchMetric(
    {
      period: printPeriod
        ? printPeriod
        : customFrom && customTo
          ? "custom"
          : "week",
      metric: useFilterMetricPermissions([
        "engagement",
        "impressions",
        "total_link_clicks",
        "num_posts_by_type",
        "online_followers",
        "page_follow_growth",
        "page_follow_total",
        "total_posts_sent",
        "profile_views",
        "sms_click",
        "phone_click",
        "email_click",
        "reach",

        "top_hashtag",
        "top_post",
        "top_story",
        "average_like",
        "average_comment",
      ]),
      customFrom:
        !printPeriod && customFrom && customTo ? customFrom : undefined,
      customTo: !printPeriod && customFrom && customTo ? customTo : undefined,
      accountId: account.id,
    },
    true,
    InstagramOfficialSelector,
  );

  const { data: lifeTime, isLoading: isLifeTimeLoading } = useFetchMetric(
    {
      period: "lifetime",
      metric: useFilterMetricPermissions(["country", "gender"]),
      accountId: account.id,
    },
    true,
    InstagramOfficialSelectorLifetime,
  );

  useEffect(() => {
    if (isBrowser) {
      if (printPeriod && !isLoading) {
        (window as any).print();
      }
    }
  }, [printPeriod, isLoading]);

  const rowOne = {
    page_follow_total: {
      title: __("Total audience"),
      hide: !masterPermissions.insights_audience_growth,
    },
    growth: {
      title: __("New followers"),
      hide: !masterPermissions.insights_audience_growth,
    },
    average_engagement: {
      title: __("Average Engagements"),
      hide: !masterPermissions.insights_engagement,
      scale: 2,
    },
    total_link_clicks: {
      title: __("Link Clicks"),
      hide: !masterPermissions.insights_summary,
      hint: __(
        "Total number of clicks on the website address of Instagram bio in the selected period of time",
      ),
    },
    profile_views: {
      title: __("Profile view"),
      hide: !masterPermissions.insights_summary,
      hint: __(
        "Overall number of profile visits in the selected period of time",
      ),
    },
    total_posts_sent: {
      title: __("Total published posts"),
      hide: !masterPermissions.insights_posts_publish,
    },
  };

  const rowTwo = {
    email_click: {
      title: __("Email click"),
      hide: !masterPermissions.insights_audience_growth,
      hint: __(
        "Total number of clicks on the E-mail button of Instagram bio in the selected period of time",
      ),
    },
    phone_click: {
      title: __("Phone number click"),
      hide: !masterPermissions.insights_audience_growth,
      hint: __(
        "Total number of clicks on the contact button of Instagram bio in the selected period of time",
      ),
    },
    sms_click: {
      title: __("SMS click"),
      hide: !masterPermissions.insights_engagement,
      hint: __(
        "Total number of clicks on the text messages button of Instagram bio in the selected period of time",
      ),
    },
    average_like: {
      title: __("Average like"),
      hide: !masterPermissions.insights_summary,
      hint: __(
        "Average number of likes for posts that are published in the selected period of time",
      ),
    },
    average_comment: {
      title: __("Average comment"),
      hide: !masterPermissions.insights_summary,
      hint: __(
        "Average number of comments for posts that are published in the selected period of time",
      ),
    },
    average_impressions: {
      title: __("Average impressions"),
      hide: !masterPermissions.insights_posts_publish,
      hint: __(
        "Average number of impression of the published content in the selected period of time",
      ),
    },
  };

  if (data?.emptyData) {
    return (
      <EmptyPage
        text={__("Data is being called, please wait a few minutes!")}
        icon={regular("hourglass-start")}
      />
    );
  }

  const isCustom = customFrom !== undefined && customTo !== undefined;

  return (
    <ElementsGroup>
      <BoxNumbers
        loading={isLoading}
        items={data?.total || {}}
        options={rowOne}
      />
      <ChartGroup>
        <InsightsChartSection
          title={__("Audience Growth")}
          loading={isLoading}
          chartOption={data?.growth}
          selector={InstagramOfficialSelector}
          metric="page_follow_growth"
          account={account}
          isCustom={isCustom}
        />
        <InsightsChartSection
          title={__("Group Impressions")}
          hint={__(
            "Overall number of impression of published posts in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.impressions}
          selector={InstagramOfficialSelector}
          metric="impressions"
          account={account}
          isCustom={isCustom}
        />
      </ChartGroup>
      <ChartGroup>
        <InsightsChartSection
          title={__("Group Engagement")}
          hint={__(
            "Overall interaction (like+comment) of users with published post in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.engagement}
          selector={InstagramOfficialSelector}
          metric="engagement"
          account={account}
          isCustom={isCustom}
        />

        <InsightsChartSection
          title={__("Reach")}
          hint={__(
            "Overall number of unique accounts that have seen any of published posts or stories in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.reach}
          selector={InstagramOfficialSelector}
          metric="reach"
          account={account}
          isCustom={isCustom}
        />
      </ChartGroup>
      <BoxNumbers
        loading={isLoading}
        items={data?.total || {}}
        options={rowTwo}
      />
      <ChartGroup>
        <InsightsChartSection
          title={__("Media Type")}
          loading={isLoading}
          chartOption={data?.mediatype}
          selector={InstagramOfficialSelector}
          metric="num_posts_by_type"
          account={account}
          isCustom={isCustom}
        />

        <InsightsChartSection
          title={__("Popular Hashtags")}
          hint={__(
            "Most viewed hashtags that are used in the caption of published posts in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.top_hashtag}
          selector={InstagramOfficialSelector}
          metric="top_hashtag"
          account={account}
          isCustom={isCustom}
          isEmpty={!data?.top_hashtag}
          emptyMessage={__("There is no post with hashtag")}
        />
      </ChartGroup>
      <ChartGroup>
        <InsightsChartSection
          title={__("Online followers by day")}
          hint={__(
            "Total number of online followers of your Instagram profile in different days of week, in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.online_follower}
          selector={InstagramOfficialSelector}
          metric="online_followers"
          account={account}
          isCustom={isCustom}
        />

        <InsightsChartSection
          title={__("Online followers by hours")}
          hint={__(
            "Total number of online followers of your Instagram profile in different times of day, in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.online_follower_hour}
          selector={InstagramOfficialSelector}
          metric="online_follower_hour"
          account={account}
          isCustom={isCustom}
        />
      </ChartGroup>
      {data?.bestHour && (
        <BestHour from={data.bestHour[0]} to={data.bestHour[1]} />
      )}
      <ChartGroup>
        <InsightsChartSection
          title={__("Gender")}
          hint={__(
            "Gender distribution of followers in the selected period of time",
          )}
          loading={isLifeTimeLoading}
          chartOption={lifeTime?.gender}
          metric="gender"
        />
        <InsightsChartSection
          title={__("Country")}
          hint={__(
            "Region distribution of followers in the selected period of time",
          )}
          loading={isLifeTimeLoading}
          chartOption={lifeTime?.country}
          metric="country"
        />
      </ChartGroup>
      <InsightsPosts account={account} posts={data?.top_post} />
      <InsightsPosts
        account={account}
        posts={data?.top_story}
        header={__("Top story")}
        likeText={__("Impressions")}
        commentText={__("Reach")}
      />
    </ElementsGroup>
  );
}

export default React.memo(InstagramOfficial);
