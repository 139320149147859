import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  BackButtonContext,
  SetBackButtonVisibilityContext,
  TitleContext,
} from "@common/context/ThemeContext";
import { regular } from "@common/helpers/fontawesome";
import { Button } from "@app/elements/form";

export default function NavBar() {
  const backButton = useContext(BackButtonContext);
  const setbackButtonVisibility = useContext(SetBackButtonVisibilityContext);
  const title = useContext(TitleContext);

  const history = useHistory();

  return (
    <>
      <header className="relative flex flex-shrink-0 items-center border-b bg-white dark:bg-dark-background md:hidden">
        <div className="flex h-16 flex-1 items-center">
          <Button
            className="absolute inset-y-0 end-0 md:hidden md:flex-shrink-0"
            onClick={() => {
              setbackButtonVisibility(false);
              history.push("/settings");
            }}
          >
            <FontAwesomeIcon icon={regular("gear")} className="h-6 w-6" />
          </Button>

          {backButton && (
            <Button
              onClick={() => history.goBack()}
              className="absolute inset-y-0 start-0 md:hidden md:flex-shrink-0"
            >
              <FontAwesomeIcon
                icon={regular("arrow-left")}
                className="h-6 w-6 rtl:rotate-180"
              />
            </Button>
          )}

          <div className="flex-1">
            <h1 className="text-center text-base text-gray-800 dark:text-white">
              {title}
            </h1>
          </div>
        </div>
      </header>
    </>
  );
}
