import { useReducer } from "react";
import { DefaultSelectValue } from "@common/interfaces/Api";
import { useSyncNavigationParams } from "@app/hooks";

const reducer = <T>(
  state: T,
  { _, ...action }: Partial<T> & { _?: "clear" },
) => {
  return _ === "clear"
    ? action
    : {
        ...state,
        ...action,
      };
};

export type Parser =
  | "date"
  | "datetime"
  | "array"
  | string[]
  | [string[]]
  | DefaultSelectValue[]
  | [DefaultSelectValue[]]
  | ((value: string) => any);

export function useFilterReducer<T extends Record<string, any>>(
  initialValue: T = {} as any,
  parser: { [key in keyof T]: Parser } | false = {} as T,
) {
  const [filter, setFilter] = useReducer(
    reducer as (state: T, action: Partial<T>) => T,
    initialValue,
  );

  useSyncNavigationParams(parser, filter, setFilter);

  return [filter, setFilter] as const;
}
