import moment from "moment-jalaali";
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Parser } from "@common/hooks";

export function useSyncNavigationParams<T extends Record<string, any>>(
  parser: { [key in keyof T]: Parser } | false = {} as T,
  filter: T,
  setFilter: React.Dispatch<T>,
  delay = 500,
) {
  const initialValue = useRef(filter);

  const parserRef = useRef(parser);

  const search = useLocation().search;
  const history = useHistory();

  const searchRef = useRef("");

  useEffect(() => {
    if (!parserRef.current) return;

    const timer = setTimeout(() => {
      const newParams = new URLSearchParams(searchRef.current);

      let needSetLocation = false;

      let value: any;

      Object.keys(filter).forEach((key) => {
        if (
          filter[key as keyof typeof filter] !== initialValue.current[key] &&
          (value = formatter(
            filter[key as keyof typeof filter],
            (parserRef.current as any)[key],
          ))
        ) {
          if (newParams.get(key) !== value) {
            needSetLocation = true;
            newParams.set(key, value);
          }
        } else if (newParams.has(key)) {
          needSetLocation = true;
          newParams.delete(key);
        }
      });

      if (needSetLocation) {
        // prevent run effect on next render
        searchRef.current = newParams.toString();

        if (searchRef.current) searchRef.current = "?" + searchRef.current;

        history.push({ search: newParams.toString() });
      }
    }, delay);
    return () => clearTimeout(timer);
  }, [filter, delay, setFilter, history]);

  useEffect(() => {
    if (!parserRef.current) return;

    if (searchRef.current === search) return;

    searchRef.current = search;

    const newParams = new URLSearchParams(search);

    const newFilter = { ...initialValue.current };

    newParams.forEach((value, key) => {
      if (parserRef.current)
        (newFilter as any)[key] = parse(value, parserRef.current[key]);
    });

    setFilter({ ...newFilter, _: "clear" });
  }, [search, delay, setFilter]);
}

function parse(value: string, parser: Parser) {
  if (typeof parser === "function") return parser(value);

  if (Array.isArray(parser)) {
    if (Array.isArray(parser[0])) {
      const ids = value.split(",");
      return (parser[0] as any[]).filter((item) =>
        ids.includes(typeof item === "string" ? item : item.value + ""),
      );
    }
    return (parser as any[]).find(
      (item) => (typeof item === "string" ? item : item.value + "") === value,
    );
  }

  switch (parser) {
    case "date":
      return moment(value, "YYYY-MM-DD");
    case "datetime":
      return moment(value, "YYYY-MM-DD HH:mm");
    case "array":
      return value ? value.split(",") : undefined;
    default:
      return value;
  }
}

function formatter(value: any, parser: Parser): string {
  if (typeof parser === "function") return parser(value);

  if (Array.isArray(parser)) {
    if (Array.isArray(parser[0])) {
      return value
        .map((value: any) => (typeof value === "string" ? value : value.value))
        .join(",");
    }

    return typeof value === "string" ? value : value.value;
  }

  switch (parser) {
    case "date":
      return value.format("YYYY-MM-DD");
    case "datetime":
      return value.format("YYYY-MM-DD HH:mm");
    case "array":
      return value ? value.join(",") : "";
    default:
      return value.toString();
  }
}
