import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { BestHourProps } from "@common/types";
import { Section } from "@app/elements/container";

export default function BestHour({ from, to }: BestHourProps) {
  return (
    <Section>
      <h2 className="flex items-center">
        <FontAwesomeIcon icon={regular("clock")} className="me-2" />
        {__("Best hour to publish post")}
      </h2>
      <p className="best-hour-message">
        {__(
          "Best time to publish post according to your online followers time in date range is hour %{from} and %{to}.",
          {
            from,
            to,
          },
        )}
      </p>
    </Section>
  );
}
