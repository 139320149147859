import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { usePostStore } from "@common/features/Post";
import { useSearchLocation } from "@common/features/Search";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { SearchResults, SectionToggle } from "@app/elements/container";
import { TextInput } from "@app/elements/form";

export default function LocationSection(props: Partial<SectionToggleProps>) {
  const [{ location: selected, selectedAccounts }, dispatch] = usePostStore(
    ({ selectedAccounts, location }) => ({
      selectedAccounts,
      location,
    }),
    true,
  );

  const [search, setSearch] = React.useState<string>();

  const { data: locations, isLoading } = useSearchLocation(
    selectedAccounts.map((item) => item.id),
    search,
    (locations) =>
      locations.map((item) => {
        return {
          name: item.name,
          description: item.description,
          value: item,
        };
      }),
  );

  return (
    <SectionToggle
      {...props}
      title={__("Location")}
      hint={__("Location Tag is available for: Instagram, Twitter")}
      icon={
        <FontAwesomeIcon className="h-6 w-6" icon={regular("location-dot")} />
      }
      defaultOpen={selected !== undefined}
    >
      <TextInput
        disabled={!!selected}
        icon={regular("location-dot")}
        placeholder={__("Type the location")}
        loading={isLoading}
        name="location_input"
        value={selected?.name || search || ""}
        onInputChange={(search) => setSearch(search)}
        onClear={
          selected
            ? () => dispatch({ type: "locationChanged", payload: undefined })
            : undefined
        }
      />
      {!selected && (
        <SearchResults
          loading={isLoading}
          items={locations || []}
          onSelect={(item) =>
            dispatch({ type: "locationChanged", payload: item })
          }
          searchPlaceholder={__("Searching for location")}
        />
      )}
    </SectionToggle>
  );
}
