import {
  recurringCounts,
  recurringTypes,
  usePostStore,
  useSavePost,
} from "@common/features/Post";
import { ucfirst } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { PostPreviewProps } from "@common/types";
import { ElementsGroup, Section } from "@app/elements/container";
import { Button, CheckBox, Radio, Select } from "@app/elements/form";
import { CheckPermissionRadio } from "@app/elements/permission/CheckPermissionRadio";
import PostDatePicker from "@app/pages/post/PostDatePicker";

export default function PostSaveSection({ postGroup }: PostPreviewProps) {
  const [state, dispatch] = usePostStore();

  const savePost = useSavePost(state, postGroup);

  const isEdit = postGroup !== undefined;

  return (
    <Section>
      <ElementsGroup>
        <ElementsGroup space={4}>
          <Radio
            name="is_scheduled"
            label={__("Send now")}
            checked={state.saveType === "instant"}
            onChange={() =>
              dispatch({
                type: "saveType",
                payload: "instant",
              })
            }
          />
          <CheckPermissionRadio
            name="is_scheduled"
            showLockIcon
            permission="post_schedule"
            label={__("Schedule")}
            checked={state.saveType === "schedule"}
            onChange={() =>
              dispatch({
                type: "saveType",
                payload: "schedule",
              })
            }
          />
          {state.saveType === "schedule" && (
            <ElementsGroup padding="medium">
              <PostDatePicker
                date={state.scheduleDate}
                onChange={(scheduleDate) =>
                  dispatch({ type: "schedule", payload: scheduleDate })
                }
              />
              <CheckBox
                name="recurring"
                label={__("Repeat post")}
                checked={state.recurring !== undefined}
                onChange={() =>
                  dispatch({
                    type: "toggleRecurring",
                  })
                }
              />
              {state.recurring && (
                <>
                  <Select
                    value={state.recurring.type}
                    options={recurringTypes}
                    onChange={(type) =>
                      dispatch({
                        type: "setRecurring",
                        payload: { type, max: state.recurring!.max },
                      })
                    }
                    name="recurring_type"
                    label={__("Repeat every")}
                    getLabel={(type) => __(ucfirst(type))}
                    getKey={(type) => type}
                  />
                  <Select
                    value={state.recurring.max}
                    options={recurringCounts}
                    onChange={(max) =>
                      dispatch({
                        type: "setRecurring",
                        payload: { type: state.recurring!.type, max },
                      })
                    }
                    name="recurring_count"
                    label={__("Number of repeat")}
                    getLabel={(count) => __("%{count} times", { count })}
                    getKey={(count) => count}
                  />
                </>
              )}
            </ElementsGroup>
          )}

          {(!isEdit || state.saveType === "draft") && (
            <Radio
              className="my-0.5"
              name="is_draft"
              label={__("Save to draft")}
              checked={state.saveType === "draft"}
              onChange={() =>
                dispatch({
                  type: "saveType",
                  payload: "draft",
                })
              }
            />
          )}
        </ElementsGroup>
        <ElementsGroup space="between" display="flex-row">
          <ElementsGroup />
          <Button
            type="submit"
            loading={savePost.isPending}
            onClick={() => savePost.mutate()}
            variant="primary"
            padding="small"
          >
            {isEdit && state.saveType !== "draft"
              ? __("Edit Post")
              : state.saveType === "schedule"
                ? __("Schedule the post")
                : state.saveType === "draft"
                  ? __("Save to draft")
                  : __("Post now")}
          </Button>
        </ElementsGroup>
      </ElementsGroup>
    </Section>
  );
}
