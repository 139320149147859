import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox, Listbox, Transition } from "@headlessui/react";
import classNames from "classnames";
import {
  Fragment,
  KeyboardEventHandler,
  ReactNode,
  forwardRef,
  useState,
} from "react";
import { regular } from "@common/helpers/fontawesome";
import { SelectProps } from "@common/types";
import { Loading, LoadingDots } from "../loading";
import { Text } from "../text";
import { getInputClassName } from "./Input";
import { Label } from "./Label";

type ListBoxType = typeof Listbox;

type Props<
  Value,
  isMulti extends boolean = false,
  isSearchable extends boolean = false,
> = SelectProps<Value, isMulti, isSearchable> & {
  className?: string;
  by?: (a: Value, b: Value) => boolean;
};

const SelectRoot = forwardRef(function SelectRoot<
  Value,
  isMulti extends boolean = false,
  isSearchable extends boolean = false,
>(
  {
    options,
    label,
    value,
    multiple,
    placeholder,
    getLabel,
    getKey,
    maxItemShow,
    error,
    children,
    searchable,
    searchFilter,
    loading,
    className,
    size,
    hideMenu,
    onSubmit,
    onInputChange,
    menuPlacement,
    success,
    autoWidth,
    ...props
  }: Props<Value, isMulti, isSearchable>,
  ref: any,
) {
  const [query, setQuery] = useState("");

  const isSelected = (option: Value) =>
    value
      ? multiple
        ? (value as Value[]).includes(option)
        : value === option
      : false;

  const getLabelInternal = getLabel || ((value: any) => value.label);

  const filteredOptions =
    query === ""
      ? options || []
      : (options || []).filter((option, index) => {
          return searchFilter
            ? searchFilter(query, option)
            : getLabelInternal(option, true, isSelected(option), index)
                .toLowerCase()
                .includes(query.toLowerCase());
        });

  const RootComponent: any = searchable ? Combobox : Listbox;

  const optionsList = filteredOptions.map((option, index) => (
    <Option
      key={getKey ? getKey(option) : (option as any).value}
      value={option}
      searchable={searchable}
    >
      {isSelected(option) && (
        <span className="absolute inset-y-0 end-0 flex items-center pe-4">
          <FontAwesomeIcon icon={regular("circle-check")} />
        </span>
      )}
      {getLabelInternal(option as any, true, isSelected(option), index)}
    </Option>
  ));

  const errorMessage = error
    ? typeof error === "string"
      ? error
      : typeof error === "object"
        ? error.message
        : false
    : false;

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.keyCode !== 13 || e.key !== "Enter") {
      if (e.key === "Backspace" && e.currentTarget?.value === "") {
        props.onChange(
          multiple ? (value as Value[]).slice(0, -1) : (undefined as any),
        );
      }

      return;
    }
    if (filteredOptions.length === 0) e.preventDefault();
    onSubmit?.(e.currentTarget.value);
    e.currentTarget.value = "";
    setQuery("");
  };

  return (
    <RootComponent
      ref={ref}
      value={value ? value : multiple ? [] : null}
      multiple={multiple}
      as="div"
      className={classNames(
        size === "large" ? "text-base" : "",
        !autoWidth && "w-full",
        className,
      )}
      {...props}
    >
      {({ open }: any) => (
        <>
          {label && (
            <RootComponent.Label as="div">
              <Label label={label} />
            </RootComponent.Label>
          )}
          <div className="relative h-full leading-normal">
            {searchable ? (
              <div
                className={classNames(
                  "relative h-full w-full cursor-default text-left shadow-sm",
                  !autoWidth && "block w-full",
                  getInputClassName(
                    error ? "danger" : success ? "success" : "default",
                  ),
                )}
              >
                <Combobox.Button
                  className="flex w-full ring-primary-500 focus-visible:border-primary-500 focus-visible:ring-1 dark:ring-primary-600 dark:focus-visible:border-primary-600"
                  as="div"
                >
                  {multiple ? (
                    <div className="flex w-full flex-wrap gap-2 overflow-hidden p-2">
                      {maxItemShow != 0 &&
                        (value as any).map((v: any, index: number) => {
                          if (maxItemShow != undefined) {
                            if (index > maxItemShow) {
                              return null;
                            }
                            if (index === maxItemShow) {
                              return (
                                <div
                                  className="item-center flex items-center rounded-md bg-gray-200 px-2 dark:bg-gray-600 dark:text-gray-100"
                                  key="last"
                                >
                                  ...
                                </div>
                              );
                            }
                          }

                          return (
                            <span
                              className="flex overflow-hidden rounded-md bg-gray-200 dark:bg-gray-600 dark:text-gray-100"
                              key={getKey ? getKey(v) : v.value}
                            >
                              <div className="flex items-center truncate px-2">
                                {getLabelInternal(
                                  v,
                                  false,
                                  isSelected(v as any),
                                  index,
                                )}
                              </div>
                              <div
                                className="flex cursor-pointer items-center border-s border-gray-400 p-1.5 hover:bg-gray-300 dark:hover:bg-gray-500"
                                onClick={() => {
                                  props.onChange(
                                    (value as Value[]).filter(
                                      (item) => item !== v,
                                    ) as any,
                                  );
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={regular("x")}
                                  className="h-2 w-2"
                                />
                              </div>
                            </span>
                          );
                        })}
                      <div
                        className={classNames(
                          "inline-grid",
                          (maxItemShow == 0 ||
                            (value as Value[]).length === 0) &&
                            "w-full",
                        )}
                      >
                        <Combobox.Input
                          className={classNames(
                            "col-span-1 col-start-1 row-span-1 row-start-1 rounded-md border-none p-0 focus:ring-0",
                            !autoWidth && "block w-full",
                            (maxItemShow == 0 ||
                              (value as Value[]).length === 0) &&
                              "w-full",
                            size === "large" ? "text-base" : "",
                            getInputClassName(
                              error
                                ? "danger"
                                : success
                                  ? "success"
                                  : "default",
                            ),
                          )}
                          displayValue={(option: any) =>
                            !multiple
                              ? getLabelInternal(
                                  option,
                                  false,
                                  isSelected(option as any),
                                  0,
                                )
                              : undefined
                          }
                          placeholder={
                            maxItemShow == 0 || (value as Value[]).length === 0
                              ? placeholder
                              : undefined
                          }
                          size={(value as Value[]).length !== 0 ? 1 : undefined}
                          onChange={(event) => {
                            setQuery(event.target.value);
                            onInputChange?.(event.target.value) &&
                              (event.target.value = "");
                          }}
                          onKeyDown={onKeyDown}
                        />
                        {open && (
                          <span
                            className={classNames(
                              "invisible col-span-1 col-start-1 row-span-1 row-start-1 text-right",
                              size === "large" ? "text-base" : "",
                            )}
                          >
                            {query}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <Combobox.Input
                        className={classNames(
                          "w-full rounded-md border-none py-2 pe-10 ps-3 focus:ring-0",
                          !autoWidth && "block w-full",
                          getInputClassName(
                            error ? "danger" : success ? "success" : "default",
                          ),
                        )}
                        displayValue={(option: any) =>
                          !multiple && option
                            ? getLabelInternal(
                                option,
                                false,
                                isSelected(option as any),
                                0,
                              )
                            : undefined
                        }
                        onChange={(event) => {
                          setQuery(event.target.value);
                          onInputChange?.(event.target.value) &&
                            (event.target.value = "");
                        }}
                        placeholder={placeholder}
                        onKeyDown={onKeyDown}
                      />
                    </>
                  )}
                  {loading && (
                    <div className="me-12 flex">
                      <LoadingDots />
                    </div>
                  )}
                  {hideMenu !== true && (
                    <div className="absolute inset-y-0 end-0 flex items-center pe-2">
                      <FontAwesomeIcon
                        icon={regular("chevron-down")}
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </Combobox.Button>
              </div>
            ) : (
              <>
                <Listbox.Button
                  className={classNames(
                    "relative h-full w-full cursor-pointer py-2 pe-10 ps-3 text-left shadow-sm",
                    !autoWidth && "block w-full",
                    getInputClassName(
                      error ? "danger" : success ? "success" : "default",
                    ),
                  )}
                >
                  {(Array.isArray(value) &&
                    value.length !== 0 &&
                    maxItemShow !== 0) ||
                  (!Array.isArray(value) &&
                    value !== undefined &&
                    value !== null) ? (
                    multiple ? (
                      <div className="flex space-s-2">
                        {(value as any).map((value: any, index: number) => {
                          if (maxItemShow) {
                            if (index > maxItemShow) {
                              return null;
                            }
                            if (index === maxItemShow) {
                              return (
                                <div
                                  className="item-center flex items-center rounded-md bg-gray-200 px-2 dark:bg-gray-500 dark:text-gray-100"
                                  key="last"
                                >
                                  ...
                                </div>
                              );
                            }
                          }

                          return (
                            <span
                              className="item-center flex items-center rounded-md bg-gray-200 px-2 dark:bg-gray-500 dark:text-gray-100"
                              key={getKey ? getKey(value) : value.value}
                            >
                              <span className="block truncate">
                                {getLabelInternal(
                                  value,
                                  false,
                                  isSelected(value as any),
                                  index,
                                )}
                              </span>
                            </span>
                          );
                        })}
                      </div>
                    ) : (
                      <span className="flex items-center">
                        <span className="block truncate">
                          {getLabelInternal(
                            value as any,
                            false,
                            isSelected(value as any),
                            0,
                          )}
                        </span>
                      </span>
                    )
                  ) : (
                    placeholder && (
                      <span className="flex items-center text-gray-500 dark:text-gray-400">
                        <span className="block truncate">{placeholder}</span>
                      </span>
                    )
                  )}
                  {hideMenu !== true && (
                    <span className="pointer-events-none absolute inset-y-0 end-0 ms-3 flex items-center pe-3">
                      <FontAwesomeIcon
                        icon={regular("chevron-down")}
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </Listbox.Button>
                {loading && <Loading />}
              </>
            )}

            {hideMenu !== true && (
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <RootComponent.Options
                  className={
                    "absolute z-[11] mt-1 max-h-56 w-full overflow-auto rounded-md bg-gray-50 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800" +
                    (menuPlacement === "top" ? " bottom-full" : "")
                  }
                >
                  {children ? children({ options: optionsList }) : optionsList}
                </RootComponent.Options>
              </Transition>
            )}
          </div>

          {errorMessage ? (
            <Text variant="danger" className="mt-2 text-xs">
              {errorMessage}
            </Text>
          ) : success ? (
            <Text variant="success" className="mt-2 text-xs">
              {success}
            </Text>
          ) : null}
        </>
      )}
    </RootComponent>
  );
});

function Option({
  searchable,
  children,
  ...props
}: Parameters<ListBoxType["Option"]>[0] & { searchable?: boolean }) {
  const RootComponent = searchable ? Combobox : Listbox;

  return (
    <RootComponent.Option
      className={({ active, selected }: { [key: string]: any }) =>
        classNames(
          selected
            ? "bg-gray-200 text-gray-700 dark:bg-gray-600 dark:text-white"
            : active
              ? "bg-gray-200 text-gray-700 dark:bg-gray-600 dark:text-white"
              : "text-gray-900 dark:text-white",
          "relative cursor-pointer select-none px-3 py-2",
        )
      }
      {...(props as any)}
    >
      <div className="flex items-center">
        <span className="ml-3 block w-full truncate">
          {children as ReactNode}
        </span>
      </div>
    </RootComponent.Option>
  );
}

interface ComponentSelect {
  <
    Value,
    isMulti extends boolean = false,
    isSearchable extends boolean = false,
  >(
    props: Props<Value, isMulti, isSearchable> & React.RefAttributes<any>,
  ): JSX.Element;
}

export const Select = Object.assign(SelectRoot as ComponentSelect, { Option });
