import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { AnchorHTMLAttributes } from "react";
import { Link, LinkProps } from "react-router-dom";
import { ButtonProps as ButtonPropsRoot } from "@common/types";
import { Loading } from "../loading";

export type ButtonProps = Omit<ButtonPropsRoot, "onClick"> & {
  as?: string;
  name?: string;
} & (AnchorHTMLAttributes<HTMLAnchorElement> | LinkProps);

// "focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-slate-400 focus:ring-offset-primary-500"
const variants = {
  primary: {
    color:
      "border border-transparent text-white bg-primary-500 hover:bg-primary-600 dark:bg-primary-600 dark:hover:bg-primary-700",
    focus: "ring-primary-300 dark:ring-primary-800",
  },
  success: {
    color:
      "border border-transparent text-white bg-green-700 hover:bg-green-800 hover:text-white dark:bg-green-600 dark:hover:bg-green-700",
    focus: "ring-green-300 dark:ring-green-800",
  },
  danger: {
    color:
      "border border-transparent text-white bg-red-700 hover:bg-red-800 dark:bg-red-600 dark:hover:bg-red-700 dark:ring-red-900",
    focus: "ring-red-300",
  },
  warning: {
    color:
      "border border-transparent text-white bg-yellow-400 hover:bg-yellow-500 ",
    focus: "ring-yellow-300 dark:ring-yellow-900",
  },
  info: {
    color:
      "border border-transparent text-white bg-sky-700 hover:bg-sky-800 dark:bg-sky-600 dark:hover:bg-sky-700 dark:ring-sky-800",
    focus: "ring-sky-300",
  },
  light: {
    color:
      "text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 dark:bg-dark-background dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600",
    focus: "ring-gray-200 dark:ring-gray-700",
  },
  text: {
    color:
      "border border-transparent text-gray-900 hover:border-gray-300 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:hover:border-gray-600",
    focus: "",
  },
  dark: {
    color:
      "text-white bg-gray-800 hover:bg-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-gray-700",
    focus: "ring-gray-300 dark:ring-gray-700",
  },
  gray: {
    color:
      "text-gray-900 bg-gray-100 border border-transparent hover:bg-gray-200 dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-500",
    focus: "ring-gray-50 dark:ring-gray-700",
  },
  "outline-primary": {
    color:
      "text-primary-500 hover:text-white border border-primary-500 hover:bg-primary-600",
    focus: "ring-primary-300 dark:ring-primary-800",
  },
  "outline-success": {
    color:
      "text-green-700 hover:text-white border border-green-700 hover:bg-green-800 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600",
    focus: "ring-green-300 dark:ring-green-800",
  },
  "outline-danger": {
    color:
      "text-red-700 hover:text-white border border-red-700 hover:bg-red-800 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600",
    focus: "ring-red-300 dark:ring-red-900",
  },
  "outline-warning": {
    color:
      "text-yellow-400 hover:text-white border border-yellow-400 hover:bg-yellow-500 dark:border-yellow-300 dark:text-yellow-300 dark:hover:text-white dark:hover:bg-yellow-400",
    focus: "ring-yellow-300 dark:ring-yellow-900",
  },
  "outline-info": {
    color:
      "text-sky-700 hover:text-white border border-sky-700 hover:bg-sky-800 dark:border-sky-500 dark:text-sky-500 dark:hover:text-white dark:hover:bg-sky-600",
    focus: "ring-sky-300 dark:ring-sky-800",
  },
  "outline-light": {
    color:
      "text-gray-900 hover:text-gray-800 border border-gray-300 hover:bg-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700",
    focus: "ring-gray-200 dark:ring-gray-700",
  },
  "outline-dark": {
    color:
      "text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600",
    focus: "ring-gray-300 dark:ring-gray-800",
  },
  link: {
    color: "text-primay-500",
    focus: "",
  },
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      variant,
      as,
      icon,
      iconPosition = "start",
      children,
      className,
      loading,
      rounded = true,
      center,
      padding = "medium",
      block,
      ...rest
    },
    ref,
  ) {
    const Component: any =
      as === "link"
        ? Link
        : as ||
          ("to" in rest && rest.to
            ? Link
            : "href" in rest && rest.href
              ? "a"
              : "button");

    return (
      <Component
        ref={ref}
        className={classNames(
          padding === "large"
            ? "px-3 py-3"
            : padding === "medium"
              ? "px-3 py-2"
              : padding === "small" && "px-2 py-1.5",
          block ? "flex" : "inline-flex",
          loading && "relative",
          "items-center transition duration-200 hover:opacity-70 focus-visible:outline-none focus-visible:ring-2",
          variant && variants[variant].focus,
          center && "justify-center",
          rounded && "rounded-md",
          className,
          (rest as any).disabled
            ? variant &&
                "cursor-not-allowed border border-gray-300 bg-gray-200 text-black"
            : variant && variants[variant].color,
        )}
        disabled={loading}
        {...rest}
      >
        {loading && (
          <div className="absolute -inset-px">
            <Loading />
          </div>
        )}
        {icon ? (
          children ? (
            <div className="flex items-center space-s-1">
              {iconPosition === "start" &&
                (Array.isArray(icon) ? <FontAwesomeIcon icon={icon} /> : icon)}
              <div>{children}</div>
              {iconPosition === "end" &&
                (Array.isArray(icon) ? <FontAwesomeIcon icon={icon} /> : icon)}
            </div>
          ) : Array.isArray(icon) ? (
            <FontAwesomeIcon icon={icon} />
          ) : (
            icon
          )
        ) : (
          children
        )}
      </Component>
    );
  },
);
