import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { meKeys } from "@common/features/User";
import { api } from "@common/helpers/api";
import { useDisposables } from "@app/hooks";
import { UserAccess } from "../interfaces";

export const userAccessesKeys = createQueryKeys("userAccesses");

export const useFetchUserAccesses = () =>
  useQuery({
    queryKey: userAccessesKeys._def,
    queryFn: async () => {
      const response = await api.get<UserAccess[]>("user-access");
      return response.data;
    },
  });

export const useSetUserInUserAccess = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (hash?: string) =>
      api.post("user-access/" + hash).then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries();
      return queryClient.refetchQueries(meKeys.data);
    },
  });
};

export const useChangeUser = () => {
  const disposable = useDisposables();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (user_id?: number) =>
      api.post("user-access", { user_id }).then((response) => response.data),
    onSuccess: async () => {
      await queryClient.refetchQueries(meKeys.data);

      disposable.nextFrame(() =>
        queryClient.resetQueries({
          predicate: (query) => query.queryKey !== meKeys.data.queryKey,
        }),
      );
    },
  });
};
