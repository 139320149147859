import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "@common/helpers/api";
import {
  deleteDataOnArray,
  insertDataOnArray,
  updateDataOnArray,
} from "@common/helpers/queries";
import { PostTag } from "../interfaces";

export const postGroupTagsKeys = createQueryKeys("postGroupTagsKeys");

export const useFetchPostTags = () =>
  useQuery({
    queryKey: postGroupTagsKeys._def,
    queryFn: async () => {
      const response = await api.get<PostTag[]>("post-group-tag");
      return response.data;
    },
  });

export const useDeletePostTags = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => api.delete(`post-group-tag/${id}`),
    onSuccess: (_data, variables) => {
      queryClient.setQueryData<PostTag[]>(postGroupTagsKeys._def, (previous) =>
        deleteDataOnArray(previous, variables),
      );

      queryClient.invalidateQueries({
        queryKey: postGroupTagsKeys._def,
        refetchType: "none",
      });
    },
  });
};

export const useCreatePostTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (postTag: { name: string; color: string }) =>
      api.post("post-group-tag", postTag).then((response) => response.data),
    onSuccess: (response) => {
      queryClient.setQueryData<PostTag[]>(postGroupTagsKeys._def, (previous) =>
        insertDataOnArray(previous, response),
      );

      queryClient.invalidateQueries({
        queryKey: postGroupTagsKeys._def,
        refetchType: "none",
      });
    },
  });
};

export const useUpdatePostTag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      ...postTag
    }: {
      id: number;
      name: string;
      color: string;
    }) =>
      api
        .put("post-group-tag/" + id, postTag)
        .then((response) => response.data),
    onSuccess: (response) => {
      queryClient.setQueryData<PostTag[]>(postGroupTagsKeys._def, (previous) =>
        updateDataOnArray(previous, response),
      );

      queryClient.invalidateQueries({
        queryKey: postGroupTagsKeys._def,
        refetchType: "none",
      });
    },
  });
};
