import { useEffect } from "react";
import { useRegisterSW } from "virtual:pwa-register/react";
import notify from "@common/helpers/NotificationManager";
import { __ } from "@common/helpers/i18n";
import { Button } from "@app/elements/form";

export function useUpdatePwa() {
  // check for updates every hour
  const period = 6 * 60 * 60 * 1000;

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (period <= 0) return;
      if (r?.active?.state === "activated") {
        registerPeriodicSync(period, swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener("statechange", (e) => {
          const sw = e.target as ServiceWorker;
          if (sw.state === "activated") registerPeriodicSync(period, swUrl, r);
        });
      }
    },
  });

  useEffect(() => {
    if (needRefresh) {
      const notification = notify.info({
        title: "",
        message: (
          <div>
            <div>
              {__("New version available, click on reload button to update.")}
            </div>
            <div className="flex space-s-4 mt-4">
              <Button
                variant="primary"
                onClick={() => {
                  updateServiceWorker(true);
                  setNeedRefresh(false);
                }}
              >
                {__("Update")}
              </Button>
            </div>
          </div>
        ),
        timeout: false,
        onClose: () => {
          setNeedRefresh(false);
        },
      });
      return () => notify.remove(notification);
    }
  }, [needRefresh, setNeedRefresh, updateServiceWorker]);
}

/**
 * This function will register a periodic sync check every hour, you can modify the interval as needed.
 */
function registerPeriodicSync(
  period: number,
  swUrl: string,
  r: ServiceWorkerRegistration,
) {
  if (period <= 0) return;

  setInterval(async () => {
    if ("onLine" in navigator && !navigator.onLine) return;

    const resp = await fetch(swUrl, {
      cache: "no-store",
      headers: {
        cache: "no-store",
        "cache-control": "no-cache",
      },
    });

    if (resp?.status === 200) await r.update();
  }, period);
}
