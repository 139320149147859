import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { __ } from "@common/helpers/i18n";
import { Button } from "../form";
import { Section } from "./Section";

export function EmptyPage({
  icon,
  text,
  showAddAccount,
  hideOnSmall,
  description,
}: {
  text: string;
  icon: [IconPrefix, IconName];
  showAddAccount?: boolean;
  hideOnSmall?: boolean;
  description?: string;
}) {
  return (
    <Section
      pure
      className={classNames(
        "m-4 flex-1 flex-col items-center space-y-4 bg-transparent py-16",
        hideOnSmall ? "hidden md:flex" : "flex",
      )}
      hasBackground={false}
    >
      <div className="mb-2 rounded-full bg-gray-200 dark:bg-gray-700">
        <FontAwesomeIcon className="p-8 text-6xl" icon={icon} />
      </div>
      <h3 className="mb-2 text-2xl font-bold">{text}</h3>
      <p className="text-sm">
        {description ??
          __("To use all features you have to connect at least one account.")}
      </p>
      {showAddAccount && (
        <Button to="/accounts/new" variant="primary" padding="medium">
          {__("Add new account")}
        </Button>
      )}
    </Section>
  );
}
