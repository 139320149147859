import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { CaptionModalProps } from "@common/types";
import { ElementsGroup } from "@app/elements/container";
import { Button, TextArea, TextInput } from "@app/elements/form";
import { Confirm, Modal } from "@app/elements/modals";
import {
  useCreateCaption,
  useDeleteCaption,
  useFetchCaptions,
} from "../queries/captions";

export function CaptionModal({
  type,
  onSelect,
  onClose,
  ...rest
}: CaptionModalProps) {
  const [captionNew, setCaptionNew] = useState(false);
  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");

  const { data } = useFetchCaptions(type);

  const deletecaption = useDeleteCaption();
  const createCaption = useCreateCaption(type);

  function resetState() {
    setCaptionNew(false);
    setTitle("");
    setCaption("");
  }

  function saveCaption() {
    createCaption.mutate({ title, caption }, { onSuccess: () => resetState() });
  }

  return (
    <Modal
      {...rest}
      onClose={() => {
        onClose?.();
        resetState();
      }}
      showCloseIcon
      padding="medium"
    >
      <ElementsGroup display="block">
        {captionNew ? (
          <>
            <Modal.Title>
              {type === "post" ? __("New caption") : __("New saved answer")}
            </Modal.Title>
            <TextInput
              label={__("Name")}
              name="captionTitle"
              value={title}
              onInputChange={(title) => setTitle(title)}
            />
            <TextArea
              label={type === "post" ? __("Caption") : __("Saved answer")}
              name="captionText"
              value={caption}
              onInputChange={(captionText) => setCaption(captionText)}
            />
            <Button
              onClick={saveCaption}
              className={"button button-medium linked mt-md button-successful"}
              icon={regular("floppy-disk")}
              variant="primary"
            >
              {__("Save")}
            </Button>
          </>
        ) : (
          <>
            <Modal.Title>
              {type === "post" ? __("Saved caption") : __("Saved answers")}
            </Modal.Title>
            {data &&
              data.map((item) => (
                <Button
                  variant="outline-light"
                  block
                  key={item.id}
                  rounded={false}
                  className="relative flex w-full cursor-pointer flex-col items-center px-1 py-5"
                  onClick={() => {
                    onSelect(item);
                    onClose();
                  }}
                >
                  <h3 className="text-base font-semibold">{item.title}</h3>
                  <div className="info">
                    <p className="whitespace-pre">{item.caption}</p>
                  </div>
                  <Button
                    variant="light"
                    padding="none"
                    className="absolute end-4 top-2 h-10 w-10"
                    center
                    onClick={(e) => {
                      Confirm({
                        title: __("Are you sure to delete this?"),
                        okText: __("Yes, remove it"),
                        onOk: () => deletecaption.mutate(item.id),
                      });
                      e.stopPropagation();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={regular("trash")}
                      className="h-4 w-4"
                    />
                  </Button>
                </Button>
              ))}
            <div>
              <Button
                variant="primary"
                className="mt-2"
                onClick={() => setCaptionNew(true)}
                icon={regular("plus")}
              >
                {type === "post" ? __("New caption") : __("New saved answer")}
              </Button>
            </div>
          </>
        )}
      </ElementsGroup>
    </Modal>
  );
}
