import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { roundNumber } from "@common/helpers/functions";
import { InsightsBox } from "@app/elements/insights";

export interface BoxNumbersProps<
  P extends {
    [key: string]: {
      title: string;
      hide?: boolean;
      hint?: string;
      postfix?: string;
      scale?: number;
    };
  },
> {
  items: {
    [key in keyof P]?: string | number;
  };
  options: P;
  loading: boolean;
}

export function BoxNumbers<
  P extends {
    [key: string]: {
      title: string;
      icon?: [IconPrefix, IconName];
      hide?: boolean;
      hint?: string;
      postfix?: string;
      scale?: number;
      stringValue?: boolean;
    };
  },
>({
  items,
  loading,
  options,
  ...rest
}: BoxNumbersProps<P> & React.ComponentProps<typeof InsightsBox>) {
  return (
    <InsightsBox {...rest}>
      {Object.entries(options).map(([key, item]) => (
        <InsightsBox.Item
          key={key}
          label={item.title}
          hint={item.hint}
          value={
            !item.hide
              ? (item.stringValue
                  ? items[key]
                  : roundNumber(items[key], item.scale)) + (item.postfix || "")
              : "-"
          }
          icon={item.icon}
          loading={loading}
        />
      ))}
    </InsightsBox>
  );
}
