import classNames from "classnames";
import { AnchorHTMLAttributes, useContext } from "react";
import { LinkProps } from "react-router-dom";
import { ShowUpgradeModalContext } from "@common/context/ThemeContext";
import { Permissions } from "@common/features/User";
import { useMasterPermissions } from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { CheckPermissionButtonProps } from "@common/types";
import { Button } from "../form";
import { Badge } from "../text";

export const CheckPermissionButton: (
  props: CheckPermissionButtonProps &
    (AnchorHTMLAttributes<HTMLAnchorElement> | LinkProps),
) => JSX.Element = ({
  showNoPermissionIcon,
  permission,
  variant,
  noPermissionVariant,
  onClick,
  to,
  href,
  children,
  className,
  ...props
}: any) => {
  const showUpgradeModal = useContext(ShowUpgradeModalContext);

  const masterPermissions = useMasterPermissions();
  const hasPermission =
    typeof permission === "string"
      ? masterPermissions[permission as keyof Permissions]
      : permission;
  return (
    <Button
      {...props}
      className={classNames(className, "relative")}
      variant={
        hasPermission || !noPermissionVariant ? variant : noPermissionVariant
      }
      to={hasPermission ? to : undefined}
      href={hasPermission ? href : undefined}
      onClick={(e: any) => {
        if (!hasPermission) {
          e.preventDefault();
          showUpgradeModal();
          return;
        }
        onClick?.(e);
      }}
    >
      {showNoPermissionIcon && !hasPermission && (
        <Badge
          circle
          variant="primary"
          padding="none"
          className="absolute -right-2 -top-2 p-1"
          icon={regular("bolt")}
        />
      )}
      {children}
    </Button>
  );
};
