import { useExtraPostState } from "@common/features/Post";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { SectionToggle } from "@app/elements/container";
import { SocialIcon } from "@app/elements/social";
import TwitterTimeline from "@app/pages/post/sections/TwitterTimeline";

export default function TwitterSection(props: Partial<SectionToggleProps>) {
  const [data, dispatch] = useExtraPostState("Twitter");
  return (
    <SectionToggle
      {...props}
      title={__("Threaded Tweet")}
      hint={__("Threaded is available for: Twitter")}
      icon={<SocialIcon social="Twitter" />}
      defaultOpen={(data.tweets?.length || 0) > 0}
    >
      <TwitterTimeline
        tweets={data.tweets || []}
        onChange={(tweets) => dispatch("tweets", tweets)}
      />
    </SectionToggle>
  );
}
