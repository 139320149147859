import { useState } from "react";
import noImage from "@common/assets/no-image-square.jpg";
import { AccountPost, useFetchAccountPost } from "@common/features/Social";
import { socialAssetLink } from "@common/helpers/functions";
import { MediaWithFallback } from "@app/elements/helpers";

export function AccountPostImage({
  accountPost,
  ...rest
}: {
  accountPost: AccountPost;
} & React.ComponentProps<typeof MediaWithFallback>) {
  const [hasError, setHasError] = useState(false);
  const { data: freshAccountPost } = useFetchAccountPost(
    hasError ? accountPost : undefined,
  );

  return (
    <MediaWithFallback
      src={socialAssetLink(
        (freshAccountPost || accountPost).image,
        accountPost.social,
      )}
      altImage={noImage}
      onError={() => {
        setHasError(true);
      }}
      {...rest}
    />
  );
}
