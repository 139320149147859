import { Tab as TabRoot } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { TabProps } from "@common/types";
import { Select } from "../form";

type TabRootType = typeof TabRoot;

function Group<T extends { value: string | number; label: ReactNode }>({
  children,
  tabs,
  onChange,
  selectedIndex,
  ...rest
}: TabProps<T>) {
  const [internalSelectedIndex, setInternalSelectedIndex] = useState<
    number | undefined
  >(undefined);

  const activeIndex = selectedIndex || internalSelectedIndex;

  return (
    <>
      <TabRoot.Group
        selectedIndex={activeIndex}
        {...rest}
        onChange={(index) => {
          setInternalSelectedIndex(index);
          onChange?.(tabs[index]);
        }}
      >
        <>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <Select
              onChange={(tab) => {
                setInternalSelectedIndex(tabs.findIndex((t) => t === tab));
                onChange?.(tab!);
              }}
              options={tabs}
              value={activeIndex ? tabs[activeIndex] : undefined}
              className="block w-full rounded-md"
            />
          </div>
          <div className="hidden sm:block">
            <TabRoot.List className="relative z-0 flex rounded-lg divide-s">
              {tabs.map((tab, index) => (
                <TabRoot
                  key={tab.value}
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? "text-gray-900"
                        : "text-gray-500 hover:text-gray-700",
                      index === 0 ? "rounded-ts-md" : "",
                      index === tabs.length - 1 ? "rounded-te-md" : "",
                      "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center font-semibold hover:bg-gray-50 focus:z-10 focus:outline-none",
                    )
                  }
                >
                  {({ selected }) => (
                    <>
                      <span>{tab.label}</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          selected ? "bg-primary-500" : "bg-gray-200",
                          "absolute inset-x-0 bottom-0 h-0.5",
                        )}
                      />
                    </>
                  )}
                </TabRoot>
              ))}
            </TabRoot.List>
          </div>
          {children}
        </>
      </TabRoot.Group>
    </>
  );
}

function Panels(props: Parameters<TabRootType["Panels"]>[0]) {
  return <TabRoot.Panels {...props} />;
}

function Panel(props: Parameters<TabRootType["Panel"]>[0]) {
  return <TabRoot.Panel {...props} />;
}

export const Tab = Object.assign(Group, { Panels, Panel });
