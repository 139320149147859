import React, { useEffect } from "react";
import { useFetchMetric } from "@common/features/Insights";
import {
  useFilterMetricPermissions,
  useMasterPermissions,
} from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { isBrowser } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { ElementsGroup, EmptyPage } from "@app/elements/container";
import BestHour from "@app/pages/insights/BestHour";
import ChartGroup from "@app/pages/insights/ChartGroup";
import InsightsPosts from "@app/pages/insights/InsightsPosts";
import { TwitterSelector } from "@app/pages/insights/selectors";
import { BoxNumbers } from "../components/BoxNumbers";
import { InsightsChartSection } from "../components/InsightsChartSection";
import { CommonSocialProps } from "../helpers/common";

function Twitter({
  account,
  filter: { customFrom, customTo, printPeriod },
}: CommonSocialProps) {
  const masterPermissions = useMasterPermissions();

  const { data, isLoading } = useFetchMetric(
    {
      period: printPeriod
        ? printPeriod
        : customFrom && customTo
          ? "custom"
          : "week",
      metric: useFilterMetricPermissions([
        "engagement",
        "num_posts_by_type",
        "page_follow_growth",
        "page_follow_total",
        "total_posts_sent",
        "share_total",
        "top_hashtag",
        "top_post",
        "sum_share",
        "average_post_daily",
      ]),
      customFrom:
        !printPeriod && customFrom && customTo ? customFrom : undefined,
      customTo: !printPeriod && customFrom && customTo ? customTo : undefined,
      accountId: account.id,
    },
    true,
    TwitterSelector,
  );

  useEffect(() => {
    if (isBrowser) {
      if (printPeriod && !isLoading) {
        (window as any).print();
      }
    }
  }, [printPeriod, isLoading]);

  const rowOne = {
    page_follow_total: {
      title: __("Total audience"),
      hide: !masterPermissions.insights_audience_growth,
    },
    growth: {
      title: __("New followers"),
      hide: !masterPermissions.insights_audience_growth,
    },
    average_engagement: {
      title: __("Average Engagements"),
      hide: !masterPermissions.insights_engagement,
    },
    sum_share: {
      title: __("Retweet"),
      hide: !masterPermissions.insights_posts_publish,
    },
    average_post_daily: {
      title: __("Average tweet daily"),
      hide: !masterPermissions.insights_posts_publish,
    },
    total_posts_sent: {
      title: __("Total tweet"),
      hide: !masterPermissions.insights_posts_publish,
    },
  };

  if (data?.emptyData) {
    return (
      <EmptyPage
        text={__("Data is being called, please wait a few minutes!")}
        icon={regular("hourglass-half")}
      />
    );
  }

  const isCustom = customFrom !== undefined && customTo !== undefined;
  return (
    <ElementsGroup>
      <BoxNumbers
        loading={isLoading}
        items={data?.total || {}}
        options={rowOne}
      />
      <ChartGroup>
        <InsightsChartSection
          title={__("Audience Growth")}
          loading={isLoading}
          chartOption={data?.growth}
          selector={TwitterSelector}
          metric="page_follow_growth"
          account={account}
          isCustom={isCustom}
        />

        <InsightsChartSection
          title={__("Group Engagement")}
          hint={__(
            "Overall interaction (like+comment) of users with published post in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.engagement}
          selector={TwitterSelector}
          metric="engagement"
          account={account}
          isCustom={isCustom}
        />
      </ChartGroup>

      <ChartGroup>
        <InsightsChartSection
          title={__("Media Type")}
          loading={isLoading}
          chartOption={data?.mediatype}
          selector={TwitterSelector}
          metric="num_posts_by_type"
          account={account}
          isCustom={isCustom}
        />

        <InsightsChartSection
          title={__("Retweet")}
          hint={__(
            "Total number of retweets of published tweets in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.share_total}
          selector={TwitterSelector}
          metric="share_total"
          account={account}
          isCustom={isCustom}
        />
      </ChartGroup>

      <ChartGroup>
        <InsightsChartSection
          title={__("Popular Hashtags")}
          hint={__(
            "Most viewed hashtags that are used in the caption of published posts in the selected period of time",
          )}
          chartOption={data?.top_hashtag}
          isEmpty={!data?.top_hashtag}
          emptyMessage={__("There is no post with hashtag")}
          selector={TwitterSelector}
          metric="top_hashtag"
          account={account}
          loading={isLoading}
          isCustom={isCustom}
        />

        <InsightsChartSection
          title={__("Mention/comment")}
          hint={__(
            "Total number of received mentions and comments in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.mention}
          selector={TwitterSelector}
          metric="mention"
          account={account}
          isCustom={isCustom}
        />
      </ChartGroup>

      {data?.bestHour && (
        <BestHour from={data.bestHour[0]} to={data.bestHour[1]} />
      )}

      <InsightsPosts account={account} posts={data?.top_post} />
    </ElementsGroup>
  );
}

export default React.memo(Twitter);
