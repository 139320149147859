import {
  Grammarly,
  GrammarlyButton,
  GrammarlyEditorPlugin,
} from "@grammarly/editor-sdk-react";
import React, { useImperativeHandle, useRef } from "react";
import { __ } from "@common/helpers/i18n";
import { EditorProps } from "@common/types";
import "@app/assets/editor.css";
import ReactQuill from "@app/helpers/ReactQuill";

const formats = {
  all: ["bold", "italic", "link"],
  none: ["link"],
};

const modules = {
  magicUrl: true,
  toolbar: false,
};

export const Editor = React.forwardRef<ReactQuill, EditorProps>(function Editor(
  { canFormat, html, onChange, grammarly, readOnly, onChangeSelection },
  ref,
) {
  const quillRef = useRef<ReactQuill>(null);
  useImperativeHandle(ref, () => quillRef.current!);

  return grammarly ? (
    <Grammarly clientId={grammarly}>
      {/* Wrap the rich text editor with <GrammarlyEditorPlugin> to add Grammarly suggestions  */}
      <GrammarlyEditorPlugin>
        {/* Add a TinyMCE rich text editor */}
        <ReactQuill
          placeholder={__("Write a caption")}
          modules={modules}
          formats={canFormat ? formats.all : formats.none}
          style={{ height: 250 }}
          ref={quillRef}
          onChange={(content, _delta, _source, editor) => {
            onChange(content, editor.getText());
          }}
          onChangeSelection={onChangeSelection}
          value={html}
          preserveWhitespace
          readOnly={readOnly}
        />
        <GrammarlyButton className="absolute bottom-4 end-4" />
      </GrammarlyEditorPlugin>
    </Grammarly>
  ) : (
    <ReactQuill
      placeholder={__("Write a caption")}
      modules={modules}
      formats={canFormat ? formats.all : formats.none}
      style={{ height: 250 }}
      ref={quillRef}
      onChange={(content, _delta, _source, editor) => {
        onChange(content, editor.getText());
      }}
      onChangeSelection={onChangeSelection}
      value={html}
      preserveWhitespace
      readOnly={readOnly}
    />
  );
});

export default Editor;
