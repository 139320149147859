import classNames from "classnames";
import { ImgHTMLAttributes, useState } from "react";
import { MediaWithFallbackProps } from "@common/types";

export const MediaWithFallback = ({
  altImage,
  className,
  src,
  onError,
  type: _type = "image",
  ...props
}: ImgHTMLAttributes<HTMLImageElement | HTMLVideoElement> &
  MediaWithFallbackProps) => {
  const [showAlt, setShowAlt] = useState<boolean>(true);
  const [type, setType] = useState<"image" | "video">(
    _type === "auto" ? "image" : _type,
  );

  return (
    <>
      {showAlt &&
        (typeof altImage === "string" ? (
          <img className={className} {...props} src={altImage} />
        ) : (
          altImage
        ))}

      {type === "image" ? (
        <img
          className={classNames(showAlt && "hidden", className)}
          {...props}
          onLoad={() => {
            setShowAlt(false);
          }}
          src={src}
          onError={(e) => {
            onError?.(e);
            if (_type === "auto") {
              setType("video");
              return;
            }
            if (altImage) {
              setShowAlt(true);
            }
          }}
        />
      ) : (
        <video
          className={classNames(showAlt && "hidden", className)}
          {...props}
          onLoadedData={() => {
            setShowAlt(false);
          }}
          src={src}
          onError={(e) => {
            onError?.(e);
            if (altImage) {
              setShowAlt(true);
            }
          }}
          controls
        />
      )}
    </>
  );
};
