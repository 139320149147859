import i18n from "i18n-js";
import memoize from "lodash/memoize";
import moment from "moment-jalaali";
import { setLocale } from "yup";

let locale = "en";

export let isRTL = false;

export function setIsRTL(_isRTL: boolean) {
  isRTL = _isRTL;
  if (isRTL) {
    moment.loadPersian({ dialect: "persian-modern" });
  }
  locale = isRTL ? "fa" : "en";
  moment.locale(locale);
}

export let language: string = "fa-IR"; //"fa-IR" | "en-US"

export function setLanguage(_language: string) {
  language = _language;
}

export let timezone = "";

export function setTimeZone(_timezone: string) {
  timezone = _timezone;
}

export const __ = memoize(
  (
    key: keyof typeof import("@app/assets/locale/fa-IR.json"),
    config: any = undefined,
  ) => i18n.t(key, { defaultValue: key, scope: "", ...config }),
  (key, config = undefined) => (config ? key + JSON.stringify(config) : key),
);

// set i18n-js config
i18n.locale = "default";
i18n.defaultSeparator = "::";

function resolveFieldName(schema: { path: string; label: string }) {
  return __((schema.label || "fields." + schema.path) as any);
}

setLocale({
  mixed: {
    default: (schema) =>
      __("%{path} is invalid", { path: resolveFieldName(schema) }),
    required: (schema) =>
      __("%{path} is a required field", {
        path: resolveFieldName(schema),
      }),
    defined: (schema) =>
      __("%{path} must be defined", { path: resolveFieldName(schema) }),
    notNull: (schema) =>
      __("%{path} cannot be null", { path: resolveFieldName(schema) }),
    oneOf: (schema) =>
      __("%{path} must be one of the following values: %{values}", {
        path: resolveFieldName(schema),
        values: schema.values,
      }),
    notOneOf: (schema) =>
      __("%{path} must not be one of the following values: %{values}", {
        path: resolveFieldName(schema),
        values: schema.values,
      }),
    notType: (schema) =>
      __("%{path} must be a %{type}", {
        path: resolveFieldName(schema),
        type: __(("types." + schema.type) as any),
      }),
  },
  string: {
    length: (schema) =>
      __("%{path} must be exactly %{length} characters", {
        path: resolveFieldName(schema),
        length: schema.length,
      }),
    min: (schema) =>
      __("%{path} must be at least %{min} characters", {
        path: resolveFieldName(schema),
      }),
    max: (schema) =>
      __("%{path} must be at most %{max} characters", {
        path: resolveFieldName(schema),
      }),
    matches: (schema) =>
      __('%{path} must match the following: "%{regex}"', {
        path: resolveFieldName(schema),
        regex: schema.regex,
      }),
    email: (schema) =>
      __("%{path} must be a valid email", {
        path: resolveFieldName(schema),
      }),
    url: (schema) =>
      __("%{path} must be a valid URL", {
        path: resolveFieldName(schema),
      }),
    uuid: (schema) =>
      __("%{path} must be a valid UUID", {
        path: resolveFieldName(schema),
      }),
    trim: (schema) =>
      __("%{path} must be a trimmed string", {
        path: resolveFieldName(schema),
      }),
    lowercase: (schema) =>
      __("%{path} must be a lowercase string", {
        path: resolveFieldName(schema),
      }),
    uppercase: (schema) =>
      __("%{path} must be a upper case string", {
        path: resolveFieldName(schema),
      }),
  },
  number: {
    min: (schema) =>
      __("%{path} must be greater than or equal to %{min}", {
        path: resolveFieldName(schema),
        min: schema.min,
      }),
    max: (schema) =>
      __("%{path} must be less than or equal to %{max}", {
        path: resolveFieldName(schema),
        max: schema.max,
      }),
    lessThan: (schema) =>
      __("%{path} must be less than %{less}", {
        path: resolveFieldName(schema),
        less: schema.less,
      }),
    moreThan: (schema) =>
      __("%{path} must be greater than %{more}", {
        path: resolveFieldName(schema),
        more: schema.more,
      }),
    positive: (schema) =>
      __("%{path} must be a positive number", {
        path: resolveFieldName(schema),
      }),
    negative: (schema) =>
      __("%{path} must be a negative number", {
        path: resolveFieldName(schema),
      }),
    integer: (schema) =>
      __("%{path} must be an integer", { path: resolveFieldName(schema) }),
  },
  date: {
    min: (schema) =>
      __("%{path} field must be later than %{min}", {
        path: resolveFieldName(schema),
        min: schema.min,
      }),
    max: (schema) =>
      __("%{path} field must be at earlier than %{max}", {
        path: resolveFieldName(schema),
        max: schema.max,
      }),
  },
  boolean: {
    isValue: (schema) =>
      __("%{path} field must be %{value}", {
        path: resolveFieldName(schema),
        value: schema.value,
      }),
  },
  object: {
    noUnknown: (schema) =>
      __("%{path} field has unspecified keys: %{unknown}", {
        path: resolveFieldName(schema),
        unknown: schema.unknown,
      }),
  },
  array: {
    min: (schema) =>
      __("%{path} field must have at least %{min} items", {
        path: resolveFieldName(schema),
        min: schema.min,
      }),
    max: (schema) =>
      __("%{path} field must have less than or equal to %{max} items", {
        path: resolveFieldName(schema),
        max: schema.max,
      }),
    length: (schema) =>
      __("%{path} must have %{length} items", {
        path: resolveFieldName(schema),
        length: schema.length,
      }),
  },
});

export function formatDate(
  from: string | moment.Moment,
  format = isRTL ? "jD jMMMM jYYYY" : "D MMMM YYYY",
) {
  if (isRTL) {
    try {
      if (typeof from === "string") {
        return moment
          .utc(from)
          .utcOffset(timezone)
          .locale(locale)
          .format(format);
      } else {
        return from.locale(locale).format(format);
      }
    } catch (error) {
      return moment().locale(locale).format(format);
    }
  } else {
    try {
      if (typeof from === "string") {
        return moment
          .utc(from)
          .utcOffset(timezone)
          .locale(locale)
          .format(format);
      } else {
        return from.format(format);
      }
    } catch (error) {
      return moment().format(format);
    }
  }
}

export const formatDateTime = (
  from: string | moment.Moment,
  format = isRTL ? "jD jMMMM jYYYY HH:mm" : "D MMMM YYYY HH:mm",
) => {
  return formatDate(from, format);
};

export const formatFromNow = (
  from: string | moment.Moment,
  format = isRTL ? "dddd - jD jMMMM jYYYY HH:mm" : "dddd - D MMMM YYYY HH:mm",
) => {
  try {
    if (typeof from === "string") {
      from = moment.utc(from).utcOffset(timezone).locale(locale);
    }
  } catch (error) {
    from = moment().locale(locale);
  }

  if (isRTL) {
    from.locale(locale);
  }

  return Math.abs(from.diff(moment.now(), "days")) > 7
    ? formatDate(from, format)
    : from.fromNow();
};

export const toMoment = (
  from: string | moment.Moment | number,
  format = "YYYY/MM/DD",
) => {
  try {
    return moment.utc(from, format).utcOffset(timezone).locale(locale);
  } catch (error) {
    return moment().utc().utcOffset(timezone).locale(locale);
  }
};

export const formatPrice = (price: string | number, currency: string) =>
  currency === "IRR"
    ? __("price.IRR", {
        price: formatMoney(isRTL ? +price / 10 : price, 0, "", ","),
      })
    : __(("price." + currency) as any, { price: formatMoney(+price) });

export function formatMoney(
  amount: string | number,
  decimalCount = 2,
  decimal = ".",
  thousands = ",",
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = +amount < 0 ? "-" : "";

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(+amount - +i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    return amount + "";
  }
}

export function convertDateToUTC(date: moment.Moment) {
  // Current timezone's offset. Minutes offset from UTC
  const offset = date.utcOffset();

  // Convert selected date to UTC
  date = date.utcOffset(timezone);

  // Adjust the time to.
  return date.add(offset - +timezone, "minutes");
}

export function IntlDateFormat(
  date: Date | number,
  options?: Intl.DateTimeFormatOptions,
) {
  return new Intl.DateTimeFormat(
    isRTL ? "fa-IR-u-nu-latn" : "en-us",
    options,
  ).format(date);
}

export function IntlDateFormatParts(
  date: Date | number,
  options?: Intl.DateTimeFormatOptions,
) {
  return new Intl.DateTimeFormat(isRTL ? "fa-IR-u-nu-latn" : "en-us", options)
    .formatToParts(date)
    .reduce<{ [key in Intl.DateTimeFormatPartTypes]?: string }>((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
}

export function makeDate(
  year: number,
  month = 0,
  day = 0,
  hour = 0,
  minute = 0,
  second = 0,
) {
  return isRTL
    ? moment(
        `${year}/${("0" + month).slice(-2)}/${("0" + day).slice(-2)} ${(
          "0" + hour
        ).slice(-2)}:${("0" + minute).slice(-2)}:${("0" + second).slice(-2)}`,
        "jYYYY/jMM/jDD hh:mm:ss",
      )
    : moment(
        `${year}/${("0" + month).slice(-2)}/${("0" + day).slice(-2)} ${(
          "0" + hour
        ).slice(-2)}:${("0" + minute).slice(-2)}:${("0" + second).slice(-2)}`,
        "YYYY/MM/DD hh:mm:ss",
      );
}
