import React, { useEffect } from "react";
import { socialAssetLink } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { SearchResultsProps } from "@common/types";
import { Button } from "../form";
import { Text } from "../text";

export function SearchResults<T>({
  items,
  loading,
  onSelect,
  searchPlaceholder,
}: SearchResultsProps<T>) {
  const [showMore, setShowMore] = React.useState(false);

  useEffect(() => {
    setShowMore(false);
  }, [items]);

  return (
    <div className="flex flex-col divide-y p-4">
      {loading ? (
        <Text variant="muted">{searchPlaceholder || __("Searching...")}</Text>
      ) : (
        items.length !== 0 && (
          <>
            {items.map((item, index) => (
              <Button
                padding="small"
                rounded={false}
                center={false}
                className="text-start"
                key={index}
                style={{
                  display: showMore || index < 3 ? undefined : "none",
                }}
                onClick={() => onSelect(item)}
              >
                {item.image && (
                  <img
                    src={socialAssetLink(item.image, "InstagramOfficial")}
                    className="me-4 h-8 w-8 rounded-full bg-gray-200 dark:bg-gray-600"
                    alt=""
                  />
                )}
                <div className="flex flex-col">
                  <span>{item.name}</span>
                  <Text variant="muted">{item.description}</Text>
                </div>
              </Button>
            ))}
            {!showMore && items.length >= 3 && (
              <Button
                padding="small"
                rounded={false}
                center={false}
                className="text-start"
                onClick={() => setShowMore(true)}
              >
                {__("+%{number} more", {
                  number: items.length - 3,
                })}
              </Button>
            )}
          </>
        )
      )}
    </div>
  );
}
