import { createContext, useCallback, useContext, useRef } from "react";
import { useSyncExternalStoreWithSelector } from "use-sync-external-store/with-selector";

export default function createStore<TState, TAction>(
  reducer: (state: TState, action: TAction) => TState,
  initialState: TState,
) {
  function useStoreData() {
    const store = useRef(initialState);

    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set<() => void>());

    const dispatch = useCallback((action: TAction) => {
      store.current = reducer(store.current, action);
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      dispatch,
      subscribe,
    };
  }

  const StoreContext = createContext<ReturnType<typeof useStoreData> | null>(
    null,
  );

  function Provider({ children }: { children: React.ReactNode }) {
    return (
      <StoreContext.Provider value={useStoreData()}>
        {children}
      </StoreContext.Provider>
    );
  }

  function useStore<SelectorOutput = TState>(
    selector: (store: TState) => SelectorOutput = (store) => store as any,
    shallowEqual = false,
  ) {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Store not found");
    }

    const state = useSyncExternalStoreWithSelector(
      store.subscribe,
      store.get,
      store.get,
      selector,
      shallowEqual ? shallowCompare : undefined,
    );

    return [state, store.dispatch] as const;
  }

  return {
    Provider,
    useStore,
  };
}

const shallowCompare = (obj1: any, obj2: any) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    (key) =>
      Object.prototype.hasOwnProperty.call(obj2, key) &&
      obj1[key] === obj2[key],
  );
