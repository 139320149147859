import { useEffect, useState } from "react";
import { useExtraPostState, usePostStore } from "@common/features/Post";
import { useSearchPeople } from "@common/features/Search";
import { regular } from "@common/helpers/fontawesome";
import { parseIntUndefined } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { useEvent } from "@common/hooks";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { CheckBox, Select, TextInput } from "@app/elements/form";
import { Tooltip } from "@app/elements/helpers";
import { SocialIcon } from "@app/elements/social";
import { Text } from "@app/elements/text";
import { DropBox } from "@app/features/filemanager";

export default function ReelsSection(props: Partial<SectionToggleProps>) {
  const [coverToggle, setCoverToggle] = useState(false);
  const [data, dispatch] = useExtraPostState("InstagramOfficial");

  const [selectedAccounts] = usePostStore(
    ({ selectedAccounts }) => selectedAccounts,
  );

  const [search, setSearch] = useState<string>();

  const { data: searchPeoples, isLoading: isSearching } = useSearchPeople(
    (selectedAccounts || []).map((item) => item.id),
    selectedAccounts !== undefined ? search : undefined,
  );

  const setTag = useEvent(() => {
    if (searchPeoples) {
      setSearch(undefined);
      if (searchPeoples.length > 0)
        dispatch("reels_tags", [
          ...(data.reels_tags || []),
          { username: searchPeoples[0].username },
        ]);
    }
  });

  useEffect(() => {
    setTag();
  }, [searchPeoples, setTag]);

  return (
    <SectionToggle
      {...props}
      title={__("Instagram reels")}
      hint={__("Instagram reels only available for Instagram and video")}
      icon={<SocialIcon social="InstagramOfficial" />}
      defaultOpen={
        data.reels_sharetofeed !== undefined ||
        data.reels_tags !== undefined ||
        data.video_cover !== undefined
      }
    >
      <ElementsGroup>
        <CheckBox
          checked={data.reels_sharetofeed === 1}
          onInputChange={(checked) =>
            dispatch("reels_sharetofeed", checked ? 1 : 0)
          }
          label={
            <Tooltip
              tooltip={__(
                "By selecting this video will send in feed otherwise it only available in reels tab",
              )}
            >
              <Text icon={regular("circle-question")} iconPosition="end" block>
                {__("Share to feed")}
              </Text>
            </Tooltip>
          }
        />
        <CheckBox
          id="video_cover"
          checked={coverToggle}
          onInputChange={() => setCoverToggle(!coverToggle)}
          label={
            <>
              <Tooltip
                tooltip={
                  "توصیه می‌شود تصویر کاور با تناسب ۹ در ۱۶ و فرمت jpeg و حجم کمتر از ۸ مگابایت باشد."
                }
              >
                <Text
                  icon={regular("circle-question")}
                  iconPosition="end"
                  block
                >
                  {__("Cover image")}
                </Text>
              </Tooltip>
            </>
          }
        />
        {coverToggle && (
          <DropBox
            selectedFileId={parseIntUndefined(data.video_cover)}
            onChange={(fileId) => dispatch("video_cover", fileId + "")}
            allowType={["image"]}
            sizeCaption={{ maxSize: 8 }}
          />
        )}
        <TextInput
          label={__("Change reels audio name")}
          placeholder={__("Enter audio name")}
          value={data.reels_audioname || ""}
          onInputChange={(audioname) => dispatch("reels_audioname", audioname)}
          name="audioname"
        />
        <Select
          label={
            <>
              <Tooltip
                tooltip={__(
                  "You can only tag public profiles & write the username in full.",
                )}
              >
                <Text
                  icon={regular("circle-question")}
                  iconPosition="end"
                  block
                >
                  {__("Tag users in video")}
                </Text>
              </Tooltip>
            </>
          }
          placeholder={__("Enter the username and press Enter")}
          onSubmit={(search) => setSearch(search)}
          searchable
          multiple
          value={data.reels_tags || []}
          onChange={(reels_tags) => dispatch("reels_tags", reels_tags)}
          getLabel={(item) => item.username}
          getKey={(item) => item.username}
          hideMenu
          loading={isSearching}
        />
      </ElementsGroup>
    </SectionToggle>
  );
}
