import { Account } from "@common/features/Account";
import { useSocialTypes } from "@common/features/User";
import { __ } from "@common/helpers/i18n";
import { ElementsGroup } from "@app/elements/container";
import { Radio } from "@app/elements/form";
import Warnings from "@app/pages/post/Warnings";
import { usePostStore } from "../store/post";

export type PostWarningsType = Array<[Account, string]>;

export function PostWarnings({ isStory }: { isStory: boolean }) {
  const [{ selectedAccounts, selectedFiles, captions }, dispatch] =
    usePostStore(
      ({ selectedAccounts, selectedFiles, captions }) => ({
        selectedAccounts,
        selectedFiles,
        captions,
      }),
      true,
    );

  const socialTypes = useSocialTypes();

  const warnings: PostWarningsType = [];

  const type =
    selectedFiles.length == 0
      ? "text"
      : selectedFiles.length == 1
        ? selectedFiles[0].fileType
        : "album";

  selectedAccounts.forEach((account) => {
    if (!socialTypes[type].includes(account.type)) {
      const supportVideo = socialTypes.video.includes(account.type);
      const supportImage = socialTypes.image.includes(account.type);

      const supportAlbum = socialTypes.album.includes(account.type);

      warnings.push([
        account,
        __("%{social} requires %{type}.", {
          social: __(account.type),
          type:
            supportVideo && supportImage
              ? supportAlbum
                ? __("at least an image or a video")
                : __("an image or a video")
              : supportVideo
                ? __("a video")
                : __("an image"),
        }),
      ]);
    }

    if (
      selectedFiles.length > 1 &&
      selectedFiles.some((file) => file.fileType === "document")
    ) {
      warnings.push([account, __("You can't send document in album.")]);
    }

    if (
      selectedFiles.length === 1 &&
      selectedFiles[0].fileType === "video" &&
      account.type === "Twitter" &&
      selectedFiles[0].filesize > 50 * 1024 * 1024
    ) {
      warnings.push([
        account,
        __("Video upload size limit for Twitter is 50 MB."),
      ]);
    }

    if (
      selectedFiles.length === 1 &&
      selectedFiles[0].fileType === "image" &&
      account.type === "Twitter" &&
      selectedFiles[0].filesize > 5 * 1024 * 1024
    ) {
      warnings.push([
        account,
        __("Image upload size limit for Twitter is 5 MB."),
      ]);
    }

    if (
      account.type === "Telegram" &&
      (captions.text.Telegram || captions.text.main).length - 1 > 1024 &&
      //(this.state.mediaType === "image" || this.state.mediaType === "video") &&
      selectedFiles.length > 0 &&
      selectedFiles[0].filesize > 5 * 1024 * 1024
    ) {
      warnings.push([
        account,
        __(
          "There is no preview in images/videos with a size of more than 5 MB and it will be uploaded as a file.",
        ),
      ]);
    }
  });

  return (
    <>
      {selectedAccounts.length !== 0 &&
        !selectedAccounts.some(
          (account) => !socialTypes.story.includes(account.type),
        ) && (
          <ElementsGroup display="block" space={false}>
            <Radio
              label={__("Post")}
              name="post-type"
              checked={!isStory}
              onChange={() =>
                dispatch({
                  type: "postTypeChanged",
                  payload: { type: "post", storyTypes: socialTypes.story },
                })
              }
            />
            <Radio
              label={__("Story")}
              name="post-type"
              checked={isStory}
              onChange={() =>
                dispatch({
                  type: "postTypeChanged",
                  payload: { type: "story", storyTypes: socialTypes.story },
                })
              }
            />
          </ElementsGroup>
        )}
      <Warnings warnings={warnings} />
    </>
  );
}
