import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccountPostImage } from "@common/features/Social";
import { regular } from "@common/helpers/fontawesome";
import { ucfirst } from "@common/helpers/functions";
import { __, formatDateTime, isRTL } from "@common/helpers/i18n";
import { InsightsPostsProps } from "@common/types";
import { Box } from "@app/elements/container";
import { Tooltip } from "@app/elements/helpers";
import { SocialIcon } from "@app/elements/social";
import { Text } from "@app/elements/text";
import { Alert } from "@app/elements/text/Alert";
import { post_type_icons } from "../posts/PostBox";

export default function InsightsPosts({
  account,
  commentText = __("Comment"),
  header = __("Top posts"),
  likeText = __("Like"),
  posts,
}: InsightsPostsProps) {
  return (
    <div>
      <h3 className="mb-4">
        {header}{" "}
        <Tooltip
          tooltip={__("Best performing posts, in the selected period of time")}
        >
          <FontAwesomeIcon
            icon={regular("question")}
            style={{
              transform: isRTL ? "scaleX(-1)" : undefined,
            }}
          />
        </Tooltip>
      </h3>
      {posts && posts.length === 0 && (
        <Alert variant="gray">
          {__("There isn't any post in selected range")}
        </Alert>
      )}
      <Box role="list">
        {posts &&
          posts.map((post) => {
            return (
              <Box.Item key={post.id} className="divide-y">
                <div className="group relative block w-full overflow-hidden bg-gray-100 dark:bg-gray-700">
                  <div className="aspect-h-9 aspect-w-16">
                    {post.image ? (
                      <AccountPostImage
                        accountPost={post}
                        className="pointer-events-none w-full object-cover group-hover:opacity-75"
                      />
                    ) : (
                      <div className="flex items-center justify-center">
                        <FontAwesomeIcon
                          icon={regular("image")}
                          className="h-10 w-10"
                        />
                      </div>
                    )}
                  </div>
                  <p className="pointer-events-none absolute bottom-0 end-0 flex items-center bg-gray-800 p-1 font-semibold text-zinc-100 opacity-70">
                    <FontAwesomeIcon
                      icon={post_type_icons[post.type]}
                      className="me-1"
                    />
                    {" " + __(ucfirst(post.type))}
                  </p>
                </div>
                <div className="w-full p-2">
                  <p className="my-3 line-clamp-2 block h-10">{post.content}</p>
                  <Text variant="gray">
                    <div>{__("Date") + ": " + formatDateTime(post.date)}</div>
                    {likeText && (
                      <div>
                        {likeText}: {post.like_count}
                      </div>
                    )}
                    {commentText && (
                      <div>
                        {commentText}: {post.comment_count}
                      </div>
                    )}
                  </Text>
                  <dl className="mt-1 flex flex-grow flex-col text-center">
                    <dt className="sr-only">Account</dt>
                    <dd className="my-3 space-s-1">
                      <Tooltip tooltip={account.name} key={post.id}>
                        <SocialIcon social={account.type} />
                      </Tooltip>
                    </dd>
                  </dl>
                </div>
              </Box.Item>
            );
          })}
      </Box>
    </div>
  );
}
