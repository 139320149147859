import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";
import { useMasterUser } from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { LayoutComponentProps } from "@app/App";
import Error500 from "@app/elements/errors/Error500";
import { Button } from "@app/elements/form";
import { LoadProgress, Loading } from "@app/elements/loading";
import { Badge } from "@app/elements/text";
import { useUpdatePwa } from "@app/hooks";
import { generateMenu } from "@app/menu";
import Footer from "./Footer";
import Header from "./Header";
import Menu from "./Menu";
import NavBar from "./Navbar";
import PwaPopUp from "./PwaPopUp";
import SiteNotification from "./SiteNotification";
import SubMenu from "./SubMenu";

export default function Main({ children, ...props }: LayoutComponentProps) {
  return (
    <ErrorBoundary
      fallback={({ error, componentStack }) => (
        <Error500 error={error} info={{ componentStack }} />
      )}
    >
      <MainLayout {...props}>{children}</MainLayout>
    </ErrorBoundary>
  );
}

function MainLayout({ isPublic, children }: LayoutComponentProps) {
  useUpdatePwa();
  const hasUser = !!useMasterUser();

  const masterUser = useMasterUser();

  return (
    <>
      <PwaPopUp />
      <LoadProgress hasFooter={!isPublic} />
      <div className="flex h-full flex-col md:flex-row">
        {!isPublic && hasUser && (
          <>
            <NavBar />
            <Menu generateMenu={generateMenu} />
          </>
        )}
        <div className="flex h-full min-h-0 min-w-0 flex-1 flex-col">
          {!isPublic && hasUser && (
            <Header>
              <Button to="/ticket">{__("Support")}</Button>
              {masterUser.package_id === -1 && (
                <Button variant="dark" to="/renew">
                  <Badge
                    circle
                    variant="primary"
                    padding="none"
                    className="me-2 p-1"
                    icon={regular("bolt")}
                  />
                  {__("Upgrade")}
                </Button>
              )}
            </Header>
          )}
          {/* Bottom section */}
          <div className="flex min-h-0 flex-1 flex-col overflow-hidden md:flex-row">
            {!isPublic && hasUser && <SubMenu generateMenu={generateMenu} />}
            <div id="start-menu" className="min-h-0 flex" />
            <main className="relative min-h-0 min-w-0 flex-1 md:flex">
              <section
                id="main-content"
                aria-labelledby="primary-heading"
                className={
                  "flex h-full min-w-0 flex-1 flex-col overflow-y-auto overflow-x-hidden" +
                  (hasUser ? " 2x1l:px-9 p-3" : "")
                }
              >
                <Suspense fallback={<Loading />}>
                  {!isPublic && hasUser && <SiteNotification />}
                  {children}
                </Suspense>
              </section>
            </main>
            <div id="end-menu" className="flex overflow-hidden" />
          </div>
        </div>
        {!isPublic && <Footer />}
      </div>
    </>
  );
}
