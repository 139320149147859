import React from "react";
import { Route, RouteProps, Switch } from "react-router-dom";
import Error500 from "./elements/errors/Error500";
import { PrivateRoute, PublicRoute } from "./helpers/route";

const Login = React.lazy(() => import("./pages/login/Login"));

const Dashboard = React.lazy(() => import("./pages/dashboard/OnBoarding"));

const Orders = React.lazy(() => import("./pages/settings/orders/Orders"));

const Comments = React.lazy(() => import("./pages/comments/Comments"));

const Post = React.lazy(() => import("./pages/post/Post"));

const Posts = React.lazy(() => import("./pages/posts/Posts"));

const Draft = React.lazy(() => import("./pages/posts/Draft"));

const Calendar = React.lazy(() => import("./pages/calendar/Calendar"));

const Account = React.lazy(() => import("./pages/settings/account/Account"));

const Accounts = React.lazy(() => import("./pages/settings/accounts/Accounts"));

const Ticket = React.lazy(() => import("./pages/settings/tickets/Ticket"));

const Tickets = React.lazy(() => import("./pages/settings/tickets/Tickets"));

const Inbox = React.lazy(() => import("./pages/inbox/Inbox"));

const Team = React.lazy(() => import("./pages/settings/teams/Team"));

const Teams = React.lazy(() => import("./pages/settings/teams/Teams"));
const TeamStatistics = React.lazy(
  () => import("./pages/settings/teams/Statistics"),
);

const PostInsights = React.lazy(() => import("./pages/insights/PostInsights"));

const Insights = React.lazy(() => import("./pages/insights/Insights"));

const User = React.lazy(() => import("./pages/settings/user/User"));

const Affiliate = React.lazy(() => import("./pages/settings/Affiliate"));

const Renew = React.lazy(() => import("./pages/renew/Renew"));

const Checkout = React.lazy(() => import("./pages/renew/Checkout"));

const Authorize = React.lazy(() => import("./pages/Authorize"));

const Integrations = React.lazy(
  () => import("./pages/settings/integrations/Integrations"),
);

const Settings = React.lazy(() => import("./pages/settings/Settings"));

const Automation = React.lazy(() => import("./pages/automation/Automation"));

const AutomationMenu = React.lazy(() => import("./pages/automation/Menu"));

const AutomationQuestion = React.lazy(
  () => import("./pages/automation/Question"),
);

const Product = React.lazy(() => import("./pages/automation/Product"));

const Products = React.lazy(() => import("./pages/automation/Products"));

const Response = React.lazy(() => import("./pages/automation/Response"));

const Responses = React.lazy(() => import("./pages/automation/Responses"));

const Form = React.lazy(() => import("./pages/automation/Form"));

const Forms = React.lazy(() => import("./pages/automation/Forms"));

const FormResponses = React.lazy(
  () => import("./pages/automation/FormResponses"),
);

const Logs = React.lazy(() => import("./pages/automation/Logs"));
const Leeds = React.lazy(() => import("./pages/automation/Leeds"));

export const publicApps: {
  [key: string]: RouteProps["render"];
} = {
  "/:page(signup)/affiliate/:affiliate": () => (
    <PublicRoute>
      <Login />
    </PublicRoute>
  ),
  "/:page(signup)/jaryan/:jaryan": () => (
    <PublicRoute>
      <Login />
    </PublicRoute>
  ),
  "/:page(signup|login)/:implicit(implicit)": () => (
    <PublicRoute>
      <Login />
    </PublicRoute>
  ),
  "/:page(login|signup|recovery)/:hash?": () => (
    <PublicRoute>
      <Login />
    </PublicRoute>
  ),
  "/:page(complete|verify|verify-email)": () => (
    <PrivateRoute verify={false}>
      <Login />
    </PrivateRoute>
  ),
  "/authorize": () => (
    <PrivateRoute>
      <Authorize />
    </PrivateRoute>
  ),
};

export const apps: {
  [key: string]: RouteProps["render"];
} = {
  "/dashboard": () => (
    <PrivateRoute>
      <Dashboard />
    </PrivateRoute>
  ),
  "/order": () => (
    <PrivateRoute>
      <Orders />
    </PrivateRoute>
  ),
  "/comment/:commentId?": () => (
    <PrivateRoute>
      <Comments />
    </PrivateRoute>
  ),
  "/:type(post|story)/:postId?": () => (
    <PrivateRoute>
      <Post />
    </PrivateRoute>
  ),
  "/posts": () => (
    <PrivateRoute>
      <Posts />
    </PrivateRoute>
  ),
  "/draft": () => (
    <PrivateRoute>
      <Draft />
    </PrivateRoute>
  ),
  "/schedule-calendar": () => (
    <PrivateRoute>
      <Calendar />
    </PrivateRoute>
  ),
  "/accounts/:social?/new": () => (
    <PrivateRoute>
      <Account />
    </PrivateRoute>
  ),
  "/accounts/:accountId/:relogin(relogin)?": () => (
    <PrivateRoute>
      <Account />
    </PrivateRoute>
  ),
  "/accounts": () => (
    <PrivateRoute>
      <Accounts />
    </PrivateRoute>
  ),
  "/ticket/:ticketId": () => (
    <PrivateRoute>
      <Ticket />
    </PrivateRoute>
  ),
  "/ticket": () => (
    <PrivateRoute>
      <Tickets />
    </PrivateRoute>
  ),
  "/inbox/:conversationId?": () => (
    <PrivateRoute>
      <Inbox />
    </PrivateRoute>
  ),
  "/team/:teamId/statistics": () => (
    <PrivateRoute>
      <TeamStatistics />
    </PrivateRoute>
  ),
  "/team/:teamId": () => (
    <PrivateRoute>
      <Team />
    </PrivateRoute>
  ),
  "/team": () => (
    <PrivateRoute>
      <Teams />
    </PrivateRoute>
  ),
  "/insights/post/:postId/:accountId?": () => (
    <PrivateRoute>
      <PostInsights />
    </PrivateRoute>
  ),
  "/insights/:accountId?": () => (
    <PrivateRoute>
      <Insights />
    </PrivateRoute>
  ),
  "/user/:section?": () => (
    <PrivateRoute>
      <User />
    </PrivateRoute>
  ),
  "/affiliate/:action?": () => (
    <PrivateRoute>
      <Affiliate />
    </PrivateRoute>
  ),
  "/renew/:packageId?": () => (
    <PrivateRoute>
      <Renew />
    </PrivateRoute>
  ),
  "/checkout": () => (
    <PrivateRoute>
      <Checkout />
    </PrivateRoute>
  ),
  "/integrations/:integrationId": () => (
    <PrivateRoute>
      <Account />
    </PrivateRoute>
  ),
  "/integrations": () => (
    <PrivateRoute>
      <Integrations />
    </PrivateRoute>
  ),
  "/settings": () => (
    <PrivateRoute>
      <Settings />
    </PrivateRoute>
  ),
  "/automation-accounts": () => (
    <PrivateRoute>
      <Automation />
    </PrivateRoute>
  ),
  "/automation/:accountId?": () => (
    <PrivateRoute>
      <Switch>
        <Route path="/automation/:accountId/question">
          <AutomationQuestion />
        </Route>
        <Route path="/automation/:accountId/product/:productId">
          <Product />
        </Route>
        <Route path="/automation/:accountId/product">
          <Products />
        </Route>
        <Route path="/automation/:accountId/response/:responseId">
          <Response />
        </Route>
        <Route path="/automation/:accountId/response">
          <Responses />
        </Route>
        <Route path="/automation/:accountId/form/:formId/responses">
          <FormResponses />
        </Route>
        <Route path="/automation/:accountId/form/:formId">
          <Form />
        </Route>
        <Route path="/automation/:accountId/form">
          <Forms />
        </Route>
        <Route path="/automation/:accountId/log">
          <Logs />
        </Route>
        <Route path="/automation/:accountId/leed">
          <Leeds />
        </Route>
        <Route path="/automation/:accountId/menu">
          <AutomationMenu />
        </Route>
      </Switch>
    </PrivateRoute>
  ),
};

const Error404 = React.lazy(() => import("./elements/errors/Error404"));

export const errors = {
  "404": Error404,
  "500": Error500,
};
