import classNames from "classnames";
import { forwardRef } from "react";
import { SectionProps } from "@common/types";

export const Section = forwardRef<
  HTMLDivElement,
  SectionProps & { containerClassName?: string } & Omit<
      React.HTMLAttributes<HTMLDivElement>,
      "title"
    >
>(function Section(
  {
    title,
    subtitle,
    className,
    children,
    padding = "medium",
    action,
    containerClassName,
    pure,
    hasBackground = true,
    ...rest
  },
  ref,
) {
  return (
    <div
      className={classNames(
        pure !== true && "relative rounded-md border shadow-md",
        "border-gray-200 dark:border-gray-800",
        "text-gray-700 dark:text-zinc-100",
        hasBackground && "bg-white dark:bg-dark-background",
        className,
      )}
      ref={ref}
      {...rest}
    >
      {title &&
        (pure ? (
          <div className="border-b px-4 py-5 sm:px-6">
            <div className="flex items-center justify-between">
              <div className="w-full">
                <h3 className="text-base text-gray-900 dark:text-white">
                  {title}
                </h3>
                {subtitle && <p className="mt-1 text-gray-500">{subtitle}</p>}
              </div>
              {action && <div className="flex-shrink-0">{action}</div>}
            </div>
          </div>
        ) : (
          <div className="border-b px-4 py-5 sm:px-6">
            <div className="flex items-center justify-between">
              <div className="w-full">
                <h3 className="text-base font-bold leading-6 text-gray-900 dark:text-white">
                  {title}
                </h3>
                {subtitle && <p className="mt-1 text-gray-500">{subtitle}</p>}
              </div>
              {action && <div className="flex-shrink-0">{action}</div>}
            </div>
          </div>
        ))}
      {pure !== true ? (
        <div
          className={classNames(
            containerClassName,
            padding === "large"
              ? "px-8 py-8"
              : padding === "medium"
                ? "px-4 py-4"
                : padding === "small"
                  ? "px-2 py-2"
                  : "",
          )}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
});
