import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import notify from "@common/helpers/NotificationManager";
import { api, apiUrl } from "@common/helpers/api";
import { isNative } from "@common/helpers/functions";
import { File as FileModel } from "../interfaces";
import { filesKeys } from "./files";

declare const FormData: any;
declare const XMLHttpRequest: any;

let uploadQueue: Promise<any> = Promise.resolve();

export type UploadPreview<T> = {
  previewUrl: string;
  percent: number;
  abort: () => void;
  file?: T;
  url?: string;
};

export const useUploadFile = <T>() => {
  const [previews, setPreviews] = useState<UploadPreview<T>[]>([]);

  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      file,
      from,
      url,
      variant,
      onProgress,
      signal,
    }: {
      file?: T;
      from?: T;
      url?: string;
      variant?: string;
      onProgress: (percent: number) => void;
      signal: AbortSignal;
    }) => {
      if (!isNative) {
        return api
          .post<FileModel>("file", file ? { file, variant } : { url, from }, {
            headers: file
              ? {
                  "Content-Type": "multipart/form-data",
                }
              : undefined,
            onUploadProgress: (progressEvent) =>
              onProgress(
                progressEvent.total
                  ? Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100,
                    )
                  : 0,
              ),
            signal,
          })
          .then((response) => response.data);
      }

      if (file) {
        return new Promise<FileModel>((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);

          if (variant) formData.append("variant", variant);

          const oReq = new XMLHttpRequest();
          oReq.addEventListener("load", () => {
            if (oReq.status === 200)
              return resolve(JSON.parse(oReq.responseText));
            const message: any = JSON.parse(oReq.responseText);
            if (message?.error?.message || oReq.statusText)
              notify.error(message?.error?.message || oReq.statusText);
            console.error(
              "Error message: " +
                (message?.message || message?.error?.message) +
                " \ntrace id:" +
                message?.error?.trace_id,
            );
            reject(message);
          });
          (signal as any)?.addEventListener("abort", () => {
            oReq.abort();
            reject();
          });
          oReq.open("POST", apiUrl + "/file");
          oReq.onprogress = function (progressEvent: any) {
            onProgress(
              progressEvent.total
                ? Math.round((progressEvent.loaded / progressEvent.total) * 100)
                : 0,
            );
          };
          oReq.setRequestHeader(
            "authorization",
            api.defaults.headers.common.Authorization,
          );
          oReq.setRequestHeader("Content-Type", "multipart/form-data");
          oReq.send(formData);
        });
      } else {
        return api
          .post<FileModel>(
            "file",
            { url },
            {
              onUploadProgress: (progressEvent) =>
                onProgress(
                  progressEvent.total
                    ? Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100,
                      )
                    : 0,
                ),
              signal,
            },
          )
          .then((response) => response.data);
      }
    },
    onSuccess: (result) => {
      queryClient.invalidateQueries({ queryKey: filesKeys.list._def });

      queryClient.invalidateQueries(filesKeys.info);

      queryClient.setQueryData<FileModel>(
        filesKeys.file(result.id).queryKey,
        result,
      );

      queryClient.invalidateQueries({
        queryKey: filesKeys.file(result.id).queryKey,
        refetchType: "none",
      });
    },
  });

  const upload = useCallback(
    (
      {
        url,
        file,
        previewUrl,
        from,
        variant,
      }: {
        file?: T;
        url?: string;
        previewUrl: string;
        from?: T;
        variant?: string;
      },
      onSuccess?: (file: FileModel) => void,
    ) => {
      const abortSignal = new AbortController();

      const preview = {
        percent: 0,
        previewUrl,
        file,
        url,
        abort: () => {
          abortSignal.abort();
          setPreviews((previews) =>
            previews.filter((item) => item !== preview),
          );
        },
      };

      setPreviews((previews) => [...previews, preview]);

      uploadQueue = uploadQueue.then(async () => {
        try {
          await mutateAsync(
            {
              file,
              url,
              from,
              signal: abortSignal.signal,
              variant,
              onProgress: (percent) =>
                setPreviews((previews) =>
                  previews.map((item) => {
                    if (item === preview) {
                      item.percent = percent;
                    }
                    return item;
                  }),
                ),
            },
            { onSuccess },
          );
        } catch (error) {
          // ignore
        } finally {
          setPreviews((previews) =>
            previews.filter((item) => item !== preview),
          );
        }
      });
    },
    [mutateAsync],
  );

  return { previews, upload, isPending };
};
