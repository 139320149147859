import { useRef, useState } from "react";
import {
  File as FileModel,
  useFetchFileInfo,
  useUploadFile,
} from "@common/features/File";
import { FilemanagerEditorSectionRef } from "@common/types";
import { FileManager } from "@app/features/filemanager";
import { PreviewBox } from "@app/features/filemanager";
import EditorWrapper from "@app/pages/post/EditorWrapper";
import FilemanagerEditorSection from "@app/pages/post/FilemanagerEditorSection";
import { PostState, usePostStore } from "../store/post";
import { PollModal } from "./PollModal";
import { PostPollFileSelected } from "./PostPollFileSelected";
import { PostSections } from "./PostSections";
import { PostWarnings } from "./PostWarnings";

export const allowType: {
  [key in PostState["postType"]]: Array<FileModel["fileType"]>;
} = {
  post: ["image", "video", "document"],
  story: ["image", "video"],
};

export function PostMain() {
  const fileManagerRef = useRef<FilemanagerEditorSectionRef>(null);
  const [fileManagerModal, setFileManagerModal] = useState(false);
  const [pollModal, setPollModal] = useState(false);

  const [postType, dispatch] = usePostStore((state) => state.postType);

  const { previews, isPending, upload } = useUploadFile();
  const { data: info } = useFetchFileInfo();

  const isStory = postType === "story";

  return (
    <>
      <FilemanagerEditorSection
        ref={fileManagerRef}
        maxSize={info ? +info.max_file_size : undefined}
        allowType={allowType[postType]}
        onUpload={(files) => {
          if (isStory) {
            if (isPending) return;
            files = files.slice(0, 1);
            dispatch({ type: "fileClear" });
          }
          files.forEach((file) => {
            upload(file, (resp) => {
              dispatch({ type: "fileAdd", payload: resp });
            });
          });
        }}
      >
        <PostWarnings isStory={isStory} />
        <EditorWrapper
          onUpload={() => fileManagerRef.current!.open()}
          onFileManager={() => setFileManagerModal(true)}
          readOnly={isStory}
          onPollModal={() => setPollModal(true)}
        >
          <PostPollFileSelected
            onSave={(image, from) => {
              dispatch({ type: "fileRemove", payload: from });
              upload(
                { url: image, previewUrl: image, from: from.id },
                (resp) => {
                  dispatch({ type: "fileAdd", payload: resp });
                },
              );
            }}
            onPollModal={() => setPollModal(true)}
          />
          {previews.map((item) => (
            <PreviewBox preview={item} key={item.previewUrl} />
          ))}
        </EditorWrapper>
        {!isStory && <PostSections />}
      </FilemanagerEditorSection>
      <FileManager
        isOpen={fileManagerModal}
        onClose={() => setFileManagerModal(false)}
        mediaType={allowType[postType]}
      />
      <PollModal isOpen={pollModal} onClose={() => setPollModal(false)} />
    </>
  );
}
