import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";
import { regular } from "@common/helpers/fontawesome";
import { isRTL } from "@common/helpers/i18n";
import { InsightsBoxItemProps } from "@common/types";
import { Tooltip } from "../helpers";
import { Loading } from "../loading";

function InsightsBoxRoot(
  props: { children?: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>,
) {
  return (
    <div
      className="relative grid grid-cols-2 gap-2 md:auto-cols-fr md:grid-flow-col xl:gap-4 2xl:gap-10"
      {...props}
    />
  );
}

function Item({
  label,
  value,
  children,
  icon,
  hint,
  loading,
  ...rest
}: InsightsBoxItemProps &
  (
    | React.HTMLAttributes<HTMLDivElement>
    | React.HTMLAttributes<HTMLAnchorElement>
    | LinkProps
  )) {
  const Component: any = "to" in rest ? Link : "href" in rest ? "a" : "div";

  return (
    <Component
      className="relative flex min-w-0 flex-1 flex-col overflow-hidden rounded-lg bg-white p-4 shadow dark:bg-dark-background xl:p-6"
      {...rest}
    >
      {loading && <Loading />}
      {label && (
        <dt className="text-gray-500 dark:text-gray-400 lg:font-bold">
          {icon && (
            <div className="absolute hidden rounded-md bg-primary-500 p-1 dark:bg-primary-500/90 lg:flex xl:p-3">
              <FontAwesomeIcon
                icon={icon}
                className="text-white lg:h-4 lg:w-4 xl:h-6 xl:w-6"
              />
            </div>
          )}
          <div className={icon ? "lg:ms-8 xl:ms-16" : ""}>
            {label}
            {hint && (
              <Tooltip tooltip={hint}>
                <FontAwesomeIcon
                  icon={regular("circle-question")}
                  style={{
                    transform: isRTL ? "scaleX(-1)" : undefined,
                  }}
                />
              </Tooltip>
            )}
          </div>
        </dt>
      )}
      <dd
        className={classNames(
          "mt-1 text-xl font-semibold text-gray-900 dark:text-gray-200 lg:text-3xl",
          icon ? "lg:ms-8 xl:ms-16" : "",
        )}
      >
        {typeof value === "undefined" ? "-" : value}
      </dd>
      {children}
    </Component>
  );
}

export const InsightsBox = Object.assign(InsightsBoxRoot, { Item });
