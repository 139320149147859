import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { BadgeProps } from "@common/types";

const variants = {
  primary: "bg-primary-500 text-white dark:bg-primary-600 dark:text-zinc-100",
  success: "bg-green-500 text-white",
  danger: "bg-rose-500 text-white",
  warning: "bg-amber-500 text-white",
  info: "bg-sky-500 text-white",
  light: "border-gray-300 bg-white border text-gray-500",
  dark: "bg-slate-900 text-white",
  default: "bg-gray-200 text-gray-700",
  "outline-primary": "border border-primary-300 text-primary-500",
  "outline-success": "border border-green-300 text-green-500",
  "outline-danger": "border border-rose-300 text-rose-500",
  "outline-warning": "border border-amber-300 text-amber-500",
  "outline-info": "border border-sky-300 text-sky-500",
  "outline-light": "border-gray-300 border text-gray-500",
  "outline-dark": "border border-slate-700 text-slate-900",
};

export function Badge({
  variant,
  tag,
  icon,
  children,
  className,
  padding = "medium",
  rounded = true,
  circle,
  ...rest
}: BadgeProps &
  (
    | React.HTMLAttributes<HTMLDivElement>
    | React.HTMLAttributes<HTMLParagraphElement>
  )) {
  const Component: any = tag || "div";
  return (
    <Component
      className={classNames(
        padding === "large"
          ? "px-3 py-3"
          : padding === "medium"
            ? "px-3 py-2"
            : padding === "small" && "px-2 py-0.5 text-xs",
        circle ? "rounded-full" : rounded && "rounded-md",
        "inline-flex items-center transition-colors",
        className,
        variants[variant || "default"],
      )}
      {...rest}
    >
      {icon ? (
        <div className="flex items-center space-s-1">
          {Array.isArray(icon) ? <FontAwesomeIcon icon={icon} /> : icon}
          {children && <div>{children}</div>}
        </div>
      ) : (
        children
      )}
    </Component>
  );
}
