import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { useChangeUser, useFetchUserAccesses } from "@common/features/Team";
import { useIsTeammate, useMasterUser, useUser } from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { Button } from "@app/elements/form";
import { FadeTransition } from "@app/elements/helpers";
import { Loading } from "@app/elements/loading";
import { useLogout } from "@app/features/user/queries/user";

export default function HeaderUser() {
  const user = useUser();
  const masterUser = useMasterUser();

  const isTeammate = useIsTeammate();

  const { data: userAccesses } = useFetchUserAccesses();
  const changeUser = useChangeUser();

  const activeUser = isTeammate
    ? (userAccesses || []).find((user) => user.master_user_id === masterUser.id)
    : {
        name: user.name,
        email: user.email,
      };
  const logout = useLogout();

  return (
    <div>
      {changeUser.isPending && (
        <div className="absolute inset-0 z-50">
          <Loading rounded={false} />
        </div>
      )}
      <Popover as="div" className="relative inline-block">
        <Popover.Button className="flex items-center rounded-full focus:outline-none">
          <span className="sr-only">Open user menu</span>
          <div className="me-2 truncate" style={{ maxWidth: "24ch" }}>
            {__("%{name} dashboard", {
              name: isTeammate
                ? userAccesses?.find(
                    (item) => item.master_user_id == masterUser!.id,
                  )?.name || ""
                : user.name,
            })}
          </div>
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-400/80 dark:bg-gray-500/80">
            <FontAwesomeIcon className="text-white" icon={regular("user")} />
          </div>
        </Popover.Button>

        <FadeTransition>
          <Popover.Panel className="absolute end-0 z-30 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700">
            <div className="flex items-center bg-gray-100 p-4 space-s-3 dark:border dark:bg-gray-800">
              <FontAwesomeIcon
                icon={regular("user")}
                className="rounded-full bg-gray-200 p-4 dark:bg-gray-600 dark:text-white"
              />
              <div>
                <div className="text-gray-600 dark:text-zinc-200">
                  {activeUser?.name}
                </div>
                <div className="text-xs">{activeUser?.email}</div>
                <div className="text-xs space-s-4">
                  <Popover.Button
                    as={Button as any}
                    size="none"
                    to="/user/profile"
                  >
                    {__("Profile settings")}
                  </Popover.Button>
                  <Button
                    padding="none"
                    onClick={(e) => {
                      e.preventDefault();
                      logout.mutate(undefined, {
                        onError: () => (window.location.href = "/login"),
                      });
                    }}
                  >
                    {__("Logout")}
                  </Button>
                </div>
              </div>
            </div>
            {userAccesses !== undefined && userAccesses.length !== 0 && (
              <div>
                <div className="relative overflow-hidden transition-all duration-100">
                  {isTeammate && (
                    <div
                      className={classNames(
                        "flex w-full cursor-pointer items-center p-4 hover:bg-gray-50 dark:text-gray-200 dark:hover:bg-gray-600",
                        !isTeammate ? "bg-gray-100" : "",
                      )}
                      onClick={() => changeUser.mutate(undefined)}
                    >
                      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-500 text-white">
                        {user.name[0]}
                      </div>
                      <div className="ms-2 flex flex-col py-1">
                        <div className="text-xs">
                          {__("%{name} dashboard", {
                            name: user.name,
                          })}
                        </div>
                        <div>{user.email}</div>
                      </div>
                      <div className="ms-auto">
                        <FontAwesomeIcon
                          icon={regular("chevron-right")}
                          className="rtl:rotate-180"
                        />
                      </div>
                    </div>
                  )}
                  {(userAccesses || []).map(
                    (team) =>
                      masterUser.id !== team.master_user_id && (
                        <div
                          key={team.master_user_id}
                          className={classNames(
                            "flex w-full cursor-pointer items-center p-4 hover:bg-gray-50 dark:hover:bg-gray-600",
                            isTeammate && masterUser.id === team.master_user_id
                              ? "bg-gray-100"
                              : "",
                          )}
                          onClick={() => changeUser.mutate(team.master_user_id)}
                        >
                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-500 text-white">
                            {team.name[0]}
                          </div>
                          <div className="ms-2 flex flex-col py-1">
                            <div className="text-xs">
                              {__("%{name} dashboard", {
                                name: team.name,
                              })}
                            </div>
                            <div>{team.email}</div>
                          </div>
                          <div className="ms-auto">
                            <FontAwesomeIcon
                              icon={regular("chevron-right")}
                              className="rtl:rotate-180"
                            />
                          </div>
                        </div>
                      ),
                  )}
                </div>
              </div>
            )}
          </Popover.Panel>
        </FadeTransition>
      </Popover>
    </div>
  );
}
