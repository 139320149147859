import React, { useEffect } from "react";
import { useFetchMetric } from "@common/features/Insights";
import {
  useFilterMetricPermissions,
  useMasterPermissions,
} from "@common/features/User";
import { regular } from "@common/helpers/fontawesome";
import { isBrowser } from "@common/helpers/functions";
import { __ } from "@common/helpers/i18n";
import { ElementsGroup, EmptyPage } from "@app/elements/container";
import BestHour from "@app/pages/insights/BestHour";
import ChartGroup from "@app/pages/insights/ChartGroup";
import InsightsPosts from "@app/pages/insights/InsightsPosts";
import { LinkedinSelector } from "@app/pages/insights/selectors";
import { LinkedinSelectorLifetime } from "@app/pages/insights/selectors/Linkedin";
import { BoxNumbers } from "../components/BoxNumbers";
import { InsightsChartSection } from "../components/InsightsChartSection";
import { CommonSocialProps } from "../helpers/common";

function Linkedin({
  account,
  filter: { customFrom, customTo, printPeriod },
}: CommonSocialProps) {
  const masterPermissions = useMasterPermissions();

  const { data, isLoading } = useFetchMetric(
    {
      period: printPeriod
        ? printPeriod
        : customFrom && customTo
          ? "custom"
          : "week",
      metric: useFilterMetricPermissions([
        "engagement_by_type",
        "impressions",
        "num_posts_by_type",
        "follow_by_type",
        "page_follow_total",
        "total_posts_sent",
        "profile_views",
        "top_post",
      ]),
      customFrom:
        !printPeriod && customFrom && customTo ? customFrom : undefined,
      customTo: !printPeriod && customFrom && customTo ? customTo : undefined,
      accountId: account.id,
    },
    true,
    LinkedinSelector,
  );

  const { data: lifeTime, isLoading: isLifeTimeLoading } = useFetchMetric(
    {
      period: "lifetime",
      metric: useFilterMetricPermissions(["country_by_type", "job"]),
      accountId: account.id,
    },
    true,
    LinkedinSelectorLifetime,
  );

  useEffect(() => {
    if (isBrowser) {
      if (printPeriod && !isLoading) {
        (window as any).print();
      }
    }
  }, [printPeriod, isLoading]);

  const rowOne = {
    page_follow_total: {
      title: __("Total audience"),
      hide: !masterPermissions.insights_audience_growth,
    },
    growth: {
      title: __("New followers"),
      hide: !masterPermissions.insights_audience_growth,
    },
    average_engagement: {
      title: __("Average Engagements"),
      hide: !masterPermissions.insights_engagement,
    },
    profile_views: {
      title: __("View"),
      hide: !masterPermissions.insights_summary,
      hint: __(
        "Overall number of profile visits in the selected period of time",
      ),
    },
    link_clicks: {
      title: __("Link Clicks"),
      hide: !masterPermissions.insights_summary,
      hint: __(
        "Total number of clicks on the website address of Instagram bio in the selected period of time",
      ),
    },
    total_posts_sent: {
      title: __("Total published posts"),
      hide: !masterPermissions.insights_posts_publish,
    },
  };

  if (data?.emptyData) {
    return (
      <EmptyPage
        text={__("Data is being called, please wait a few minutes!")}
        icon={regular("hourglass-half")}
      />
    );
  }

  const isCustom = customFrom !== undefined && customTo !== undefined;

  return (
    <ElementsGroup>
      <BoxNumbers
        loading={isLoading}
        items={data?.total || {}}
        options={rowOne}
      />
      <ChartGroup>
        <InsightsChartSection
          title={__("Audience Growth")}
          loading={isLoading}
          chartOption={data?.growth}
          selector={LinkedinSelector}
          metric="follow_by_type"
          account={account}
          isCustom={isCustom}
        />
        <InsightsChartSection
          title={__("Group Impressions")}
          hint={__(
            "Overall number of impression of published posts in the selected period of time",
          )}
          loading={isLoading}
          chartOption={data?.impressions}
          selector={LinkedinSelector}
          metric="impressions"
          account={account}
          isCustom={isCustom}
        />
      </ChartGroup>
      <ChartGroup>
        <InsightsChartSection
          title={__("Group Engagement")}
          loading={isLoading}
          hint={__(
            "Overall interaction (like+comment) of users with published post in the selected period of time",
          )}
          chartOption={data?.engagement}
          selector={LinkedinSelector}
          metric="engagement_by_type"
          account={account}
          isCustom={isCustom}
        />
        <InsightsChartSection
          title={__("Media Type")}
          loading={isLoading}
          chartOption={data?.mediatype}
          selector={LinkedinSelector}
          metric="num_posts_by_type"
          account={account}
          isCustom={isCustom}
        />
      </ChartGroup>
      {data?.bestHour && (
        <BestHour from={data.bestHour[0]} to={data.bestHour[1]} />
      )}
      <ChartGroup>
        <InsightsChartSection
          title={__("Job")}
          hint={__(
            "Distribution of followers of company page and viewers of posts by occupation, in the selected period of time",
          )}
          loading={isLifeTimeLoading}
          chartOption={lifeTime?.job}
          metric="job"
          isCustom={isCustom}
        />
        <InsightsChartSection
          title={__("Country")}
          hint={__(
            "Region distribution of followers in the selected period of time",
          )}
          loading={isLifeTimeLoading}
          chartOption={lifeTime?.country}
          metric="insights_country"
        />
      </ChartGroup>
      <InsightsPosts account={account} posts={data?.top_post} />
    </ElementsGroup>
  );
}

export default React.memo(Linkedin);
