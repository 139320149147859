import { useState } from "react";
import { useGenerateCaption } from "@common/features/Generate";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { DefaultSelectValue } from "@common/interfaces/Api";
import { ModalProps } from "@common/types";
import { ElementsGroup } from "@app/elements/container";
import { Button, Select, TextArea } from "@app/elements/form";
import { Modal } from "@app/elements/modals";

const tones: DefaultSelectValue[] = [
  { value: "formal", label: __("Formal") },
  {
    value: "informal",
    label: __("Friendly"),
  },
  { value: "curious", label: __("Curious") },
  { value: "fearful", label: __("Fearful") },
  { value: "hopeful", label: __("Hopeful") },
  { value: "happy", label: __("Happy") },
  { value: "funny", label: __("Funny") },
  { value: "sad", label: __("Sad") },
];

const placeholders = {
  social_media_caption:
    "یک موضوع یا ایده برای تولید کپشن بنویسید\nبرای مثال: اهمیت بازاریابی در شبکه‌های اجتماعی برای کسب و کارهای حوزه مد و فشن",
  rewrite: "محتوایی که می‌خواهید بازنویسی شود را در اینجا قرار دهید",
  quote_from_celebrities:
    "یک موضوع برای نقل قول بنویسید\nبرای مثال: زندگی، فوتبال، خودباوری و...",
  pros_and_cons_list:
    "شرح مختصری از موضوعی که لیست جوانب مثبت و منفی می‌خواهید را بنویسید\nبرای مثال: دیجیتال مارکتینگ، گجت‌های پوشیدنی و...",
};

const types: DefaultSelectValue[] = [
  { value: "social_media_caption", label: __("Social media caption") },
  {
    value: "rewrite",
    label: __("Rewrite"),
  },
  { value: "quote_from_celebrities", label: __("Quote from celebrities") },
  { value: "pros_and_cons_list", label: __("Pros and cons list") },
];

const languages: DefaultSelectValue[] = [
  { value: "persian", label: "فارسی" },
  {
    value: "english",
    label: "English",
  },
];

export default function CaptionGenerateModal({
  onClose,
  ...rest
}: Omit<ModalProps, "onClose"> & { onClose: (caption?: string) => void }) {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<DefaultSelectValue>();
  const [language, setLanguage] = useState<DefaultSelectValue>();
  const [tone, setTone] = useState<DefaultSelectValue>();

  const [result, setResult] = useState<string>();

  const generateCaption = useGenerateCaption();

  return (
    <Modal {...rest} onClose={() => onClose()} padding="medium">
      <ElementsGroup>
        <Modal.Title>{__("AI generator")}</Modal.Title>
        {result ? (
          <>
            <TextArea
              label={__("Caption")}
              value={result}
              rows={4}
              onInputChange={(result) => setResult(result)}
            />
            <Button onClick={() => onClose(result)} variant="success" center>
              {__("Use in post")}
            </Button>
            <Button onClick={() => setResult(undefined)} variant="gray" center>
              <span className="text">{__("Regenerate")}</span>
            </Button>
          </>
        ) : (
          <>
            <Select
              placeholder={__("Types of request")}
              name="type"
              options={types}
              value={type}
              onChange={(type: any) => setType(type)}
            />
            <TextArea
              label={__("Subject")}
              name="subject"
              placeholder={
                type?.value ? (placeholders as any)[type?.value] : ""
              }
              value={message}
              onInputChange={(message) => setMessage(message)}
            />
            <Select
              label={__("Language")}
              placeholder={__("Language")}
              name="language"
              options={languages}
              value={language}
              onChange={(langauge: any) => setLanguage(langauge)}
            />
            <Select
              label={__("Tones")}
              placeholder={__("Tones")}
              name="tones"
              options={tones}
              value={tone}
              onChange={(tone: any) => setTone(tone)}
            />
            <ElementsGroup center>
              <Button
                onClick={() =>
                  generateCaption.mutate(
                    {
                      message,
                      language: language?.value,
                      tone: tone?.value,
                      type: type?.value,
                    },
                    { onSuccess: (result) => setResult(result.message) },
                  )
                }
                variant="primary"
                center
                loading={generateCaption.isPending}
                icon={regular("wand-magic-sparkles")}
              >
                {__("Generate caption")}
              </Button>
            </ElementsGroup>
          </>
        )}
      </ElementsGroup>
    </Modal>
  );
}
