import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { api } from "@common/helpers/api";

export const actionsKeys = createQueryKeys("actions", {
  categories: (accountId: number) => [{ accountId }],
  playlists: (accountId: number) => [{ accountId }],
});

export const useFetchActionCategories = (accountId: number) =>
  useQuery({
    ...actionsKeys.categories(accountId),
    queryFn: async () => {
      const response = await api.get<{ [key: string]: string }>(
        `account/${accountId}/categories`,
      );
      return response.data;
    },
  });

export const useFetchActionPlaylists = (accountId: number) =>
  useQuery({
    ...actionsKeys.playlists(accountId),
    queryFn: async () => {
      const response = await api.get<Array<{ id: string; title: string }>>(
        `account/${accountId}/playlists`,
      );
      return response.data;
    },
  });
