import { useExtraPostState } from "@common/features/Post";
import { regular } from "@common/helpers/fontawesome";
import { __ } from "@common/helpers/i18n";
import { SectionToggleProps } from "@common/types";
import { ElementsGroup, SectionToggle } from "@app/elements/container";
import { TextInput } from "@app/elements/form";
import { SocialIcon } from "@app/elements/social";

export default function PinterestSection(props: Partial<SectionToggleProps>) {
  const [data, dispatch] = useExtraPostState("Pinterest");
  return (
    <SectionToggle
      {...props}
      title={__("Pinterest settings")}
      icon={<SocialIcon social="Pinterest" />}
      defaultOpen={!!data.pinterest_url || !!data.pinterest_title}
    >
      <ElementsGroup>
        <TextInput
          placeholder={__("Enter the Pinterest title")}
          onInputChange={(url) => dispatch("pinterest_title", url)}
          value={data.pinterest_title || ""}
          icon={regular("tag")}
          name="pinterest_title"
        />
        <TextInput
          placeholder={__("Enter the full address of the Pinterest link")}
          onInputChange={(url) => dispatch("pinterest_url", url)}
          value={data.pinterest_url || ""}
          icon={regular("link")}
          name="pinterest_link"
        />
      </ElementsGroup>
    </SectionToggle>
  );
}
