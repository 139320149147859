import moment from "moment-jalaali";
import React, { ReactNode, useEffect } from "react";
import { Account, useFetchTeamAccounts } from "@common/features/Account";
import PostSaveSection from "@common/features/Post/components/PostSaveSection";
import { SocialNetwrokType } from "@common/features/Social";
import {
  useFeatureSupports,
  useHasTeammatePermissions,
} from "@common/features/User";
import { withExpiredOrPermission } from "@common/helpers/Expired";
import { regular } from "@common/helpers/fontawesome";
import { __, toMoment } from "@common/helpers/i18n";
import { useThemeTitle } from "@common/hooks";
import { ElementsGroup, EmptyPage } from "@app/elements/container";
import MediaPreview from "@app/pages/post/MediaPreview";
import { PostMain } from "../components/PostMain";
import { useFetchPostGroupDetail } from "../queries/posts";
import { PostStoreProvider, usePostStore } from "../store/post";

type PostProviderProp = {
  date?: string | null;
  caption?: string | null;
  from?: number;
  postId?: number;
  children?: (props: { children: ReactNode }) => JSX.Element;
  Sidebar: React.ComponentType;
};

function Post({
  caption,
  date,
  from,
  postId,
  children,
  Sidebar,
}: PostProviderProp) {
  const [, dispatch] = usePostStore(() => null);

  const featureSupports = useFeatureSupports();

  const { data: accounts } = useFetchTeamAccounts((accounts) =>
    accounts.filter(
      (item) =>
        item.login_required == 0 && !featureSupports.hidden.includes(item.type),
    ),
  );

  const { data: postGroup } = useFetchPostGroupDetail(
    accounts !== undefined ? postId : undefined,
  );

  const { data: fromPostGroup } = useFetchPostGroupDetail(
    accounts !== undefined && from ? from : undefined,
  );

  useEffect(() => {
    dispatch({ type: "reset" });
  }, [postId, dispatch]);

  useEffect(() => {
    if (postGroup && accounts) {
      dispatch({ type: "initialFromPost", payload: { postGroup, accounts } });
    }
  }, [postGroup, accounts, dispatch]);

  useEffect(() => {
    if (fromPostGroup) {
      dispatch({
        type: "initialFromPost",
        payload: { postGroup: fromPostGroup },
      });
    }
  }, [fromPostGroup, dispatch]);

  useEffect(() => {
    if (date) {
      const today = moment();
      dispatch({
        type: "schedule",
        payload: toMoment(date, "YYYY/MM/DD").set({
          hour: today.hour(),
          minute: today.minute(),
        }),
      });
    } else if (caption) {
      dispatch({
        type: "initialCaption",
        payload: caption || "",
      });
    }
  }, [date, caption, dispatch]);

  const Children = children || React.Fragment;

  return (
    <>
      <Sidebar />
      <Children>
        {accounts === undefined ? null : accounts?.length ? (
          <>
            <PostMain />
            <ElementsGroup>
              <PostSaveSection postGroup={postGroup} />
              <MediaPreview />
            </ElementsGroup>
          </>
        ) : (
          <EmptyPage
            text={__("There is not account connected.")}
            icon={regular("plug")}
            showAddAccount
            hideOnSmall
          />
        )}
      </Children>
    </>
  );
}

export const checkTypeExist = (
  accounts: Account[],
  typeSearch: SocialNetwrokType,
) => accounts.some((account) => account.type === typeSearch);

export const checkTypeExistArray = (
  accounts: Account[],
  typeSearch: SocialNetwrokType[],
) => accounts.some((account) => typeSearch.includes(account.type));

export const PostProvider = withExpiredOrPermission(
  (props: PostProviderProp) => (
    <PostStoreProvider>
      <Post {...props} />
    </PostStoreProvider>
  ),
  () => {
    useThemeTitle(__("title.post"));

    const hasPermission = useHasTeammatePermissions("post_schedule");

    return hasPermission;
  },
);
