import { Switch } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { api } from "@common/helpers/api";
import { useLocalStorage } from "@app/hooks";

export default function HeaderLiveModeToggle() {
  const queryClient = useQueryClient();

  const [liveMode, setLiveMode] = useLocalStorage("live-mode", false);

  useEffect(() => {
    const newHeader = liveMode ? "1" : undefined;

    if (newHeader !== api.defaults.headers["X-Live-Mode"]) {
      api.defaults.headers["X-Live-Mode"] = newHeader || false;
      queryClient.invalidateQueries();
    }
  }, [liveMode, queryClient]);

  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch.Label className="me-2 flex items-center space-s-1">
          <div>Live mode</div>
        </Switch.Label>
        <Switch
          onChange={setLiveMode}
          checked={liveMode}
          className={({ checked }) =>
            `${
              checked ? "bg-primary-500" : "bg-gray-200 dark:bg-gray-400"
            } relative inline-flex h-4 w-8 items-center rounded-full transition-colors`
          }
        >
          {({ checked }) => (
            <span
              className={`${
                checked
                  ? "ltr:translate-x-4 rtl:-translate-x-4"
                  : "ltr:translate-x-1 rtl:-translate-x-1"
              } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
            />
          )}
        </Switch>
      </div>
    </Switch.Group>
  );
}
