import axios from "axios";
import { ErrorInfo, useState } from "react";
import { api } from "@common/helpers/api";

export default function useReportError(
  error: Error,
  info: ErrorInfo,
  getUrl: () => string,
) {
  const [traceId, setTraceId] = useState<null | string>(null);

  if (traceId) return traceId;

  if (axios.isAxiosError(error)) {
    setTraceId(error.response?.data?.error?.trace_id || "");
  } else {
    api
      .post("error", {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        info,
        stack: error.stack,
        url: getUrl(),
      })
      .then((response) => {
        setTraceId(response?.data?.error?.trace_id || "");
      });
  }
  if (typeof document !== "undefined")
    (document as any).getElementsByClassName("splash")[0]?.remove();

  return traceId;
}
