import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Link, useHistory, useLocation } from "react-router-dom";
import { __ } from "@common/helpers/i18n";
import { checkIfActive } from "@app/helpers/menu";
import { ChildMenu } from "@app/layout/Menu";
import { Select } from "../form";

export type SubMenuItemsProps = {
  items: ChildMenu[];
  baseUrl?: string;
  showSelectOnSmall?: boolean;
};

export default function SubMenuItems({
  items,
  baseUrl,
  showSelectOnSmall,
}: SubMenuItemsProps) {
  const history = useHistory();
  const location = useLocation();

  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  const activeChild = items.find((child) =>
    checkIfActive(location, [baseUrl + child.url], child.exact),
  );

  return (
    <>
      <div className="block space-y-1">
        {items.map((child, index) => {
          const url = baseUrl + child.url;

          const isActive = activeChild === child;
          return (
            <Link
              key={index}
              to={!child.external ? url : { pathname: child.url }}
              target={child.external ? "_blank" : undefined}
              className={classNames(
                isActive
                  ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white"
                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 hover:dark:bg-gray-700",
                "group flex items-center rounded-md px-3 py-2 font-semibold",
              )}
              aria-current={!isActive ? "page" : undefined}
            >
              {child.icon && (
                <FontAwesomeIcon
                  className={classNames(
                    !child.icon
                      ? "text-gray-500"
                      : "text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300",
                    "me-3 h-6 w-6 flex-shrink-0",
                  )}
                  aria-hidden="true"
                  icon={child.icon}
                />
              )}
              <span className="truncate text-gray-900 dark:text-gray-300">
                {__(child.label as any)}
              </span>
              {child.unread ? (
                <span
                  className={classNames(
                    !child.icon
                      ? "bg-white"
                      : "bg-gray-100 group-hover:bg-gray-200",
                    "ms-auto inline-block rounded-full px-3 py-0.5 text-xs",
                  )}
                >
                  {child.unread()}
                </span>
              ) : null}
            </Link>
          );
        })}
      </div>
      {showSelectOnSmall &&
        domReady &&
        createPortal(
          <div className="relative z-30 mx-3 mt-3 lg:hidden w-full">
            <Select
              onChange={(child) => {
                const url = baseUrl + child.url;

                history.push(url);
              }}
              options={items}
              placeholder={__("Status")}
              value={activeChild}
              getLabel={(child) => (
                <div className="flex items-center">
                  {child.icon && (
                    <FontAwesomeIcon
                      className="me-3 h-4 w-4 flex-shrink-0"
                      aria-hidden="true"
                      icon={child.icon}
                    />
                  )}
                  <span>{__(child.label as any)}</span>
                </div>
              )}
              getKey={(child) => child.url}
            />
          </div>,
          document.getElementById("start-menu")!,
        )}
    </>
  );
}
