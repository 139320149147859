import {
  InsightsResponse,
  ProcessData,
  backgroundColors,
} from "@common/features/Insights";
import { AccountPost } from "@common/features/Social";
import { __ } from "@common/helpers/i18n";

interface InsightsData {
  growth: { data: Highcharts.Options; summary: number };
  engagement: { data: Highcharts.Options; summary: number };
  mention: Highcharts.Options;
  top_hashtag: Highcharts.Options;
  mediatype: Highcharts.Options;
  share_total: { data: Highcharts.Options; summary: number };
  bestHour?: [string, string];

  total: {
    page_follow_total?: number;
    growth?: number;
    average_engagement?: number;
    sum_share?: number;
    average_post_daily?: number;
    total_posts_sent?: number;
  };

  top_post: AccountPost[];

  emptyData: boolean;
}

export function TwitterSelector<TMetric extends string | undefined = undefined>(
  _response: InsightsResponse,
  metric?: TMetric,
): TMetric extends string ? Highcharts.Options : InsightsData {
  const data: Partial<InsightsData> = {};

  if (typeof metric === "undefined") {
    data.total = {};
  }

  _response.insights.forEach((response) => {
    if (!response.data) {
      return;
    }

    switch (response.metric) {
      case "page_follow_growth": {
        const growth = ProcessData.genericMetric(response.data);

        data.growth = {
          data: {
            xAxis: {
              categories: growth.label,
            },
            series: [
              {
                data: growth.data,
                type: "column",
                name: __("Twitter"),
                color: backgroundColors.Twitter[1],
                edgeColor: backgroundColors.Twitter[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        if (!metric) {
          data.total!.growth = ProcessData.sumSummary(response.data);
        }
        break;
      }
      case "engagement": {
        const engagement = ProcessData.genericMetric(response.data);

        data.engagement = {
          data: {
            xAxis: {
              categories: engagement.label,
            },
            series: [
              {
                type: "line",
                name: __("Group Engagement"),
                data: engagement.data,
                negativeColor: backgroundColors.Twitter[0],
                color: backgroundColors.Twitter[1],
                borderColor: backgroundColors.Twitter[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };

        data.mention = {
          xAxis: {
            categories: engagement.label,
          },
          series: [
            {
              type: "line",
              name: __("Mention/comment"),
              data: engagement.data,
              negativeColor: backgroundColors.Twitter[0],
              color: backgroundColors.Twitter[1],
              borderColor: backgroundColors.Twitter[1],
            },
          ],
        };

        if (!metric) {
          data.total!.average_engagement = ProcessData.avgSummary(
            response.data,
          );
        }
        break;
      }
      case "share_total": {
        const share_total = ProcessData.genericMetric(response.data);
        data.share_total = {
          data: {
            xAxis: {
              categories: share_total.label,
            },
            series: [
              {
                type: "line",
                name: __("Retweet"),
                data: share_total.data,
                negativeColor: backgroundColors.Twitter[0],
                color: backgroundColors.Twitter[1],
                borderColor: backgroundColors.Twitter[1],
              },
            ],
          },
          summary: ProcessData.summaryDiff(response),
        };
        break;
      }
      case "top_post": {
        data.top_post = response.data;
        break;
      }
      case "num_posts_by_type": {
        const mediatype = ProcessData.numPostsByType(response.data);
        data.mediatype = {
          xAxis: {
            categories: mediatype.label,
          },
          series: [
            {
              type: "column",
              name: __("Text"),
              data: mediatype.data.text,
              color: backgroundColors.Text[0],
              colorAxis: backgroundColors.Text[1],
              borderColor: backgroundColors.Text[1],
            },
            {
              type: "column",
              name: __("Image"),
              data: mediatype.data.image,
              color: backgroundColors.Image[0],
              colorAxis: backgroundColors.Image[1],
              borderColor: backgroundColors.Image[1],
            },
            {
              type: "column",
              name: __("Video"),
              data: mediatype.data.video,
              color: backgroundColors.Video[0],
              colorAxis: backgroundColors.Video[1],
              borderColor: backgroundColors.Video[1],
            },
            {
              type: "column",
              name: __("Album"),
              data: mediatype.data.text,
              color: backgroundColors.Album[0],
              colorAxis: backgroundColors.Album[1],
              borderColor: backgroundColors.Album[1],
            },
            {
              type: "column",
              name: __("Story"),
              data: mediatype.data.story,
              color: backgroundColors.Story[0],
              colorAxis: backgroundColors.Story[1],
              borderColor: backgroundColors.Story[1],
            },
          ],
          plotOptions: {
            column: {
              stacking: "normal",
            },
          },
        };
        break;
      }
      case "top_hashtag": {
        data.top_hashtag =
          Object.keys(response.data).length !== 0
            ? {
                series: [
                  {
                    type: "wordcloud",
                    data: ProcessData.hashtag(response.data),
                    name: __("Hashtag"),
                  },
                ],
              }
            : undefined;
        break;
      }
      case "page_follow_total":
      case "average_post_daily":
      case "sum_share":
      case "total_posts_sent": {
        data.total![response.metric] = response.data;
        break;
      }
    }
  });

  if (metric === "mention") return data.mention as any;

  data.emptyData = _response.insights.length === 0;
  return metric ? Object.values(data)[0] : (data as any);
}
